import { FC } from 'react';
import styled from '@ui/styles/styled';
import Container from '@ui/Container';
import Card from '@ui/Card';
import CardContent from '@material-ui/core/CardContent';

import { lilmUrl } from '@core/config/Url';
import LogoDark from '../../../assets/images/logo-lilm-dark.png';

import {
    authContainerStyle,
    authLogoLinkStyle,
    authCardStyle,
    authCardContentStyle,
} from './styles';

const StyledAuthContainer = styled(Container)(authContainerStyle);
const StyledAuthLogoLink = styled('a')(authLogoLinkStyle);
const StyledAuthCard = styled(Card)(authCardStyle);
const StyledAuthCardContent = styled(CardContent)(authCardContentStyle);

const AuthContainer: FC = ({ children }) => (
    <StyledAuthContainer maxWidth="sm">
        <StyledAuthLogoLink href={lilmUrl} target="_blank">
            <img src={LogoDark} alt="Logo LILM" />
        </StyledAuthLogoLink>

        <StyledAuthCard elevation={0}>
            <StyledAuthCardContent>
                {children}
            </StyledAuthCardContent>
        </StyledAuthCard>
    </StyledAuthContainer>
);

export default AuthContainer;
