import ReactDOM from 'react-dom';
import * as WebFont from 'webfontloader';
import App from './view/App';

export default () => {
    ReactDOM.render(
        <App />,
        document.querySelector('#root'),
    );

    WebFont.load({
        google: {
            families: ['Fjalla One', 'Lato:400,700,900', 'Montserrat:400,600,700&display=swap'],
        },
    });
};
