/** Country Ids */
export enum CountryId {
    FR = 'FR',
    CH = 'CH',
    BE = 'BE',
    LU = 'LU',
    IT = 'IT',
}

export const deliveryCountryIds = [
    CountryId.FR,
    CountryId.BE,
    CountryId.CH,
    CountryId.LU,
];

export const artisanCountryIds = [
    CountryId.FR,
    CountryId.BE,
    CountryId.CH,
    CountryId.LU,
    CountryId.IT,
];

export default CountryId;
