import Country from '@core/constants/Country';
import PersonaId from '@core/constants/Persona';

export enum IUserKey {
    Id = 'id',
    Role = 'role',
    Password = 'password',
    CreationDate = 'creationDate',
    LastUpdateDate = 'lastUpdateDate',
    LastActionDate = 'lastActionDate',
    Persona = 'persona',
    FirstName = 'firstName',
    LastName = 'lastName',
    Email = 'email',
    EmailVerified = 'emailVerified',
    Phone = 'phone',
    ShippingAddress = 'shippingAddress',
    ShippingAddressCity = 'shippingAddressCity',
    ShippingAddressZip = 'shippingAddressZip',
    ShippingAddressCountry = 'shippingAddressCountry',
    ShippingAddressFloor = 'shippingAddressFloor',
    ShippingAddressAccess = 'shippingAddressAccess',
}

export enum IUserRole {
    User = 'user',
    Admin = 'admin',
}

export interface IUserBase {
    [IUserKey.Id]?: string
    [IUserKey.Role]: IUserRole
    [IUserKey.Password]?: string
    [IUserKey.CreationDate]?: Date
    [IUserKey.LastUpdateDate]?: Date
    [IUserKey.LastActionDate]?: Date
    [IUserKey.FirstName]?: string
    [IUserKey.LastName]?: string
    [IUserKey.Email]: string
    [IUserKey.EmailVerified]?: boolean
    [IUserKey.Phone]?: string
    [IUserKey.ShippingAddress]?: string
    [IUserKey.ShippingAddressCity]?: string
    [IUserKey.ShippingAddressZip]?: string
    [IUserKey.ShippingAddressCountry]?: Country
    [IUserKey.ShippingAddressFloor]?: string
}

export interface IUserCredentials {
    [IUserKey.Password]: string
    [IUserKey.Email]: string
}

export interface IUserUser extends IUserBase {
    [IUserKey.Role]: IUserRole.User
    [IUserKey.Persona]?: PersonaId
}

export interface IUserAdmin extends IUserBase {
    [IUserKey.Role]: IUserRole.Admin
}

export type IUser =
    | IUserUser
    | IUserAdmin;
