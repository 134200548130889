export enum RouteKey {
    Account = 'account',
    Artisan = 'artisan',
    ArtisanApp = 'artisanApp',
    ArtisanCompany = 'artisanCompany',
    ArtisanDedicatedApp = 'artisanDedicatedApp',
    ArtisanDedicatedAppForm = 'artisanDedicatedAppForm',
    ArtisanDedicatedAppFormGroup = 'artisanDedicatedAppFormGroup',
    ArtisanDedicatedAppFormGroupStep = 'artisanDedicatedAppFormGroupStep',
    ArtisanDedicatedAppFormStep = 'artisanDedicatedAppFormStep',
    ArtisanDedicatedAppTmpSubmission = 'artisanDedicatedAppTmpSubmission',
    ArtisanDelivery = 'artisanDelivery',
    ArtisanForm = 'artisanForm',
    ArtisanFormGroup = 'artisanFormGroup',
    ArtisanFormGroupStep = 'artisanFormGroupStep',
    ArtisanForms = 'artisanForms',
    ArtisanFormStep = 'artisanFormStep',
    ArtisanTmpSubmission = 'artisanTmpSubmission',
    ArtisanHome = 'artisanHome',
    ArtisanMarketPlace = 'artisanMarketPlace',
    ArtisanMaterials = 'artisanMaterials',
    ArtisanNewPasswordRequest = 'artisanNewPasswordRequest',
    ArtisanOnboarding = 'artisanOnboarding',
    ArtisanPortfolio = 'artisanPortfolio',
    ArtisanProjectForm = 'artisanProjectForm',
    ArtisanProjectFormTmpSubmission = 'artisanProjectFormTmpSubmission',
    ArtisanPortfolioHome = 'artisanPortfolioHome',
    ArtisanPortfolioAdd = 'artisanPortfolioAdd',
    ArtisanPortfolioEdit = 'artisanPortfolioEdit',
    ArtisanProfile = 'artisanProfile',
    ArtisanRequests = 'artisanRequests',
    ArtisanServices = 'artisanServices',
    ArtisanSettings = 'artisanSettings',
    ArtisanStats = 'artisanStats',
    Form = 'form',
    FormGroup = 'formGroup',
    FormGroupStep = 'formGroupStep',
    FormRequest = 'formRequest',
    FormRequestRedirect = 'formRequestRedirect',
    FormRequests = 'formRequests',
    Forms = 'forms',
    FormStep = 'formStep',
    FormSubmission = 'formSubmission',
    FormTmpSubmission = 'formTmpSubmission',
    Landing = 'landing',
    PasswordReset = 'passwordReset',
    PublicSubmission = 'publicSubmission',
    PureSubmission = 'pureSubmission',
    Sales = 'sales',
    SalesArtisans = 'salesArtisans',
    SalesArtisansProjects = 'salesArtisansProjects',
    SalesRequests = 'salesRequests',
    SalesSubmissions = 'salesSubmissions',
    SalesSubmission = 'salesSubmission',
    SignIn = 'signIn',
    SignOut = 'signOut',
    SignUp = 'signUp',
    Success = 'success',
    SuccessArtisanForms = 'successArtisanForms',
    SuccessArtisanDedicatedApp = 'successArtisanDedicatedApp',
    Welcome = 'welcome',
}

export const Routes: Readonly<{ [key in RouteKey]: string}> = {
    [RouteKey.Landing]                    : '/',
    [RouteKey.SignUp]                     : '/signup',
    [RouteKey.SignIn]                     : '/signin',
    [RouteKey.SignOut]                    : '/signout',
    [RouteKey.Success]                    : '/success/:formId/:submissionId',
    [RouteKey.SuccessArtisanForms]        : '/successArtisanForm/:formId/:submissionId/:artisanSlug',
    [RouteKey.SuccessArtisanDedicatedApp] : '/successDedicatedApp/:formId/:submissionId/:artisanSlug',
    [RouteKey.Welcome]                    : '/',
    [RouteKey.Account]                    : '/account',
    [RouteKey.PasswordReset]              : '/password-reset',

    [RouteKey.Sales]                 : '/sales',
    [RouteKey.SalesArtisansProjects] : '/artisans-projects',
    [RouteKey.SalesArtisans]         : '/artisans/:artisanId?/:tabId?',
    [RouteKey.SalesRequests]         : '/requests/:requestId?',
    [RouteKey.SalesSubmissions]      : '/submissions',
    [RouteKey.SalesSubmission]       : '/submissions/:submissionId',

    [RouteKey.ArtisanNewPasswordRequest]: '/artisan-new-password-request',

    [RouteKey.ArtisanOnboarding]: '/artisan-signup/:step?',

    [RouteKey.Artisan]              : '/admin',
    [RouteKey.ArtisanHome]          : '/',
    [RouteKey.ArtisanRequests]      : '/requests/:requestId?/:action?',
    [RouteKey.ArtisanMarketPlace]   : '/submissions/:submissionId?',
    [RouteKey.ArtisanStats]         : '/stats',
    [RouteKey.ArtisanServices]      : '/services',
    [RouteKey.ArtisanProfile]       : '/profile',
    [RouteKey.ArtisanSettings]      : '/options',
    [RouteKey.ArtisanDelivery]      : '/delivery',
    [RouteKey.ArtisanMaterials]     : '/materials',
    [RouteKey.ArtisanCompany]       : '/company',
    [RouteKey.ArtisanApp]           : '/app',
    [RouteKey.ArtisanPortfolio]     : '/portfolio',
    [RouteKey.ArtisanPortfolioHome] : '',
    [RouteKey.ArtisanPortfolioAdd]  : '/add',
    [RouteKey.ArtisanPortfolioEdit] : '/edit/:artisanProjectId?',

    [RouteKey.FormRequests]: '/requests',

    [RouteKey.Forms]             : '/forms',
    [RouteKey.Form]              : '/forms/:formId/',
    [RouteKey.FormStep]          : '/forms/:formId/step/:stepId',
    [RouteKey.FormTmpSubmission] : '/forms/:formId/step/:stepId/tmp/:tmpSubmissionId',

    [RouteKey.ArtisanProjectForm]              : '/artisanProjectForm/:formId/:artisanProjectId',
    [RouteKey.ArtisanProjectFormTmpSubmission] : '/artisanProjectForm/:formId/:artisanProjectId/step/:stepId/tmp/:tmpSubmissionId',

    [RouteKey.FormGroup]     : '/formGroup/:formGroupId/:formId?/',
    [RouteKey.FormGroupStep] : '/formGroup/:formGroupId/:formId/step/:stepId',

    [RouteKey.ArtisanForms]         : '/artisanForms/:artisanSlug',
    [RouteKey.ArtisanFormGroup]     : '/artisanFormGroup/:artisanSlug/:formGroupId/:formId?/',
    [RouteKey.ArtisanFormGroupStep] : '/artisanFormGroup/:artisanSlug/:formGroupId/:formId/step/:stepId',
    [RouteKey.ArtisanForm]          : '/artisanForms/:artisanSlug/:formId/',
    [RouteKey.ArtisanFormStep]      : '/artisanForms/:artisanSlug/:formId/step/:stepId',
    [RouteKey.ArtisanTmpSubmission] : '/artisanForms/:artisanSlug/:formId/step/:stepId/tmp/:tmpSubmissionId',

    [RouteKey.ArtisanDedicatedApp]              : '/app/:artisanSlug',
    [RouteKey.ArtisanDedicatedAppFormGroup]     : '/app/:artisanSlug/:formGroupId/:formId?/',
    [RouteKey.ArtisanDedicatedAppFormGroupStep] : '/app/:artisanSlug/:formGroupId/:formId/step/:stepId',
    [RouteKey.ArtisanDedicatedAppForm]          : '/app/:artisanSlug/:formId/',
    [RouteKey.ArtisanDedicatedAppFormStep]      : '/app/:artisanSlug/:formId/step/:stepId',
    [RouteKey.ArtisanDedicatedAppTmpSubmission] : '/app/:artisanSlug/:formId/step/:stepId/tmp/:tmpSubmissionId',

    [RouteKey.FormSubmission]      : '/forms/:formId/submissions/:submissionId',
    [RouteKey.FormRequestRedirect] : '/forms/:formId/submissions/:submissionId/requests/:requestId',
    [RouteKey.FormRequest]         : '/forms/:formId/requests/:requestId',

    [RouteKey.PublicSubmission] : '/public-submission/:publicSubmissionId',
    [RouteKey.PureSubmission]   : '/pure-submission/:submissionId',
};

export default Routes;
