export enum ShippingFloorId {
    Rdc = '0',
    First = '1',
    Second = '2',
    Third = '3',
    Fourth = '4',
    Fifth = '5',
    FifthPlus = '6+',
}

export enum ShippingAccessId {
    Easy = 'easy',
    Hard = 'hard',
}
