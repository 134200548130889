import { ShapeId } from '@core/constants/Shape';

export const ShapesFR: { [key in ShapeId]: { name: string, label?: string } } = {
    airplaneWings               : { name: "Aile d'avion" },
    barHangerCloth              : { name: 'Penderie' },
    bedCasters                  : { name: 'Roulettes' },
    bedDouble                   : { name: 'Lit 2 places' },
    bedHead                     : { name: 'Tête de lit' },
    bedLighting                 : { name: 'Eclairage' },
    bedRound                    : { name: 'Lit rond' },
    bedSafe                     : { name: 'Coffre intégré' },
    bedSingle                   : { name: 'Lit 1 place' },
    bedStorage                  : { name: 'Rangements intégrés' },
    beltHanger                  : { name: 'Porte ceinture' },
    butterflyExtension          : { name: 'Papillon' },
    cableGland                  : { name: 'Passe-câble' },
    centralExtension            : { name: 'Centrale' },
    centralLimon                : { name: 'Limon central' },
    classicHandle               : { name: 'Poignée classique', label: '€' },
    claustraFixed               : { name: 'Fixe' },
    claustraIndoor              : { name: 'Intérieur' },
    claustraMobile              : { name: 'Mobile' },
    claustraOpenWork            : { name: 'Paroi Ajourée' },
    claustraOutdoor             : { name: 'Extérieur' },
    claustraSemiMobile          : { name: 'Semi mobile' },
    claustraSolid               : { name: 'Paroi pleine' },
    closetFolding               : { name: 'Pliante' },
    closetHandle                : { name: 'Poignée' },
    closetLock                  : { name: 'Serrure' },
    closetNormal                : { name: 'Normal' },
    closetSliding               : { name: 'Coulissante' },
    closetSlopeLeft             : { name: 'Pente à gauche' },
    closetSlopeRight            : { name: 'Pente à droite' },
    closetSwing                 : { name: 'Battante' },
    cremoneClosure              : { name: 'Fermeture crémone' },
    deckBoardGroove             : { name: 'Lames rainurées' },
    deckBoardSmooth             : { name: 'Lames lisses' },
    deckBoardVein               : { name: 'Lames veinées' },
    diagonalRoom                : { name: 'Diagonale' },
    door                        : { name: 'Porte' },
    doubleDoor                  : { name: 'Porte double' },
    doubleDoorGlassWall         : { name: 'Porte double' },
    doubleRoofVeranda           : { name: 'Toit double' },
    doubleShowerScreen          : { name: 'Paroi de douche avec porte' },
    doubleSwingDoors            : { name: 'Porte battante double' },
    doubleWindow                : { name: 'Fenêtre double' },
    doubleWindowDoor            : { name: 'Porte fenêtre double' },
    doubleWindowsGlassWall      : { name: 'Fenêtre double' },
    drainer                     : { name: 'Nombre de rainures' },
    drawer                      : { name: 'Tiroir' },
    drawerCase                  : { name: 'Tiroir compartimenté' },
    eleConnector                : { name: 'Prise électrique' },
    ethernetConnector           : { name: 'Prise Ethernet/RJ45' },
    facadeIntegratedhandle      : { name: 'Poignée intégrée', label: '€€' },
    flatRoofVeranda             : { name: 'Toit plat' },
    fenceFillingUniform         : { name: 'Uniforme' },
    fenceFillingSimpleSmooth    : { name: 'Simple Lisse' },
    fenceFillingDoubleSmooth    : { name: 'Double Lisse' },
    fenceMezzanine              : { name: 'Garde-corps Mezzanine' },
    fenceRoundPoles             : { name: 'Poteau rond' },
    fenceSquarePoles            : { name: 'Poteau carré' },
    fenceStaircase              : { name: 'Garde-corps escalier' },
    fenceTerrace                : { name: 'Garde-corps terrasse' },
    fenceWindow                 : { name: 'Garde-corps fenêtre' },
    fencingOpenWork             : { name: 'Ajouré' },
    fencingSolid                : { name: 'Plein' },
    fencingOther                : { name: 'Autre' },
    flatAndBevelGroove          : { name: 'Gorge classique', label: '€€€' },
    frameRectangle              : { name: 'Rectangle' },
    frameRound                  : { name: 'Rond' },
    frameSquare                 : { name: 'Carré' },
    halfCircleHighWind          : { name: 'Marquise en arc de cercle' },
    handleOnEdge                : { name: 'Poignée sur chant', label: '€' },
    hdmiConnector               : { name: 'Prise HDMI' },
    hexagonal                   : { name: 'Hexagonale' },
    highWindDoor                : { name: 'Marquise de porte' },
    highWindWindow              : { name: 'Marquise de fenêtre' },
    horizontal                  : { name: 'Horizontale' },
    integratedAppliance         : { name: 'Découpe pour plaque' },
    integratedSink              : { name: 'Découpe évier' },
    integratedStorage           : { name: 'Rangements intégrés' },
    kitchenBakingTray           : { name: 'Plaques de cuisson' },
    kitchenCook                 : { name: 'Cuisinière' },
    kitchenDishwasher           : { name: 'Lave-vaisselle' },
    kitchenFridge               : { name: 'Réfrigérateur' },
    kitchenHood                 : { name: 'Hotte' },
    kitchenMicroWave            : { name: 'Micro-ondes' },
    kitchenOven                 : { name: 'Four' },
    kitchenSink                 : { name: 'Évier' },
    kitchenColumn               : { name: 'Colonne' },
    kitchenLowFurniture         : { name: 'Élément bas' },
    kitchenTallFurniture        : { name: 'Élément haut' },
    kitchenArtDeco              : { name: 'Art-déco' },
    kitchenFullBlack            : { name: 'Full black' },
    kitchenIndustrial           : { name: 'Industriel' },
    kitchenIndustrialLoft       : { name: 'Loft industriel' },
    kitchenJapandi              : { name: 'Japandi' },
    kitchenMinimalist           : { name: 'Minimalist' },
    kitchenModern               : { name: 'Moderne' },
    kitchenNatural              : { name: 'Naturelle' },
    kitchenPastel               : { name: 'Pastel' },
    kitchenRural                : { name: 'Champêtre' },
    kitchenRusticChic           : { name: 'Rustique chic' },
    kitchenRusticModern         : { name: 'Rustique moderne' },
    kitchenScandinavian         : { name: 'Scandinave' },
    kitchenStrikingMarble       : { name: 'Marbre saisissant' },
    kitchenTerracotta           : { name: 'Terracotta' },
    kitchenTerrazzo             : { name: 'Terrazzo' },
    kitchenTraditional          : { name: 'Traditionelle' },
    kitchenVegetated            : { name: 'Vegetalisée' },
    kitchenWineCellar           : { name: 'Cave à vin' },
    lateralLimon                : { name: 'Limon latéral' },
    layoutIsland                : { name: 'Implantation avec îlot' },
    layoutL                     : { name: 'Implantation en L' },
    layoutParallel              : { name: 'Implantation parallèle' },
    layoutStraight              : { name: 'Implantation linéaire' },
    layoutU                     : { name: 'Implantation en U' },
    legsAdventurer              : { name: 'Aventurier' },
    legsClassic                 : { name: 'Classiques' },
    legsCross                   : { name: 'En croix' },
    legsGreedy                  : { name: 'Gourmands' },
    legsHairPin                 : { name: 'En épingle à cheveux' },
    legsHarmonious              : { name: 'Harmonieux' },
    legsMalicious               : { name: 'Malicieux' },
    legsMikado                  : { name: 'Mikado' },
    legsMonastery               : { name: 'Pied Monastère' },
    legsOther                   : { name: 'Autre' },
    legsRider                   : { name: 'Cavalier' },
    legsRightCrossPiece         : { name: 'Droit avec traverse' },
    legsRoundLegs               : { name: 'Rond' },
    legsScandinavian            : { name: 'Scandinave' },
    legsSquare                  : { name: 'Carré' },
    legsSquareCrossPiece        : { name: 'Carré avec traverse' },
    legsTrapeze                 : { name: 'Trapèze' },
    legsTrapezeCentral          : { name: 'Trapèze central' },
    legsTrapezeInverted         : { name: 'Trapèze inversé' },
    legsTrestleCross            : { name: 'Tréteau avec traverse' },
    legsUShaped                 : { name: 'En U' },
    legsUShapedStrengthened     : { name: 'En U renforcé' },
    legsVShaped                 : { name: 'En V sur socle' },
    legsVShapedCentral          : { name: 'En V central' },
    lightedSteps                : { name: 'Éclairage marches' },
    lightRamp                   : { name: 'Éclairage rampes' },
    liveEdge                    : { name: 'Bords naturels ou Live-Edge' },
    lowTableDocumentHolder      : { name: 'Porte-documents' },
    lowTableDoubleTray          : { name: 'Double plateau' },
    lowTableDrawer              : { name: 'Tiroirs' },
    lowTableLed                 : { name: 'LED' },
    lowTableLiftableTray        : { name: 'Plateau relevable' },
    lowTableOpenStorage         : { name: 'Rangement ouvert' },
    lowTableSlidingStorage      : { name: 'Rangement coulissant' },
    lRoom                       : { name: 'Renfoncement' },
    lShape                      : { name: 'Forme en L' },
    lTerraceShape               : { name: 'Forme en L' },
    magnetClosure               : { name: 'Fermeture aimantée' },
    mezzanineSingle             : { name: 'Lit 1 place' },
    mezzanineDouble             : { name: 'Lit 2 places' },
    molding                     : { name: 'Moulures' },
    niche                       : { name: 'Niche' },
    nicheLarge                  : { name: 'Niche grande' },
    nicheMedium                 : { name: 'Niche moyenne' },
    officeUnderMezzanine        : { name: 'Bureau sous mezzanine' },
    offsetExtension             : { name: 'Déportées' },
    ovalMirror                  : { name: 'Miroir ovale' },
    pantsHanger                 : { name: 'Porte pantalon' },
    pergolaLeaned               : { name: 'Adossée' },
    pergolaSelfSupporting       : { name: 'Auto-portée' },
    pergolaLighting             : { name: 'Eclairage' },
    pergolaHeater               : { name: 'Chauffage' },
    pergolaStore                : { name: 'Store' },
    portalConcave               : { name: 'Portail concave' },
    portalConvex                : { name: 'Portail convexe' },
    portalDiagonalHight         : { name: 'Portail en biais haut' },
    portalDiagonalLow           : { name: 'Portail en biais bas' },
    portalOneLeave              : { name: '1 vantail' },
    portalOpeningSlide          : { name: 'Coulissant' },
    portalOpeningSwing          : { name: 'Battant' },
    portalOpenWork              : { name: 'Ajouré' },
    portalPolicemanHat          : { name: 'Portail chapeau de gendarme' },
    portalPolicemanHatInverted  : { name: 'Portail chapeau de gendarme inversé' },
    portalSolid                 : { name: 'Plein' },
    portalStraight              : { name: 'Portail droit' },
    portalTowLeave              : { name: '2 vantaux' },
    portalIntercom              : { name: 'Interphone' },
    portalLighting              : { name: 'Éclairage' },
    portalVideophone            : { name: 'Visiophone' },
    pushPullHandle              : { name: 'Poignée push & pull', label: '€€' },
    radiator                    : { name: 'Radiateur' },
    rectangleMirror             : { name: 'Miroir rectangulaire' },
    rectangleRoom               : { name: 'Rectangle' },
    rectangleShape              : { name: 'Forme rectangulaire' },
    rectangleTerraceShape       : { name: 'Forme rectangulaire' },
    returnLeft                  : { name: 'Angle avec retour à gauche' },
    returnRight                 : { name: 'Angle avec retour à droite' },
    roolUpDoor                  : { name: 'Enroulable' },
    roundedHalfCircle           : { name: 'Arrondis en demi-cercle' },
    roundedHighWind             : { name: 'Marquise arrondi' },
    roundedQuarterCircle        : { name: 'Arrondis en quart de cercle' },
    roundMirror                 : { name: 'Miroir rond' },
    roundTerraceShape           : { name: 'Forme ronde' },
    shelf                       : { name: 'Étagères' },
    shoesDrawer                 : { name: 'Rangements tiroirs' },
    shoesLocker                 : { name: 'Casiers' },
    shoesQuantity               : { name: 'Paires de chaussures' },
    shoesStorageBattants        : { name: 'Rangements battants' },
    shoesStorageClassic         : { name: 'Rangements classiques' },
    sectionalDoor               : { name: 'Sectionelle' },
    shadeTexture                : { name: 'Texturé', label: '€' },
    shadePattern                : { name: ' A motif', label: '€' },
    shadeWoodEffect             : { name: 'Effet bois', label: '€' },
    shadeStoneEffect            : { name: 'Effet pierre', label: '€' },
    shadeConcreteEffect         : { name: 'Effet béton', label: '€' },
    shadeMarbleEffect           : { name: 'Effet marbre', label: '€' },
    shadeUltraMattEffect        : { name: 'Ultra mat', label: '€€' },
    shadeGraniteEffect          : { name: 'Granit', label: '€€€' },
    shadeQuartzEffect           : { name: 'Quartz', label: '€€€' },
    shadeCeramicEffect          : { name: 'Céramique', label: '€€€' },
    shadeTerrazzoEffect         : { name: 'Terrazzo', label: '€€€' },
    sideExtension               : { name: 'Latérales' },
    simpleDoorGlassWall         : { name: 'Porte simple' },
    simpleDrawer                : { name: 'Tiroir simple' },
    simpleHighWind              : { name: 'Marquise droite' },
    simpleShowerScreen          : { name: 'Paroi de douche' },
    simpleWindowDoor            : { name: 'Porte fenêtre simple' },
    simpleWindowsGlassWall      : { name: 'Fenêtre simple' },
    singleDoor                  : { name: 'Porte simple' },
    singleShape                 : { name: 'Forme linéaire' },
    singleWindow                : { name: 'Fenêtre simple' },
    slidingDoorGlassWall        : { name: 'Porte coulissante' },
    slidingDoor                 : { name: 'Coulissante' },
    slidingDoors                : { name: 'Porte coulissante' },
    slidingShelf                : { name: 'Tablette' },
    slidingWindow               : { name: 'Fenêtre coulissante' },
    slidingWindowDoor           : { name: 'Porte fenêtre coulissante' },
    slopingRoofVeranda          : { name: 'Toit en pente' },
    squareMirror                : { name: 'Miroir carré' },
    squareRoom                  : { name: 'Carré' },
    squareShape                 : { name: 'Forme carrée' },
    squareStraightEdge          : { name: 'Droits carrés' },
    squareTerraceShape          : { name: 'Forme carrée' },
    staircaseQuarterTurnDown    : { name: 'Escalier 1/4 tournant bas' },
    staircaseQuarterTurnHigh    : { name: 'Escalier 1/4 tournant haut' },
    staircaseQuarterTurnLeft    : { name: 'À gauche' },
    staircaseQuarterTurnMiddle  : { name: 'Escalier 1/4 tournant milieu' },
    staircaseQuarterTurnRight   : { name: 'À droite' },
    staircaseRailing            : { name: 'Main courante' },
    staircaseWithoutRisers      : { name: 'Escalier sans contremarches' },
    staircaseWithRisers         : { name: 'Escalier avec contremarches' },
    storageBox                  : { name: 'Caisson à tiroirs' },
    storageUnderMezzanine       : { name: 'Rangement sous mezzanine' },
    straightEdgeChamfer         : { name: 'Droits avec chanfreins' },
    straightEdgeSoftened        : { name: 'Droits adoucis' },
    swingDoor                   : { name: 'Battante' },
    telescopicHangerCloth       : { name: 'Penderie téléscopique' },
    tieHanger                   : { name: 'Porte cravate' },
    tiltingDoor                 : { name: 'Basculable ' },
    traverseCrossedGlassWall    : { name: 'Style croisé' },
    traverseHorizontalGlassWall : { name: 'Style horizontale' },
    traverseVerticalGlassWall   : { name: 'Style verticale' },
    trayArc                     : { name: 'Arc de cercle' },
    trayHexagonal               : { name: 'Hexagonale' },
    trayOval                    : { name: 'Ovale' },
    trayRectangle               : { name: 'Rectangulaire' },
    trayRound                   : { name: 'Ronde' },
    traysSquare                 : { name: 'Carrée' },
    trayTriangle                : { name: 'Triangle' },
    traysVolumeSquare           : { name: 'Carrée' },
    trayVolumeRectangle         : { name: 'Rectangulaire' },
    trayVolumeRound             : { name: 'Ronde' },
    trayVolumeOval              : { name: 'Ovale' },
    trayVolumeHexagonal         : { name: 'Hexagonale' },
    trayVolumeTriangle          : { name: 'Triangle' },
    tShape                      : { name: 'Forme en T' },
    tTerraceShape               : { name: 'Forme en T' },
    tvDoor                      : { name: 'Porte' },
    tvDrawer                    : { name: 'Tiroir' },
    tvNiche                     : { name: 'Niche' },
    tvNoLegs                    : { name: 'Au sol (sans piètements)' },
    tvRoundLegs                 : { name: 'Rond' },
    tvSquareLegs                : { name: 'Carré' },
    tvULegs                     : { name: 'En U' },
    usbConnector                : { name: 'Prise USB' },
    uShape                      : { name: 'Forme en U' },
    uTerraceShape               : { name: 'Forme en U' },
    verandaBurglarProofGlazing  : { name: 'Vitrage anti-effraction' },
    verandaHeater               : { name: 'Chauffage' },
    vearndaLighting             : { name: 'Eclairage' },
    verandaStore                : { name: 'Stores' },
    vearndaThermalInsulation    : { name: 'Isolation thermique renforcée' },
    vegetalWallArtificial       : { name: 'Artificiel' },
    vegetalWallFern             : { name: 'Fougère' },
    vegetalWallFoamBall         : { name: 'Mousse boule' },
    vegetalWallFoamLichen       : { name: 'Mousse lichen' },
    vegetalWallIndoor           : { name: 'Intérieur' },
    vegetalWallIvy              : { name: 'Lierre' },
    vegetalWallLighting         : { name: 'Eclairage' },
    vegetalWallLogo             : { name: 'Logo' },
    vegetalWallLushNature       : { name: 'Nature luxuriante' },
    vegetalWallNatural          : { name: 'Naturel' },
    vegetalWallOutdoor          : { name: 'Extérieur' },
    vegetalWallPhilodendron     : { name: 'Phildendron' },
    vegetalWallStabilized       : { name: 'Stabilisé' },
    vertical                    : { name: 'verticale' },
    wardrobeLight               : { name: 'Luminaires intégrés' },
    windowBay                   : { name: 'Fenêtre en saillie' },
    windowBlindsAwing           : { name: 'Store banne' },
    windowBlindsAwingProjection : { name: 'Store bannette à projection' },
    windowBlindsDoubleAwing     : { name: 'Store double pente' },
    windowBlindsFlat            : { name: 'Appartement' },
    windowBlindsHouse           : { name: 'Maison' },
    windowBlindsBalcony         : { name: 'Balcon' },
    windowBlindsBlackout        : { name: 'Occultant' },
    windowBlindsBoat            : { name: 'Store bateau' },
    windowBlindsCalifornian     : { name: 'Store californien' },
    windowBlindsDayNight        : { name: 'Jour et nuit' },
    windowBlindsJapanese        : { name: 'Store Japonais' },
    windowBlindsPleated         : { name: 'Store plissé' },
    windowBlindsSifting         : { name: 'Tamisant' },
    windowBlindsTerrace         : { name: 'Terrasse' },
    windowBlindsVelux           : { name: 'Store velux' },
    windowBlindsVenetian        : { name: 'Store vénitien' },
    windowBlindsWinder          : { name: 'Store enrouleur' },
    windowBlindsWindow          : { name: 'Fenêtre' },
    windowCorner                : { name: 'Fenêtre à angle' },
    windowDiamond               : { name: 'Fenêtre losange' },
    windowElbow                 : { name: 'Fenêtre à coude' },
    windowLowered               : { name: 'Fenêtre surbaissée' },
    windowOneLeaf               : { name: 'Fenêtre avec 1 vantail ' },
    windowPointedTrapezoidal    : { name: 'Fenêtre en trapèze pointue' },
    windowRound                 : { name: 'Fenêtre ronde' },
    windowSemicircular          : { name: 'Fenêtre plein cintre' },
    windowThreeLeaf             : { name: 'Fenêtre avec 3 vantaux' },
    windowTrapezoidal           : { name: 'Fenêtre trapèze' },
    windowTrapezoidalWithShell  : { name: 'Fenêtre trapèze avec obus' },
    windowTriangle              : { name: 'Fenêtre triangle' },
    windowTwoLeaf               : { name: 'Fenêtre avec 2 vantaux' },
    wineCellarBuiltIn           : { name: 'Encastrable' },
    wineCellarCellar            : { name: 'Cave' },
    wineCellarDoorOpenLeft      : { name: 'À gauche' },
    wineCellarDoorOpenRight     : { name: 'À droite' },
    wineCellarDoorQuantity      : { name: 'Nombre de portes' },
    wineCellarFreeStanding      : { name: 'Pose libre' },
    wineCellarFullDoor          : { name: 'Porte pleine' },
    wineCellarGarage            : { name: 'Garage' },
    wineCellarGlassDoor         : { name: 'Porte vitrée' },
    wineCellarLighting          : { name: 'Éclairage' },
    wineCellarLock              : { name: 'Serrure' },
    wineCellarLShape            : { name: 'Forme en L' },
    wineCellarNbBottle          : { name: 'Nombre de bouteilles' },
    wineCellarOther             : { name: 'Autres pièces' },
    wineCellarOutside           : { name: 'Extérieur' },
    wineCellarPresentationShelf : { name: 'Présentation' },
    wineCellarSlidingShelf      : { name: 'Coulissantes' },
    wineCellarStorageShelf      : { name: 'Stockage' },
    wineCellarStorageSpace      : { name: 'Espace de stockage' },
    wineCellarStraight          : { name: 'Forme droite' },
    wineCellarTempPanel         : { name: 'Contrôle température' },
    wineCellarUShape            : { name: 'Forme en U' },
    withoutHandlesWithThroat    : { name: 'Gorge intégrée', label: '€€€' },
};

export default ShapesFR;
