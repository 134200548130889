import {
    FC,
} from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import Routes, { RouteKey } from '@core/constants/Route';
import Spinner from '@ui/Spinner';
import SignInPage from '../../pages/SignInPage';
import { useUserState } from '../../context/UserContext';
import { IUserRole } from '../../../interfaces/User';

const AuthRoute: FC<{adminOnly?: boolean} & RouteProps> = ({ adminOnly, ...properties }) => {
    const { user } = useUserState();

    if (user === undefined) return <Spinner />;

    if (user === null) return <SignInPage />;

    if (!!user && user.role !== IUserRole.Admin && adminOnly) return (<Redirect to={Routes[RouteKey.Account]} />);

    return (<Route {...properties} />);
};

export default AuthRoute;
