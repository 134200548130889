export enum AvailabilityOptionId {
    NoPreference = 'noPreference',
    ByPhoneNineToTwelve = 'ByPhoneNineToTwelve',
    ByPhoneTwelveToTwo = 'ByPhoneTwelveToTwo',
    ByPhoneTwoToSix = 'ByPhoneTwoToSix',
    ByPhoneAfterSix = 'ByPhoneAfterSix',
    ByMail = 'byMail',
}

export default AvailabilityOptionId;
