/* eslint-disable import/extensions */
import CountryId from '@core/constants/Country';
import FurnitureId from '@core/constants/Furniture';
import {
    RequestDeclineArtisanReasonId,
    RequestDeclineUserReasonId,
    RequestMatchingSourceId,
    RequestStatusId,
} from '@core/constants/Request';
import { PseudoPlanCommission } from '@core/constants/Artisan';
import PersonaId from '@core/constants/Persona';
import AvailabilityOptionId from '@core/constants/AvailabilityOption';
import FormGroupId from '@core/constants/FormGroupId';
import PropertyOptionId from '@core/constants/PropertyOption';
import OwnerTenantOptionId from '@core/constants/OwnerTenantOption';
import {
    DeckBoardWidthId,
    GarageDoorOptionsId,
    PergolaRoofId,
    WindowInsulationId,
    TerraceObstacleId,
    WindowGlazingId,
    WindowOpeningSideId,
    WineCellarFunctionalityId,
    WindowBlindsOptionsId,
    ClosetDoorSensId,
    FencingOptionId,
    KitchenProjectTypeId,
    FinishingMaterialOptionId,
    ApplianceLevelOptionId,
    BudgetOptionId,
} from '@core/constants/Option';
import LookingForOptionId from '@core/constants/LookingForOption';
import EmergencyOptionId from '@core/constants/EmergencyOption';
import FinishingOptionId from '@core/constants/FinishingOption';
import { RouteKey } from '@core/constants/Route';
import { ShippingAccessId, ShippingFloorId } from '@core/constants/ShippingDetail';
import { FieldDimensionId } from '@core/constants/FieldDimension';
import { DeprecatedFormId, PublishedFormId } from '@furnish-hx/constants/FormId';
import SubmissionStatusId from '@core/constants/SubmissionStatus';
import JobsFR from '@furnish-hx/translations/Job';
import ProjectsFR from '@furnish-hx/translations/Project';
import ServicesFR from '@furnish-hx/translations/Service';
import ArtisanLabelsFR from './ArtisanLabels';
import { MaterialsFR } from './Material';
import { ShapesFR } from './Shape';
import { fieldsPortfolioFR } from './FieldsPortfolio';
import { fieldsChatFR } from './FieldsChat';
import { FormValuesAnswersFR } from './FormValuesAnswers';
import { RoutesFR } from './Routes';
import SubmissionSaleStatusFR from './SubmissionSaleStatus';
import { IUserKey, IUserRole } from '../interfaces/User';
import {
    IFormFieldContactKey,
    IFormFieldAdditionalDataKey,
    IFormFieldDimensionDefaultValueKey,
    IFormFieldProjectInformationKey,
} from '../interfaces/Form';
import { IReason } from '../interfaces/Interfiled';
import InternalError from '../constants/InternalError';
import { ExtraStep } from '../interfaces/IStep';
import { DesignProjectFR, DesignProjectCategoriesFR } from './DesignProject';
import DeptCodeFR from './DeptCode';
import jobSelectionAnswersFR from './Jobs';
import { formsTranslations } from './forms';
import { FirebaseErrorId } from '../constants/ApiErrors';
import { artisanProfileFR } from './ArtisanProfile';
import requestFR from './Request';
import ProjectSearchFR from './ProjectSearch';
import InternalContactQualificationStatusFR from './InternalContactQualificationStatus';
import InternalQualificationStatusFR from './InternalQualificationStatus';

export default {
    'fr-FR': {
        artisanLabels          : ArtisanLabelsFR,
        jobs                   : JobsFR,
        projects               : ProjectsFR,
        services               : ServicesFR,
        artisanProfileServices : 'Services proposés',
        expand                 : 'Voir plus',
        collapse               : 'Voir moins',
        jobsStructureNavigator : {
            serviceId : 'Quel service ?',
            projectId : 'Quel type de projet ?',
            formId    : 'Quel projet ?',
        },
        appNav: {
            artisanDashboardLinkLabel : 'Je suis artisan',
            buyGuideLinkLabel         : 'Guide d\'achat',
            quoteRequest              : 'Demande de devis',
            quoteRequestAction        : 'Déposer un projet',
            interestedService         : 'Quel service vous intéresse ?',
            interestedProject         : 'Quel projet vous interesse ?',
        },
        artisanProfilePage: {
            artisanVerified    : 'Artisan vérifié',
            quoteRequest       : 'Demander un devis à {artisanName}',
            quoteRequestAction : 'Demander un devis',
            interestedService  : 'Quel service de cet artisan vous intéresse ?',
            interestedProject  : 'Quel type de projet voulez-vous réaliser ?',
            labelsTitle        : 'Entreprise Engagée',
            iconsTitle         : 'Réseaux sociaux',
        },
        fieldsPortfolio                : fieldsPortfolioFR,
        fieldsChat                     : fieldsChatFR,
        deptCode                       : DeptCodeFR,
        materialCategories             : MaterialsFR.materialCategories,
        materialClasses                : MaterialsFR.materialClasses,
        materials                      : MaterialsFR.materials,
        materialTints                  : MaterialsFR.materialTints,
        materialTypes                  : MaterialsFR.materialTypes,
        designProjects                 : DesignProjectFR,
        designProjectCategories        : DesignProjectCategoriesFR,
        shapes                         : ShapesFR,
        routes                         : RoutesFR,
        formValuesAnswers              : FormValuesAnswersFR,
        jobSelectionAnswers            : jobSelectionAnswersFR,
        artisanProfile                 : artisanProfileFR,
        request                        : requestFR,
        projectSearch                  : ProjectSearchFR,
        servicesAssociatedWith         : 'Services associés à {artisanJob}',
        otherServices                  : 'Autres services',
        jobsAssociatedWith             : 'Populaires',
        otherJobs                      : 'Autres métiers',
        hyphen                         : '-',
        seoTitleDefault                : 'Concevoir | LILM',
        seoDescriptionDefault          : 'Concevoir mon meuble sur-mesure | LILM',
        orderIdenticalProject          : 'Je veux le même !',
        viewProject                    : 'Voir la page de détail',
        artisanConversionBlockTitle    : 'Vous avez un projet en tête ?',
        artisanConversionBlockSubtitle : 'Devis gratuit et sans engagement',
        showAllProjectsOf              : 'Afficher tous les projets de {artisanName}',
        requestQuoteToArtisan          : 'Demander un devis à {artisanName}',
        materialsUsed                  : 'Matériaux',
        loadMoreProjects               : 'Afficher les réalisations suivantes',
        favoriteProject                : 'Réalisation favorite de {artisanName}',
        action                         : 'Action',
        jobsSearchPlaceholder          : 'Menuisier, Agenceur, Ferronnier...',
        citiesSearchPlaceholder        : 'Lieu de votre projet',
        regularAppliances              : 'Électroménagers',
        shapeStyles                    : {
            intemporel : 'Les intemporels',
            tendance   : 'Les dernières tendances',
        },
        finishing: {
            [FinishingOptionId.Basic]    : 'Basique',
            [FinishingOptionId.Standard] : 'Standard',
            [FinishingOptionId.Premium]  : 'Premium',
            [FinishingOptionId.Luxe]     : 'Luxe',
        },
        archiveRequest: {
            archiveSucceed   : 'Demande archivée',
            title            : 'Archives',
            unarchiveSucceed : 'Demande désarchivée',
        },
        searchArtisan: {
            headerTitle        : 'Vos projets par les meilleurs artisans',
            emptyResultMessage : 'Aucun resultat pour cette recherche...',
            totalArtisans      : '{count, plural, =0 {0 Artisan} one {# Artisan} other {# Artisans}}',
            totalProjects      : '{count, plural, =0 {0 Projet} one {# Projet} other {# Projets}}',
        },
        serviceMultiSelector: {
            labelEmpty : 'Services',
            label      : 'Services • {size}',
            header     : 'Filtrer par <strong>services</strong>:',
            clear      : 'Effacer',
            submit     : 'Enregistrer',
        },
        dropImages                               : 'Déposez vos images ici.',
        addImages                                : 'Ajouter des images',
        dropFile                                 : 'Déposez votre fichier ici.',
        dropFiles                                : 'Déposez vos fichiers ici.',
        addFiles                                 : 'Ajouter des fichiers',
        fileUploadDescription                    : 'Maximum {filesLimit, plural, =0 {0 fichier} one {# fichier} other {# fichiers}} ({types})',
        delete                                   : 'Supprimer',
        removeFavorite                           : 'Retirer des favoris',
        addFavorite                              : 'Ajouter aux favoris',
        favorite                                 : 'Favori',
        published                                : 'Publié',
        draft                                    : 'Broullion',
        publish                                  : 'Publier',
        draftBack                                : 'Dépublier',
        projectsFavorite                         : 'Favoris',
        projectsPublished                        : 'Publiés',
        projectsDraft                            : 'Brouillons',
        descriptionRecap                         : 'Description',
        deleteConfirmNotice                      : 'Êtes-vous sûr de vouloir supprimer cette réalisation ? Cette opération est irréversible.',
        publishConfirmNotice                     : 'Confirmer la publication sur votre profil ?',
        helpAndTraining                          : 'Aide et formations',
        getFileLimitExceedMessage                : 'Désolé, nous autorisons un maximum de {filesLimit} fichier(s).',
        getFileAddedMessage                      : 'Le fichier {fileName} a été ajouté avec succès.',
        getFileRemovedMessage                    : 'Le fichier {fileName} a été supprimé avec succès.',
        getDropRejectMessage                     : "Le fichier n'est pas au bon format (.jpg/.png).",
        activateApp                              : 'Activer mes formulaires',
        appArtisanLauncherNotice                 : 'Activez vos formulaires personnalisés en définissant votre typologie de projets via l\'onglet "Mes services", puis en séléctionnant ici vos projets clients.',
        linkToArtisanProfil                      : 'Lien profil',
        copyArtisanAppLink                       : 'Copier le lien',
        copyArtisanAppLinkInfoLabel              : 'Votre lien unique',
        copyArtisanAppLinkSuccessNotice          : 'Le lien de votre application a été copié dans votre presse-papier, vous pouvez dès maintenant le partager afin de recevoir des demandes qualifiées.',
        copyArtisanAppLinkInfoNotice             : 'Vos formulaires personnalisés sont activés. Vous pouvez désormais partager votre lien et recevoir des projets qualifiés.',
        copyArtisanAppLinkWarningNotice          : 'Sélectionnez vos services et projets dans l’onglet "Mes services" afin d’activer votre application.',
        copyArtisanAppLinkWarningNoticeBis       : 'Sélectionnez vos projets à afficher dans votre application ci-dessous pour activer votre application.',
        targetArtisanExternalSubmissionNotice    : "Cette demande a été spécifiquement adressée à {artisanName} depuis son application dédiée et ne devrait pas être envoyée à d'autres artisans, tant que {artisanName} ne l'a pas déclinée.",
        targetArtisanPrivateSubmissionNotice     : "Cette demande a été spécifiquement adressée à {artisanName} depuis son profil et ne devrait pas être envoyée à d'autres artisans, tant que {artisanName} ne l'a pas déclinée.",
        targetArtisanPublicSubmissionNotice      : "Cette demande a été adressée à {artisanName} depuis son profil, mais elle peut être envoyée à d'autres artisans.",
        closedSubmissionNotice                   : 'L\'utilisateur ne souhaite plus recevoir de devis pour cette demande.',
        matchingSource                           : 'Source',
        artisanProfileMissingProjectsNotice      : "Afin de recevoir des demandes qui vous correspondent et afin d'activer vos formulaires personnalisés, veuillez renseigner votre typologie de projets.",
        artisanProfilePicture                    : 'Profil',
        artisanProfileLogo                       : 'Logo',
        artisanProfileLink                       : 'Voir mon profil',
        artisanDescription                       : 'Bio',
        artisanDescriptionAdd                    : 'Ajouter ma bio',
        artisanDescriptionEdit                   : 'Modifier ma bio',
        artisanLabelsTitle                       : 'Labels',
        artisanLabelsAdd                         : 'Ajouter mes labels',
        artisanLabelsEdit                        : 'Modifier mes labels',
        artisanInvoice                           : 'Facturation',
        artisanInvoiceButtonLabel                : 'Accéder à ma facturation',
        artisanInvoiceContent                    : "Mettez à jour vos informations de facturation et téléchargez vos factures d'abonnement.",
        myBiography                              : 'Mon atelier en quelques mots',
        description                              : 'Ici décrire mon atelier en quelques mots',
        myCompanyContact                         : 'Contact',
        myCompanyInfo                            : 'Entreprise',
        myExternalLinks                          : 'Liens externes',
        submissionLilmStatus                     : 'État de la demande',
        internalContactQualificationStatus       : 'Qualification du contact',
        internalQualificationStatus              : 'Etat de la qualification',
        submissionCreationDate                   : 'Date de dépôt du projet : ',
        submissionQualificationDate              : 'Date de qualification : ',
        submissionInternalStatus                 : 'État du suivi client',
        submissionInternalStatusUpdateDate       : 'Date du suivi commercial : ',
        submissionLilmComment                    : 'Commentaire pour l\'artisan',
        submissionInternalComment                : 'Commentaires internes LILM',
        goPro                                    : 'Direction LILM Pro',
        goProBis                                 : "Je m'abonne",
        submissionFreemiumCta                    : 'Accès illimité à tous les projets',
        knowMore                                 : 'Prenez rdv gratuitement',
        shouldDefineProjectsNotice               : '🤙🏻 Pour démarrer avec LILM, sélectionnez les projets que vous voulez recevoir.<br/>Vous pourrez les modifier à tout moment.',
        shouldDefineProjects                     : '<br /><br />Recevez des projets qui vous correspondent vraiment',
        shouldUpdateProjectsNotice               : "<p>On améliore le ciblage de vos projets. Ce qui change pour vous :</p><ol><li>Votre métier n'est plus lié à 3 services : vous pouvez choisir autant de services que vous voulez<br /><br /></li><li>Dans chaque service, définissez les projets pour lesquels vous souhaitez recevoir des demandes<br /><br /></li><li>Les matériaux sont également pris en compte dans le ciblage.<br /><br /></li></ol>",
        shouldUpdateProjects                     : '⚠️ Mettez à jour vos services et projets',
        shouldDefineMaterials                    : 'Ajoutez les matériaux que vous travaillez',
        metroFrance                              : '(hors DOM-TOM et Corse)',
        artisanOnboardingShouldResetPassword     : 'Cet email est déjà utilisé sur LILM et le mot de passe associé est incorrect.',
        artisanOnboardingShouldResetPasswordBis  : 'Vous pouvez, soit utiliser une autre adresse email, soit ',
        artisanOnboardingShouldResetPasswordLink : 'définir votre mot de passe.',
        menuLilmStatus                           : 'Un expert LILM a contacté le client',
        lilmStatus                               : 'Statut LILM',
        youAre                                   : 'Vous êtes',
        change                                   : 'changer',
        myJob                                    : 'Métier',
        myDept                                   : 'Département',
        myServices                               : 'Services',
        myProjects                               : 'Projets',
        selectAll                                : 'tout sélectionner',
        true                                     : 'Oui',
        false                                    : 'Non',
        copy                                     : 'Copier',
        userAskArtisanQuote                      : 'Prenez contact avec ce professionnel par téléphone pour avancer sur votre projet',
        submissionVerify                         : "Note de l'expert LILM",
        submissionVerified                       : 'Demande vérifiée',
        phoneVerified                            : 'Téléphone vérifié',
        coherentProject                          : 'Projet cohérent',
        publicSubmissionCreationSuccess          : 'La demande a bien été créée et ajoutée à votre presse-papier : {publicSubmissionUrl}',
        noOptionText                             : 'Aucun résultat',
        signUpArtisan                            : 'Inscription gratuite',
        servicesSelectionAnswer                  : 'Parfait ! 👌<br/>Ces informations nous permettent déjà de vous proposer des projets dédiés.',
        goPrevStep                               : 'Précédent',
        goNextStep                               : 'Suivant',
        homeSearchPlaceholder                    : 'Ex: Table "Live-Edge"',
        linkGoogleMyBusiness                     : 'Google My Business',
        linkFacebook                             : 'Facebook',
        linkLinkedIn                             : 'LinkedIn',
        linkInstagram                            : 'Instagram',
        linkWebsite                              : 'Site internet',
        signUpPhoneSecondary                     : 'Téléphone secondaire',
        onboardingSignUpPhone                    : 'Téléphone',
        onboardingSignUpPassword                 : 'Mot de passe',
        onboardingSignUpLastName                 : 'Nom',
        onboardingSignUpFirstName                : 'Prénom',
        onboardingSignUpEmail                    : 'Email',
        onboardingSignUpTitle                    : 'Faisons plus ample connaissance !',
        onboardingServicesTitle                  : 'Quels sont vos principaux services ?',
        onboardingServicesNotice                 : 'Pour un profil optimisé, ajoutez au moins 3 services.',
        onboardingServicesQuestionPlaceholder    : 'Ex: {service}',
        onboardingServicesQuestion1              : 'Quel est votre service principal ?',
        onboardingServicesQuestion2              : 'Quel est votre service secondaire ?',
        onboardingServicesQuestion3              : 'Quel est votre troisième service ?',
        onboardingServicesQuestion4              : 'Un autre service ?',
        onboardingJobTitle                       : 'Bienvenue 👋',
        onboardingJobNotice                      : 'Prenez un instant pour renseigner vos informations et recevez des projets qui vous ressemblent.',
        onboardingJobQuestionPlaceholder         : 'Ex: Menuisier - Ebéniste',
        onboardingJobQuestion                    : 'Quel est votre métier ?',
        onboardingJob                            : 'Expertise',
        onboardingCompanyTitle                   : 'Plus d’informations sur votre société',
        onboardingCompanySubtitle                : 'Dites-nous en un peu plus sur {companyName}...',
        onboardingCompanyName                    : 'Nom de la société',
        onboardingCompanyAddress                 : 'Adresse de la société',
        onboardingCompanyZip                     : 'Code Postal',
        onboardingCompanyCity                    : 'Ville',
        onboardingCompanyCountry                 : 'Pays',
        onboardingSignUp                         : 'Profil',
        onboardingDelivery                       : "Zone d'intervention",
        onboardingCompany                        : 'Société',
        searchArtisanWithOurApp                  : 'En <b>5 min</b> avec notre app',
        artisanGoogleMyBusinessId                : 'Google My Business Id',
        artisanGoogleMyBusinessName              : 'Nom de l\'entreprise sur Google My Business',
        new                                      : 'NEW',
        full                                     : 'Complet',
        tooLateToEstimate                        : 'Trop tard, déjà 5 entreprises ont envoyé une estimation.',
        noSubmissionLeft                         : "Il n'y a actuellement aucune demande réclamant votre attention. 👌",
        artisanRequestCreationSuccess            : 'Votre estimation a bien été envoyée au client.<br/>Retrouvez-la dorénavant dans votre onglet "Mes demandes".',
        artisanOnboardingLink                    : 'Artisan ou fabricant ?',
        resetPasswordInvalidCodeTitle            : 'Lien périmé',
        resetPasswordInvalidCodeSubtitle         : 'Votre code est périmé. Veuillez entrer votre adresse email afin que nous puissions vous renvoyer un email de vérification.',
        sendQuoteNotice                          : 'Bravo, {clientName} a accepté votre offre !👌<br/>Vous pouvez dorénavant lui envoyer un devis détaillé.',
        submissionEstimate                       : 'Estimation',
        submissionEstimates                      : 'Vos estimations',
        submissionEstimatesCount                 : 'Estimations ({count})',
        submissionEstimatesTitle                 : 'Des artisans ont estimé votre projet',
        submissionEstimatesNotice                : "Demandez un devis détaillé à l'artisan de votre choix. Si l'estimation ne vous convient pas, échangez avec l'artisan pour optimiser votre projet.<br>Service gratuit, sans engagement et sans obligation d'achat.",
        submissionEstimatesEmpty                 : '👏🏻 Vos estimations arrivent !<br/>Vous retrouverez ici les propositions des artisans.<br/>On vous envoie un email pour chaque nouvelle estimation.',
        submissionSummary                        : 'Détails du projet',
        requestUserDeclineTitle                  : 'Motif du refus donné par le client',
        requestEstimateTitle                     : 'Estimation envoyée',
        requestEstimateSummary                   : 'Résumé',
        requestEstimateDetail                    : 'Détail',
        requestEstimateContact                   : 'Contact',
        requestEstimateArtisanPhoneNumber        : 'Téléphone : {phone}',
        requestEstimateArtisanEmailContact       : 'Email : {email}',
        userLastName                             : 'Nom',
        userFirstName                            : 'Prénom',
        requestDpUserEmail                       : 'Email du client',
        requestDpUserPhone                       : 'Téléphone du client',
        requestShowContact                       : 'Pas assez d information pour estimer ? Contacter le client.',
        requestEstimateArtisanEmail              : "Email de l'artisan",
        requestEstimateArtisanPhone              : "Téléphone de l'artisan",
        requestEstimateArtisanNameLabel          : '{artisan}',
        requestEstimatePriceTTCLabel             : 'Prix estimé (TTC)',
        requestQuotePriceHTLabel                 : 'Prix final (HT)',
        requestEstimatePrice                     : '{price}<small>€<small>',
        requestEstimateMinPrice                  : 'Entre {price}<small>€<small> ',
        requestEstimateMaxPrice                  : ' et {price}<small>€<small>',
        requestEstimateShippingDelayLabel        : 'Délai de livraison',
        requestEstimateShippingDelay             : '{delay}<small>sem.<small>',
        requestEstimateShippingInRegion          : 'Livre et intervient dans le {code}',
        searchArtisanBlockShippingInRegion       : 'Livre et intervient ',
        requestEstimateComment                   : 'Message',
        requestEstimateProjects                  : "Réalisations de l'artisan",
        requestEstimateAttachmentsClient         : "Pièces jointes de l'artisan",
        requestEstimateAttachments               : 'Pièces jointes',
        requestQuoteAttachmentsClient            : "Devis de l'artisan",
        requestQuoteAttachments                  : 'Devis',
        requestEstimatePriceHTLabel              : 'Prix HT',
        requestQuotePriceHT                      : '{price}€',
        requestEstimatePriceHT                   : '<small>~</small>{price}€',
        requestEstimatePriceMinHT                : 'Entre <small>~</small>{price}€ ',
        requestEstimatePriceMaxHT                : ' et <small>~</small>{price}€',
        requestEstimateShippingPriceLabel        : 'Prix livraison HT',
        requestEstimateShippingPriceIncluded     : 'Livraison incluse',
        requestEstimateShippingPrice             : '<small>~</small>{price}€',
        requestEstimatePriceTotalLabel           : 'Total TTC',
        requestEstimatePriceTotal                : '<small>~</small>{price}€',
        requestEstimateMinPriceTotal             : 'Entre <small>~</small>{price}€ ',
        requestEstimateMaxPriceTotal             : ' et <small>~</small>{price}€',
        requestEstimateTVAExonerated             : 'Exonéré',
        requestEstimateTVA                       : '{TVA}%',
        requestEstimateTVALabel                  : 'Taux de TVA',
        requestEstimateAccept                    : 'Continuer avec ce pro',
        requestEstimateAskContact                : 'Poser une question à {companyName}',
        requestQuoteLink                         : 'Voir le devis',
        requestEstimateRefuse                    : 'Cette estimation ne me convient pas',
        requestEstimateContactArtisanTitle       : "Contactez l'artisan !",
        requestEstimateContactArtisanNotice      : "N'hésitez pas à contacter {artisanName} directement afin de définir plus en détails votre projet.",
        requestDpContactUserTitle                : 'Contactez le client !',
        requestDpContactUserNotice               : 'Voici les coordonées du client, vous permettant un premier contact pour faire une estimation plus juste.',
        workInProgress                           : 'Cette fonctionalité est en cours de développement.',
        comingSoon                               : '(bientôt)',
        outdatedAppVersion                       : "Vous êtes offline ou utilisez une version obsolète de l'application.",
        upgrade                                  : 'Actualiser',
        sendQuoteEmail                           : 'Envoyer mon devis',
        installApp                               : "Installer l'App",
        updateCompanyNameDisabled                : 'Vous ne pouvez pas changer le nom de votre société.',
        updateEmailDisabled                      : 'Vous ne pouvez pas changer votre adresse email.',
        project                                  : 'Projet',
        lilmAvatar                               : 'Mika',
        userAvatar                               : 'Vous',
        all                                      : 'Tous',
        yes                                      : 'Oui',
        no                                       : 'Non',
        continue                                 : 'Continuer',
        from                                     : ' de {name}',
        termsAndConditionsAccept                 : "J'accepte les ",
        termsAndConditions                       : 'CGU',
        confirm                                  : 'Confirmer',
        validate                                 : 'Valider',
        lilmCommentary                           : 'Commentaire LILM',
        start                                    : 'Démarrer',
        open                                     : 'Ouvrir',
        edit                                     : 'Modifier',
        invalidZipCode                           : 'Combinaison pays, code postal invalide',
        appFormsSelectionPlaceholder             : 'Ex: Table de ferme',
        appFormsSelectionLabel                   : 'Quels projets proposez-vous ?',
        deliveryAreasPlaceholder                 : 'Ex: Paris, France',
        deliveryAreasLabel                       : 'Dans quelles zones livrez-vous ?',
        deliveryAreas                            : "Zones d'intervention",
        deliveryCountries                        : "Pays d'intervention",
        deliveryCountryFull                      : '(entière)',
        deliveryZone                             : 'Choisissez votre zone de livraison',
        deliveryZoneLegendActive                 : 'Sélectionné',
        deliveryZoneLegendInactive               : 'Non sélectionné',
        artisan                                  : 'Artisan',
        send                                     : 'Envoyer',
        next                                     : 'Suivant',
        accept                                   : 'Accepter',
        estimate                                 : 'Estimer',
        decline                                  : 'Refuser',
        answer                                   : 'Répondre',
        update                                   : 'Modifier',
        updating                                 : 'Mise à jour',
        loadMore                                 : 'Voir plus',
        readMore                                 : 'Lire plus',
        readLess                                 : 'Lire moins',
        readMoreDetails                          : 'Voir les détails',
        readLessDetails                          : 'Cacher les détails',
        loadAll                                  : 'Tout charger',
        status                                   : 'Statut',
        statusHistory                            : 'Historique',
        moreInfo                                 : 'En savoir plus',
        showAll                                  : 'Tout afficher',
        cancel                                   : 'Annuler',
        verified                                 : 'Vérifié',
        verifiedByLilm                           : 'Notre équipe a validé ce projet avec le client',
        save                                     : 'Enregistrer',
        saveAndPublish                           : 'Enregistrer et publier',
        notVerified                              : 'Non-vérifié',
        undefined                                : 'Indéfini',
        creationDate                             : 'Date',
        recapCreationDate                        : 'Date de dépôt',
        lastUpdateDate                           : 'Dernière modification',
        approvalUpdateDate                       : 'Réponse',
        submissions                              : 'Demandes',
        submissionReference                      : 'Référence',
        submissionId                             : 'Demande ID',
        submissionStatusArtisan                  : 'Statut et commentaire vu par l\'artisan',
        requests                                 : 'Demandes',
        recommendedForYou                        : 'Opportunités recommandées pour vous',
        baseOnService                            : "Basé sur vos services proposés et votre zone d'intervention",
        myCurrentRequests                        : 'Mes demandes en cours',
        needYourAttention                        : 'Vos projets en cours nécessitant votre attention',
        requestReference                         : 'Référence',
        requestId                                : 'Requête ID',
        contact                                  : 'Client',
        contactData                              : 'Informations de contact',
        userReference                            : 'Référence utilisateur',
        menu                                     : 'Menu',
        requestReview                            : 'Voir ma demande',
        keepGoing                                : 'Continuer sur LILM.co',
        keepGoingToProfileFromExternal           : 'Voir le profil de {artisanName}',
        keepGoingToProfile                       : 'Retour au profil de {artisanName}',
        submissionNumberRequest                  : '<b>Pros matché : {numberRequests}</b> ',
        letsStart                                : 'Démarrer',
        concurrency                              : 'Offres',
        detail                                   : 'Détail',
        materialsLabel                           : 'Matériau',
        stylesLabel                              : 'Style',
        catchmentAreaLabel                       : 'Zone de chalandise',
        furnituresLabel                          : 'Type de projet',
        artisanDashboardTypeLabel                : 'Type de projet',
        artisanDashboardEmergencyLabel           : 'Délais de\nlivraison',
        prefix                                   : 'Préfixe',
        countMeIn                                : 'Je veux en être!',
        password                                 : 'Mot de passe',
        resetPassword                            : 'Mot de passe oublié',
        resetPasswordDefineTitle                 : 'Nouveau mot de passe',
        resetPasswordDefineSubtitle              : 'Entrez ci-dessous le nouveau mot de passe à associer à votre compte LILM:',
        resetPasswordAgain                       : 'Me renvoyer un email de réinitialisation',
        resetPasswordSentTitle                   : 'Envoyé',
        resetPasswordSentSubtitle                : "Nous vous avons envoyé un email de validation à l'adresse renseignée afin de définir votre mot de passe.",
        resetPasswordSendingTitle                : 'Envoi en cours...',
        resetPasswordSendingSubtitle             : "Nous vous envoyons un email de validation à l'adresse renseignée afin de définir votre mot de passe.",
        source                                   : 'Source',
        forgottenPassword                        : 'Mot de passe oublié ?',
        enterPassword                            : 'Pouvez-vous entrer votre mot de passe ?',
        submitPassword                           : 'Me connecter',
        definePassword                           : 'Enregister',
        needsPassword                            : 'Vous avez déjà fait une demande de projet sur LILM 👍! Afin de sécuriser votre compte, il me faut un mot de passe.',
        wantsToDefinePasswordIntent              : 'Le définir',
        wantsToConnectIntent                     : 'Me connecter',
        wantsToDefinePassword                    : 'Je veux définir mon mot de passe.',
        wantsToConnect                           : 'Je connais mon mot de passe.',
        definedPassword                          : 'Voici mon mot de passe:<br/>●●●●●●●●●●●●●',
        passwordPattern                          : 'Minimum 8 caractères.',
        isResettingPassword                      : 'Choisissez votre mot de passe :',
        resetPasswordEmailSent                   : 'Je viens de vous envoyer un email afin de définir votre mot de passe.',
        greetings                                : `Bonjour {${IUserKey.FirstName}} !`,
        connectionConfirmationNotice             : 'Vous êtes bien connecté sur LILM',
        artisanProjectSourceLabel                : 'Demande faite depuis votre portfolio',
        requestHiddenContactNotice               : 'Envoyez une première estimation au client et accédez à son contact.',
        requestHiddenContactNoticeBis            : 'Envoyez une première estimation au client.',
        distance                                 : 'Distance',
        sideStepperNotice                        : 'La solution du meuble sur-mesure<br/>Made with ❤️ in the French Alps - <small>© LILM 2021</small><br/>Nos <a href="{termsAndConditionsUrl}" target="_blank">CGU</a>',
        sideNavFooterNotice                      : 'La solution commerciale des ateliers<br/>Made with ❤️ in the French Alps - <small>© LILM 2021</small><br/>Nos <a href="{termsAndConditionsUrl}" target="_blank">CGU</a>',
        signMeUp                                 : 'Créer mon profil gratuitement',
        tryToMatch                               : 'Lancer le matching automatique',
        profilePictureDnDLabel                   : 'Glisser-déposer votre photo de profile ou cliquer',
        profileLogoDnDLabel                      : 'Glisser-déposer votre logo ou cliquer',
        otherProjects                            : 'Projets disponibles',
        uploadFileCopy                           : 'Plans, croquis, photos d\'inspiration',
        addFile                                  : 'Ajouter un plan',
        passThisStep                             : 'Passer cette étape',
        estimateRequestErrorMessage              : 'Le prix min doit être inférieur au prix max',
        headerSentenceInRecapFormFlow            : 'Les détails du projet : {projectTitle}',
        portfolioJobsStructureLabels             : {
            serviceId: {
                hightLightedItemsLabel : 'Les services sélectionnés dans mon profil',
                regularItemsLabel      : 'Les autres services',
                neglectedItemsLabel    : "Vous n'avez pas trouvé ?",
                disabledItemsLabel     : 'Options désactivées',
            },
            projectId: {
                hightLightedItemsLabel : 'Les projets sélectionnés dans mon profil',
                regularItemsLabel      : 'Les autres projets',
                neglectedItemsLabel    : "Vous n'avez pas trouvé ?",
                disabledItemsLabel     : 'Options désactivées',
            },
            formId: {
                hightLightedItemsLabel : 'Les réalisations associées à vos projets',
                regularItemsLabel      : 'Les autres réalisations',
                neglectedItemsLabel    : "Vous n'avez pas trouvé ?",
                disabledItemsLabel     : 'Options désactivées',
            },
        },
        artisanProfileTabs: {
            profile  : 'Profil',
            projects : 'Réalisations',
        },
        multiSelectorInfos: {
            kitchenLowFurniture  : 'Dimensions standards : H.90cm x L.60cm x P.60cm',
            kitchenTallFurniture : 'Dimensions standards : H.60cm x L.60cm x P.40cm',
            kitchenColumn        : 'Dimensions standards : H.200cm x L.40cm x P.60cm',
        },
        imagesUpload: {
            accept    : 'Seuls les formats {formats} sont autorisés.',
            limit     : 'Vous ne pouvez ajouter que {limit} images.',
            size      : 'La taille des fichiers ne peut excéder {size}ko.',
            minWidth  : 'Les dimensions minimales des images sont de {min}px',
            minHeight : 'Les dimensions minimales des images sont de {min}px',
            maxWidth  : 'Les dimensions maximales des images sont de {max}px',
            maxHeight : 'Les dimensions maximales des images sont de {max}px',
        },
        portfolioData: {
            title: {
                placeholder : 'Ex: Table sur-mesure',
                label       : 'Titre du projet',
            },
            place: {
                placeholder : 'Ex: Paris',
                label       : 'Lieu de livraison',
            },
            price: {
                placeholder : 'Ex: 3500€',
                label       : 'Prix TTC livré',
                adornment   : '€',
            },
            description: {
                placeholder : 'Entrez ici la description de votre projet',
                label       : 'Description du projet',
            },
            images: {
                label  : 'Minimum 1 image',
                notice : 'Maximum {max} images de minimum {width}x{height}px ({extensions})',
            },
        },
        artisanHelpModal: {
            [RouteKey.ArtisanApp]: {
                title     : 'Formulaires personnalisés',
                subtitle1 : '⚡️ Personnalisation et activation',
                content1  : 'Sélectionnez les services et types de projets qui vous correspondent depuis l\'onglet "Mes services", puis activez votre application personnalisée !',
                subtitle2 : '🌍 Partagez votre lien',
                content2  : "Copiez le lien et placez le directement sur votre site ! Vous n'avez pas de site ? Partagez simplement le lien sur vos réseaux sociaux ou cartes de visites.",
                subtitle3 : '⏰ Suivez vos projets',
                content3  : "Centralisez vos demandes clients et simplifiez-vous le suivi dans une seule et même interface. Vos demandes clients sont identifiable facilement et ne sont jamais partagée à d'autres professionnels.",
            },
        },
        artisanNewPasswordRequest: {
            title  : 'Activer mon compte',
            notice : 'Pour accéder à votre tableau de bord, définissez votre mot de passe. On vous envoie un email à cette adresse :',
        },
        artisanOnboarding: {
            title  : 'Vous êtes artisan?',
            notice : 'Découvrez plus de 2000 projets déposés sur LILM chaque mois !',
        },
        formGroups: {
            [FormGroupId.CounterBar]           : 'Comptoir de bar',
            [FormGroupId.CustomMadeFurniture]  : 'Meuble sur-mesure',
            [FormGroupId.Default]              : 'Les projets phare',
            [FormGroupId.Desk]                 : 'Bureau',
            [FormGroupId.DpEvents]             : 'Agencement évènementiel',
            [FormGroupId.DpHouse]              : 'Agencement maison',
            [FormGroupId.DpOfficeAndWorkspace] : 'Agencement bureau et espace de travail',
            [FormGroupId.DpRestaurant]         : 'Agencement CHR',
            [FormGroupId.DpShop]               : 'Agencement commerce',
            [FormGroupId.GlassAndMirror]       : 'Projet en verre ou miroir',
            [FormGroupId.GlassWall]            : 'Verrière sur-mesure',
            [FormGroupId.Library]              : 'Bibliothèque',
            [FormGroupId.Pergola]              : 'Pergola',
            [FormGroupId.ShoesStorage]         : 'Meuble à chaussure',
            [FormGroupId.Staircase]            : 'Escalier',
            [FormGroupId.Table]                : 'Table',
            [FormGroupId.Terrace]              : 'Terrasse',
            [FormGroupId.TvUnit]               : 'Meuble de télévision',
            [FormGroupId.WardrobesAndStorages] : 'Dressings et rangements',
            [FormGroupId.WindowBlinds]         : 'Store',
            [FormGroupId.WineCellar]           : 'Cave à vin',
            [FormGroupId.Worktop]              : 'Plan de travail',
            [FormGroupId.Beds]                 : 'Lits',
            [FormGroupId.Consoles]             : 'Consoles',
        },
        dashboardSale: {
            titles: {
                company             : 'Atelier',
                delivery            : 'Zone de chalandise',
                jobs                : 'Métier',
                materials           : 'Matériaux',
                profile             : 'Profil',
                projects            : 'Projets',
                portfolio           : 'Réalisations',
                portfolioProjects   : 'Réalisations',
                portfolioPublished  : 'Réalisations publiées',
                portfolioLastUpdate : 'Dernière modification du portfolio',
                services            : 'Services',
                subscription        : 'Abonnement',
                id                  : 'Référence',
                form                : 'Formulaire',
                price               : 'Prix (€)',
                place               : 'Lieu',
                creationDate        : 'Date de création',
                artisanId           : 'Référence Artisan',
                isPublished         : 'Publié',
                title               : 'Titre',
                reviews             : 'Avis',
            },
            subscription: {
                expirationDate   : "Fin de l'abonnement*",
                plan             : 'Abonnement*',
                subscriptionDate : "Début de l'abonnement*",
                artisanPrio      : 'Artisan Prio',
                help             : '*requis',
            },
        },
        dashboardArtisan: {
            portfolio: {
                addItem          : 'Ajouter une réalisation',
                formSelection    : 'Quelle est votre réalisation ?',
                projectSelection : 'À quelle typologie de projet votre réalisation correspond-elle ?',
                serviceSelection : 'À quel service correspond votre réalisation ?',
                emptyWarning     : "L'onglet \"Réalisations\" est pour le moment désactivé sur votre page de profil car vous ne disposez d'aucune réalisation publiée.",
                emptyTitle       : 'Augmentez votre visibilité en ligne',
                emptySubtitle1   : '🚀Montrez vos réalisations',
                emptyContent1    : "LILM, aujourd'hui, c'est plus de 70 000 visiteurs mensuels, une source de trafic énorme pour votre atelier. Vous boostez votre visibilité sur le web en mettant en avant votre savoir-faire et vos créations.",
                emptySubtitle2   : '✨Inspirez vos futurs clients',
                emptyContent2    : "Lors de l'ajout d'un projet dans votre portfolio, vous définissez tous les critères propres à vos créations (dimensions, matériaux, finitions...). Les visiteurs LILM peuvent vous faire une demande de devis directement et en moins de 5 minutes !",
            },
            modalBudget: {
                title             : 'Budget/Gamme',
                subtitle          : 'Indicateur prix/qualité',
                standardFinishing : 'Je veux le prix le plus bas possible !',
                premiumFinishing  : 'Les deux, le meilleur rapport qualité prix !',
                luxeFinishing     : 'Je privilégie la qualité, je veux le meilleur !',
                conclusion        : "Vous retrouvez dans cette colonne le budget émis par le client. S'il n'a pas de budget en tête, un indicateur sur sa priorité entre le prix et la qualité.",
            },
            modalArchiveAuto: {
                title      : 'Archivage de vos demandes',
                subtitle   : 'On fait le ménage ✨',
                content    : 'Chaque jour, les clients sont toujours plus nombreux à déposer leurs projets sur LILM.<br/> <br/>Pour vous aider à y voir plus clair dans votre tableau "Mes demandes", les projets de +30 jours restés sans réponses, refusés, ou annulés seront automatiquement archivés.',
                conclusion : 'Vous pouvez toujours les retrouver dans ',
                enable     : "C'est parti",
            },
            stepper: {
                received       : 'Demandes reçues',
                pending        : 'À estimer',
                accepted       : 'Acceptées',
                quotes         : 'Devis',
                estimated      : 'Estimées',
                acceptedByUser : 'Acceptées',
            },
            titles: {
                [RouteKey.ArtisanApp]         : 'Formulaires personnalisés',
                [RouteKey.ArtisanProfile]     : 'Mon profil atelier',
                [RouteKey.ArtisanPortfolio]   : 'Mes réalisations',
                [RouteKey.ArtisanServices]    : 'Mes services',
                [RouteKey.ArtisanCompany]     : 'Mes coordonnées',
                [RouteKey.ArtisanSettings]    : "Adaptez l'application à vos besoins",
                [RouteKey.ArtisanStats]       : 'Analysez les tendances LILM',
                [RouteKey.ArtisanRequests]    : 'Mes demandes',
                [RouteKey.ArtisanMarketPlace] : 'Opportunités',
                [RouteKey.ArtisanHome]        : 'Bonjour {name}',
                [RouteKey.ArtisanDelivery]    : "Ma zone d'intervention",
                [RouteKey.ArtisanMaterials]   : 'Mes matériaux',
            },
            subtitles: {
                [RouteKey.ArtisanApp]         : 'Votre application personnalisée pour gérer simplement vos demandes clients',
                [RouteKey.ArtisanProfile]     : 'Entrez ici vos informations de profil publiques',
                [RouteKey.ArtisanPortfolio]   : 'Ajoutez ici vos réalisations',
                [RouteKey.ArtisanServices]    : 'Définissez ici vos typologies de projets',
                [RouteKey.ArtisanCompany]     : "Définissez vos informations d'entreprise",
                [RouteKey.ArtisanSettings]    : 'Définissez ici les critères de projets qui vous correspondent',
                [RouteKey.ArtisanStats]       : 'Cette page met à votre disposition les analyses de marché LILM',
                [RouteKey.ArtisanRequests]    : 'Cet espace centralise la gestion quotidienne de vos demandes client',
                [RouteKey.ArtisanHome]        : 'Bienvenue sur LILM',
                [RouteKey.ArtisanDelivery]    : "Déterminez ici votre zone d'intervention",
                [RouteKey.ArtisanMaterials]   : 'Déterminez ici les matériaux que vous utilisez',
                [RouteKey.ArtisanMarketPlace] : {
                    default  : 'Cet espace rassemble les dernières demandes client disponibles',
                    freemium : 'Une sélection des meilleurs projets selon votre profil : services, matériaux et zone d’intervention.',
                },
            },
        },
        declineReasons: {
            label                                                  : 'Motifs de refus',
            [RequestDeclineArtisanReasonId.BudgetTooLow]           : 'Budget client insuffisant',
            [RequestDeclineArtisanReasonId.CannotWorkTrayMaterial] : 'Je ne travaille pas ce matériau',
            [RequestDeclineArtisanReasonId.CannotWorkExtensions]   : 'Je ne réalise pas de rallonges',
            [RequestDeclineArtisanReasonId.CannotWorkLegsShape]    : 'Je ne travaille pas ce type de piètements',
            [RequestDeclineArtisanReasonId.ProjectTooComplex]      : 'La demande est trop complexe',
            [RequestDeclineArtisanReasonId.CannotWorkDimensions]   : 'Je ne peux pas travailler ces dimensions',
            [RequestDeclineArtisanReasonId.CannotMakeWhole]        : "Je ne peux réaliser qu'une partie de ce projet",
            [RequestDeclineArtisanReasonId.TooFar]                 : 'Je ne livre pas si loin de mon atelier',
            [RequestDeclineArtisanReasonId.NotAvailable]           : 'Mon planning est full ces prochaines semaines',
            [RequestDeclineArtisanReasonId.DeliveryTooShort]       : 'La date de livraison est trop courte',
            [RequestDeclineArtisanReasonId.CannotWorkStyle]        : 'Je ne réalise pas ce style ',
            [RequestDeclineArtisanReasonId.CannotMakeSeries]       : 'Notre atelier ne gère pas encore de commande en série',
            [RequestDeclineArtisanReasonId.Other]                  : 'Autre',
            matching                                               : 'Matching de la demande',
            quality                                                : 'Qualité de la demande',
            management                                             : 'Facteurs organisationnels',
            commentBudget                                          : 'Votre estimation (€)',
            comment                                                : 'Votre commentaire',
        },
        userDeclineReasons: {
            label                                              : 'Motifs de refus',
            where                                              : 'Où?',
            [RequestDeclineUserReasonId.AlreadyFoundAtLILM]    : 'J\'ai trouvé mon artisan chez LILM',
            [RequestDeclineUserReasonId.AlreadyFoundElsewhere] : 'J\'ai trouvé ailleurs',
            [RequestDeclineUserReasonId.BudgetTooHigh]         : 'Ce n\'est pas dans mon budget',
            [RequestDeclineUserReasonId.DeliveryTooLate]       : 'Les délais sont trop longs',
            [RequestDeclineUserReasonId.NoLongerRelevant]      : 'Mon projet n\'est plus d\'actualité',
            [RequestDeclineUserReasonId.Other]                 : 'Autre',
        },
        submission: {
            cancel: {
                title   : 'Annuler',
                notice  : "Vous êtes sur le point d'annuler cette demande et toutes les requêtes artisan associées.💣💥</br>Êtes-vous sûr?",
                confirm : 'Annuler la demande',
            },
            userCancel: {
                title   : '💣',
                notice  : 'Vous êtes sur le point de clôturer votre demande.',
                confirm : 'Confirmer',
                button  : 'Ne plus recevoir d\'offres',
            },
            success: {
                submissionClosed   : 'Vous ne recevrez plus d\'offres pour votre demande.',
                submissionCanceled : 'Votre demande a été annulée.',
            },
        },
        multiSelectorOptions: {
            [WineCellarFunctionalityId.WineCellarMultifunction] : 'Cave à vin multifonction',
            [WineCellarFunctionalityId.WineCellarAging]         : 'Cave à vin pour le vieillissement',
            [WineCellarFunctionalityId.WineCellarService]       : 'Cave à vin pour le service',
            [TerraceObstacleId.PlantsObstacle]                  : 'Végétaux',
            [TerraceObstacleId.StaircaseObstacle]               : 'Escalier',
            [TerraceObstacleId.ManholeCoverObstacle]            : "Bouche d'égout",
            [TerraceObstacleId.MoundObstacle]                   : 'Surface en pente ou butte',
            [TerraceObstacleId.PoolObstacle]                    : 'Piscine',
            [TerraceObstacleId.OtherObstacle]                   : 'Autre',
            [TerraceObstacleId.NoObstacle]                      : 'Pas de contraintes',
            [GarageDoorOptionsId.GarageDoorMotorization]        : 'Motorisation',
            [GarageDoorOptionsId.GarageDoorBlinker]             : 'Feu clignotant',
            [GarageDoorOptionsId.None]                          : 'Aucune',
            [FencingOptionId.FencingUseProtect]                 : 'Protéger votre intimité',
            [FencingOptionId.FencingUseDelimit]                 : 'Délimiter votre terrain',
            [FencingOptionId.FencingUseBring]                   : 'Apporter une touche esthétique',
            [FencingOptionId.FencingUseOther]                   : 'Autre',
        },
        interFieldsReasons: {
            [IReason.TrayMaterialConflictWithBorder]                 : 'Incompatible avec le type de bords',
            [IReason.TrayMaterialConflictWithOffsetExtension]        : 'Incompatible avec le type de rallonges',
            [IReason.TrayMaterialConflictWithCentralExtension]       : 'Incompatible avec le type de rallonges',
            [IReason.BorderConflictWithLiveEdgeMaterial]             : 'Incompatible avec le matériau de plateau',
            [IReason.BorderConflictWithAirplaneWingsMaterial]        : 'Incompatible avec le matériau de plateau',
            [IReason.ExtensionConflictWithRightCrossPieceLegsShape]  : 'Incompatible avec le type de pieds',
            [IReason.ExtensionConflictWithSquareCrossPieceLegsShape] : 'Incompatible avec le type de pieds',
            [IReason.ExtensionConflictWithTrestleCrossLegsShape]     : 'Incompatible avec le type de pieds',
            [IReason.ExtensionConflictWithRoundTrayShape]            : 'Incompatible avec la forme de plateau',
            [IReason.ExtensionConflictWithOvalTrayShape]             : 'Incompatible avec la forme de plateau',
            [IReason.ExtensionConflictWithTrayMaterial]              : 'Incompatible avec le matériau de plateau',
            [IReason.LegsShapeConflictWithExtension]                 : 'Incompatible avec les rallonges',
            [IReason.LegsShapeConflictWithTrayShape]                 : 'Incompatible avec la forme de plateau',
            [IReason.TrayShapeConflictWithLegsShape]                 : 'Incompatible avec le type de pieds',
            [IReason.TrayShapeConflictWithExtension]                 : 'Incompatible avec les rallonges',
            [IReason.TrayShapeConflictWithExtensionAngle]            : 'Incompatible avec le type de retour',
            [IReason.LegsShapeConflictLegsMaterial]                  : 'Incompatible avec le matériau de pieds',
            [IReason.LegsMaterialConflictLegsShape]                  : 'Incompatible avec le type de pieds',
        },
        furnitures: {
            [FurnitureId.TableDinner]     : 'Table à manger',
            [FurnitureId.TableTrayOnly]   : 'Plateau seul',
            [FurnitureId.TableLow]        : 'Table basse',
            [FurnitureId.TableExtendable] : 'Table extensible',
            [FurnitureId.TableBar]        : 'Table de bar',
            [FurnitureId.TableHigh]       : 'Table haute',
            [FurnitureId.TableMeeting]    : 'Table de réunion',
            [FurnitureId.TableDesk]       : 'Bureau',
            [FurnitureId.TableLegOnly]    : 'Pied seul',
            [FurnitureId.TablePedestal]   : 'Guéridon',
        },
        artisans: {
            title                      : 'Artisans',
            requests                   : 'Requêtes',
            requests30Days             : 'Requêtes 30 jours',
            requestsPending30days      : 'Request waiting 30d',
            requestsMarketplace30days  : 'Request Opp. 30d',
            requestsAuto30days         : 'Request M. Auto 30d',
            requestsManual30days       : 'Request M. Man. 30d',
            requests100Km30days        : 'Request 100Kms 30d',
            requestDate                : "Date d'envoi",
            requestStatus              : 'Statut',
            name                       : 'Nom',
            status                     : 'Status',
            enabled                    : 'actif',
            disabled                   : 'inactif',
            plan                       : 'Abonnement',
            email                      : 'Email',
            phone                      : 'Téléphone',
            slug                       : 'Profil',
            zip                        : 'Code postal',
            country                    : 'Pays',
            state                      : 'Code de département',
            lastConnection             : 'Dernière connexion',
            subscriptionDate           : 'Date adhésion',
            subscriptionExpirationDate : 'fin d\'abonnement',
            FREEMIUM                   : 'Freemium',
            START                      : 'Start',
            ATELIER                    : 'Atelier',
            'ATELIER+'                 : 'Atelier+',
            [PseudoPlanCommission]     : 'Commission',
        },
        user: {
            [IUserKey.Phone] : 'Téléphone',
            [IUserKey.Email] : 'Email',
        },
        roles: {
            name             : 'Rôle',
            [IUserRole.User] : {
                name: 'Particulier',
            },
            [IUserRole.Admin]: {
                name: 'Administrateur',
            },
        },
        countries: {
            [CountryId.FR]: {
                label        : 'France',
                labelFlagged : '🇫🇷 France',
                code         : 'FR',
                codeFlagged  : '🇫🇷 FR',
            },
            [CountryId.BE]: {
                label        : 'Belgique',
                labelFlagged : '🇧🇪 Belgique',
                code         : 'BE',
                codeFlagged  : '🇧🇪 BE',
            },
            [CountryId.CH]: {
                label        : 'Suisse',
                labelFlagged : '🇨🇭 Suisse',
                code         : 'CH',
                codeFlagged  : '🇨🇭 CH',
            },
            [CountryId.LU]: {
                label        : 'Luxembourg',
                labelFlagged : '🇱🇺 Luxembourg',
                code         : 'LU',
                codeFlagged  : '🇱🇺 LU',
            },
            [CountryId.IT]: {
                label        : 'Italie',
                labelFlagged : '🇮🇹 Italie',
                code         : 'IT',
                codeFlagged  : '🇮🇹 IT',
            },
        },
        submissionStatutes: {
            [SubmissionStatusId.Draft]             : 'Brouillon',
            [SubmissionStatusId.PendingAutoMatch]  : 'Matching auto',
            [SubmissionStatusId.PendingManuMatch]  : 'À traîter',
            pendingManuMatchStatusLilm             : 'Traîté',
            [SubmissionStatusId.Submitted]         : 'Envoyée',
            [SubmissionStatusId.Accepted]          : 'Acceptée',
            [SubmissionStatusId.Canceled]          : 'Annulée',
            [SubmissionStatusId.ClosedForRequests] : 'Fermée',
        },
        submissionSaleStatus           : SubmissionSaleStatusFR,
        contactQualificationSaleStatus : InternalContactQualificationStatusFR,
        qualificationSaleStatus        : InternalQualificationStatusFR,
        requestStatutes                : {
            [RequestStatusId.Pending]          : 'Envoyée',
            [RequestStatusId.Accepted]         : 'Acceptée',
            [RequestStatusId.Declined]         : 'Refusée',
            [RequestStatusId.Estimated]        : 'Estimée',
            [RequestStatusId.AcceptedByUser]   : 'Estimation acceptée',
            [RequestStatusId.DeclinedByUser]   : 'Estimation refusée',
            [RequestStatusId.QuoteSent]        : 'Devis reçue',
            [RequestStatusId.DepositPaid]      : 'Acompte versé',
            [RequestStatusId.ProjectBilled]    : 'Facture reçue',
            [RequestStatusId.ProjectDelivered] : 'Projet livré',
            [RequestStatusId.ProjectFinalized] : 'Projet finalisé',
            [RequestStatusId.Canceled]         : 'Projet annulée',
        },
        requestSources: {
            [RequestMatchingSourceId.Auto]        : 'Matching auto',
            [RequestMatchingSourceId.Manual]      : 'Matching manuel',
            [RequestMatchingSourceId.MarketPlace] : 'Place de marché',
            [RequestMatchingSourceId.User]        : 'Profil artisan',
            [RequestMatchingSourceId.External]    : 'App embarquée',
        },
        forms: {
            name             : 'Éléments',
            fieldsCategories : {
                specifications: {
                    title: 'Spécifications générales',
                },
                designProjects: {
                    title: "Éléments d'agencement",
                },
                tray: {
                    title: 'Plateau',
                },
                legs: {
                    title: 'Pieds',
                },
                extensions: {
                    title: 'Rallonges',
                },
                staircaseGuard: {
                    title: 'Garde-corps ',
                },
                dwelling: {
                    title: 'Projet',
                },
            },
            ...formsTranslations,
        },
        errors: {
            firebase: {
                [FirebaseErrorId.AuthEmailAlreadyInUse]        : 'Email indisponible',
                [FirebaseErrorId.AuthInvalidActionCode]        : 'Votre lien est périmé',
                [FirebaseErrorId.AuthInvalidContinueUri]       : 'Addresse de redirection invalide',
                [FirebaseErrorId.AuthInvalidCredentials]       : 'Combinaison email/mot de passe invalide',
                [FirebaseErrorId.AuthInvalidEmail]             : 'Email invalide',
                [FirebaseErrorId.AuthInvalidPassword]          : 'Mot de passe invalide',
                [FirebaseErrorId.AuthInvalidPhoneNumber]       : 'Numéro de téléphone invalide',
                [FirebaseErrorId.AuthInvalidVerificationCode]  : 'Code invalide',
                [FirebaseErrorId.AuthOperationNotAllowed]      : 'Opération impossible',
                [FirebaseErrorId.AuthPhoneNumberAlreadyExists] : 'Numéro de téléphone déjà utilisé par un autre compte',
                [FirebaseErrorId.AuthProviderAlreadyLinked]    : 'Un numéro de téléphone déjà renseigné pour ce compte',
                [FirebaseErrorId.AuthTooManyRequests]          : 'Trop de tentatives, veuillez réessayer plus tard.',
                [FirebaseErrorId.AuthUserDisabled]             : 'Utilisateur désactivé',
                [FirebaseErrorId.AuthUserNotFound]             : 'Utilisateur inexistant',
                [FirebaseErrorId.AuthWeakPassword]             : 'Mot de passe trop faible',
                [FirebaseErrorId.AuthWrongPassword]            : 'Mot de passe invalide',
                [FirebaseErrorId.AuthCodeExpired]              : 'Le code SMS a expiré',
                [FirebaseErrorId.PermissionDenied]             : "Vous n'avez pas les permissions suffisantes pour réaliser cette opération",
                [FirebaseErrorId.Aborted]                      : 'Opération annulée',
            },
            [InternalError.uploadFileSizeError] : 'Le poids du/des fichier(s) fait plus de 20Mo',
            missingUser                         : 'Utilisateur manquant',
            cancelationFailed                   : 'Oups! L\'annulation a échoué :(',
            dropzone                            : {
                'file-invalid-type' : 'format de fichier non valide',
                'file-too-large'    : 'Le fichier doit être inférieur à 10MB',
                'file-too-small'    : 'fichier trop petit',
                'too-many-files'    : 'trop de fichiers',
            },
        },
        components: {
            FormFieldDescription: {
                tilte: 'Mon atelier en quelque mots',
            },
            subscriptionPlan: {
                title             : 'Prêt pour le décollage vers LILM Pro ?! 🚀',
                titleFromEstimate : "Oops... vous n'êtes pas encore passé à LILM Pro ?",
                notice            : 'Vous souhaîtez estimer ce projet ? Accéder au contact du client ?<br/><strong>Envolez-vous vers le compte Premium,</strong> et accédez à toutes les fonctionnalités !',
                content           : 'Céline & Guillaume vous aident à <b>booster vos ventes</b> près de chez vous !',
                subtitle1         : 'Rejoignez nos <b>300 artisans</b> qui utilisent déjà LILM !',
                subtitle2         : 'Une question ?',
                titleSuccess      : 'À très bientôt dans la communauté LILM 👋',
                contentSuccess    : 'Nous avons bien reçu votre demande, un expert projet va vous recontacter rapidement.',
            },
            declineBrowsers: {
                title   : 'IL SEMBLE QUE VOTRE NAVIGATEUR SOIT UN PEU VIEUX.',
                content : 'Pour profiter de notre application dans les meilleures conditions, mettez à jour votre navigateur.',
            },
            UserSync: {
                signUpVerifyEmail : 'Bienvenue sur LILM {name} ! Nous vous avons envoyé un mail de confirmation afin de finaliser votre inscription.',
                signInSuccess     : 'Bonjour {name} ! Vous êtes bien connecté sur LILM et pouvez dès à présent donner vie à vos projets de mobilier !',
            },
            PasswordStrengthBar: {
                bad    : 'Mauvais',
                weak   : 'Faible',
                okay   : 'Moyen',
                good   : 'Bon',
                strong : 'Fort',
                short  : 'Trop court',
            },
            Account: {
                title                             : 'Profil',
                subtitle                          : 'Vous trouverez ci-dessous les informations personnelles liées à votre compte LILM.',
                [IUserKey.FirstName]              : 'Prénom',
                [IUserKey.LastName]               : 'Nom',
                [IUserKey.Phone]                  : 'Téléphone',
                [IUserKey.ShippingAddress]        : 'Adresse',
                [IUserKey.ShippingAddressCity]    : 'Ville',
                [IUserKey.ShippingAddressZip]     : 'Code postal',
                [IUserKey.ShippingAddressCountry] : 'Pays',
                [IUserKey.Email]                  : 'Email',
            },
            SignIn: {
                title               : 'Me connecter',
                subtitle            : "👋🏼 Content de vous revoir!<br/>Entrez vos informations personnelles ci-dessous afin d'accéder à votre compte LILM:",
                [IUserKey.Email]    : 'Email',
                [IUserKey.Password] : 'Mot de passe',
            },
            SignUp: {
                title    : 'Enregistrement',
                subtitle : {
                    default     : 'Entrez ci-dessous vos informations afin de créer votre compte LILM.',
                    linkPhone   : 'Ajouter votre numéro de téléphone à votre compte LILM.',
                    unlinkPhone : 'Supprimer le numéro de téléphone de votre compte LILM.',
                },
                code                : 'Code',
                getCode             : 'Vérifier mon numéro',
                verifyHelp          : 'Entrez le code à 6 chiffres que nous avons envoyé au {phoneNumber}.',
                verify              : 'Vérifier le code',
                unlink              : 'Supprimer',
                [IUserKey.Email]    : 'Email',
                [IUserKey.Password] : 'Mot de passe',
                [IUserKey.Phone]    : 'Téléphone',
            },
            FormComposer: {
                completeAnswer            : "Parfait ! J'ai maintenant toutes les informations nécessaires les meilleurs pro pour votre projet.",
                completeAnswerExternal    : "Parfait ! J'ai maintenant toutes les informations nécessaires pour répondre à votre projet.",
                completeSubmit            : 'Soumettre ma demande',
                import                    : 'Importer un modèle',
                export                    : 'Exporter le modèle',
                reset                     : 'Réinitialiser',
                recap                     : 'Détails du projet',
                sideStepperCatchExternal  : 'Votre projet sur-mesure par {artisanName}',
                sideStepperCatchFormGroup : {
                    [FormGroupId.Beds]                 : 'Votre lit par les meilleurs artisans',
                    [FormGroupId.Consoles]             : 'Votre console par les meilleurs artisans',
                    [FormGroupId.CounterBar]           : 'Votre comptoir de bar par les meilleurs artisans',
                    [FormGroupId.CustomMadeFurniture]  : 'Votre meuble sur-mesure par les meilleurs artisans',
                    [FormGroupId.Default]              : 'Votre projet sur-mesure par les meilleurs artisans',
                    [FormGroupId.Desk]                 : 'Votre bureau sur-mesure par les meilleurs artisans',
                    [FormGroupId.DpEvents]             : 'Votre agencement évènementiel par les meilleurs artisans',
                    [FormGroupId.DpHouse]              : 'Votre agencement de maison par les meilleurs artisans',
                    [FormGroupId.DpOfficeAndWorkspace] : 'Votre agencement de bureau et espace de travail par les meilleurs artisans',
                    [FormGroupId.DpRestaurant]         : 'Votre agencement CHR par les meilleurs artisans',
                    [FormGroupId.DpShop]               : 'Votre agencement de commerce par les meilleurs artisans',
                    [FormGroupId.GlassAndMirror]       : 'Votre projet en verre par les meilleurs artisans',
                    [FormGroupId.GlassWall]            : 'Votre verrière par les meilleurs artisans',
                    [FormGroupId.Library]              : 'Votre bibliothèque par les meilleurs artisans',
                    [FormGroupId.Pergola]              : 'Votre pergola par les meilleurs artisans',
                    [FormGroupId.Staircase]            : 'Votre escalier par les meilleurs artisans',
                    [FormGroupId.Table]                : 'Votre table sur-mesure par les meilleurs artisans',
                    [FormGroupId.Terrace]              : 'Votre terrasse par les meilleurs artisans',
                    [FormGroupId.TvUnit]               : 'Votre meuble de télévision par les meilleurs artisans',
                    [FormGroupId.WardrobesAndStorages] : 'Votre dressing ou rangement par les meilleurs artisans',
                    [FormGroupId.WindowBlinds]         : 'Votre store par les meilleurs artisans',
                    [FormGroupId.Worktop]              : 'Votre plan de travail par les meilleurs artisans',
                    [FormGroupId.Default]              : 'Votre projet sur-mesure par les meilleurs artisans',
                },
                sideStepperCatchForm: {
                    undefined                                 : 'Votre projet sur-mesure par les meilleurs artisans',
                    [PublishedFormId.Worktop]                 : 'Votre plan de travail linéaire par les meilleurs artisans',
                    [PublishedFormId.WorktopAssembled]        : 'Votre plan de travail assemblé par les meilleurs artisans',
                    [PublishedFormId.ChildBedroomLayout]      : 'Votre chambre d\'enfant par les meilleurs artisans',
                    [PublishedFormId.ClosetDoor]              : 'Votre porte de placard par les meilleurs artisans',
                    [PublishedFormId.Console]                 : 'Votre console par les meilleurs artisans',
                    [PublishedFormId.ConsoleSuspended]        : 'Votre console suspendu par les meilleurs artisans',
                    [PublishedFormId.CounterBar]              : 'Votre comptoir de bar droit par les meilleurs artisans',
                    [PublishedFormId.CounterBarAngle]         : 'Votre comptoir de bar avec angle par les meilleurs artisans',
                    [PublishedFormId.CounterBarCentral]       : 'Votre comptoir de bar en îlot par les meilleurs artisans',
                    [PublishedFormId.CounterBarCurved]        : 'Votre comptoir de bar arrondi par les meilleurs artisans',
                    [PublishedFormId.Credenza]                : 'Votre crédence par les meilleurs artisans',
                    [PublishedFormId.CustomBeds]              : 'Votre lit par les meilleurs artisans',
                    [PublishedFormId.CustomBlind]             : 'Votre store intérieur par les meilleurs artisans',
                    [PublishedFormId.CustomBlindOutdoor]      : 'Votre store extérieur par les meilleurs artisans',
                    [PublishedFormId.CustomBunkBed]           : 'Votre lit superposé par les meilleurs artisans',
                    [PublishedFormId.CustomMezzanine]         : 'Votre mezzanine par les meilleurs artisans',
                    [PublishedFormId.Desk]                    : 'Votre bureau par les meilleurs artisans',
                    [PublishedFormId.DeskExecutive]           : 'Votre bureau de direction par les meilleurs artisans',
                    [PublishedFormId.DpBakery]                : 'Votre agencement de boulangerie par les meilleurs artisans',
                    [PublishedFormId.DpBar]                   : 'Votre agencement de bar par les meilleurs artisans',
                    [PublishedFormId.DpBeautyInstitute]       : 'Votre agencement d\'institut de beauté par les meilleurs artisans',
                    [PublishedFormId.DpButcherShop]           : 'Votre agencement de boucherie par les meilleurs artisans',
                    [PublishedFormId.DpCheeseDairy]           : 'Votre agencement de fromagerie par les meilleurs artisans',
                    [PublishedFormId.DpEvents]                : 'Votre agencement d\'évènement par les meilleurs artisans',
                    [PublishedFormId.DpFastFood]              : 'Votre agencement de fast-food par les meilleurs artisans',
                    [PublishedFormId.DpGrocery]               : 'Votre agencement d\'épicerie fine par les meilleurs artisans',
                    [PublishedFormId.DpHairdresser]           : 'Votre agencement de salon de coiffure par les meilleurs artisans',
                    [PublishedFormId.DpHotel]                 : 'Votre agencement d\'hôtel par les meilleurs artisans',
                    [PublishedFormId.DpHouseInterior]         : 'Votre agencement d\'interieur de maison par les meilleurs artisans',
                    [PublishedFormId.DpHouseOutside]          : 'Votre agencement d\'extérieur de maison par les meilleurs artisans',
                    [PublishedFormId.DpRealEstate]            : 'Votre agencement d\'agence immobilière par les meilleurs artisans',
                    [PublishedFormId.DpRestaurant]            : 'Votre agencement de restaurant par les meilleurs artisans',
                    [PublishedFormId.DpShop]                  : 'Votre agencement de boutique par les meilleurs artisans',
                    [PublishedFormId.DpWineCellar]            : 'Votre agencement de cave à vin par les meilleurs artisans',
                    [PublishedFormId.DpWorkspace]             : 'Votre agencement d\'espace de travail par les meilleurs artisans',
                    [PublishedFormId.Fence]                   : 'Votre garde-corps par les meilleurs artisans',
                    [PublishedFormId.Fencing]                 : 'Votre clôture par les meilleurs artisans',
                    [PublishedFormId.GlassGreenHouse]         : 'Votre serre en verre par les meilleurs artisans',
                    [PublishedFormId.GlassShape]              : 'Votre découpe de verre par les meilleurs artisans',
                    [PublishedFormId.GlassWall]               : 'Votre verrière simple par les meilleurs artisans',
                    [DeprecatedFormId.GlassWallComplex]       : 'Votre verrière complexe par les meilleurs artisans',
                    [DeprecatedFormId.GlassWallWithBase]      : 'Votre verrière avec soubassement par les meilleurs artisans',
                    [DeprecatedFormId.GlassWallWithFrame]     : 'Votre verrière avec traverse par les meilleurs artisans',
                    [PublishedFormId.HighWind]                : 'Votre marquise par les meilleurs artisans',
                    [PublishedFormId.KitchenDesign]           : 'Votre cuisine par les meilleurs artisans',
                    [PublishedFormId.LibraryComposition]      : 'Votre bibliothèque personnalisée par les meilleurs artisans',
                    [DeprecatedFormId.LibraryWall]            : 'Votre bibliothèque murale par les meilleurs artisans',
                    [DeprecatedFormId.LibraryWithNiches]      : 'Votre bibliothèque à niches par les meilleurs artisans',
                    [DeprecatedFormId.LibraryWithShelves]     : 'Votre bibliothèque à étagères par les meilleurs artisans',
                    [PublishedFormId.LowTable]                : 'Votre table basse sur pieds par les meilleurs artisans',
                    [PublishedFormId.LowTableNoLegs]          : 'Votre table basse sans pieds par les meilleurs artisans',
                    [PublishedFormId.LowTableNesting]         : 'Votre table basse gigogne sur pieds par les meilleurs artisans',
                    [PublishedFormId.LowTableNestingNoLegs]   : 'Votre table basse gigogne sans pieds par les meilleurs artisans',
                    [PublishedFormId.MeetingTable]            : 'Votre table de réunion par les meilleurs artisans',
                    [PublishedFormId.Mirror]                  : 'Votre miroir par les meilleurs artisans',
                    [PublishedFormId.PartitionWall]           : 'Votre cloison de séparation par les meilleurs artisans',
                    [PublishedFormId.PavingSlab]              : 'Votre dalle de sol par les meilleurs artisans',
                    [PublishedFormId.PergolaBioclimatic]      : 'Votre pergola bioclimatique par les meilleurs artisans',
                    [PublishedFormId.PergolaClassic]          : 'Votre pergola sur-mesure par les meilleurs artisans',
                    [PublishedFormId.Shelf]                   : 'Votre étagère par les meilleurs artisans',
                    [PublishedFormId.ShelfShoes]              : 'Votre étagère à chaussure par les meilleurs artisans',
                    [PublishedFormId.ShoesCabinet]            : 'Votre armoire à chaussure par les meilleurs artisans',
                    [PublishedFormId.ShowerScreen]            : 'Votre paroi de douche par les meilleurs artisans',
                    [PublishedFormId.StaircaseHalfTurn]       : 'Votre escalier 1/2 tournant par les meilleurs artisans',
                    [PublishedFormId.StaircaseQuarterTurn]    : 'Votre escalier 1/4 tournant par les meilleurs artisans',
                    [PublishedFormId.StaircaseSpiralRound]    : 'Votre escalier hélicoïdal rond par les meilleurs artisans',
                    [PublishedFormId.StaircaseSpiralSquare]   : 'Votre escalier hélicoïdal carré par les meilleurs artisans',
                    [PublishedFormId.StaircaseStraight]       : 'Votre escalier par les meilleurs artisans',
                    [PublishedFormId.StaircaseTurnLanding]    : 'Votre escalier tournant avec palier par les meilleurs artisans',
                    [PublishedFormId.StorageUnderStairs]      : 'Votre rangement sous escalier par les meilleurs artisans',
                    [PublishedFormId.Table]                   : 'Votre table personnalisée par les meilleurs artisans',
                    [PublishedFormId.TableConcrete]           : 'Votre table en béton par les meilleurs artisans',
                    [PublishedFormId.TableFarm]               : 'Votre table de ferme par les meilleurs artisans',
                    [PublishedFormId.TableIndustrial]         : 'Votre table industrielle par les meilleurs artisans',
                    [PublishedFormId.TableLegs]               : 'Vos pieds de table par les meilleurs artisans',
                    [PublishedFormId.TableLiveEdge]           : 'Votre table "Live-Edge" par les meilleurs artisans',
                    [PublishedFormId.TableMarbleAndStone]     : 'Votre table marbre et pierre par les meilleurs artisans',
                    [PublishedFormId.TableMikado]             : 'Votre table mikado par les meilleurs artisans',
                    [PublishedFormId.TableRiverTable]         : 'Votre table "River Table" par les meilleurs artisans',
                    [PublishedFormId.TableScandinavian]       : 'Votre table scandinave par les meilleurs artisans',
                    [PublishedFormId.TableVintage]            : 'Votre table vintage par les meilleurs artisans',
                    [PublishedFormId.TerraceWood]             : 'Votre terrasse par les meilleurs artisans',
                    [PublishedFormId.Tray]                    : 'Votre plateau seul par les meilleurs artisans',
                    [PublishedFormId.TvUnitStand]             : 'Votre meuble télévision au sol par les meilleurs artisans',
                    [PublishedFormId.TvUnitSuspended]         : 'Votre meuble télévision suspendu par les meilleurs artisans',
                    [PublishedFormId.Veranda]                 : 'Votre veranda par les meilleurs artisans',
                    [PublishedFormId.WardrobeRoom]            : 'Votre dressing personnalisé par les meilleurs artisans',
                    [DeprecatedFormId.WardrobeUnderEaves]     : 'Votre dressing sous pente ou combles par les meilleurs artisans',
                    [PublishedFormId.Windows]                 : 'Votre fenêtre double vitrage par les meilleurs artisans',
                    [PublishedFormId.WineCellar]              : 'Votre cave à vin par les meilleurs artisans',
                    [PublishedFormId.WineCellarDedicatedRoom] : 'Votre cave à vin pour pièce dédiée par les meilleurs artisans',
                    [PublishedFormId.Worktop]                 : 'Votre plan de travail linéaire par les meilleurs artisans',
                    [PublishedFormId.WorktopAssembled]        : 'Votre plan de travail assemblé par les meilleurs artisans',
                },
                [ExtraStep.Intro]: {
                    intro                   : 'Bienvenue sur LILM 👋',
                    introArtisan            : 'Bonjour 👋',
                    introBis                : 'Je suis Mika ! Je vais vous aider à créer votre projet sur-mesure, en moins de 5 minutes, promis 😉.',
                    introBisArtisan         : 'Je suis Mika ! Je vais vous aider à faire votre demande de devis auprès du professionnel sélectionné {companyName}. Commençons par créer votre projet sur-mesure, en moins de 5 minutes, promis 😉.',
                    introBisArtisanApp      : 'Merci de faire confiance à {companyName} pour votre demande. Je vais vous aider à créer votre projet sur-mesure, en moins de 5 min promis 😉.',
                    introBisArtisanAppNamed : 'Je suis {artisanName} ! Merci de faire confiance à {companyName} pour votre demande. Je vais vous aider à créer votre projet sur-mesure, en moins de 5 min promis 😉.',
                    confirm                 : '',
                    answers                 : {
                        default                                   : 'Je voudrais concevoir un/une {formName}.',
                        [PublishedFormId.ChildBedroomLayout]      : "Je voudrais concevoir une chambre d'enfant.",
                        [PublishedFormId.ClosetDoor]              : 'Je voudrais concevoir une porte de placard.',
                        [PublishedFormId.Console]                 : 'Je voudrais concevoir une console.',
                        [PublishedFormId.ConsoleSuspended]        : 'Je voudrais concevoir une console suspendu.',
                        [PublishedFormId.CounterBar]              : 'Je voudrais concevoir un comptoir de bar droit.',
                        [PublishedFormId.CounterBarAngle]         : 'Je voudrais concevoir un comptoir de bar avec angle.',
                        [PublishedFormId.CounterBarCentral]       : 'Je voudrais concevoir un comptoir de bar en îlot.',
                        [PublishedFormId.CounterBarCurved]        : 'Je voudrais concevoir un comptoir de bar arrondi.',
                        [PublishedFormId.Credenza]                : 'Je voudrais concevoir une crédence.',
                        [PublishedFormId.CustomBeds]              : 'Je voudrais concevoir un lit.',
                        [PublishedFormId.CustomBlind]             : 'Je voudrais concevoir store intérieur',
                        [PublishedFormId.CustomBlindOutdoor]      : 'Je voudrais concevoir store extérieur',
                        [PublishedFormId.CustomBunkBed]           : 'Je voudrais concevoir un lit superposé.',
                        [PublishedFormId.CustomMezzanine]         : 'Je voudrais concevoir une mezzanine.',
                        [PublishedFormId.Desk]                    : 'Je voudrais concevoir un bureau.',
                        [PublishedFormId.DeskExecutive]           : 'Je voudrais concevoir un bureau de direction.',
                        [PublishedFormId.DpBakery]                : 'Je voudrais concevoir un agencement de boulangerie.',
                        [PublishedFormId.DpBar]                   : 'Je voudrais concevoir un agencement de bar.',
                        [PublishedFormId.DpBeautyInstitute]       : "Je voudrais concevoir un agencement d'institut de beauté.",
                        [PublishedFormId.DpButcherShop]           : 'Je voudrais concevoir un agencement de boucherie.',
                        [PublishedFormId.DpCheeseDairy]           : 'Je voudrais concevoir un agencement de fromagerie.',
                        [PublishedFormId.DpEvents]                : "Je voudrais concevoir un agencement d'évènement.",
                        [PublishedFormId.DpFastFood]              : 'Je voudrais concevoir un agencement de fast-food.',
                        [PublishedFormId.DpGrocery]               : "Je voudrais concevoir un agencement d'épicerie fine.",
                        [PublishedFormId.DpHairdresser]           : 'Je voudrais concevoir un agencement de salon de coiffure.',
                        [PublishedFormId.DpHotel]                 : "Je voudrais concevoir un agencement d'hôtel.",
                        [PublishedFormId.DpHouseInterior]         : "Je voudrais concevoir un agencement d'interieur de maison.",
                        [PublishedFormId.DpHouseOutside]          : "Je voudrais concevoir un agencement d'extérieur de maison.",
                        [PublishedFormId.DpRealEstate]            : "Je voudrais concevoir un agencement d'agence immobilière.",
                        [PublishedFormId.DpRestaurant]            : 'Je voudrais concevoir un agencement de restaurant.',
                        [PublishedFormId.DpShop]                  : 'Je voudrais concevoir un agencement de boutique.',
                        [PublishedFormId.DpWineCellar]            : 'Je voudrais concevoir un agencement de cave à vin.',
                        [PublishedFormId.DpWorkspace]             : "Je voudrais concevoir un agencement d'espace de travail.",
                        [PublishedFormId.Fence]                   : 'Je voudrais concevoir un garde-corps.',
                        [PublishedFormId.Fencing]                 : 'Je voudrais concevoir une clôture.',
                        [PublishedFormId.GlassGreenHouse]         : 'Je voudrais concevoir une serre en verre.',
                        [PublishedFormId.GlassShape]              : 'Je voudrais concevoir une découpe de verre.',
                        [PublishedFormId.GlassWall]               : 'Je voudrais concevoir une verrière simple.',
                        [DeprecatedFormId.GlassWallComplex]       : 'Je voudrais concevoir une verrière complexe.',
                        [DeprecatedFormId.GlassWallWithBase]      : 'Je voudrais concevoir une verrière avec soubassement.',
                        [DeprecatedFormId.GlassWallWithFrame]     : 'Je voudrais concevoir une verrière avec traverse.',
                        [PublishedFormId.HighWind]                : 'Je voudrais concevoir une marquise.',
                        [PublishedFormId.LibraryComposition]      : 'Je voudrais concevoir une bibliothèque personnalisée.',
                        [DeprecatedFormId.LibraryWall]            : 'Je voudrais concevoir une bibliothèque murale.',
                        [DeprecatedFormId.LibraryWithNiches]      : 'Je voudrais concevoir une bibliothèque à niches.',
                        [DeprecatedFormId.LibraryWithShelves]     : 'Je voudrais concevoir une bibliothèque à étagères.',
                        [PublishedFormId.LowTable]                : 'Je voudrais concevoir une table basse sur pieds.',
                        [PublishedFormId.LowTableNoLegs]          : 'Je voudrais concevoir une table basse sans pieds.',
                        [PublishedFormId.LowTableNesting]         : 'Je voudrais concevoir une table basse gigogne sur pieds.',
                        [PublishedFormId.LowTableNestingNoLegs]   : 'Je voudrais concevoir une table basse gigogne sans pieds.',
                        [PublishedFormId.MeetingTable]            : 'Je voudrais concevoir une table de réunion.',
                        [PublishedFormId.Mirror]                  : 'Je voudrais concevoir un miroir.',
                        [PublishedFormId.PartitionWall]           : 'Je voudrais concevoir une cloison de séparation.',
                        [PublishedFormId.PavingSlab]              : 'Je voudrais concevoir une dalle de sol.',
                        [PublishedFormId.PergolaBioclimatic]      : 'Je voudrais concevoir une pergola bioclimatique.',
                        [PublishedFormId.PergolaClassic]          : 'Je voudrais concevoir une pergola sur-mesure.',
                        [PublishedFormId.Shelf]                   : 'Je voudrais concevoir une étagère.',
                        [PublishedFormId.ShowerScreen]            : 'Je voudrais concevoir une paroi de douche.',
                        [PublishedFormId.StaircaseHalfTurn]       : 'Je voudrais concevoir un escalier 1/2 tournant.',
                        [PublishedFormId.StaircaseQuarterTurn]    : 'Je voudrais concevoir un escalier 1/4 tournant.',
                        [PublishedFormId.StaircaseSpiralRound]    : 'Je voudrais concevoir un escalier hélicoïdal rond.',
                        [PublishedFormId.StaircaseSpiralSquare]   : 'Je voudrais concevoir un escalier hélicoïdal carré.',
                        [PublishedFormId.StaircaseStraight]       : 'Je voudrais concevoir un escalier droit.',
                        [PublishedFormId.StaircaseTurnLanding]    : 'Je voudrais concevoir un escalier tournant avec palier.',
                        [PublishedFormId.StorageUnderStairs]      : 'Je voudrais concevoir un rangement sous escalier.',
                        [PublishedFormId.Table]                   : 'Je voudrais concevoir une table personnalisée.',
                        [PublishedFormId.TableConcrete]           : 'Je voudrais concevoir une table en béton.',
                        [PublishedFormId.TableFarm]               : 'Je voudrais concevoir une table de ferme.',
                        [PublishedFormId.TableIndustrial]         : 'Je voudrais concevoir une table industrielle.',
                        [PublishedFormId.TableLegs]               : 'Je voudrais concevoir des pieds de table.',
                        [PublishedFormId.TableLiveEdge]           : 'Je voudrais concevoir une table "Live-Edge".',
                        [PublishedFormId.TableMarbleAndStone]     : 'Je voudrais concevoir une table marbre et pierre.',
                        [PublishedFormId.TableMikado]             : 'Je voudrais concevoir une table mikado.',
                        [PublishedFormId.TableRiverTable]         : 'Je voudrais concevoir une table "River Table".',
                        [PublishedFormId.TableScandinavian]       : 'Je voudrais concevoir une table scandinave.',
                        [PublishedFormId.TableVintage]            : 'Je voudrais concevoir une table vintage.',
                        [PublishedFormId.TerraceWood]             : 'Je voudrais concevoir une terrasse.',
                        [PublishedFormId.Tray]                    : 'Je voudrais concevoir un plateau seul.',
                        [PublishedFormId.TvUnitStand]             : 'Je voudrais concevoir un meuble télévision au sol.',
                        [PublishedFormId.TvUnitSuspended]         : 'Je voudrais concevoir un meuble télévision suspendu.',
                        [PublishedFormId.Veranda]                 : 'Je voudrais concevoir une veranda.',
                        [PublishedFormId.WardrobeRoom]            : 'Je voudrais concevoir un dressing personnalisé.',
                        [DeprecatedFormId.WardrobeUnderEaves]     : 'Je voudrais concevoir un dressing sous pente ou combles.',
                        [PublishedFormId.Windows]                 : 'Je voudrais concevoir une fenêtre double vitrage.',
                        [PublishedFormId.WineCellar]              : 'Je voudrais concevoir une cave a vin.',
                        [PublishedFormId.WineCellarDedicatedRoom] : 'Je voudrais concevoir une cave a vin pour pièce dédiée.',
                        [PublishedFormId.Worktop]                 : 'Je voudrais concevoir un plan de travail linéaire.',
                        [PublishedFormId.WorktopAssembled]        : 'Je voudrais concevoir un plan de travail assemblé.',
                    },
                    titles: {
                        undefined                          : 'Quel type de projet souhaitez-vous réaliser ?',
                        [FormGroupId.Default]              : 'Quel type de projet souhaitez-vous réaliser ?',
                        [FormGroupId.Beds]                 : 'Quel type de lit souhaitez-vous réaliser ?',
                        [FormGroupId.Consoles]             : 'Quel type de console souhaitez-vous réaliser ?',
                        [FormGroupId.CounterBar]           : 'Quel type de comptoir de bar souhaitez-vous réaliser ?',
                        [FormGroupId.CustomMadeFurniture]  : 'Quel type de meuble sur-mesure souhaitez-vous réaliser ?',
                        [FormGroupId.Desk]                 : 'Quel type de bureau souhaitez-vous réaliser ?',
                        [FormGroupId.DpEvents]             : 'Quel type d\'agencement d\'évènement souhaitez-vous réaliser ?',
                        [FormGroupId.DpHouse]              : 'Quel type d\'agencement de maison souhaitez-vous réaliser ?',
                        [FormGroupId.DpOfficeAndWorkspace] : 'Quel type d\'agencement de bureau et espace de travail souhaitez-vous réaliser ?',
                        [FormGroupId.DpRestaurant]         : 'Quel type d\'agencement CHR souhaitez-vous réaliser ?',
                        [FormGroupId.DpShop]               : 'Quel type d\'agencement de boutique souhaitez-vous réaliser ?',
                        [FormGroupId.GlassAndMirror]       : 'Quel type de projet en verre souhaitez-vous réaliser ?',
                        [FormGroupId.GlassWall]            : 'Quel type de verrière souhaitez-vous réaliser ?',
                        [FormGroupId.Library]              : 'Quel type de bibliothèque souhaitez-vous réaliser ?',
                        [FormGroupId.Pergola]              : 'Quel type de pergola souhaitez-vous réaliser ?',
                        [FormGroupId.ShoesStorage]         : 'Quel type de meuble à chaussure souhaitez-vous réaliser ?',
                        [FormGroupId.Staircase]            : 'Quel type d\'escalier souhaitez-vous réaliser ?',
                        [FormGroupId.Table]                : 'Quel style de table souhaitez-vous réaliser ?',
                        [FormGroupId.Terrace]              : 'Quel type de terrasse souhaitez-vous réaliser ?',
                        [FormGroupId.TvUnit]               : 'Quel type de meuble de télévision souhaitez-vous réaliser ?',
                        [FormGroupId.WardrobesAndStorages] : 'Quel type de dressing ou rangement souhaitez-vous réaliser ?',
                        [FormGroupId.WindowBlinds]         : 'Quel type de store souhaitez-vous réaliser ?',
                        [FormGroupId.WineCellar]           : 'Quel type de cave à vin souhaitez-vous réaliser ?',
                        [FormGroupId.Worktop]              : 'Quel type de plan de travail souhaitez-vous réaliser ?',
                    },
                    labels: {
                        undefined                          : 'Projet',
                        [FormGroupId.Default]              : 'Projet',
                        [FormGroupId.Beds]                 : 'Type de lit',
                        [FormGroupId.Consoles]             : 'Type de console',
                        [FormGroupId.CounterBar]           : 'Type de comptoir de bar',
                        [FormGroupId.CustomMadeFurniture]  : 'Meuble',
                        [FormGroupId.Desk]                 : 'Type de bureau',
                        [FormGroupId.DpEvents]             : "Type d'agencement",
                        [FormGroupId.DpHouse]              : "Type d'agencement",
                        [FormGroupId.DpOfficeAndWorkspace] : "Type d'agencement",
                        [FormGroupId.DpRestaurant]         : "Type d'agencement",
                        [FormGroupId.DpShop]               : "Type d'agencement",
                        [FormGroupId.GlassAndMirror]       : 'Type de projet en verre',
                        [FormGroupId.GlassWall]            : 'Type de verrière',
                        [FormGroupId.Library]              : 'Type de bibliothèque ',
                        [FormGroupId.Pergola]              : 'Type de pergola',
                        [FormGroupId.ShoesStorage]         : 'Meuble à chaussure',
                        [FormGroupId.Staircase]            : "Type d'escalier",
                        [FormGroupId.Table]                : 'Style',
                        [FormGroupId.Terrace]              : 'Type de terrasse',
                        [FormGroupId.TvUnit]               : 'Type de meuble',
                        [FormGroupId.WardrobesAndStorages] : 'Type de dressing/rangement',
                        [FormGroupId.WindowBlinds]         : 'Type de store',
                        [FormGroupId.WineCellar]           : 'Type de cave à vin',
                        [FormGroupId.Worktop]              : 'Type de plan de travail',
                    },
                },
            },
            FormFieldRadioList: {
                radioListOptions: {
                    [DeckBoardWidthId.TwelveToFifteen]: {
                        title  : 'Entre 12 et 15 cm',
                        label  : 'Entre 12 et 15 cm',
                        answer : 'Je souhaite entre 12 et 15 cm.',
                    },
                    [DeckBoardWidthId.FifteenToTwenty]: {
                        title  : 'Entre 15 et 20 cm',
                        label  : 'Entre 15 et 20 cm',
                        answer : 'Je souhaite entre 15 et 20 cm',
                    },
                    [DeckBoardWidthId.TwentyToTwentyFive]: {
                        title  : 'Entre 20 et 25 cm',
                        label  : 'Entre 20 et 25 cm',
                        answer : 'Je souhaite entre 20 et 25 cm',
                    },
                    [PergolaRoofId.ClassicCanvas]: {
                        title  : 'Toile classique',
                        label  : 'Toile classique',
                        answer : 'Je souhaite une toile classique',
                    },
                    [PergolaRoofId.RollUpCanvas]: {
                        title  : 'Toile enroulable',
                        label  : 'Toile enroulable',
                        answer : 'Je souhaite une toile enroulable',
                    },
                    [PergolaRoofId.GlassRoof]: {
                        title  : 'Toit vitré',
                        label  : 'Toit vitré',
                        answer : 'Je souhaite un toit vitré',
                    },
                    [PergolaRoofId.AdjustableBlades]: {
                        title  : 'Lames orientables',
                        label  : 'Lames orientables',
                        answer : 'Je souhaite des lames orientables',
                    },
                    [WindowOpeningSideId.WindowOpeningRight]: {
                        title  : 'Tirant droit',
                        label  : 'Tirant droit',
                        answer : 'Je souhaite un tirant droit',
                    },
                    [WindowOpeningSideId.WindowOpeningLeft]: {
                        title  : 'Tirant gauche',
                        label  : 'Tirant gauche',
                        answer : 'Je souhaite un tirant gauche',
                    },
                    [WindowOpeningSideId.WindowOpeningCasement]: {
                        title  : 'Ouverture battante',
                        label  : 'Ouverture battante',
                        answer : 'Je souhaite une ouverture battante',
                    },
                    [WindowOpeningSideId.WindowOpeningSliding]: {
                        title  : 'Ouverture coulissante',
                        label  : 'Ouverture coulissante',
                        answer : 'Je souhaite une ouverture coulissante',
                    },
                    [WindowOpeningSideId.WindowOpeningFrench]: {
                        title  : 'Ouverture à la Française',
                        label  : 'Ouverture à la Française',
                        answer : 'Je souhaite une ouverture à la Française',
                    },
                    [WindowOpeningSideId.WindowOpeningSwingTilting]: {
                        title  : 'Ouverture oscillo battante',
                        label  : 'Ouverture oscillo battante',
                        answer : 'Je souhaite une ouverture oscillo battante',
                    },
                    [WindowOpeningSideId.WindowOpeningToggle]: {
                        title  : 'Ouverture bascullante',
                        label  : 'Ouverture bascullante',
                        answer : 'Je souhaite une ouverture bascullante',
                    },
                    [WindowOpeningSideId.WindowOpeningNone]: {
                        title  : 'Aucun tirant',
                        label  : 'Aucun tirant',
                        answer : 'Je ne souhaite aucun tirant',
                    },
                    [WindowGlazingId.WindowSingleGlazing]: {
                        title  : 'Simple vitrage',
                        label  : 'Simple vitrage',
                        answer : 'Je souhaite un simple vitrage',
                    },
                    [WindowGlazingId.WindowDoubleGlazing]: {
                        title  : 'Double vitrage',
                        label  : 'Double vitrage',
                        answer : 'Je souhaite un double vitrage',
                    },
                    [WindowGlazingId.WindowTripleGlazing]: {
                        title  : 'Triple Vitrage',
                        label  : 'Triple Vitrage',
                        answer : 'Je souhaite un triple Vitrage',
                    },
                    [WindowGlazingId.WindowSoundproofGlazing]: {
                        title  : 'Vitrage phonique',
                        label  : 'Vitrage phonique',
                        answer : 'Je souhaite un vitrage phonique',
                    },
                    [WindowGlazingId.WindowSafetyGlazing]: {
                        title  : 'Vitrage de sécurité',
                        label  : 'Vitrage de sécurité',
                        answer : 'Je souhaite vitrage de sécurité',
                    },
                    [WindowInsulationId.WindowStandardInsulation]: {
                        title  : 'Standard',
                        label  : 'Standard',
                        answer : 'Je souhaite une isolation standard',
                    },
                    [WindowInsulationId.WindowEfficientInsulation]: {
                        title  : 'Performante',
                        label  : 'Performante',
                        answer : 'Je souhaite une isolation performante',
                    },
                    [WindowInsulationId.WindowUltraEfficientInsulation]: {
                        title  : 'Ultra performante',
                        label  : 'Ultra performante',
                        answer : 'Je souhaite une isolation ultra performante',
                    },
                    [WindowBlindsOptionsId.FillingUniform]: {
                        title  : 'Uniforme',
                        label  : 'Uniforme',
                        answer : 'Je souhaite un remplissage uniforme',
                    },
                    [WindowBlindsOptionsId.FillingOpenwork]: {
                        title  : 'Ajouré',
                        label  : 'Ajouré',
                        answer : 'Je souhaite un remplissage ajouré',
                    },
                    [WindowBlindsOptionsId.IntegralChest]: {
                        title  : 'Coffre intégral',
                        label  : 'Coffre intégral',
                        answer : 'Je souhaite un coffre intégral',
                    },
                    [WindowBlindsOptionsId.SemiIntegralChest]: {
                        title  : 'Coffre semi intégral',
                        label  : 'Coffre semi intégral',
                        answer : 'Je souhaite un coffre semi intégral',
                    },
                    [WindowBlindsOptionsId.WithoutChest]: {
                        title  : 'Sans coffre de protection',
                        label  : 'Sans coffre de protection',
                        answer : 'Je ne souhaite pas de coffre de protection',
                    },
                    [WindowBlindsOptionsId.MotorAndControl]: {
                        title  : 'Moteur et télécommande',
                        label  : 'Moteur et télécommande',
                        answer : 'Je souhaite avec moteur et télécommande',
                    },
                    [WindowBlindsOptionsId.MotorAndSwitch]: {
                        title  : 'Moteur et interrupteur',
                        label  : 'Moteur et interrupteur',
                        answer : 'Je souhaite avec moteur et interrupteur',
                    },
                    [WindowBlindsOptionsId.Manual]: {
                        title  : 'Manuelle',
                        label  : 'Manuelle',
                        answer : 'Je souhaite Manuelle',
                    },
                    [WindowBlindsOptionsId.FrontOnWall]: {
                        title  : 'De face sur un mur',
                        label  : 'De face sur un mur',
                        answer : 'De face sur un mur',
                    },
                    [WindowBlindsOptionsId.UnderSlab]: {
                        title  : 'Au plafond sous une dalle',
                        label  : 'Au plafond sous une dalle',
                        answer : 'Au plafond sous une dalle',
                    },
                    [WindowBlindsOptionsId.Other]: {
                        title  : 'Autre',
                        label  : 'Autre',
                        answer : 'Autre',
                    },
                    [ClosetDoorSensId.ClosetDoorRight]: {
                        title  : 'Droite ',
                        label  : 'Droite ',
                        answer : 'Je ne souhaite une ouverture à droite',
                    },
                    [ClosetDoorSensId.ClosetDoorLeft]: {
                        title  : 'Gauche',
                        label  : 'Gauche',
                        answer : 'Je ne souhaite une ouverture à gauche',
                    },
                    [FencingOptionId.FencingPoseWall]: {
                        title  : 'Sur un mur bas ',
                        label  : 'Sur un mur bas ',
                        answer : 'Je ne souhaite une sur un mur bas ',
                    },
                    [FencingOptionId.FencingPoseGround]: {
                        title  : 'Au sol',
                        label  : 'Au sol',
                        answer : 'Je ne souhaite une au sol',
                    },
                    [FencingOptionId.FencingPoseOther]: {
                        title  : 'Autre',
                        label  : 'Autre',
                        answer : 'Je ne souhaite une autre pose',
                    },
                    [KitchenProjectTypeId.Renovation]: {
                        title  : 'Une rénovation',
                        label  : 'Rénovation',
                        answer : 'Mon projet concerne une rénovation',
                    },
                    [KitchenProjectTypeId.Construction]: {
                        title  : 'Une construction',
                        label  : 'Construction',
                        answer : 'Mon projet concerne une construction',
                    },
                    [FinishingMaterialOptionId.FinishingMatt]: {
                        title  : 'Finition mat',
                        label  : 'Finition',
                        answer : 'Finition mat',
                    },
                    [FinishingMaterialOptionId.FinishingNatural]: {
                        title  : 'Finition naturelle',
                        label  : 'Finition',
                        answer : 'Finition naturelle',
                    },
                    [FinishingMaterialOptionId.FinishingGlossy]: {
                        title  : 'Finition brillante',
                        label  : 'Finition',
                        answer : 'Finition brillante',
                    },
                    [FinishingMaterialOptionId.FinishingOther]: {
                        title  : 'Autre',
                        label  : 'Finition',
                        answer : 'Autre',
                    },
                    applianceLevelOption: {
                        [ApplianceLevelOptionId.Eco]: {
                            name    : '<h2>Economique</h2>',
                            title   : 'Economique',
                            label   : 'Gamme électroménager',
                            answer  : 'Je souhaite la gamme économique.',
                            recap   : 'Economique (Beko, Schneider, Candy...) ',
                            content : '<img src="/resized-images/logo/beko-logo-tablet.png" /> <img src="/resized-images/logo/schneider-logo-tablet.png" /> <img src="/resized-images/logo/candy-logo-tablet.png" />',
                        },
                        [ApplianceLevelOptionId.Premium]: {
                            name    : '<h2>Premium</h2>',
                            title   : 'Premium',
                            label   : 'Gamme électroménager',
                            answer  : 'Je souhaite la gamme premium',
                            recap   : 'Confort (Electrolux, Siemens, Whirlpool...) ',
                            content : '<img src="/resized-images/logo/electrolux-logo-tablet.png" /> <img src="/resized-images/logo/siemens-logo-tablet.png" /> <img src="/resized-images/logo/whirlpool-logo-tablet.png" />',
                        },
                        [ApplianceLevelOptionId.Luxe]: {
                            name    : '<h2>Luxe</h2>',
                            title   : 'Luxe',
                            label   : 'Gamme électroménager',
                            answer  : 'Je souhaite la gamme luxe',
                            recap   : 'Luxe (Liebherr, Miele, Gaggenau...)',
                            content : '<img src="/resized-images/logo/liebherr-logo-tablet.png" /> <img src="/resized-images/logo/miele-logo-tablet.png" /> <img src="/resized-images/logo/gaggenau-logo-tablet.png" />',
                        },
                    },
                    budgetOption: {
                        titles: {
                            gamme        : 'Gamme',
                            implantation : 'Implantation',
                            material     : 'Matériaux',
                        },
                        [BudgetOptionId.Eco]: {
                            name         : '<h2>Moins de 5000€</h2>',
                            title        : 'Moins de 5000€',
                            gamme        : '<h3>Economique</h3>Une cuisine fonctionnelle pour les petits budgets.',
                            implantation : 'Linéraire - En L',
                            material     : 'Stratifié',
                            label        : 'Budget',
                            answer       : 'J\'ai un budget de moins de 5000€',
                        },
                        [BudgetOptionId.Premium]: {
                            name         : '<h2>Entre 5 000€ et 10 000€</h2>',
                            title        : 'Entre 5 000€ et 10 000€',
                            gamme        : '<h3>Confort</h3>Le meilleur rapport qualité/prix pour une cuisine dans l\'ère du temps.',
                            implantation : 'Toutes les implantations',
                            material     : 'Stratifié',
                            label        : 'Budget',
                            answer       : 'J\'ai un budget entre 5 000€ et 10 000€',
                        },
                        [BudgetOptionId.High]: {
                            name         : '<h2>Entre 10 000€ et 15 000€</h2>',
                            title        : 'Entre 10 000€ et 15 000€',
                            gamme        : '<h3>Haut de gamme</h3>Haute qualité de finitions et matériaux bruts pour une cuisine de rêve.',
                            implantation : 'Toutes les implantations',
                            material     : 'Stratifié - Pierres naturelles - Céramique',
                            label        : 'Budget',
                            answer       : 'J\'ai un budget entre 10 000€ et 15 000€',
                        },
                        [BudgetOptionId.Luxe]: {
                            name         : '<h2>15 000€ et plus</h2>',
                            title        : '15 000€ et plus',
                            gamme        : '<h3>Luxe</h3>Le nec plus ultra des matériaux et de l\'électroménager, pour une cuisine exceptionnelle.',
                            implantation : 'Toutes les implantations',
                            material     : 'Stratifié - Laques - Pierres naturelles - Céramique - Inox',
                            label        : 'Budget',
                            answer       : 'J\'ai un budget de 15 000€ et plus',
                        },
                    },
                },
            },
            FormFieldProjectInformation: {
                [IFormFieldProjectInformationKey.Laying]: {
                    label : 'Service de pose',
                    title : 'Souhaitez-vous la pose ?',
                },
                [IFormFieldProjectInformationKey.Accompaniment]: {
                    label    : 'Service décoration intérieure',
                    title    : 'Souhaitez-vous être accompagné par un décorateur d\'intérieur, à partir de 99€ ?',
                    content1 : '● Un accompagnement personnalisé avec un décorateur d\'intérieur',
                    content2 : '● Une expertise des dernieres tendances',
                    content3 : '● Des conseils en décoration et aménagement',
                    content4 : '● Recevez un devis détaillé avec une shopping list',
                },
                [IFormFieldProjectInformationKey.Property]: {
                    label                    : 'Type d\'habitation',
                    labelAlternate           : 'Vous habitez ?',
                    title                    : 'Vous habitez ?',
                    [PropertyOptionId.House] : {
                        title  : 'Une maison',
                        recap  : 'Maison',
                        answer : 'J\' habite une maison.',
                    },
                    [PropertyOptionId.Flat]: {
                        title  : 'Un appartement',
                        recap  : 'Appartement',
                        answer : 'J\' habite un appartement.',
                    },
                },
                [IFormFieldProjectInformationKey.OwnerTenant]: {
                    label                       : 'Statut',
                    title                       : 'Vous êtes ?',
                    [OwnerTenantOptionId.Owner] : {
                        title  : 'Propriétaire',
                        label  : 'Propriétaire',
                        answer : 'Je suis propriétaire.',
                    },
                    [OwnerTenantOptionId.Tenant]: {
                        title  : 'Locataire',
                        label  : 'Locataire',
                        answer : 'Je suis locataire.',
                    },
                },
            },
            FormFieldComment: {
                comment: {
                    label  : 'Mon projet personnalisé',
                    title  : 'Dernière étape avant de recevoir un devis !',
                    title2 : 'Vous pouvez personnaliser votre projet avec les dimensions, matériaux, finitions :',
                },
            },
            FormFieldAdditionalData: {
                [IFormFieldAdditionalDataKey.Quantity]: {
                    value    : '{quantity} exemplaire(s)',
                    label    : 'Quantité',
                    question : {
                        default                                 : "Combien d'exemplaires souhaitez-vous ?",
                        [PublishedFormId.Table]                 : "Combien d'exemplaires de cette table souhaitez-vous ?",
                        [PublishedFormId.TableIndustrial]       : "Combien d'exemplaires de cette table industrielle souhaitez-vous ?",
                        [PublishedFormId.TableScandinavian]     : "Combien d'exemplaires de cette table scandinave souhaitez-vous ?",
                        [PublishedFormId.TableLiveEdge]         : 'Combien d\'exemplaires de cette table "Live-Edge" souhaitez-vous ?',
                        [PublishedFormId.TableRiverTable]       : 'Combien d\'exemplaires de cette "River Table" souhaitez-vous ?',
                        [PublishedFormId.TableFarm]             : "Combien d'exemplaires de cette table de ferme souhaitez-vous ?",
                        [PublishedFormId.TableConcrete]         : "Combien d'exemplaires de cette table en béton souhaitez-vous ?",
                        [PublishedFormId.TableMarbleAndStone]   : "Combien d'exemplaires de cette table souhaitez-vous ?",
                        [PublishedFormId.TableMikado]           : "Combien d'exemplaires de cette table mikado souhaitez-vous ?",
                        [PublishedFormId.TableVintage]          : "Combien d'exemplaires de cette table vintage souhaitez-vous ?",
                        [PublishedFormId.TableLegs]             : "Combien d'exemplaires de ces pieds de table souhaitez-vous ?",
                        [PublishedFormId.Tray]                  : "Combien d'exemplaires de ce plateau souhaitez-vous ?",
                        [PublishedFormId.Desk]                  : "Combien d'exemplaires de ce bureau souhaitez-vous ?",
                        [PublishedFormId.DeskExecutive]         : "Combien d'exemplaires de ce bureau souhaitez-vous ?",
                        [PublishedFormId.MeetingTable]          : "Combien d'exemplaires de cette table de réunion souhaitez-vous ?",
                        [PublishedFormId.CounterBar]            : "Combien d'exemplaires de ce comptoir souhaitez-vous ?",
                        [PublishedFormId.CounterBarCentral]     : "Combien d'exemplaires de ce comptoir souhaitez-vous ?",
                        [PublishedFormId.CounterBarCurved]      : "Combien d'exemplaires de ce comptoir souhaitez-vous ?",
                        [PublishedFormId.CounterBarAngle]       : "Combien d'exemplaires de ce comptoir souhaitez-vous ?",
                        [PublishedFormId.Worktop]               : "Combien d'exemplaires de ce plan de travail souhaitez-vous ?",
                        [PublishedFormId.WorktopAssembled]      : "Combien d'exemplaires de ce plan de travail souhaitez-vous ?",
                        [PublishedFormId.WardrobeRoom]          : "Combien d'exemplaires de ce dressing souhaitez-vous ?",
                        [DeprecatedFormId.WardrobeUnderEaves]   : "Combien d'exemplaires de ce dressing souhaitez-vous ?",
                        [PublishedFormId.StorageUnderStairs]    : "Combien d'exemplaires de ce rangement souhaitez-vous ?",
                        [PublishedFormId.LibraryComposition]    : "Combien d'exemplaires de cette bibliothèque souhaitez-vous ?",
                        [DeprecatedFormId.LibraryWall]          : "Combien d'exemplaires de cette bibliothèque souhaitez-vous ?",
                        [DeprecatedFormId.LibraryWithNiches]    : "Combien d'exemplaires de cette bibliothèque souhaitez-vous ?",
                        [DeprecatedFormId.LibraryWithShelves]   : "Combien d'exemplaires de cette bibliothèque souhaitez-vous ?",
                        [PublishedFormId.LowTable]              : 'Combien d\'exemplaires de cette table basse sur pieds souhaitez-vous ?',
                        [PublishedFormId.LowTableNoLegs]        : 'Combien d\'exemplaires de cette table basse sans pieds souhaitez-vous ?',
                        [PublishedFormId.LowTableNesting]       : 'Combien d\'exemplaires de cette table basse gigogne sur pieds souhaitez-vous ?',
                        [PublishedFormId.LowTableNestingNoLegs] : 'Combien d\'exemplaires de cette table basse gigogne sans pieds souhaitez-vous ?',
                        [PublishedFormId.GlassWall]             : "Combien d'exemplaires de cette verrière souhaitez-vous ?",
                        [DeprecatedFormId.GlassWallWithFrame]   : "Combien d'exemplaires de cette verrière souhaitez-vous ?",
                        [DeprecatedFormId.GlassWallWithBase]    : "Combien d'exemplaires de cette verrière souhaitez-vous ?",
                        [DeprecatedFormId.GlassWallComplex]     : "Combien d'exemplaires de cette verrière souhaitez-vous ?",
                        [PublishedFormId.TvUnitSuspended]       : "Combien d'exemplaires de ce meuble de télévision souhaitez-vous ?",
                        [PublishedFormId.TvUnitStand]           : "Combien d'exemplaires de ce meuble de télévision souhaitez-vous ?",
                        [PublishedFormId.ClosetDoor]            : "Combien d'exemplaires de cette porte placard souhaitez-vous ?",
                        [PublishedFormId.Credenza]              : "Combien d'exemplaires de cette crédence souhaitez-vous ?",
                        [PublishedFormId.Fence]                 : "Combien d'exemplaires de ce garde-corps souhaitez-vous ?",
                        [PublishedFormId.GlassGreenHouse]       : "Combien d'exemplaires de cette serre souhaitez-vous ?",
                        [PublishedFormId.GlassShape]            : "Combien d'exemplaires de cette découpe souhaitez-vous ?",
                        [PublishedFormId.HighWind]              : "Combien d'exemplaires de cette marquise souhaitez-vous ?",
                        [PublishedFormId.Mirror]                : "Combien d'exemplaires de ce miroir souhaitez-vous ?",
                        [PublishedFormId.PartitionWall]         : "Combien d'exemplaires de cette cloison souhaitez-vous ?",
                        [PublishedFormId.PergolaClassic]        : "Combien d'exemplaires de cette pergola souhaitez-vous ?",
                        [PublishedFormId.PergolaBioclimatic]    : "Combien d'exemplaires de cette pergola bioclimatique souhaitez-vous ?",
                        [PublishedFormId.PavingSlab]            : "Combien d'exemplaires de cette dalle souhaitez-vous ?",
                        [PublishedFormId.Shelf]                 : "Combien d'exemplaires de cette étagère souhaitez-vous ?",
                        [PublishedFormId.ShowerScreen]          : "Combien d'exemplaires de cette paroi souhaitez-vous ?",
                        [PublishedFormId.Veranda]               : "Combien d'exemplaires de cette veranda souhaitez-vous ?",
                        [PublishedFormId.CustomBlind]           : "Combien d'exemplaires de ce store intérieur souhaitez-vous ?",
                        [PublishedFormId.CustomBlindOutdoor]    : "Combien d'exemplaires de ce store extérieur souhaitez-vous ?",
                    },
                    answer: 'Je souhaite {quantity} exemplaire(s).',
                },
                [IFormFieldAdditionalDataKey.LookingFor]: {
                    label                        : 'Votre recherche',
                    labelAlternate               : 'Recherche',
                    title                        : 'Quel est votre besoin ?',
                    [LookingForOptionId.Curious] : {
                        title  : 'Je veux une estimation de mon projet',
                        label  : 'Curieux',
                        answer : 'Je souhaite une estimation de mon projet.',
                    },
                    [LookingForOptionId.Serious]: {
                        title  : 'Je cherche un professionnel pour réaliser mon projet',
                        label  : 'Serieux',
                        answer : 'Je souhaite trouver un professionnel pour la réalisation de mon projet.',
                    },
                    [LookingForOptionId.BankQuote]: {
                        title  : 'J\'ai besoin d\'un devis pour ma banque',
                        label  : 'Devis banque',
                        answer : 'J\'ai besoin d\'un devis pour ma banque.',
                    },
                },
                [IFormFieldAdditionalDataKey.Emergency]: {
                    label                        : 'Délai de livraison',
                    labelAlternate               : 'Pour dans',
                    title                        : "Pouvez-vous m'en dire plus sur le délai de livraison souhaité ?",
                    [EmergencyOptionId.OneMonth] : {
                        title  : 'Au plus tôt, délai minimum de 1 mois.',
                        label  : '1 mois',
                        answer : 'Je souhaite 1 mois de délai.',
                    },
                    [EmergencyOptionId.ThreeMonth]: {
                        title  : 'Le plus rapidement, dans les 3 mois.',
                        label  : '3 mois',
                        answer : 'Je souhaite 3 mois de délai.',
                    },
                    [EmergencyOptionId.SixMonth]: {
                        title  : 'Dans les 6 mois.',
                        label  : '6 mois',
                        answer : 'Je souhaite 6 mois de délai.',
                    },
                    [EmergencyOptionId.MoreSixMonth]: {
                        title  : 'Plus de 6 mois.',
                        label  : '+ 6 mois',
                        answer : 'Je souhaite plus de 6 mois de délai.',
                    },
                },
                [IFormFieldAdditionalDataKey.Finishing]: {
                    label                        : 'Niveau de gamme',
                    labelAlternate               : 'Gamme',
                    title                        : 'Vous cherchez plutôt le prix ou la qualité ?',
                    [FinishingOptionId.Basic]    : 'Basique : le plus économique',
                    [FinishingOptionId.Standard] : 'Je veux le prix le plus bas possible',
                    [FinishingOptionId.Premium]  : 'Les deux, le meilleur rapport qualité prix !',
                    [FinishingOptionId.Luxe]     : 'Je privilégie la qualité, je veux le meilleur !',
                },
                [IFormFieldAdditionalDataKey.Budget]: {
                    label         : 'Budget',
                    value         : '{budget}€',
                    answer        : "J'ai un budget de {budget}€.",
                    title         : 'Avez-vous un budget pour ce projet ? Cela peut aider les artisans à optimiser votre projet.',
                    titleExternal :
            "Un budget à ne pas dépasser ? Cela peut m'aider à optimiser votre projet avec des solutions adaptées.",
                    null              : 'Budget indéfini',
                    nullAnswer        : "Non, je n'ai pas de budget défini.",
                    hasBudget         : 'Oui, je connais mon budget.',
                    hasBudgetQuestion : 'Les artisans répondent plus facilement aux projets avec un budget défini. Ils pourront ainsi vous proposer des optimisations sur les matériaux ou les finitions. Vous pouvez leur faire confiance 🤝 <br/>Quel est le budget à ne pas dépasser ?',
                },
                [IFormFieldAdditionalDataKey.Comment]: {
                    label         : 'Commentaire',
                    title         : 'D\'autres informations à transmettre aux pros ?',
                    titleExternal : 'Des informations complémentaires ?',
                    null          : 'Pas de commentaire',
                    nullAnswer    : 'Pas de commentaire à ajouter.',
                },
                [IFormFieldAdditionalDataKey.UploadFile]: {
                    label : "Image d'inspiration",
                    title :
            "Une image vaut mille mots ! Ajoutez une photo d'inspiration, un croquis ou un plan.",
                    null       : "Pas d'image",
                    nullAnswer : "Pas d'image à ajouter.",
                    upload     : 'Envoyer un fichier',
                    fileAnswer : "Oui, j'ai une image, la voici:",
                },
                [IFormFieldAdditionalDataKey.IsPrivate]: {
                    label     : 'Votre demande',
                    title     : "Voulez-vous recevoir des devis d'autres artisans ?",
                    answerYes : "Oui, je souhaite recevoir des devis d'autres artisans.",
                    answerNo  :
            "Non, je ne souhaite pas recevoir de devis d'autres artisans.",
                },
            },
            CalculateNumberOfSeat: {
                label: 'Places assises',
            },
            UploadFiles: {
                label      : 'Plan, inspiration',
                title      : 'Si vous avez des plans ou des photos de votre cuisine déposez-les ici :',
                null       : "Pas d'image",
                nullAnswer : "Pas d'image à ajouter.",
                upload     : 'Envoyer un fichier',
                fileAnswer : "Oui, j'ai une image, la voici:",
            },
            FormFieldDimension: {
                [FieldDimensionId.Thickness]       : 'Épaisseur',
                [FieldDimensionId.ThicknessTable2] : 'Épaisseur deuxième table',
                [FieldDimensionId.Width]           : 'Largeur',
                [FieldDimensionId.WidthTable2]     : 'Largeur deuxième table',
                [FieldDimensionId.Length]          : 'Longueur',
                [FieldDimensionId.LengthTable2]    : 'Longueur deuxième table',
                [FieldDimensionId.Height]          : 'Hauteur',
                [FieldDimensionId.HeightTable2]    : 'Hauteur deuxième table',
                [FieldDimensionId.Depth]           : 'Profondeur',
                [FieldDimensionId.Diameter]        : 'Diamètre',
                [FieldDimensionId.DiameterTable2]  : 'Diamètre deuxième table',
                [FieldDimensionId.Radius]          : 'Rayon',
                [FieldDimensionId.LengthReturn]    : 'Longueur du retour',
                [FieldDimensionId.DepthReturn]     : 'Profondeur du retour',
                [FieldDimensionId.LengthReturn1]   : 'Longueur du 1er retour',
                [FieldDimensionId.DepthReturn1]    : 'Profondeur du 1er retour',
                [FieldDimensionId.LengthReturn2]   : 'Longueur du 2nd retour',
                [FieldDimensionId.DepthReturn2]    : 'Profondeur du 2nd retour',
                [FieldDimensionId.Width1]          : '➊ Largeur',
                [FieldDimensionId.Width2]          : '➋ Largeur',
                [FieldDimensionId.Width3]          : '➌ Largeur',
                [FieldDimensionId.Width4]          : '➍ Largeur',
                [FieldDimensionId.Width5]          : '➎ Largeur',
                [FieldDimensionId.Width1Table2]    : '➊ Largeur deuxième table',
                [FieldDimensionId.Width2Table2]    : '➋ Largeur deuxième table',
                [FieldDimensionId.Width3Table2]    : '➌ Largeur deuxième table',
                [FieldDimensionId.Length1]         : '➊ Longueur',
                [FieldDimensionId.Length2]         : '➋ Longueur',
                [FieldDimensionId.Length3]         : '➌ Longueur',
                [FieldDimensionId.Length4]         : '➍ Longueur',
                [FieldDimensionId.Height1]         : '➊ Hauteur',
                [FieldDimensionId.Height2]         : '➋ Hauteur',
                [FieldDimensionId.Height3]         : '➌ Hauteur',
                [FieldDimensionId.Height4]         : '➍ Hauteur',
                [FieldDimensionId.Height5]         : '➎ Hauteur',
                [FieldDimensionId.HeightRoofTop]   : 'Hauteur au faîte',
                [FieldDimensionId.HeightWall]      : 'Hauteur paroi',
                [FieldDimensionId.HeightBase]      : 'Hauteur de soubassement',
                [FieldDimensionId.HeightCeiling]   : 'Hauteur sous plafond',
                [FieldDimensionId.HeightTotal]     : 'Hauteur totale',
                [FieldDimensionId.Depth1]          : '➊ Profondeur',
                [FieldDimensionId.Depth2]          : '➋ Profondeur',
                [FieldDimensionId.Depth3]          : '➌ Profondeur',
                [FieldDimensionId.Depth4]          : '➍ Profondeur',
                [FieldDimensionId.HeightStaircase] : 'Hauteur du sol au plafond',
                [FieldDimensionId.WidthStaircase]  : 'Largeur au sol',
                [FieldDimensionId.LengthStaircase] : 'Longueur au sol',
                [FieldDimensionId.Degrees]         : 'Température',
                [FieldDimensionId.Humidity]        : 'Humidité',
                [FieldDimensionId.SquareMeter]     : 'Surface en m²',
                [FieldDimensionId.Diagonal]        : 'Diagonale',
                [FieldDimensionId.Diagonal1]       : '➊ Diagonale',
                [FieldDimensionId.Diagonal2]       : '➋ Diagonale',
                [FieldDimensionId.Diagonal3]       : '➌ Diagonale',
                [FieldDimensionId.WallLength1]     : '➊ Longueur du mur 1',
                [FieldDimensionId.WallLength2]     : '➋ Longueur du mur 2',
                [FieldDimensionId.WallLength3]     : '➌ Longueur du mur 3',
                [FieldDimensionId.WallLength4]     : '➍ Longueur du mur 4',
                [FieldDimensionId.WallLength5]     : '➎ Longueur du mur 5',
                [FieldDimensionId.WallLength6]     : '➏ Longueur du mur 6',
                defaultValues                      : {
                    [IFormFieldDimensionDefaultValueKey.CoffeeTable]       : 'Table basse',
                    [IFormFieldDimensionDefaultValueKey.Table]             : 'Table repas',
                    [IFormFieldDimensionDefaultValueKey.BarTable]          : 'Table haute',
                    [IFormFieldDimensionDefaultValueKey.StandardDiameter]  : 'Diamètre standard',
                    [IFormFieldDimensionDefaultValueKey.StandardHeight]    : 'Hauteur standard',
                    [IFormFieldDimensionDefaultValueKey.StandardWidth]     : 'Largeur standard',
                    [IFormFieldDimensionDefaultValueKey.StandardHeight]    : 'Hauteur standard',
                    [IFormFieldDimensionDefaultValueKey.StandardLength]    : 'Longueur standard',
                    [IFormFieldDimensionDefaultValueKey.StandardDepth]     : 'Profondeur standard',
                    [IFormFieldDimensionDefaultValueKey.StandardThickness] : 'Epaisseur standard',
                    [IFormFieldDimensionDefaultValueKey.MediumThickness]   : 'Epaisseur moyenne',
                    [IFormFieldDimensionDefaultValueKey.LargeThickness]    : 'Grande épaisseur',
                    [IFormFieldDimensionDefaultValueKey.BabyBed]           : 'Lit bébé',
                    [IFormFieldDimensionDefaultValueKey.ChildBed]          : 'Lit enfant',
                    [IFormFieldDimensionDefaultValueKey.TeenagerBed]       : 'Lit adolescent',
                    [IFormFieldDimensionDefaultValueKey.AdultBed]          : 'Lit adulte',
                    [IFormFieldDimensionDefaultValueKey.StandardBed]       : 'Standard',
                    [IFormFieldDimensionDefaultValueKey.QueenSizeBed]      : 'Queen size',
                    [IFormFieldDimensionDefaultValueKey.KingSizeBed]       : 'King size',
                    [IFormFieldDimensionDefaultValueKey.SuperKingSizeBed]  : 'Super king size',
                },
            },
            FormFieldMaterial: {
                title         : 'Matériaux',
                subtitle      : 'Matériaux structure',
                categories    : 'Quel/quelle {material} désirez-vous ?',
                classes       : '{material}, très bien 👍 Et plus précisément ?',
                materials     : 'Ok ! Choisissez maintenant votre {material} !',
                materialsType : 'Ok ! Choisissez maintenant votre gamme !',
            },
            FormFieldShape: {
                title: 'Forme',
            },
            FormFieldContact: {
                [IFormFieldContactKey.Persona] : 'Je suis',
                personaRecap                   : 'Type de client',
                personaOptions                 : {
                    [PersonaId.InteriorDesigner] : "Architecte d'intérieur/Prescripteur",
                    [PersonaId.Reseller]         : 'Revendeur',
                    [PersonaId.Professional]     : 'Professionnel',
                    [PersonaId.Particular]       : 'Particulier',
                },
                [IFormFieldContactKey.FirstName]    : 'Prénom',
                [IFormFieldContactKey.LastName]     : 'Nom',
                [IFormFieldContactKey.Email]        : 'Email',
                [IFormFieldContactKey.Phone]        : 'Téléphone',
                [IFormFieldContactKey.Availability] : 'Comment vous joindre ?',
                availabilityRecap                   : 'Disponibilités',
                availabilityOptions                 : {
                    [AvailabilityOptionId.NoPreference]        : "Je n'ai pas de préférence",
                    [AvailabilityOptionId.ByPhoneNineToTwelve] : 'Le matin, entre 09h00 et 12h00',
                    [AvailabilityOptionId.ByPhoneTwelveToTwo]  : 'Le midi, entre 12h00 et 14h00',
                    [AvailabilityOptionId.ByPhoneTwoToSix]     : "L'aprés-midi, entre 14h00 et 18h00",
                    [AvailabilityOptionId.ByPhoneAfterSix]     : 'Le soir, après 18h00',
                    [AvailabilityOptionId.ByMail]              : '📧 Par mail',
                },
                firstContact                                  : '1er contact avec le client',
                [IFormFieldContactKey.ShippingAddress]        : 'Adresse',
                [IFormFieldContactKey.ShippingAddressCity]    : 'Ville',
                [IFormFieldContactKey.ShippingAddressZip]     : 'Code postal',
                [IFormFieldContactKey.ShippingAddressCountry] : 'Pays de livraison',
                [IFormFieldContactKey.ShippingAddressFloor]   : 'Étage',
                floorOptions                                  : {
                    [ShippingFloorId.Rdc]       : 'Rdc',
                    [ShippingFloorId.First]     : '1',
                    [ShippingFloorId.Second]    : '2',
                    [ShippingFloorId.Third]     : '3',
                    [ShippingFloorId.Fourth]    : '4',
                    [ShippingFloorId.Fifth]     : '5',
                    [ShippingFloorId.FifthPlus] : '6 et +',
                },
                [IFormFieldContactKey.ShippingAddressAccess] : 'Accès',
                accessOptions                                : {
                    [ShippingAccessId.Easy] : "Facile d'accès",
                    [ShippingAccessId.Hard] : "Difficile : entrée ou cage d'escalier étroite",
                },
            },
            FormFieldRecap: {
                title : 'Détails du projet',
                type  : 'Type de projet',
            },
            SuccessPage: {
                title : 'Votre projet est entre de bonnes mains !',
                body1 : 'Nous recherchons les meilleurs professionnels à proximité.',
                body2 : 'Ils vont prendre contact avec vous dans les plus brefs délais',
            },
            WelcomePage: {
                headerImageAlt : 'Inspiration image',
                title          : 'Meuble sur-mesure, aménagement et travaux',
                subtitle       : 'Trouvez le meilleur artisan près de chez vous.',
                body1          : '🤝 Devis gratuit et sans engagement.',
                body2          : '✨ Des ateliers rigoureusement sélectionnés par notre équipe.',
                body3          : '🌱 Des meubles éco-responsables, conçus avec des matériaux durables.',
            },
        },
    },
};
