import {
    FC, useState, useEffect, useCallback,
} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SyncIcon from '@material-ui/icons/Sync';
import Loadable, { LoadingComponentProps } from 'react-loadable';
import { FormattedMessage, useIntl } from 'react-intl';

import StyledButton from '@ui/StyledButton';
import Spinner from '@ui/Spinner';
import SnackbarContent from '../SnackbarContent';
import { ISnackbarContentMode } from '../SnackbarContent/types';
import { buttonStyle } from './styles';

const LazyComponentLoading: FC<LoadingComponentProps> = ({
    error,
    timedOut,
    pastDelay,
}) => {
    const intl = useIntl();

    const [timedOutOrError, setTimedOutOrError] = useState<boolean>(false);

    const resetTimedOutOrError = useCallback(() => { setTimedOutOrError(false); }, [setTimedOutOrError]);

    const handleWindowReload = useCallback(() => { window.location.reload(true); }, []);

    useEffect(() => setTimedOutOrError(!!error || !!timedOut), [error, timedOut]);

    if (timedOutOrError) {
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={timedOutOrError}
                onClose={resetTimedOutOrError}
            >
                <SnackbarContent
                    mode={ISnackbarContentMode.Info}
                    message={(
                        <>
                            <FormattedMessage id="outdatedAppVersion" />
                            <StyledButton
                                style={buttonStyle}
                                type="button"
                                aria-label={intl.formatMessage({ id: 'upgrade' })}
                                onClick={handleWindowReload}
                            >
                                <SyncIcon />
                                <FormattedMessage id="upgrade" />
                            </StyledButton>
                        </>
                    )}
                    onClose={resetTimedOutOrError}
                />
            </Snackbar>
        );
    }

    if (pastDelay) return <Spinner />;

    return null;
};

// @ts-ignore
const LazyComponentLoader = (options: any) => Loadable({
    ...options,
    loading : LazyComponentLoading,
    timeout : 10000,
});

export default LazyComponentLoader;
