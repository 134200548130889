export const artisanProfileFR = {
    title                : '{companyName}, {job} à {city}, {country} - LILM',
    titleWithStateNumber : '{companyName}, {job} à {city} ({stateNumber}), {country} - LILM',
    description          : 'Vous recherchez un {job} {state} ? Trouvez un professionnel de confiance près de chez vous : demandez un devis à {companyName}',
    descriptionTitle     : '{companyName} en quelques mots',
    projectBy            : '{num} projet par {companyName}',
    projectsBy           : '{num} projets par {companyName}',
    reviews              : 'Avis sur {companyName}',
    projectPage          : {
        title                          : '{projectName} par {companyName} à {city} - LILM',
        description                    : 'Découvrez le projet de {projectName} : {projectDescription}',
        otherProjectsOf                : 'Autres projets de {companyName}',
        primaryImageTitle              : '{projectName} {companyName} {formName} - LILM',
        primaryImageAlt                : '{formName} en {materials} par {companyName} à {place} - LILM',
        primaryImageAltWithoutMaterial : '{formName} par {companyName} à {place} - LILM',
        bannerAlt                      : 'Demander un devis à {companyName}, {job}',
    },
};

export default artisanProfileFR;
