import { IFormFieldKey } from '../interfaces/Form';

export const fieldsPortfolioFR: {[key in IFormFieldKey]: any} = {
    additionalData     : undefined,
    contact            : undefined,
    projectInformation : undefined,
    uploadFiles        : undefined,
    budget             : undefined,
    applianceLevel     : undefined,
    comment            : undefined,
    portfolioData      : {
        question: {
            default: 'Décrivez votre réalisation',
        },
        label: 'Description',
    },
    description: {
        question: {
            default: 'Décrivez votre réalisation',
        },
        label: 'Description',
    },
    trayMaterial: {
        question: {
            default               : 'Quel matériau pour le plateau ?',
            tableScandinavian     : 'Quel matériau pour le plateau de table scandinave ?',
            tableLiveEdge         : 'Quel matériau pour le plateau de table "Live-Edge" ?',
            tableRiverTable       : 'Quel matériau pour le plateau de table "River Table" ?',
            tableFarm             : 'Quel matériau pour le plateau de table de ferme ?',
            tableIndustrial       : 'Quel matériau pour le plateau de table industrielle ?',
            tableConcrete         : 'Quel matériau pour le plateau de table en béton ?',
            tableMarbleAndStone   : 'Quel matériau pour le plateau de table ?',
            tableMikado           : 'Quel matériau pour le plateau de table Mikado ?',
            tableVintage          : 'Quel matériau pour le plateau de table vintage ?',
            table                 : 'Quel matériau pour le plateau de table personnalisée ?',
            tray                  : 'Quel matériau pour le plateau ?',
            meetingTable          : 'Quel matériau pour le plateau de table de réunion ?',
            desk                  : 'Quel matériau pour le plateau de bureau ?',
            deskExecutive         : 'Quel matériau pour le plateau de bureau ?',
            lowTable              : 'Quel matériau pour votre table basse ?',
            lowTableNoLegs        : 'Quel matériau pour votre table basse ?',
            lowTableNesting       : 'Quel matériau pour votre table basse gigogne ?',
            lowTableNestingNoLegs : 'Quel matériau pour votre table basse gigogne ?',
        },
        label: 'Matériau',
    },
    windowMaterial: {
        question: {
            default: 'Quel type de verre ?',
        },
        label: 'Verre',
    },
    legsMaterial: {
        question: {
            default: 'Quel matériau pour les pieds ?',
        },
        label: 'Matériau des pieds',
    },
    wineCellarMaterial: {
        question: {
            default: 'Quel matériau avez-vous utilisé pour la structure de votre cave à vin ?',
        },
        label: 'Matériau structure',
    },
    wineCellarShelfMaterial: {
        question: {
            default: 'Quel matériau pour les clayettes ?',
        },
        label: 'Matériau clayettes',
    },
    wineCellarDegrees: {
        question: {
            default: 'Connaissez-vous la température ambiante de la pièce de destination ?',
        },
        label             : 'Température',
        dimensionQuestion : 'La température ambiante de la pièce de destination ?',
    },
    wineCellarHumidity: {
        question: {
            default: 'Connaissez-vous le taux d\'humidité de la pièce de destination ?',
        },
        label             : 'Taux d\'humidité',
        dimensionQuestion : 'Le taux d\'humidité de la pièce de destination de cette cave à vin ?',
    },
    wineCellarSquareMeter: {
        question: {
            default: 'Connaissez-vous la surface de cette cave à vin ?',
        },
        label             : 'Surface en m²',
        dimensionQuestion : 'La surface en m² de la cave à vin ?',
    },
    wineCellarLightingExpose: {
        question: {
            default: 'Cette cave à vin était-elle exposée à de la lumière naturelle ?',
        },
        label: 'Luminosité',
    },
    wineCellarShape: {
        question: {
            default: 'La forme de la cave à vin ?',
        },
        label: 'Forme',
    },
    material: {
        question: {
            default               : 'Quel matériau avez-vous utilisé ?',
            staircaseStraight     : 'Quel matériau pour les marches d\'escalier ?',
            staircaseHalfTurn     : 'Quel matériau pour les marches d\'escalier ?',
            staircaseQuarterTurn  : 'Quel matériau pour les marches d\'escalier ?',
            staircaseSpiralRound  : 'Quel matériau pour les marches d\'escalier ?',
            staircaseSpiralSquare : 'Quel matériau pour les marches d\'escalier ?',
            staircaseTurnLanding  : 'Quel matériau pour les marches d\'escalier ?',
            mirror                : 'Quel type de verre pour votre miroir ?',
            closetDoor            : 'Quel matériau pour le remplissage ?',
            portal                : 'Quel matériau pour le portail ?',
        },
        label: 'Matériau',
    },
    frameMaterial: {
        question: {
            default: 'Quel matériau pour la structure ?',
        },
        label: 'Structure',
    },
    wineCellarPose: {
        question: {
            default: 'Quel type de pose ?',
        },
        label: 'Pose',
    },
    wineCellarNbDoor: {
        question: {
            default: 'Combien de portes pour cette cave à vin ?',
        },
        label: 'Nombre de portes',
    },
    wineCellarRoom: {
        question: {
            default: 'Dans quelle pièce avez-vous aménagé cette cave à vin ?',
        },
        label: 'Pièce dédiée',
    },
    wineCellarDoor: {
        question: {
            default: 'Quel style de porte pour cette cave à vin ?',
        },
        label: 'Style de porte',
    },
    wineCellarSensDoor: {
        question: {
            default: 'Quel sens d\'ouverture pour cette porte ?',
        },
        label: 'Sens ouverture',
    },
    wineCellarShelf: {
        question: {
            default: 'Quel type de clayettes ?',
        },
        label: 'Clayettes',
    },
    wineCellarNbBottle: {
        question: {
            default: 'La capacité de cette cave à vin ?',
        },
        label: 'Nombre de bouteilles',
    },
    trayShapeDimension: {
        question: {
            default             : 'Quelle forme de plateau ?',
            tableScandinavian   : 'Quelle forme pour le plateau de table scandinave ?',
            tableLiveEdge       : 'Quelle forme pour le plateau de table "Live-Edge" ?',
            tableRiverTable     : 'Quelle forme pour le plateau de "River Table" ?',
            tableFarm           : 'Quelle forme pour le plateau de table de ferme ?',
            tableIndustrial     : 'Quelle forme pour le plateau de table industrielle ?',
            tableConcrete       : 'Quelle forme pour le plateau de table en béton ?',
            tableMarbleAndStone : 'Quelle forme pour le plateau de table ?',
            tableMikado         : 'Quelle forme pour le plateau de table Mikado ?',
            tableVintage        : 'Quelle forme pour le plateau de table vintage ?',
            table               : 'Quelle forme pour le plateau de table personnalisée ?',
            tray                : 'Quelle forme pour le plateau ?',
            meetingTable        : 'Quelle forme pour le plateau de table de réunion ?',
            desk                : 'Quelle forme pour le plateau de bureau ?',
            deskExecutive       : 'Quelle forme pour le plateau de bureau ?',
        },
        label             : 'Plateau',
        dimensionQuestion : 'Quelles dimensions pour ce plateau ?',
    },
    shapeDimension: {
        question: {
            default               : 'Quelle forme ?',
            pavingSlab            : 'Quelle forme de dalle ?',
            lowTable              : 'Quel forme de table basse ?',
            lowTableNoLegs        : 'Quel forme de table basse ?',
            lowTableNesting       : 'Quel forme de table basse gigogne ?',
            lowTableNestingNoLegs : 'Quel forme de table basse gigogne ?',
            portal                : 'Combien de vantaux ? ',
        },
        label             : 'Forme',
        dimensionQuestion : 'Quelles dimensions ?',
    },
    trayShape: {
        question: {
            default             : 'Quelle forme de plateau ?',
            tableScandinavian   : 'Quelle forme pour le plateau de table scandinave ?',
            tableLiveEdge       : 'Quelle forme pour le plateau de table "Live-Edge" ?',
            tableRiverTable     : 'Quelle forme pour le plateau de "River Table" ?',
            tableFarm           : 'Quelle forme pour le plateau de table de ferme ?',
            tableIndustrial     : 'Quelle forme pour le plateau de table industrielle ?',
            tableConcrete       : 'Quelle forme pour le plateau de table en béton ?',
            tableMarbleAndStone : 'Quelle forme pour le plateau de table ?',
            tableMikado         : 'Quelle forme pour le plateau de table Mikado ?',
            tableVintage        : 'Quelle forme pour le plateau de table vintage ?',
            table               : 'Quelle forme pour le plateau de table personnalisée ?',
            tray                : 'Quelle forme pour le plateau ?',
            meetingTable        : 'Quelle forme pour le plateau de table de réunion ?',
            desk                : 'Quelle forme pour le plateau de bureau ?',
            deskExecutive       : 'Quelle forme pour le plateau de bureau ?',
        },
        label: 'Forme de plateau',
    },
    shape: {
        question: {
            default: 'Quelle forme ?',
        },
        label: 'Forme',
    },
    glassWallStyle: {
        question: {
            default: 'Quel style pour votre verrière ?',
        },
        label: 'Style',
    },
    closingType: {
        question: {
            default: 'Quel type de fermetures ?',
        },
        label: 'Fermetures',
    },
    legsShape: {
        question: {
            default: 'Quel type de pieds ?',
        },
        label: 'Type de pieds',
    },
    border: {
        question: {
            default: 'Quelle forme pour les bords ?',
        },
        label: 'Bords',
    },
    trayDimension: {
        question: {
            default: 'Quelles dimensions pour le plateau ?',
        },
        label: 'Dimensions de plateau',
    },
    connectics: {
        question: {
            default: 'Avez-vous ajouté des connectiques ?',
        },
        label: 'Connectique',
    },
    legsDimension: {
        question: {
            default: 'Quelle hauteur pour ces pieds (épaisseur de plateau incluse) ?',
        },
        label: 'Hauteur des pieds',
    },
    dimension: {
        question: {
            default                 : 'Quelles dimensions ?',
            wineCellarDedicatedRoom : 'Quelle hauteur sous plafond minimale ?',
            customBlindOutdoor      : 'Quelles dimensions pour le store ?',
        },
        label: 'Dimensions',
    },
    cutting: {
        question: {
            default: 'Des découpes ?',
        },
        label: 'Découpe',
    },
    openings: {
        question: {
            default: 'Des ouvertures ?',
        },
        label: 'Ouvertures',
    },
    addOns: {
        question: {
            default                 : 'Avez-vous ajouté des éléments ?',
            libraryWithNiches       : 'Combien de niches avez-vous ajoutées ?',
            libraryWithShelves      : 'Combien d\'étagères avez-vous ajoutées ?',
            staircaseStraight       : 'Avez-vous ajouté des options à votre escalier ?',
            staircaseSpiralRound    : 'Avez-vous ajouté des options à votre escalier ?',
            staircaseSpiralSquare   : 'Avez-vous ajouté des options à votre escalier ?',
            staircaseTurnLanding    : 'Avez-vous ajouté des options à votre escalier ?',
            staircaseQuarterTurn    : 'Avez-vous ajouté des options à votre escalier ?',
            staircaseHalfTurn       : 'Avez-vous ajouté des options à votre escalier ?',
            wineCellar              : 'Avez-vous ajouté des options à votre cave à vin ?',
            wineCellarDedicatedRoom : 'Avez-vous ajouté des options à votre cave à vin ?',
            lowTable                : 'Avez-vous ajouté des options à votre table basse ?',
            lowTableNoLegs          : 'Avez-vous ajouté des options à votre table basse ?',
            lowTableNesting         : 'Avez-vous ajouté des options à votre table basse gigogne ?',
            lowTableNestingNoLegs   : 'Avez-vous ajouté des options à votre table basse gigogne ?',
        },
        label: 'Options',
    },
    obstacles: {
        question: {
            default: 'Des obstacles éventuels ?',
        },
        label: 'Obstacles',
    },
    limonShape: {
        question: {
            default: 'Quel type de limon ?',
        },
        label: 'Limon',
    },
    plainSteps: {
        question: {
            default: 'Des contremarches ?',
        },
        label: 'Contremarches',
    },
    stepsDimension: {
        question: {
            default: 'Quelles largeur pour les marches ?',
        },
        label: 'Largeur des marches',
    },
    staircaseSideTurn: {
        question: {
            default: 'De quel côté était le retour tournant ?',
        },
        label: 'Tournant',
    },
    staircaseLanding: {
        question: {
            default: 'Quel type d\'escalier 1/4 tournant ?',
        },
        label: 'Palier',
    },
    designProject: {
        question: {
            default: 'De quels éléments se composait votre projet d\'agencement ?',
        },
        label: 'Éléments d\'agencement',
    },
    extension: {
        question: {
            default: 'Des rallonges ?',
        },
        label             : 'Rallonges',
        shapeQuestion     : 'Quel type de rallonges ?',
        dimensionQuestion : 'Quelles dimensions pour ces rallonges ?',
    },
    staircaseRailing: {
        question: {
            default: 'Avez-vous ajouté une main courante ?',
        },
        label            : 'Main courante',
        materialQuestion : 'Quel matériau pour la main courante ?',
    },
    staircaseGuard: {
        label    : 'Garde corps',
        question : {
            default: 'Avez-vous ajouté un garde corps ?',
        },
        materialPrimaryQuestion   : 'Quel matériau pour le garde corps ?',
        materialSecondaryQuestion : 'Quel matériau pour la structure ?',
    },
    background: {
        question: {
            default: 'Un fond ?',
        },
        label: 'Fond',
    },
    staircaseLayingStairs: {
        question: {
            default: 'Avez-vous effectué la pose de l\'escalier ?',
        },
        label: 'Pose de l\'escalier',
    },
    staircaseProtection: {
        question: {
            default: 'Une protection pour les enfants ?',
        },
        label: 'Protection enfant',
    },
    multiSelector: {
        question: {
            default                 : 'Des options à ajouter à votre projet ?',
            wineCellar              : 'Quelles fonctions pour votre cave à vin ?',
            wineCellarDedicatedRoom : 'Quelles fonctions pour votre cave à vin ?',
        },
        label         : 'Fonctions',
        labelSingular : 'Fonction',
        noValue       : 'Pas d\'option spécifique.',
    },
    highWindDestination: {
        question: {
            default: 'Quelle destination ?',
        },
        label: ' Pièce de destination',
    },
    highWindLaying: {
        question: {
            default: 'Avez-vous effectué la pose ?',
        },
        label: 'Pose',
    },
    deckBoardShape: {
        label    : 'Lames Bois',
        question : {
            default: 'Quel aspect ?',
        },
    },
    deckBoardWidth: {
        label    : 'Dimensions Bois',
        question : {
            default: 'Quelle largeur de lame ?',
        },
    },
    constraints: {
        label    : 'Contraintes',
        question : {
            default: 'Des obstacles éventuels ?',
        },
    },
    terraceLaying: {
        question: {
            default: 'Avez vous effectué la pose ?',
        },
        label: 'Pose',
    },
    terraceLight: {
        question: {
            default: 'Avez-vous ajouté des éclairages ?',
        },
        label: 'Lumières',
    },
    terracePedestals: {
        question: {
            default: 'Terrasse sur plots ?',
        },
        label: 'Plots',
    },
    fenceDestination: {
        question: {
            default: 'Quel était le lieu de desintation de votre garde-corps ?',
        },
        label: 'Desintation ',
    },
    fencePoles: {
        label    : 'Poteaux',
        question : {
            default: 'Avez-vous ajouté des poteaux à votre garde-corps ?',
        },
        shapeQuestion    : 'Quel forme de potaux ?',
        materialQuestion : 'Quel matériau ?',
    },
    fenceRailing: {
        label    : 'Main courante',
        question : {
            default: 'Avez-vous ajouté une main-courante avec votre garde-corps ?',
        },
        materialQuestion: 'Quel matériau ?',
    },
    fenceFilling: {
        label    : 'Remplissage',
        question : {
            default: 'Quel style de remplissage ?',
        },
    },
    fenceProtection: {
        label    : 'Protection enfant',
        question : {
            default: 'Garde-corps adapté à la sécurité des enfants ?',
        },
    },
    fenceMaterial: {
        label    : 'Matériaux',
        question : {
            default: 'Quel type de matériau pour le remplissage ? ',
        },
    },
    fenceSecondMaterial: {
        label    : 'Matériaux',
        question : {
            default: 'Un deuxième matériau pour le remplissage ?',
        },
        materialQuestion: 'Quel matériau ?',
    },
    pergolaShape: {
        label    : 'Style',
        question : {
            default: 'Quel style de pergola ?',
        },
    },
    pergolaRoof: {
        label    : 'Toit',
        question : {
            default: 'Quel type de toit ?',
        },
    },
    pergolaOptions: {
        label    : 'Options ',
        question : {
            default: 'Des options ?',
        },
    },
    windowLeaf: {
        question: {
            default: 'Combien de vantaux ?',
        },
        label: 'Vantaux',
    },
    windowOpening: {
        radioQuestion : 'Quel tirant ?',
        label         : 'Tirant',
    },
    windowGlazing: {
        radioQuestion : 'Quel type de vitrage ?',
        label         : 'Vitrage',
    },
    windowInsulation: {
        radioQuestion : 'Quel niveau d\'isolation ?',
        label         : 'Isolation ',
    },
    windowInstallation: {
        question: {
            default: 'Avez vous effectué la pose ?',
        },
        label: 'Pose  ',
    },
    garageDoorOpening: {
        label    : 'Ouverture',
        question : {
            default: 'Quel type de porte de garage ?',
        },
    },
    garageDoorGroove: {
        label    : 'Finitions',
        question : {
            default: 'Quel style de rainures ?',
        },
    },
    garageDoorGate: {
        question: {
            default: 'Un portillon ?',
        },
        label: 'Portillon',
    },
    garageDoorOptions: {
        label    : 'Options',
        question : {
            default: 'Quelles options ?',
        },
    },
    claustraDestination: {
        label    : 'Destination',
        question : {
            default: 'Quel est le lieu de destination ?',
        },
    },
    claustraMobility: {
        label    : 'Type',
        question : {
            default: 'Quel type de claustra ?',
        },
    },
    claustraType: {
        label    : 'Style',
        question : {
            default: 'Quel type de paroi ?',
        },
    },
    claustraLaying: {
        label    : 'Pose',
        question : {
            default: 'Avez vous effectué la pose ?',
        },
    },
    quantity: {
        question: {
            default      : 'Combien en souhaitez-vous ?',
            shoesCabinet : 'Combien de casiers ?',
            shelfShoes   : 'Combien de casiers ?',
        },
        label: 'Casiers',
    },
    shoesQuantity: {
        question: {
            default: 'Combien de paires de chaussures pouvons nous ranger ?',
        },
        label: 'Chaussures',
    },
    secondMaterial: {
        question: {
            default: 'Un autre matériau ?',
        },
        label            : 'Matériau secondaire',
        materialQuestion : 'Lequel ?',
    },
    groundShapeDimension: {
        question: {
            default: 'Quelle forme ?',
        },
        label             : 'Surface',
        dimensionQuestion : 'Quelles dimensions au sol ?',
    },
    roofShapeDimension: {
        question: {
            default: 'Quelle forme de toit ?',
        },
        label             : 'Toit',
        dimensionQuestion : 'Quelle hauteur ?',
    },
    destinationShape: {
        label    : 'Destination',
        question : {
            default     : 'Quel était le lieu de destination ?',
            vegetalWall : 'Quel était le lieu de destination de votre mur végétal ?',
        },
    },
    vegetals: {
        question: {
            default     : 'Quels végétaux ?',
            vegetalWall : 'Quels végétaux pour habiller votre mur ?',
        },
        label: 'Plantes',
    },
    type: {
        question: {
            default     : 'Quels type ?',
            vegetalWall : 'Quel type de mur végétal ?',
            closetDoor  : 'Quel style de porte ?',
            fencing     : 'Quel type de remplissage ?',
        },
        label: 'Type',
    },
    bedShapeDimension: {
        question: {
            default       : 'Quel type de lit ?',
            customBunkBed : 'Combien de places pour le lit du bas ?',
        },
        label             : 'Couchage',
        dimensionQuestion : 'Quelles dimensions pour le couchage ?',
    },
    topBedShapeDimension: {
        question: {
            default       : 'Quel type de lit ?',
            customBunkBed : 'Combien de places pour le lit du haut ?',
        },
        label             : 'Couchage haut',
        dimensionQuestion : 'Quelles dimensions pour le couchage du haut ?',
    },
    frameShapeDimension: {
        question: {
            default    : 'Quel type de structure ?',
            customBeds : 'Quel type de structure pour le lit ?',
        },
        label             : 'Structure',
        dimensionQuestion : 'Quelles dimensions pour la structure?',
    },
    windowBlindsStyle: {
        question: {
            default: 'Quel style ?',
        },
        label: 'Style',
    },
    windowBlindsFilling: {
        question: {
            default: 'Quel type de remplissage ?',
        },
        label: 'Remplissage',
    },
    windowBlindsFunction: {
        question: {
            default: 'Quelle fonction pour votre store ?',
        },
        label: 'Fonction',
    },
    windowBlindsDimension: {
        question: {
            default     : 'Quelles dimensions ?',
            customBlind : 'Quelles dimensions pour votre store ?',
        },
        label: 'Dimensions store',
    },
    windowBlindsGlassDimension: {
        question: {
            default     : 'Quelles dimensions ?',
            customBlind : 'Dimension du vitrage ?',
        },
        label: 'Dimensions vitrage',
    },
    windowBlindsElectric: {
        question: {
            default: 'Avez-vous ajouter un système électrique ?',
        },
        label: 'Electrique',
    },
    windowBlindsDisposition: {
        question: {
            default: 'Un store pour : ',
        },
        label: 'Disposition',
    },
    windowBlindsDwelling: {
        question: {
            default: 'Dans quel type d\'habitation ?',
        },
        label: 'Habitation',
    },
    windowBlindsProtection: {
        question: {
            default: 'Quel type de protection ?',
        },
        label: 'Protection',
    },
    windowBlindsManeuver: {
        question: {
            default: 'Quel type de manoeuvre ?',
        },
        label: 'Manoeuvre',
    },
    windowBlindsLight: {
        question: {
            default: 'Avez-vous ajouter un éclairage ?',
        },
        label: 'Eclairage',
    },
    windowBlindsPose: {
        question: {
            default: 'Avez-vous effectué la pose des stores ?',
        },
        radioQuestion : 'Plus précisément quel type de pose ?',
        label         : 'Pose',
    },
    mirrorFrameMaterial: {
        question: {
            default: 'Un cadre pour votre miroir ?',
        },
        label            : 'Cadre',
        materialQuestion : 'Quel matériau pour la structure du miroir ?',
    },
    closetDoorSens: {
        label    : 'Ouverture',
        question : {
            default: 'Quel sens d\'ouverture ?',
        },
    },
    closetDoorFrameMaterial: {
        question: {
            default: 'Avez-vous utilisé un deuxième matériau ? ',
        },
        label            : 'Structure',
        materialQuestion : 'Quel matériau pour la structure ?',
    },
    fencingDestination: {
        label    : 'Usage',
        question : {
            default: 'Quel usage pour la clôture ?',
        },
    },
    fenceGateDimension: {
        question: {
            default: 'Avez-vous ajouté un portillon ?',
        },
        label             : 'Portillon',
        dimensionQuestion : 'Qu\'elles dimensions pour le portillon ?',
    },
    fencingPose: {
        question: {
            default: 'Avez-vous effectué la pose ?',
        },
        radioQuestion : 'Plus précisément quel type de pose ?',
        label         : 'Pose',
    },
    portalShape: {
        question: {
            default: 'Quelle forme pour le portail ?',
        },
        label: 'Forme',
    },
    portalShapeDimension: {
        question: {
            default: 'Combien de vantaux ?',
        },
        label             : 'Vantaux',
        dimensionQuestion : 'Quelles dimensions ?',
    },
    portalOpening: {
        question: {
            default: 'Quelle type d\'ouverture ?',
        },
        label: 'Ouverture',
    },
    portalMotorization: {
        question: {
            default: 'Portail avec motorisation ?',
        },
        label: 'Motorisation',
    },
    portalFilling: {
        question: {
            default: 'Quel remplissage ?',
        },
        label: 'Remplissage',
    },
    portalGate: {
        question: {
            default: 'Avez-vous ajouter un portillon ?',
        },
        label             : 'Portillon',
        dimensionQuestion : 'Quelles dimensions pour le portillon?',
    },
    portalLaying: {
        question: {
            default: 'Avez vous effectué la pose ?',
        },
        label: 'Pose',
    },
    kitchenFurniture: {
        question: {
            default: 'Combien d\'éléments ?',
        },
        label: 'Éléments',
    },
    kitchenHomeAppliance: {
        question: {
            default: 'Choisissez votre électroménager ?',
        },
        label: 'Électroménager',
    },
    kitchenProjectType: {
        question: {
            default: 'Ce projet de cuisine concerné :',
        },
        label: 'Type de projet',
    },
    kitchenHandle: {
        label    : 'Poignées',
        question : {
            default: 'Quel style de poignées ?',
        },
    },
    kitchenShape: {
        question: {
            default: 'Quelle était la configuration de la pièce ?',
        },
        label             : 'Pièce',
        dimensionQuestion : 'Dimensions de la pièce ?',
    },
    kitchenLayout: {
        question: {
            default: 'Quelle implantation ?',
        },
        label: 'Implantation',
    },
    finishing: {
        question: {
            default: 'Quelle finition pour le plan de travail ?',
        },
        label: 'Finition',
    },
    kitchenStyle: {
        question: {
            default: 'Quelle style de cuisine ?',
        },
        label: 'Esthétique',
    },
    kitchenMaterialShade: {
        question: {
            default: 'Quelle teinte pour le plan de travail ?',
        },
        label: 'Plan de travail',
    },
};

export default fieldsPortfolioFR;
