import {
    FC, useEffect, useRef,
} from 'react';
import {
    useLocation,
} from 'react-router-dom';
import firebaseService from '../../../services/Firebase';

const TrackRouter: FC = () => {
    const location = useLocation();
    // avoid first call
    const isFirst = useRef(true);

    useEffect(() => {
        (async () => {
            if (!isFirst.current) (await firebaseService.getInstance()).trackPage(location.pathname);
            else isFirst.current = false;
        })();
    }, [location, isFirst]);

    return null;
};

export default TrackRouter;
