export enum ClosetDoorSensId {
    ClosetDoorRight = 'closetDoorRight',
    ClosetDoorLeft = 'closetDoorLeft',
}

export enum DeckBoardWidthId {
    TwelveToFifteen = 'twelveToFifteen',
    FifteenToTwenty = 'fifteenToTwenty',
    TwentyToTwentyFive = 'twentyToTwentyFive',
}

export enum KitchenProjectTypeId {
    Renovation = 'renovation',
    Construction = 'construction',
}

export enum TerraceObstacleId {
    PlantsObstacle = 'plantsObstacle',
    StaircaseObstacle = 'staircaseObstacle',
    ManholeCoverObstacle = 'manholeCoverObstacle',
    MoundObstacle = 'moundObstacle',
    PoolObstacle = 'poolObstacle',
    OtherObstacle = 'otherObstacle',
    NoObstacle = 'noObstacle',
}

export enum WineCellarFunctionalityId {
    WineCellarMultifunction = 'wineCellarMultifunction',
    WineCellarAging = 'wineCellarAging',
    WineCellarService = 'wineCellarService',
}

export enum PergolaRoofId {
    ClassicCanvas = 'classicCanvas',
    RollUpCanvas = 'rollUpCanvas',
    GlassRoof = 'glassRoof',
    AdjustableBlades = 'adjustableBlades',
}

export enum WindowOpeningSideId {
    WindowOpeningRight = 'windowOpeningRight',
    WindowOpeningLeft = 'windowOpeningLeft',
    WindowOpeningCasement = 'windowOpeningCasement',
    WindowOpeningSliding = 'windowOpeningSliding',
    WindowOpeningFrench = 'windowOpeningFrench',
    WindowOpeningSwingTilting = 'windowOpeningSwingTilting',
    WindowOpeningToggle = 'windowOpeningToggle',
    WindowOpeningNone = 'windowOpeningNone',
}

export enum WindowGlazingId {
    WindowSingleGlazing = 'windowSingleGlazing',
    WindowDoubleGlazing = 'windowDoubleGlazing',
    WindowTripleGlazing = 'windowTripleGlazing',
    WindowSoundproofGlazing = 'windowSoundproofGlazing',
    WindowSafetyGlazing = 'windowSafetyGlazing',
}

export enum WindowInsulationId {
    WindowStandardInsulation = 'windowStandardInsulation',
    WindowEfficientInsulation = 'windowEfficientInsulation',
    WindowUltraEfficientInsulation = 'windowUltraEfficientInsulation',
}

export enum GarageDoorOptionsId {
    GarageDoorMotorization = 'garageDoorMotorization',
    GarageDoorBlinker = 'garageDoorBlinker',
    None = 'none',
}

export enum WindowBlindsOptionsId {
    FillingUniform = 'fillingUniform',
    FillingOpenwork = 'fillingOpenwork',
    IntegralChest = 'integralChest',
    SemiIntegralChest = 'semiIntegralChest',
    WithoutChest = 'withoutChest',
    MotorAndControl = 'MotorAndControl',
    MotorAndSwitch = 'MotorAndSwitch',
    Manual = 'manual',
    FrontOnWall = 'frontOnWall',
    UnderSlab = 'underSlab',
    Other = 'other',
}

export enum FencingOptionId {
    FencingUseProtect = 'fencingUseProtect',
    FencingUseDelimit = 'fencingUseDelimit',
    FencingUseBring = 'fencingUseBring',
    FencingUseOther = 'fencingUseOther',
    FencingPoseWall = 'fencingPoseWall',
    FencingPoseGround = 'fencingPoseGround',
    FencingPoseOther = 'fencingPoseOther',
}

export enum FinishingMaterialOptionId {
    FinishingMatt = 'finishingMatt',
    FinishingNatural = 'finishingNatural',
    FinishingGlossy = 'finishingGlossy',
    FinishingOther = 'finishingOther',
}

export enum ApplianceLevelOptionId {
    Eco = 'eco',
    Premium = 'premium',
    Luxe = 'luxe',
}

export enum BudgetOptionId {
    Eco = 'eco',
    Premium = 'premium',
    High = 'high',
    Luxe = 'luxe',
}

export type OptionId =
| ApplianceLevelOptionId
| BudgetOptionId
| ClosetDoorSensId
| DeckBoardWidthId
| FencingOptionId
| FinishingMaterialOptionId
| GarageDoorOptionsId
| KitchenProjectTypeId
| PergolaRoofId
| TerraceObstacleId
| WindowBlindsOptionsId
| WindowGlazingId
| WindowInsulationId
| WindowOpeningSideId
| WineCellarFunctionalityId

export default OptionId;
