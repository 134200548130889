import FirebaseService from './service';

const firebaseConfig = {
    apiKey            : process.env.REACT_APP_API_KEY,
    authDomain        : process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL       : process.env.REACT_APP_DATABASE_URL,
    projectId         : process.env.REACT_APP_PROJECT_ID,
    storageBucket     : process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId : process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId             : process.env.REACT_APP_APP_ID,
    measurementId     : process.env.REACT_APP_MEASUREMENT_ID,
};

class FirebaseSingleton {
    private instance: FirebaseService | null = null;

    public readonly getInstance = async (): Promise<FirebaseService> => {
        if (!this.instance) {
            const Firebase = (await import(/* webpackChunkName: "firebase-service" */ './service')).default;
            if (!this.instance) this.instance = new Firebase(firebaseConfig);
        }

        return this.instance;
    }
}

export default new FirebaseSingleton();
