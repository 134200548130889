import { fullHeight, spaceLarge } from '@ui/styles/spaces';

export const authContainerStyle = {
    display        : 'flex' as 'flex',
    flexDirection  : 'column' as 'column',
    justifyContent : 'center' as 'center',
    alignItems     : 'center' as 'center',
    height         : fullHeight,
};

export const authLogoLinkStyle = {
    display      : 'block',
    marginBottom : spaceLarge,
    width        : 60,

    '& img': {
        objectFit      : 'contain' as 'contain',
        objectPosition : 'left top',
        width          : '100%',
    },
};

export const authCardStyle = {
    width        : '100%',
    boxSizing    : 'border-box' as 'border-box',
    marginBottom : spaceLarge,
};

export const authCardContentStyle = {
    width          : '100%',
    boxSizing      : 'border-box' as 'border-box',
    display        : 'flex' as 'flex',
    flexDirection  : 'column' as 'column',
    justifyContent : 'center' as 'center',
    alignItems     : 'center' as 'center',
};
