import { Component } from 'react';
import * as Sentry from '@sentry/browser';
import Snackbar from '@material-ui/core/Snackbar';
import { injectIntl, IntlShape } from 'react-intl';
import SnackbarContent from '../SnackbarContent';
import { ISnackbarContentMode } from '../SnackbarContent/types';

type ErrorState = { error?: Error & { code?: string } }

type Props = { intl: IntlShape }

class ErrorBoundarySnackbar extends Component<Props, ErrorState> {
    constructor(props: Props) {
        super(props);
        this.state = { error: undefined };
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    componentDidCatch(error: Error) {
        this.setState({ error });
        Sentry.captureException(error);
    }

    resetError = () => {
        this.setState({ error: undefined });
    }

    render() {
        const { children, intl } = this.props;
        const { error } = this.state;

        return (
            <>
                {children}

                {error && error.code && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={!!error}
                        autoHideDuration={6000}
                        onClose={this.resetError}
                    >
                        <SnackbarContent
                            mode={ISnackbarContentMode.Error}
                            message={intl.formatMessage({ id: `errors.firebase.${error.code}` })}
                            onClose={this.resetError}
                        />
                    </Snackbar>
                )}
            </>
        );
    }
}

export default injectIntl(ErrorBoundarySnackbar);
