import { RequestMatchingSourceId, RequestStatusId } from '@core/constants/Request';
import { RequestEstimateOptionId } from '../view/components/FormEstimator';
import { RequestEstimateKey } from '../interfaces/Request';

const matchingSource: {
    [key in RequestMatchingSourceId]: string
} & {
    detail : {
        [key in RequestMatchingSourceId]: string
    }
} = {
    auto        : 'Matching automatique',
    user        : 'Page profil',
    manual      : 'Matching manuel',
    marketPlace : 'Opportunités',
    external    : 'Formulaires personnalisés',
    detail      : {
        auto        : 'Notre algoritme vous a envoyé cette demande automatiquement selon vos préférences profil.',
        user        : 'Un utilisateur vous a adressé cette demande spécifiquement depuis votre page profil.',
        manual      : 'Un membre de l\'équipe LILM vous a envoyé cette demande manuellement selon vos préférences profil.',
        marketPlace : 'Vous avez choisi de répondre à cette demande depuis l\'onglet "Opportunités".',
        external    : 'Un client vous envoie cette demande spécifique depuis vos formulaires personnalisés. Celle-ci n\'est pas visible par d\'autres professionnels.',
    },
};

const status: {label: string} & {[key in RequestStatusId]: string} = {
    label               : 'Statut',
    pending             : 'À estimer',
    accepted            : 'Acceptée',
    declined            : 'Refusée',
    estimated           : 'Estimation envoyée',
    acceptedByUser      : 'Estimation acceptée',
    declinedByUser      : 'Estimation refusée',
    quoteSent           : 'Devis envoyé',
    quoteAcceptedByUser : 'Devis accepté',
    quoteDeclinedByUser : 'Devis refusé',
    depositPaid         : 'Acompte versé',
    projectBilled       : 'Projet facturé',
    projectDelivered    : 'Projet livré',
    projectFinalized    : 'Projet finalisé',
    projectCanceled     : 'Projet annulé',
    canceled            : 'Annulée',
};

const statusClient: {label: string} & {[key in RequestStatusId]: string} = {
    label               : 'Statut',
    pending             : 'À estimer',
    accepted            : 'Acceptée',
    declined            : 'Refusée',
    estimated           : 'L\'artisan attend votre réponse',
    acceptedByUser      : 'Contactez directement {companyName}',
    declinedByUser      : 'Offre refusée',
    quoteSent           : 'Devis reçu par email',
    quoteAcceptedByUser : 'Devis accepté',
    quoteDeclinedByUser : 'Devis refusé',
    depositPaid         : 'Acompte versé',
    projectBilled       : 'Facturé',
    projectDelivered    : 'Livré',
    projectFinalized    : 'Finalisé',
    projectCanceled     : 'Projet annulé',
    canceled            : 'Annulée',
};

const priceState: {
[key in RequestEstimateOptionId]: {
    label: string,
}} = {
    priceFixed: {
        label: 'Prix fixe',
    },
    priceRange: {
        label: 'Fourchette de prix',
    },
};

const accept: {
    title:string;
    notice:string;
    noticeBis:string;
} & {[key in RequestEstimateKey]: {
    label : string,
    placeholder?: string,
    adornment ?: string,
    template ?: string,
}} = {
    title     : 'Estimation rapide',
    notice    : 'Gagnez du temps et envoyez une première estimation.<br/>Vous pourrez envoyer votre devis final dans un second temps.',
    noticeBis : 'Gagnez du temps et envoyez une première estimation.<br/>Cette demande sera ajoutée à votre onglet "Mes demandes" afin de pouvoir en faire le suivi.<br/>Vous aurez également accès aux coordonnées client.',
    priceHT   : {
        label       : 'Prix HT',
        placeholder : 'Ex: 3000',
        adornment   : '€',
    },
    priceMinHT: {
        label       : 'Prix min HT',
        placeholder : 'Ex: 1000',
        adornment   : '€',
    },
    priceMaxHT: {
        label       : 'Prix max HT',
        placeholder : 'Ex: 3000',
        adornment   : '€',
    },
    taxExonerated: {
        label: 'Exonéré de TVA',
    },
    taxRate: {
        label       : 'Taux de TVA',
        placeholder : 'Ex: 20',
        adornment   : '%',
    },
    shippingDelay: {
        label       : 'Délai de livraison',
        placeholder : 'Ex: 3',
        adornment   : 'semaines',
    },
    shippingIncluded: {
        label: 'Livraison incluse',
    },
    shippingPrice: {
        label       : 'Livraison HT',
        placeholder : 'Ex: 600',
        adornment   : '€',
    },
    comment: {
        label       : 'Message au client',
        placeholder : 'Les détails de votre estimation',
        template    : 'Bonjour,\nJe suis {firstname} de la société {companyName} et je vous contacte au sujet de votre projet de {projectName}.\nVoici mon estimation : ...',
    },
};

export const requestFR = {
    matchingSource,
    status,
    statusClient,
    accept,
    priceState,
    actionsIcons: {
        archived          : 'Archiver',
        unarchived        : 'Désarchiver',
        archiveRequests   : 'Demandes archivées',
        unarchiveRequests : 'Retour vers mes demandes',
    },
    send: {
        title  : 'Envoyer',
        notice : 'Vous êtes sur le point de demander à {artisanName} de préparer un devis pour ce projet de {projectName}.<br/><br/>Êtes-vous certain de vouloir procéder à cette opération?',
    },
    decline: {
        title  : 'Je passe mon tour',
        notice : 'Vous êtes sur le point de refuser cette demande.<br/> Afin d\'assurer la qualité des prochaines demandes pourriez-vous nous indiquer les raisons de votre refus ?',
    },
    userDecline: {
        title  : 'Pouvez vous nous en dire plus',
        notice : 'Vous êtes sur le point de refuser cette offre.<br/> Afin d\'assurer la qualité des prochaines offres pourriez-vous nous indiquer les raisons de votre refus ?',
    },
    externalDecline: {
        notice      : 'Vous êtes sur le point de refuser une demande de vos formulaires personnalisés.<br/> Merci de donner quelques mots d\'explications au client.',
        placeholder : 'Message au client',
    },
    distance: {
        unknown        : 'Inconnu',
        nearby         : 'À proximité',
        distanceKm     : '{distance}km',
        atDistanceKmIn : 'À {distance}km : {location}',
        fromYou        : {
            unknown    : 'Inconnu',
            nearby     : 'À proximité de chez vous',
            distanceKm : 'À {distance}km de chez vous',
        },
    },
};

export default requestFR;
