import { JobId } from '../constants/JobId';

const JobsFR: { [key in JobId]: string } = {
    cabinetmakerCarpenter               : 'Menuisier - Ébéniste',
    designer                            : 'Agenceur',
    facade                              : 'Façadier',
    floorTilerAndInstaller              : 'Carreleur et poseur de revêtement de sol',
    glazierMirrorMaker                  : 'Vitrier - Miroitier',
    ironworkerMetalWorkerZincWorker     : 'Ferronnier - Métallier - Zingueur',
    kitchenDesigner                     : 'Cuisiniste et concepteur de cuisine',
    landscaperGardener                  : 'Paysagiste - Jardinier',
    locksmith                           : 'Serrurier',
    marbrierStoneCutter                 : 'Marbrier - Tailleur de pierre',
    parquetFloorWorker                  : 'Parqueteur',
    professionalSecondWork              : 'Professionnel Second Œuvre',
    rooferCarpenter                     : 'Couvreur - Charpentier',
    sellerAndInstallerOfFenceAndGate    : 'Vendeur et installateur de clôtures et portails',
    specialistDeckPatioAndVeranda       : 'Spécialiste terrasses, patios et vérandas',
    swimmingPoolAndSpaSpecialist        : 'Pisciniste et spécialiste spa',
    upholstererUpholsteryAndCarpet      : 'Tapissier, tissu d\'ameublement et tapis',
    windowdoorShutterAndBlindSpecialist : 'Spécialiste fenêtres, portes, volets et stores',
    wineCellarDesigner                  : 'Concepteur et agenceur de cave à vin',
};

export default JobsFR;
