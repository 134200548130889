import { JobId } from '@furnish-hx/constants/JobId';

export const jobSelectionAnswersFR: {[key in JobId]: string} = {
    designer                            : 'Vous êtes <b>agenceur</b> ?!<br/>Parfait, nous avons certainement des projets à vous proposer ! 👌',
    floorTilerAndInstaller              : 'Vous êtes <b>carreleur et poseur de revêtement de sol</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    wineCellarDesigner                  : 'Vous êtes <b>concepteur et agenceur de cave à vin</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    rooferCarpenter                     : 'Vous êtes <b>couvreur ou charpentier</b> ?!<br/>Parfait, nous avons certainement des projets à vous proposer ! 👌',
    kitchenDesigner                     : 'Vous êtes <b>cuisiniste et concepteur de cuisine</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    facade                              : 'Vous êtes <b>façadier</b> ?!<br/>Parfait, nous avons certainement des projets à vous proposer ! 👌',
    ironworkerMetalWorkerZincWorker     : 'Vous êtes <b>ferronnier/métallier/zingueur</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    landscaperGardener                  : 'Vous êtes <b>paysagiste ou jardinier</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    marbrierStoneCutter                 : 'Vous êtes <b>marbrier ou tailleur de pierre</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    cabinetmakerCarpenter               : 'Vous êtes <b>menuisier ou ébéniste</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    parquetFloorWorker                  : 'Vous êtes <b>parqueteur</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    swimmingPoolAndSpaSpecialist        : 'Vous êtes <b>pisciniste et spécialiste spa</b> ?!<br/>Parfait, nous avons certainement des projets à vous proposer ! 👌',
    professionalSecondWork              : 'Vous êtes <b>professionnel second œuvre</b> ?!<br/>Parfait, nous avons certainement des projets à vous proposer ! 👌',
    locksmith                           : 'Vous êtes <b>serrurier</b> ?!<br/>Parfait, nous avons certainement des projets à vous proposer ! 👌',
    windowdoorShutterAndBlindSpecialist : 'Vous êtes <b>spécialiste fenêtres, portes, volets et stores</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    specialistDeckPatioAndVeranda       : 'Vous êtes <b>spécialiste terrasses, patios et vérandas</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    upholstererUpholsteryAndCarpet      : 'Vous êtes <b>tapissier, tissu d\'ameublement et tapis</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    sellerAndInstallerOfFenceAndGate    : 'Vous êtes <b>vendeur et installateur de clôtures et portails</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
    glazierMirrorMaker                  : 'Vous êtes <b>vitrier ou miroitier</b> ?!<br/>Super, les clients de LILM ont besoin de vos services ! 👍',
};

export default jobSelectionAnswersFR;
