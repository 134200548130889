import { ProjectId } from '../constants/ProjectId';

const ProjectFR: { [key in ProjectId]: string } = {
    default                                   : 'Autre',
    aboveGroundPool                           : 'Piscine hors-sol',
    accordionDoorInstallation                 : 'Pose de porte accordéon',
    acousticCabinsAndPhoneBox                 : 'Cabines acoustiques / Phone Box',
    alluminiumFenceInstallation               : 'Installation de clôture en aluminium',
    aluminiumCarpentry                        : 'Menuiserie en aluminium',
    aluminiumCladding                         : 'Bardage en aluminium',
    aluminiumFenceInstallation                : 'Installation de clôture en aluminium',
    aluminiumFenceRepair                      : 'Réparation de clôture en aluminium',
    anticyclonicShutterInstallation           : 'Installation de volet anticyclonique',
    anticyclonicShutterRepair                 : 'Réparation de volet anticyclonique',
    apartementRenovation                      : 'Rénovation d\'appartement',
    architectHouse                            : 'Maison d\'architecte',
    armchair                                  : 'Fauteuil',
    asphalting                                : 'Asphaltage',
    asphaltShingleRoof                        : 'Toiture en bardeau bitumé',
    atticLayout                               : 'Aménagement de combles',
    atticRenovation                           : 'Rénovation de combles',
    automaticGateInstallation                 : 'Installation de portail automatique',
    automaticPortalInstallation               : 'Installation de portail automatique',
    awningCleaning                            : 'Nettoyage de store',
    awningInstallation                        : 'Pose de store',
    awningRepair                              : 'Réparation de store banne',
    babyRoomDesign                            : 'Chambre bébé',
    balconyBuild                              : 'Construction de balcon',
    balconyRepair                             : 'Réparation de balcon',
    balconyWaterproofing                      : 'Étanchéité de balcon',
    barCounter                                : 'Comptoir de bar',
    bathroomDesign                            : 'Conception de salle de bain',
    bathroomInstallation                      : 'Installation de salle de bain',
    bathroomRenovation                        : 'Rénovation de salle de bain',
    bedding                                   : 'Literie',
    bench                                     : 'Banquette',
    benchAndBench                             : 'Banc et banquette',
    billiardRepair                            : 'Réparation de billard',
    blindAndRollerShutter                     : 'Stores et volets roulants',
    blindSale                                 : 'Vente de stores',
    brickFacing                               : 'Parement de brique',
    brickRepair                               : 'Réparation de brique',
    brickSale                                 : 'Vente de brique',
    brickyard                                 : 'Briqueterie',
    carpetCleaning                            : 'Nettoyage de moquette',
    carpetLaying                              : 'Pose de moquette',
    carpetRepair                              : 'Réparation de moquette',
    carpetSale                                : 'Vente de moquette',
    carpetStretch                             : 'Étirement de moquette',
    carportBuild                              : 'Construction de carport',
    caulking                                  : 'Calfeutrage',
    ceilingFanInstallation                    : 'Installation de ventilateur de plafond',
    ceilingFanRepair                          : 'Réparation de ventilateur de plafond',
    cellarWaterproofing                       : 'Étanchéité de cave',
    chair                                     : 'Chaise',
    chaletBuild                               : 'Construction de chalet',
    changingThePoolLiner                      : 'Changement de liner de piscine',
    childAndBabyRoom                          : 'Chambre enfant et bébé',
    childBedroomLayout                        : 'Aménagement de chambre enfant',
    chimneyBuild                              : 'Construction de cheminée',
    chimneyDiagnosis                          : 'Diagnostic cheminée',
    chimneyInstallation                       : 'Installation de cheminée',
    chimneyRepair                             : 'Réparation de cheminée',
    chimneySale                               : 'Vente de cheminée',
    christmasLight                            : 'Illuminations de Noël',
    claddingInstallation                      : 'Pose de bardage',
    claustras                                 : 'Claustras',
    clearing                                  : 'Défrichement',
    closetAndStorage                          : 'Placard et rangement',
    closetInstallation                        : 'Pose de placard',
    closetMakeover                            : 'Relooking de placard',
    closetPainting                            : 'Peinture de placard',
    closetRenovation                          : 'Rénovation de placard',
    closetRepair                              : 'Réparation de placard',
    closingSale                               : 'Vente de clôture',
    completeRenovation                        : 'Rénovation complète',
    completeRestaurantLayout                  : 'Aménagement complet de restaurant',
    concreteBuild                             : 'Construction de béton',
    concreteCaulking                          : 'Calfeutrage du béton',
    concreteDrivewayInstallation              : 'Installation d\'allée carrossable en béton',
    concreteDrivewaySealing                   : 'Scellement d\'allée carrossable en béton',
    concreteFloor                             : 'Sol en béton',
    concreteRepair                            : 'Réparation de béton',
    concreteSale                              : 'Vente de béton',
    concreteTint                              : 'Teinte de béton',
    concreteWorktop                           : 'Plan de travail en béton',
    createAndRepairOfStainedGlass             : 'Création et réparation de vitrail',
    curtainCleaning                           : 'Nettoyage de rideaux',
    customBeds                                : 'Lits sur-mesure',
    customBilliard                            : 'Billard sur-mesure',
    customBlind                               : 'Stores sur-mesure',
    customBrazier                             : 'Brasero sur-mesure',
    customChimney                             : 'Cheminée sur-mesure',
    customChimneyMantel                       : 'Manteau de cheminée sur-mesure',
    customCloset                              : 'Placard sur-mesure',
    customCupboardDoor                        : 'Porte de placard sur-mesure',
    customCupboards                           : 'Placards sur-mesure',
    customEmbroidery                          : 'Broderie personnalisée',
    customExteriorDoor                        : 'Portes extérieures sur-mesure',
    customFoldingDoor                         : 'Portes pliantes sur-mesure',
    customFurniture                           : 'Meuble sur-mesure',
    customGlassRoofs                          : 'Verrière sur-mesure',
    customInteriorDoor                        : 'Portes intérieures sur-mesure',
    customLoungeBar                           : 'Bar de salon sur-mesure',
    customMadeVanityUnit                      : 'Meuble sous-vasque sur-mesure',
    customMosquitoNet                         : 'Moustiquaire sur-mesure',
    customOffice                              : 'Bureau sur-mesure',
    customSauna                               : 'Sauna sur-mesure',
    customShelf                               : 'Étagère sur-mesure',
    customStorage                             : 'Rangements sur-mesure',
    customTvUnit                              : 'Meuble TV sur-mesure',
    customWindow                              : 'Fenêtre sur-mesure',
    deckBuild                                 : 'Construction de terrasse',
    deckLayout                                : 'Aménagement de terrasse',
    decorativeConcrete                        : 'Béton décoratif',
    demolition                                : 'Démolition',
    dockDesingAndBuild                        : 'Conception et construction de quai',
    doorPainting                              : 'Peinture de porte',
    doorRepair                                : 'Réparation de porte',
    doorSale                                  : 'Vente de porte',
    doorWindowAndPlinthJoinery                : 'Menuiseries de porte, fenêtre et plinthe',
    doubleGlazing                             : 'Double vitrage',
    dresserSideboard                          : 'Commode, buffet et bahut',
    drivewayGateInstallation                  : 'Installation de portail d\'allée carrossable',
    drivewayRenovation                        : 'Rénovation d\'allée carrossable',
    drivewayRepair                            : 'Réparation d\'allée carrossable',
    ecologicalBuild                           : 'Construction écologique',
    electricChimneyInstallation               : 'Installation de cheminée électrique',
    electricChimneyRepair                     : 'Réparation de cheminée électrique',
    elevation                                 : 'Surélévation',
    epoxyResinFloor                           : 'Sol en résine époxy',
    excavation                                : 'Excavation',
    exteriorDoorInstallation                  : 'Installation de porte extérieure',
    exteriorLightingDesign                    : 'Conception d\'éclairage extérieur',
    exteriorPainting                          : 'Peinture extérieure',
    exteriorShutter                           : 'Volets extérieurs',
    facadPlasterRepair                        : 'Réparation de crépi de façade',
    facelift                                  : 'Ravalement de façade',
    fenceInstallation                         : 'Installation de clôture',
    fenceRepair                               : 'Réparation de clôture',
    fireproofing                              : 'Ignifugation',
    floorCleaning                             : 'Nettoyage de sol',
    floorCoveringInstallation                 : 'Installation de revêtement de sol',
    floorHeating                              : 'Chauffage au sol',
    flooringSale                              : 'Vente de revêtement de sol',
    floorPlanForBuildingPermit                : 'Plan de masse pour permis de construire',
    floorPolishing                            : 'Polissage de parquet',
    floorRenovation                           : 'Rénovation de sol',
    floorSanding                              : 'Ponçage de sol',
    foldingDoor                               : 'Portes pliantes',
    frameAndJoinery                           : 'Charpente et menuiseries',
    furnitureDelivery                         : 'Livraison de meuble',
    furnitureRepair                           : 'Réparation de meuble',
    furnitureSale                             : 'Vente de meuble',
    garageBuild                               : 'Construction de garage',
    garageDoorInstallation                    : 'Installation de porte de garage',
    garageDoorRepair                          : 'Réparation de porte de garage',
    garageDoorSale                            : 'Vente de porte de garage',
    garageDoorSellerAndInstaller              : 'Vendeur et installateur de porte de garage',
    garageFlooring                            : 'Revêtement de sol de garage',
    garageFloorRenovation                     : 'Rénovation de sol de garage',
    garageLayout                              : 'Aménagement de garage',
    gardenFurnitureSale                       : 'Vente de meubles de jardin',
    gardenLightingInstallation                : 'Installation d\'éclairage de jardin',
    gazChimneyInstallation                    : 'Installation de cheminée à gaz',
    gazChimneyRepair                          : 'Réparation de cheminée à gaz',
    generalBuildingContractor                 : 'Entreprises générales de bâtiment',
    glassAndMirrorRepair                      : 'Réparation de vitrerie et miroiterie',
    glassBrickInstallation                    : 'Installation de brique de verre',
    glutterRepair                             : 'Réparation de gouttière',
    graniteWorktop                            : 'Plan de travail en granite',
    ground                                    : 'Sol',
    guardrailInstallation                     : 'Installation de garde-corps',
    guardrailRepair                           : 'Réparation de garde-corps',
    gutterCleaning                            : 'Nettoyage de gouttière',
    gutterInstallation                        : 'Installation de gouttière',
    heaterSale                                : 'Vente d\'appareils de chauffage',
    highPressureWashing                       : 'Lavage haute pression',
    hotelRoomFurniture                        : 'Mobilier chambre d\'hôtel',
    houseExtension                            : 'Extension de maison',
    houseFoundationBuild                      : 'Construction de fondations de maison',
    installationOfAwning                      : 'Installation de store banne',
    installationOfGlazingAndMirror            : 'Installation de vitrerie et miroiterie',
    installationOfVanityUnit                  : 'Installation de meuble sous lavabo',
    insulationInstallation                    : 'Pose d\'isolation',
    interiorDoorInstallation                  : 'Installation de porte intérieure',
    interiorShutter                           : 'Volets intérieurs',
    ironworker                                : 'Ferronnier',
    kitchenDesign                             : 'Conception de cuisine',
    kitchenInstallation                       : 'Installation de cuisine',
    kitchenRenovation                         : 'Rénovation de cuisine',
    laminateFlooringLaying                    : 'Pose de sol stratifié',
    laminateFlooringSale                      : 'Vente de parquet stratifié',
    laminateFloorRepair                       : 'Réparation de sol stratifié',
    landscaping                               : 'Aménagement extérieur',
    landscapingSupply                         : 'Fourniture d\'aménagement paysager',
    latticeBuild                              : 'Fabrication de treillis',
    layingLightPipe                           : 'Pose de conduits de lumière',
    layingWoodenFence                         : 'Pose de clôture en bois',
    leatherRepair                             : 'Réparation de cuir',
    levelingAndEarthworks                     : 'Ragréage et terrassement',
    levelingGround                            : 'Nivellement du sol',
    library                                   : 'Bibliothèque',
    lightingDesign                            : 'Conception d\'éclairage',
    lightingSale                              : 'Vente de luminaire',
    ligthingInstallation                      : 'Installation d\'éclairage',
    linoleumFlooringInstallation              : 'Installation de sol en linoléum',
    linoleumFlooringSale                      : 'Vente de sol en linoléum',
    linoleumFloorRepair                       : 'Réparation de sol en linoléum',
    locksmith                                 : 'Serrurerie',
    lowEnergyBuilding                         : 'Bâtiment basse consommation',
    lowTable                                  : 'Table basse',
    luxuryRenovation                          : 'Rénovation de luxe',
    marbleWorktop                             : 'Plan de travail en marbre',
    masonry                                   : 'Maçonnerie',
    meetingTable                              : 'Table de réunion',
    melamineWorktop                           : 'Plan de travail en mélaminé',
    metalCabinet                              : 'Armoire métallique',
    metalFabrication                          : 'Fabrication de métal',
    metalWorktop                              : 'Plan de travail en métal',
    mirrorPose                                : 'Pose de miroir',
    mirrorRepair                              : 'Réparation de miroir',
    mobilePedestals                           : 'Caissons mobiles',
    moistureTreatment                         : 'Traitement de l\'humidité',
    mosquitoNetInstallation                   : 'Pose de moustiquaire',
    mosquitoNetRepair                         : 'Réparation de moustiquaire',
    naturalStoneWorktop                       : 'Plan de travail en pierre naturelle',
    newHouseBuild                             : 'Construction de maison neuve',
    oldFurnitureRestoration                   : 'Restauration de meuble ancien',
    outdoorChimneyBuild                       : 'Construction de cheminée extérieure',
    outdoorFurniture                          : 'Mobilier d\'extérieur',
    outdoorKitchenDesign                      : 'Conception de cuisine extérieure',
    outdoorLightingInstallation               : 'Installation d\'éclairage extérieur',
    parquetColor                              : 'Teinte de parquet',
    parquetInstallation                       : 'Pose de parquet',
    parquetRenovation                         : 'Rénovation de parquet',
    parquetRepair                             : 'Rénovation de parquet',
    pavingStone                               : 'Pose de pavé',
    pelletStoveCleaning                       : 'Nettoyage de poêle à granulés',
    pelletStoveInstallation                   : 'Installation de poêle à granulés',
    pelletStoveRepair                         : 'Réparation de poêle à granulés',
    pepoleWithReducedMobilityLayout           : 'Aménagement pour personne à mobilité réduite',
    pergolaBuild                              : 'Pergola sur-mesure',
    plastering                                : 'Plâtrerie',
    plasteringPlaster                         : 'Pose de crépis',
    plasterRepair                             : 'Réparation de plâtre',
    playroomLayout                            : 'Aménagement de salle de jeux',
    plinthInstallation                        : 'Pose de plinthe',
    poolAndSpaRepair                          : 'Réparation de piscine et spa',
    poolDesign                                : 'Piscine',
    poolCover                                 : 'Couvre-piscine',
    poolDeckDesignAndLayout                   : 'Plage de piscine',
    poolMaintenanceAndCleaning                : 'Maintenance et nettoyage de piscine',
    portalInstallation                        : 'Installation de portail',
    portalRepair                              : 'Réparation de portail',
    portalSale                                : 'Vente de portail',
    prefabricatedHouse                        : 'Maison préfabriquée',
    printedConcrete                           : 'Béton imprimé',
    projectedInsulation                       : 'Isolation projetée',
    pVCCarpentry                              : 'Menuiserie en PVC',
    pVCCladding                               : 'Bardage en PVC',
    quartzWorktop                             : 'Plan de travail en quartz',
    railingInstallation                       : 'Installation de balustrade',
    receptionDesk                             : 'Banque d\'accueil',
    recessedLightingInstallation              : 'Installation d\'éclairage encastré',
    refrigeratedDisplay                       : 'Vitrine réfrigérée',
    removablePartition                        : 'Cloison amovible',
    replacementOfAboveGroundPoolLiner         : 'Remplacement de liner de piscine hors-sol',
    residentialWindowTinting                  : 'Teintage de vitres résidentielles',
    restaurationAfterWaterDamage              : 'Restauration après dégât des eaux',
    restorationFurniture                      : 'Restauration de meuble',
    restorationOfOldFurniture                 : 'Restauration de meuble ancien',
    retainingWallBuild                        : 'Construction de mur de soutènement',
    rollerShutterInstallation                 : 'Pose de volet roulant',
    roofCleaningAndDefoaming                  : 'Nettoyage de toiture et démoussage',
    roofDeckLayout                            : 'Aménagement de toit-terrasse',
    roofFlashingInstallation                  : 'Installation de solin de toiture',
    roofSoffitInstallation                    : 'Pose de sous-face de toiture',
    roofSoffitRepair                          : 'Réparation de sous-face de toiture',
    roofWaterproofing                         : 'Étanchéité de toiture',
    roofWindowInstallation                    : 'Pose de fenêtre de toit',
    saleAndInstalOfWindaowDoorShutterAndBlind : 'Vendeur et installateur de fenêtres, portes, volets et stores',
    saleCounter                               : 'Comptoir de vente',
    saleOfGardenFurniture                     : 'Vente de meubles de jardin',
    saleOfWorkPlan                            : 'Vente de plan de travail',
    salesTable                                : 'Table de vente',
    saunaInstallation                         : 'Installation de sauna',
    saunaRepair                               : 'Réparation de sauna',
    saunaSale                                 : 'Vente de sauna',
    seamlessBathroom                          : 'Salle de bain sans joint',
    shelving                                  : 'Rayonnage',
    shoesCabinet                              : 'Meuble à chaussure',
    showcase                                  : 'Vitrine',
    showcaseLight                             : 'Luminaire vitrine',
    showerDoorInstallation                    : 'Pose de porte de douche',
    showerDoorRepair                          : 'Réparation de porte de douche',
    showerDoorSale                            : 'Vente de porte de douche',
    showerInstallation                        : 'Installation de douche',
    shutterInstallation                       : 'Pose de volet battant',
    sideboard                                 : 'Enfilade',
    sidingRepair                              : 'Réparation de bardage',
    sidingSale                                : 'Vente de bardage',
    sign                                      : 'Enseigne',
    sitePlan                                  : 'Plans de chantier',
    sitePreparation                           : 'Préparation de chantier',
    slindingDoorInstallation                  : 'Installation de porte coulissante',
    slindingDoorRepair                        : 'Réparation de porte coulissante',
    solarSwimmingPoolWaterHeater              : 'Chauffe-eau solaire de piscine',
    solidParquetSale                          : 'Vente de parquet massif',
    soundAndAcousticInsulation                : 'Isolation phonique et acoustique',
    spaInstallation                           : 'Installation de spa',
    spaSale                                   : 'Vente de spa',
    stairInstallation                         : 'Pose d\'escalier',
    stairRepair                               : 'Réparation d\'escalier',
    steelFabrication                          : 'Fabrication d\'acier',
    stoneFacing                               : 'Parement en pierre',
    stoneSale                                 : 'Vente de pierre',
    stool                                     : 'Tabouret',
    storageUnit                               : 'Meuble de rangement',
    stretchCeiing                             : 'Plafonds tendus',
    subjectMastery                            : 'Maîtrise d\'œuvre',
    summerKitchen                             : 'Cuisine d\'été',
    swimmingPoolBuild                         : 'Piscine',
    swimingPoolLightingInstallation           : 'Installation d\'éclairage de piscine',
    swimingPoolRenovation                     : 'Rénovation de piscine',
    swimmingPoolRenovation                    : 'Rénovation de piscine',
    table                                     : 'Table',
    textileDecorationCurtainAndVeil           : 'Décoration textile, rideaux et voilage',
    threeDComputerGraphic                     : 'Infographie 3D',
    tiledWorktop                              : 'Plan de travail en carrelage',
    tilesSale                                 : 'Vente de carrelage',
    tiling                                    : 'Pose de carrelage',
    timberFrameHouse                          : 'Maison à ossature en bois',
    tinyHouseBuild                            : 'Construction de Tiny House',
    titleRepair                               : 'Réparation de carrelage',
    titlPainting                              : 'Peinture de carrelage',
    toiletRenovation                          : 'Rénovation de toilettes',
    underlaymentLaying                        : 'Pose de sous-couche de sol',
    upholstery                                : 'Tapisserie d\'ameublement',
    vanityUnitInstallation                    : 'Installation de meuble sous lavabo',
    vinylFenceInstallation                    : 'Pose de clôture en vinyle',
    vinylFlooringInsatllation                 : 'Pose de sol en vinyle',
    vinylFlooringSale                         : 'Vente de sol en vinyle',
    vinylFloorRepair                          : 'Réparation de sol en vinyle',
    wallPanel                                 : 'Panneaux muraux',
    wallpaperLaying                           : 'Pose de papier-peint',
    wardrobeAndClosetSale                     : 'Vente d\'armoire et de placard',
    wardrobeRoomAndCloset                     : 'Aménagement de dressing et placard',
    washroomDesign                            : 'Conception de salle d\'eau',
    washroomRenovation                        : 'Rénovation de salle d\'eau',
    waterproofing                             : 'Étanchéité',
    waterproofingOfWoodenDeck                 : 'Imperméabilisation de terrasse en bois',
    waxedConcrete                             : 'Béton ciré',
    welding                                   : 'Soudure',
    windowFilm                                : 'Film pour vitrage',
    windowInstallation                        : 'Pose de fenêtre',
    windowRemplacement                        : 'Remplacement de fenêtre',
    windowRepair                              : 'Réparation de fenêtre',
    windowSale                                : 'Vente de fenêtre',
    windowSeal                                : 'Joint d\'étanchéité de fenêtre',
    windowSillInstallation                    : 'Pose d\'appui de fenêtre',
    wineCellar                                : 'Cave à vin',
    wineCellarDesign                          : 'cave à vin sur-mesure',
    wineCellarDedicatedRoom                   : 'Cave à vin pour pièce dédiée',
    wineCellarLayout                          : 'Aménagement de cave à vin',
    wireMeshInstallation                      : 'Installation de grillage',
    woodDeckCleaning                          : 'Nettoyage de terrasse en bois',
    woodDeckLayout                            : 'Aménagement de terrasse en bois',
    woodDeckMaintenance                       : 'Entretien de terrasse en bois',
    woodDeckStain                             : 'Teinture de terrasse en bois',
    woodenCabinet                             : 'Armoire en bois',
    woodenDeckBuild                           : 'Construction de terrasse en bois',
    woodenDeckLightingInstallation            : 'Installation d\'éclairage de terrasse en bois',
    woodenDeckRepair                          : 'Réparation de terrasse en bois',
    woodenFenceRepair                         : 'Réparation de clôture en bois',
    woodenPatioLightingInstallation           : 'Installation d\'éclairage de terrasse en bois',
    woodFinish                                : 'Finition du bois',
    woodStoveInstallation                     : 'Installation de poêle à bois',
    woodStoveRepair                           : 'Réparation de poêle à bois',
    woodWorktop                               : 'Plan de travail en bois',
    worktopLaying                             : 'Pose de plan de travail',
    worktopRepair                             : 'Réparation de plan de travail',
    worktopSale                               : 'Vente de plan de travail',
    wroughtIronFenceInstallation              : 'Installation de clôture en fer forgé',
    wroughtIronFenceRepair                    : 'Réparation de clôture en fer forgé',
    windowAndDoorWindow                       : 'Fenêtre et porte fenêtre',
    slidingBayWindow                          : 'Baie coulissante',
    frontDoorAndService                       : "Porte d'entrée et porte de service",
    shutterAndAwning                          : 'Volet et store',
    portalAndGate                             : 'Portail et portillon',
    delimitationAndFence                      : 'Délimitation et clôture',
    layoutAndBuildOfPoolHouse                 : 'Pool House sur-mesure',
    porchDesignAndLayout                      : 'Porche sur-mesure',
    designAndBuildOfGardenSheds               : 'Abri de jardin sur-mesure',
    arborDesignAndBuild                       : 'Tonelle sur-mesure',
    gardenPavilionDesignAndBuild              : 'Pavillon de jardin sur-mesure',
    blinds                                    : 'Stores',
    staircaseDesign                           : "Conception d'escalier",
    interiorDoor                              : 'Porte intérieure',
    customGlassWall                           : 'Verrière sur-mesure',
    customGlassWal                            : 'Verrière sur-mesure',
    partitionWall                             : 'Cloison de séparation',
    terraceDesignAndBuild                     : 'Terrasse sur-mesure',
    customWardrobe                            : 'Dressing',
    buffetAndSideboard                        : 'Buffet et bahut',
    officePedestals                           : 'Caissons de bureau',
    customCabinet                             : 'Armoire',
    trunk                                     : 'Coffre et malle',
    chestsOfDrawersAndCabinet                 : 'Commode et cabinet',
    customShelves                             : 'Étagère sur-mesure',
    storage                                   : 'Rangement sur-mesure',
    customKitchenCupboard                     : 'Placard de cuisine sur-mesure',
    customKItchenCupboard                     : 'Placard de cuisine sur-mesure',
    closetDoor                                : 'Porte de placard sur-mesure',
    customCupboard                            : 'Placard sur-mesure',
    customPantry                              : 'Garde-manger sur-mesure',
    wineCellarDesignAndLayout                 : 'Cave à vin sur-mesure',
    hallwayCabinetAndShoes                    : "Meuble d'entrée et chaussure",
    tvUnit                                    : 'Meuble TV',
    diningTableHighTableBarTable              : 'Table à manger, table basse, plateau, pieds',
    sideTableAndBedsideTable                  : "Table d'appoint et table de chevet",
    counterBar                                : 'Comptoir de bar',
    dressingTable                             : 'Coiffeuse',
    sofaAndPedestalTable                      : 'Bout de canapé et guéridon',
    console                                   : 'Console',
    benchAndStool                             : 'Banc et tabouret',
    benchAndBenchSeat                         : 'Banquette',
    pouf                                      : 'Pouf',
    sofa                                      : 'Canapé',
    endOfBed                                  : 'Bout de lit',
    barChairAndStool                          : 'Chaise et tabouret de bar',
    chairAndArmchair                          : 'Chaise et fauteuil',
    customBed                                 : 'Lit sur-mesure',
    bedhead                                   : 'Tête de lit',
    nightstand                                : 'Table de chevet',
    bedroomDesign                             : 'Chambre',
    childrenBedroomDesign                     : "Chambre d'enfant",
    bedroomLayout                             : 'Aménagement de chambre',
    kitchenBuild                              : 'Cuisine sur-mesure',
    outdoorKitchenBuild                       : 'Cuisine extérieure sur-mesure',
    customKitchenCabinet                      : 'Meuble haut de cuisine',
    customKitchenBaseCabinet                  : 'Meuble bas de cuisine',
    kitchenIsland                             : 'Îlot de cuisine',
    kitchenBack                               : 'Arrière cuisine',
    stoneLaying                               : 'Pose de pierre',
    customLight                               : 'Luminaire',
    wallPaneling                              : 'Boiserie murale',
    greenWall                                 : 'Mur Végétal',
    glazingFilm                               : 'Film pour vitrage',
    showerDoor                                : 'Porte de douche',
    chrLayoutAndFurniture                     : 'Agencement et mobilier CHR',
    shopLayoutAndFurniture                    : 'Agencement et mobilier commerce',
    pharmacyLayoutAndFurniture                : 'Agencement et mobilier pharmacie',
    realEstateAgencyLayoutAndFurniture        : "Agencement et mobilier d'agence immobilière",
    butcherShopLayoutAndFurniture             : 'Agencement et mobilier boucherie',
    bakeryLayoutAndFurniture                  : 'Agencement et mobilier boulangerie',
    groceryLayoutAndFurniture                 : 'Agencement et mobilier épicerie fine',
    cheeseDairyLayoutAndFurniture             : 'Agencement et mobilier fromagerie',
    beautyInstituteLayoutAndFurniture         : 'Agencement et mobilier institut de beauté',
    hairdresserLayoutAndFurniture             : 'Agencement et mobilier salon de coiffure',
    barLayoutAndFurniture                     : 'Agencement et mobilier bar',
    hotelLayoutAndFurniture                   : 'Agencement et mobilier hôtel',
    restaurantLayoutAndFurniture              : 'Agencement et mobilier restaurant',
    workspaceLayoutAndFurniture               : "Agencement et mobilier d'espace de travail",
    eventLayoutAndFurniture                   : 'Agencement et mobilier événementiel',
    houseOutsideLayoutAndFurniture            : 'Agencement et mobilier extérieur',
    fastFoodLayoutAndFurniture                : 'Agencement et mobilier fast food',
    houseInteriorLayoutAndFurniture           : 'Agencement et mobilier intérieur',
    claustra                                  : 'Claustra',
    customBuiltInCabinet                      : 'Meuble encastré sur-mesure',
    customCurtainAndVeil                      : 'Rideaux et voilages sur-mesure',
    customGarageDoor                          : 'Porte de garage sur-mesure',
    customLibrary                             : 'Bibliothèque sur-mesure',
    customRugs                                : 'Tapis sur-mesure',
    customWorkplan                            : 'Plan de travail sur-mesure',
    customWorkPlan                            : 'Plan de travail sur-mesure',
    desk                                      : 'Bureau',
    wallHanging                               : 'Tenture murale',
    verandaDesignAndBuild                     : 'Véranda sur-mesure',
    staircaseAndGuardrail                     : 'Escalier et garde-corps',
    pergolaConstruction                       : 'Pergola sur-mesure',
    mirror                                    : 'Miroir',
    glassWindowGlazingAndMirrorCutting        : 'Découpe de verre, vitre, vitrage et miroir',
    greenhouseBuild                           : 'Serre de jardin',
    installationOfCredenza                    : 'Pose de crédence',
    stairs                                    : 'Escalier',
    guardrail                                 : 'Garde-corps',
};

export default ProjectFR;
