
export enum FieldDimensionId {
    Thickness = 'thickness',
    ThicknessTable2 = 'thicknessTable2',
    Width = 'width',
    Depth = 'depth',
    Length = 'length',
    Height = 'height',
    HeightTable2 = 'heightTable2',
    Diameter = 'diameter',
    DiameterTable2 = 'diameterTable2',
    Radius = 'radius',
    LengthReturn = 'lengthReturn',
    DepthReturn = 'depthReturn',
    LengthReturn1 = 'lengthReturn1',
    DepthReturn1 = 'depthReturn1',
    LengthReturn2 = 'lengthReturn2',
    DepthReturn2 = 'depthReturn2',
    Width1 = 'width1',
    Width2 = 'width2',
    Width3 = 'width3',
    Width4 = 'width4',
    Width5 = 'width5',
    Width1Table2 = 'width1Table2',
    Width2Table2 = 'width2Table2',
    Width3Table2 = 'width3Table2',
    WidthTable2 = 'widthTable2',
    Length1 = 'length1',
    Length2 = 'length2',
    Length3 = 'length3',
    Length4 = 'length4',
    Height1 = 'height1',
    Height2 = 'height2',
    Height3 = 'height3',
    Height4 = 'height4',
    Height5 = 'height5',
    LengthTable2 = 'lengthTable2',
    HeightRoofTop = 'heightRoofTop',
    HeightWall = 'heightWall',
    HeightBase = 'heightBase',
    HeightCeiling = 'heightCeiling',
    HeightTotal = 'heightTotal',
    Depth1 = 'depth1',
    Depth2 = 'depth2',
    Depth3 = 'depth3',
    Depth4 = 'depth4',
    WidthStaircase = 'widthStaircase',
    LengthStaircase = 'lengthStaircase',
    HeightStaircase = 'heightStaircase',
    Degrees = 'degrees',
    Humidity = 'humidity',
    SquareMeter = 'squareMeter',
    Diagonal = 'diagonal',
    Diagonal1 = 'diagonal1',
    Diagonal2 = 'diagonal2',
    Diagonal3 = 'diagonal3',
    WallLength1 = 'wallLength1',
    WallLength2 = 'wallLength2',
    WallLength3 = 'wallLength3',
    WallLength4 = 'wallLength4',
    WallLength5 = 'wallLength5',
    WallLength6 = 'wallLength6',
}

export default FieldDimensionId;
