import {
    pureWhite,
    lightMarineColor,
    bluishGrey,
    blueColor,
    azurColor,
    lighterMarineColorShade,
    greyColor,
} from '../styles/colors';
import {
    spaceSmall,
    spaceMini,
    spaceWide,
    spaceHuge,
    sizeMicro,
    sizeSmall,
    sizeRegular,
    fontMicro,
    fontSmall,
    fontRegular,
    radiusNano,
} from '../styles/spaces';
import {
    fontBody,
} from '../styles/typography';
import {
    IStyledButtonProperties,
    IStyledButtonModes,
} from './types';

export const styledButtonLinkStyle = {
    textDecoration: 'none',
};

export const styledButtonIconStyle = {
    marginLeft   : 0,
    marginRight  : 8,
    marginBottom : 0,
    marginTop    : 0,
    height       : 18,
    width        : 18,
};

export const getStyledButtonStyles = {
    boxShadow           : 'none',
    textShadow          : 'none',
    transition          : '.5s all ease',
    borderRadius        : radiusNano,
    cursor              : 'pointer',
    textTransform       : 'none' as 'none',
    fontFamily          : fontBody,
    lineHeight          : 'normal',
    display             : 'flex',
    justifyContent      : 'center' as 'center',
    alignItems          : 'center' as 'center',
    appearance          : 'none' as 'none',
    textDecoration      : 'none',
    textDecorationColor : 'rgba(255, 255, 255, 0)',
    borderStyle         : 'solid' as 'solid',

    fontWeight: ({ mode }: IStyledButtonProperties) => (
        (mode === IStyledButtonModes.Large || mode === IStyledButtonModes.Medium) && 'bold')
        || 'normal',

    padding: ({ mode }: IStyledButtonProperties) => (
        (mode === IStyledButtonModes.Large && `${0}px ${spaceHuge}px`)
        || (mode === IStyledButtonModes.Medium && `${0}px ${spaceWide}px`)
        || (mode === IStyledButtonModes.Small && `${0}px ${spaceMini}px`)
        || `${0}px ${spaceHuge}px`
    ),

    fontSize: ({ mode }: IStyledButtonProperties) => (
        (mode === IStyledButtonModes.Large && fontRegular)
        || (mode === IStyledButtonModes.Medium && fontSmall)
        || (mode === IStyledButtonModes.Small && fontMicro)
        || fontRegular
    ),

    height: ({ mode }: IStyledButtonProperties) => (
        (mode === IStyledButtonModes.Large && sizeRegular)
        || (mode === IStyledButtonModes.Medium && sizeSmall)
        || (mode === IStyledButtonModes.Small && sizeMicro)
        || sizeRegular
    ),

    background: ({
        mode, color, active, disabled, colored,
    }: IStyledButtonProperties) => (
        (disabled && 'linear-gradient(135deg, transparent 0%, transparent 50%) 0% 0% / 200% 200%')
        || (!colored && !active && mode === IStyledButtonModes.Small && `linear-gradient(135deg, ${pureWhite} 0%, ${pureWhite} 50%) 0% 0% / 200% 200%`)
        || (!colored && active && mode === IStyledButtonModes.Small && `linear-gradient(135deg, ${azurColor} 0%, ${azurColor} 50%) 0% 0% / 200% 200%`)
        || (!colored && active && `linear-gradient(135deg, ${pureWhite} 0%, ${pureWhite} 50%) 0% 0% / 200% 200%`)
        || (color === 'primary' && `linear-gradient(135deg, ${blueColor} 0%, ${azurColor} 50%) 0% 0% / 200% 200%`)
        || (color === 'secondary' && lighterMarineColorShade)
        || azurColor
    ),

    color: ({
        mode, color, active, disabled, colored,
    }: IStyledButtonProperties) => (
        (disabled && greyColor)
        || (!colored && active && mode === IStyledButtonModes.Small && pureWhite)
        || (!colored && !active && mode === IStyledButtonModes.Small && bluishGrey)
        || (color === 'secondary' && lightMarineColor)
        || (!active && pureWhite)
        || (color === 'primary' && azurColor)
        || pureWhite
    ),

    borderColor: ({
        disabled, mode, color, active,
    }: IStyledButtonProperties) => (disabled
        ? 'linear-gradient(135deg, transparent 0%, transparent 50%) 0% 0% / 200% 200%'
        : (color === 'secondary' && !active && mode !== IStyledButtonModes.Small ? lightMarineColor : 'transparent')
    ),

    borderWidth: ({
        disabled, mode, color, active,
    }: IStyledButtonProperties) => (disabled
        ? 0
        : (color === 'secondary' && !active && mode !== IStyledButtonModes.Small ? 1 : 0)
    ),

    '&:active, &:visited': {
        textDecoration      : 'none',
        textDecorationColor : 'rgba(255, 255, 255, 0)',
    },

    '&:hover': {
        background: ({
            mode, color, active, disabled, colored,
        }: IStyledButtonProperties) => (
            (disabled && 'linear-gradient(135deg, transparent 0%, transparent 50%) 100% 100% / 200% 200%')
            || (!colored && mode === IStyledButtonModes.Small && `linear-gradient(135deg, ${azurColor} 0%, ${azurColor} 50%) 100% 100% / 200% 200%`)
            || (active && `linear-gradient(135deg, ${pureWhite} 0%, ${pureWhite} 50%) 100% 100% / 200% 200%`)
            || (color === 'primary' && `linear-gradient(135deg, ${blueColor} 0%, ${azurColor} 50%) 100% 100% / 200% 200%`)
            || (color === 'secondary' && 'transparent')
            || pureWhite
        ),

        color: ({
            mode, color, active, disabled, colored,
        }: IStyledButtonProperties) => (
            (disabled && greyColor)
            || (!colored && mode === IStyledButtonModes.Small && pureWhite)
            || (color === 'secondary' && bluishGrey)
            || (!active && pureWhite)
            || (color === 'primary' && azurColor)
            || azurColor
        ),

        borderColor: ({ mode, color, active }: IStyledButtonProperties) => (
            color === 'secondary'
            && !active
            && mode !== IStyledButtonModes.Small
                ? bluishGrey
                : 'transparent'
        ),
    },

    // 💩💩💩💩💩💩💩
    // Dear maintainer:
    //
    // Once you are done trying to 'optimize' this routine,
    // and have realized what a terrible mistake that was,
    // please increment the following counter as a warning
    // to the next guy:
    //
    // total_hours_wasted_here = 42
    // 💩💩💩💩💩💩💩

    '& .MuiButton-label': {
        lineHeight: 'normal',
    },

    '& .MuiSvgIcon-root': {
        display      : ({ mode }: IStyledButtonProperties) => (mode === IStyledButtonModes.Small ? 'none' as 'none' : 'inline-block' as 'inline-block'),
        width        : sizeMicro,
        height       : sizeMicro,
        marginTop    : 0,
        marginRight  : spaceSmall,
        marginBottom : 0,
        marginLeft   : -spaceSmall,
    },

    '& span + .MuiSvgIcon-root': {
        marginTop    : 0,
        marginRight  : -spaceSmall,
        marginBottom : 0,
        marginLeft   : spaceSmall,
    },

    '& .MuiCircularProgress-root': {
        marginTop    : 0,
        marginRight  : -spaceSmall,
        marginBottom : 0,
        marginLeft   : spaceSmall,
    },
};

export default getStyledButtonStyles;
