import { IFormFieldKey } from './Form';

export enum ExtraStep {
    Intro = 'intro'
}

export type IStep = IFormFieldKey | ExtraStep;

export type ISteps = IStep[]

export default IStep;
