export interface IFormFieldExclusion {
    disabled: boolean
    reasons?: IReasons
    disabledOptions: IDisabledOption
}

export enum IReason {
    TrayMaterialConflictWithBorder = 'TrayMaterialConflictWithBorder',
    TrayMaterialConflictWithOffsetExtension = 'TrayMaterialConflictWithOffsetExtension',
    TrayMaterialConflictWithCentralExtension = 'TrayMaterialConflictWithCentralExtension',

    TrayShapeConflictWithLegsShape = 'TrayShapeConflictWithLegsShape',
    TrayShapeConflictWithExtension = 'TrayShapeConflictWithExtension',
    TrayShapeConflictWithExtensionAngle = 'TrayShapeConflictWithExtensionAngle',

    BorderConflictWithLiveEdgeMaterial = 'BorderConflictWithLiveEdgeMaterial',
    BorderConflictWithAirplaneWingsMaterial = 'BorderConflictWithAirplaneWingsMaterial',

    ExtensionConflictWithRightCrossPieceLegsShape = 'ExtensionConflictWithRightCrossPieceLegsShape',
    ExtensionConflictWithSquareCrossPieceLegsShape = 'ExtensionConflictWithSquareCrossPieceLegsShape',
    ExtensionConflictWithTrestleCrossLegsShape = 'ExtensionConflictWithTrestleCrossLegsShape',
    ExtensionConflictWithRoundTrayShape = 'ExtensionConflictWithRoundTrayShape',
    ExtensionConflictWithOvalTrayShape = 'ExtensionConflictWithOvalTrayShape',
    ExtensionConflictWithTrayMaterial = 'ExtensionConflictWithTrayMaterial',

    LegsShapeConflictWithExtension = 'LegsShapeConflictWithExtension',
    LegsShapeConflictWithTrayShape = 'LegsShapeConflictWithTrayShape',
    LegsShapeConflictLegsMaterial = 'LegsShapeConflictWithTrayShape',

    LegsMaterialConflictLegsShape = 'LegsMaterialConflictLegsShape',
}
export type IReasons = IReason[];

export interface IDisabledOption {
    [key:string]: IReasons
}

export default IFormFieldExclusion;
