/** Furniture Ids */
export enum FurnitureId {
    TableBar = 'tableBar',
    TableDesk = 'tableDesk',
    TableDinner = 'tableDinner',
    TableExtendable = 'tableExtendable',
    TableHigh = 'tableHigh',
    TableLegOnly = 'tableLegOnly',
    TableLow = 'tableLow',
    TableMeeting = 'tableMeeting',
    TablePedestal = 'tablePedestal',
    TableTrayOnly = 'tableTrayOnly',
    TvUnit = 'tvUnit',
}

export default FurnitureId;
