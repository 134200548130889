import { ButtonProps } from '@material-ui/core/Button';
import { ReactElement } from 'react';

export enum IStyledButtonModes {
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
}

export interface IStyledButtonProperties extends ButtonProps {
    active?: boolean
    colored?: boolean
    component?: string
    error?: boolean
    icon?: ReactElement
    loading?: boolean
    mode?: IStyledButtonModes
}

export default IStyledButtonProperties;
