
import * as EmailValidator from 'email-validator';
import passwordPattern from '../constants/PasswordPattern';

export function validateEmail(email: string | undefined): boolean {
    return !!(
        email
        && EmailValidator.validate(email)
    );
}

export function validatePhoneNumber(phoneNumber: string | undefined): boolean {
    return !!(
        phoneNumber
        && phoneNumber.length > 9
        && phoneNumber.length <= 13
    );
}

export const validatePassword = (password?: string) => !!(password && new RegExp(passwordPattern).test(password));
