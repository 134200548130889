import {
    IUser,
    IUserCredentials,
} from '../interfaces/User';
import { validateEmail, validatePassword } from './Auth';

export const validateUserSignInData = (
    userData: IUserCredentials,
) => (
    userData.password
    && validatePassword(userData.password)
    && userData.email
    && validateEmail(userData.email)
);

export const validateUserSignUpData = (
    userData: IUser,
) => (
    userData.id === undefined
    && userData.emailVerified === undefined
    && userData.password
    && userData.email
    && validateEmail(userData.email)
);

export default validateUserSignUpData;
