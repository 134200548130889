/* tslint:disable: max-line-length */
export const termsAndConditionsUrl = 'https://lilm.co/wp/wp-content/uploads/2019/03/201902-LILM-CGU-avec-offre-lead.pdf';

export const SentryUrl = 'https://0de8c099930d46aca4810d55bfba3513@sentry.io/4254257';

export const lilmUrl = 'https://lilm.co';

export const lilmHelpAndTrainingUrl = 'https://espace-artisan.lilm.co/';

export const lilmFacebookUrl = 'https://www.facebook.com/lilm.co/';

export const lilmInstagramUrl = 'https://www.instagram.com/lilm.co/?hl=fr';

export const lilmLinkedInUrl = 'https://www.linkedin.com/company/lilm/';

export const lilmArtisanUrl = 'https://lilm.co/atelier/?utm_source=lilm-app&utm_medium=app&utm_campaign=app-sidenav-form-link';

export const getHubspotContactUrl = (companyId: string) => `https://app.hubspot.com/contacts/1960352/company/${companyId}`;

export const signInPublicSubmissionUrl = 'https://lilm.co/atelier/?utm_source=lilm&utm_medium=app&utm_campaign=app-public-submission';

export const salesRDVUrl = 'https://app.hubspot.com/meetings/demo43';

export const buyGuideUrl = 'https://lilm.co/guide/';

export const workshopPage = 'https://lilm.co/atelier/';

export const artisanInvoiceZoho = 'https://invoice.zoho.com/portal/lilmartisan';
