export enum DeprecatedFormId {
    BabyRoomDesign = 'babyRoomDesign',
    BlindAndRollerShutter = 'blindAndRollerShutter',
    ChildAndBabyRoom = 'childAndBabyRoom',
    ClosingSale = 'closingSale',
    CustomGarageDoor = 'customGarageDoor',
    DeckBuild = 'deckBuild',
    DeckLayout = 'deckLayout',
    GarageDoorInstallation = 'garageDoorInstallation',
    PergolaBuild = 'pergolaBuild',
    PergolaConstruction = 'pergolaConstruction',
    WoodDeckLayout = 'woodDeckLayout',
    AlluminiumFenceInstallation = 'alluminiumFenceInstallation',
    AluminiumCarpentry = 'aluminiumCarpentry',
    AluminiumCladding = 'aluminiumCladding',
    AluminiumFenceInstallation = 'aluminiumFenceInstallation',
    AluminiumFenceRepair = 'aluminiumFenceRepair',
    AnticyclonicShutterInstallation = 'anticyclonicShutterInstallation',
    AnticyclonicShutterRepair = 'anticyclonicShutterRepair',
    ApartementRenovation = 'apartementRenovation',
    Asphalting = 'asphalting',
    AsphaltShingleRoof = 'asphaltShingleRoof',
    AtticLayout = 'atticLayout',
    AtticRenovation = 'atticRenovation',
    ArchitectHouse = 'architectHouse',
    AutomaticGateInstallation = 'automaticGateInstallation',
    AutomaticPortalInstallation = 'automaticPortalInstallation',
    AwningCleaning = 'awningCleaning',
    AwningRepair = 'awningRepair',
    ShowerDoor = 'showerDoor',
    AboveGroundPool = 'aboveGroundPool',
    AccordionDoorInstallation = 'accordionDoorInstallation',
    BalconyBuild = 'balconyBuild',
    BalconyRepair = 'balconyRepair',
    BalconyWaterproofing = 'balconyWaterproofing',
    BathroomDesign = 'bathroomDesign',
    BathroomInstallation = 'bathroomInstallation',
    BathroomRenovation = 'bathroomRenovation',
    BilliardRepair = 'billiardRepair',
    BrickFacing = 'brickFacing',
    BrickRepair = 'brickRepair',
    BrickSale = 'brickSale',
    Brickyard = 'brickyard',
    CarpetCleaning = 'carpetCleaning',
    CarpetLaying = 'carpetLaying',
    CarpetRepair = 'carpetRepair',
    CarpetSale = 'carpetSale',
    CarpetStretch = 'carpetStretch',
    CarportBuild = 'carportBuild',
    Caulking = 'caulking',
    CeilingFanInstallation = 'ceilingFanInstallation',
    CeilingFanRepair = 'ceilingFanRepair',
    CellarWaterproofing = 'cellarWaterproofing',
    ChaletBuild = 'chaletBuild',
    ChangingThePoolLiner = 'changingThePoolLiner',
    ChimneyDiagnosis = 'chimneyDiagnosis',
    ChimneyInstallation = 'chimneyInstallation',
    ChimneyRepair = 'chimneyRepair',
    ChimneySale = 'chimneySale',
    ChristmasLight = 'christmasLight',
    CladdingInstallation = 'claddingInstallation',
    Clearing = 'clearing',
    ClosetInstallation = 'closetInstallation',
    ClosetMakeover = 'closetMakeover',
    ClosetPainting = 'closetPainting',
    ClosetRenovation = 'closetRenovation',
    ClosetRepair = 'closetRepair',
    Closing = 'closing',
    CompleteRenovation = 'completeRenovation',
    ConcreteBuild = 'concreteBuild',
    ConcreteCaulking = 'concreteCaulking',
    ConcreteDrivewayInstallation = 'concreteDrivewayInstallation',
    ConcreteDrivewaySealing = 'concreteDrivewaySealing',
    ConcreteRepair = 'concreteRepair',
    ConcreteSale = 'concreteSale',
    ConcreteTint = 'concreteTint',
    CreateAndRepairOfStainedGlass = 'createAndRepairOfStainedGlass',
    CurtainCleaning = 'curtainCleaning',
    BlindSale = 'blindSale',
    CustomBilliard = 'customBilliard',
    CustomChimneyMantel = 'customChimneyMantel',
    CustomEmbroidery = 'customEmbroidery',
    CustomMosquitoNet = 'customMosquitoNet',
    CustomSauna = 'customSauna',
    DecorativeConcrete = 'decorativeConcrete',
    Demolition = 'demolition',
    DockDesingAndBuild = 'dockDesingAndBuild',
    DoorPainting = 'doorPainting',
    DoorRepair = 'doorRepair',
    DoorSale = 'doorSale',
    DrivewayGateInstallation = 'drivewayGateInstallation',
    DrivewayRenovation = 'drivewayRenovation',
    DrivewayRepair = 'drivewayRepair',
    EcologicalBuild = 'ecologicalBuild',
    ElectricChimneyInstallation = 'electricChimneyInstallation',
    ElectricChimneyRepair = 'electricChimneyRepair',
    Elevation = 'elevation',
    EpoxyResinFloor = 'epoxyResinFloor',
    Excavation = 'excavation',
    ExteriorDoorInstallation = 'exteriorDoorInstallation',
    ExteriorLightingDesign = 'exteriorLightingDesign',
    ExteriorPainting = 'exteriorPainting',
    CustomFoldingDoor = 'customFoldingDoor',
    FacadPlasterRepair = 'facadPlasterRepair',
    Facelift = 'facelift',
    FenceInstallation = 'fenceInstallation',
    FenceRepair = 'fenceRepair',
    Fireproofing = 'fireproofing',
    FloorCleaning = 'floorCleaning',
    FloorCoveringInstallation = 'floorCoveringInstallation',
    FloorPlanForBuildingPermit = 'floorPlanForBuildingPermit',
    FloorPolishing = 'floorPolishing',
    FloorRenovation = 'floorRenovation',
    FloorSanding = 'floorSanding',
    FoldingDoor = 'foldingDoor',
    FrameAndJoinery = 'frameAndJoinery',
    FurnitureDelivery = 'furnitureDelivery',
    FurnitureRepair = 'furnitureRepair',
    FurnitureSale = 'furnitureSale',
    GarageDoorRepair = 'garageDoorRepair',
    GarageDoorSale = 'garageDoorSale',
    GarageDoorSellerAndInstaller = 'garageDoorSellerAndInstaller',
    GarageFlooring = 'garageFlooring',
    GarageFloorRenovation = 'garageFloorRenovation',
    GardenFurnitureSale = 'gardenFurnitureSale',
    GardenLightingInstallation = 'gardenLightingInstallation',
    GazChimneyInstallation = 'gazChimneyInstallation',
    GazChimneyRepair = 'gazChimneyRepair',
    GeneralBuildingContractor = 'generalBuildingContractor',
    GlassBrickInstallation = 'glassBrickInstallation',
    GlutterRepair = 'glutterRepair',
    GutterCleaning = 'gutterCleaning',
    GutterInstallation = 'gutterInstallation',
    HeaterSale = 'heaterSale',
    HighPressureWashing = 'highPressureWashing',
    HouseExtension = 'houseExtension',
    HouseFoundationBuild = 'houseFoundationBuild',
    InstallationOfAwning = 'installationOfAwning',
    InstallationOfVanityUnit = 'installationOfVanityUnit',
    InsulationInstallation = 'insulationInstallation',
    InteriorDoorInstallation = 'interiorDoorInstallation',
    Ironworker = 'ironworker',
    KitchenInstallation = 'kitchenInstallation',
    KitchenRenovation = 'kitchenRenovation',
    LaminateFlooringLaying = 'laminateFlooringLaying',
    LaminateFlooringSale = 'laminateFlooringSale',
    LaminateFloorRepair = 'laminateFloorRepair',
    LandscapingSupply = 'landscapingSupply',
    LatticeBuild = 'latticeBuild',
    LayingLightPipe = 'layingLightPipe',
    LeatherRepair = 'leatherRepair',
    LevelingAndEarthworks = 'levelingAndEarthworks',
    LevelingGround = 'levelingGround',
    LightingDesign = 'lightingDesign',
    LightingSale = 'lightingSale',
    LigthingInstallation = 'ligthingInstallation',
    LinoleumFlooringInstallation = 'linoleumFlooringInstallation',
    LinoleumFlooringSale = 'linoleumFlooringSale',
    LinoleumFloorRepair = 'linoleumFloorRepair',
    Locksmith = 'locksmith',
    LowEnergyBuilding = 'lowEnergyBuilding',
    NestingCofeeTable = 'nestingCofeeTable',
    LuxuryRenovation = 'luxuryRenovation',
    Masonry = 'masonry',
    MetalFabrication = 'metalFabrication',
    MoistureTreatment = 'moistureTreatment',
    MosquitoNetInstallation = 'mosquitoNetInstallation',
    MosquitoNetRepair = 'mosquitoNetRepair',
    NewHouseBuild = 'newHouseBuild',
    OldFurnitureRestoration = 'oldFurnitureRestoration',
    OutdoorChimneyBuild = 'outdoorChimneyBuild',
    OutdoorKitchenDesign = 'outdoorKitchenDesign',
    OutdoorLightingInstallation = 'outdoorLightingInstallation',
    ParquetColor = 'parquetColor',
    ParquetInstallation = 'parquetInstallation',
    ParquetRenovation = 'parquetRenovation',
    PelletStoveCleaning = 'pelletStoveCleaning',
    PelletStoveInstallation = 'pelletStoveInstallation',
    PelletStoveRepair = 'pelletStoveRepair',
    PepoleWithReducedMobilityLayout = 'pepoleWithReducedMobilityLayout',
    Plastering = 'plastering',
    PlasteringPlaster = 'plasteringPlaster',
    PlasterRepair = 'plasterRepair',
    PlinthInstallation = 'plinthInstallation',
    PoolAndSpaRepair = 'poolAndSpaRepair',
    PoolCover = 'poolCover',
    PoolDesign = 'poolDesign',
    PoolMaintenanceAndCleaning = 'poolMaintenanceAndCleaning',
    PortalInstallation = 'portalInstallation',
    PortalRepair = 'portalRepair',
    PortalSale = 'portalSale',
    PrefabricatedHouse = 'prefabricatedHouse',
    PrintedConcrete = 'printedConcrete',
    ProjectedInsulation = 'projectedInsulation',
    WoodenDeckLightingInstallation = 'woodenDeckLightingInstallation',
    WoodenDeckRepair = 'woodenDeckRepair',
    WoodenFenceRepair = 'woodenFenceRepair',
    WoodenPatioLightingInstallation = 'woodenPatioLightingInstallation',
    WoodFinish = 'woodFinish',
    WoodStoveInstallation = 'woodStoveInstallation',
    WoodStoveRepair = 'woodStoveRepair',
    WroughtIronFenceInstallation = 'wroughtIronFenceInstallation',
    WroughtIronFenceRepair = 'wroughtIronFenceRepair',
    WireMeshInstallation = 'wireMeshInstallation',
    WoodDeckCleaning = 'woodDeckCleaning',
    WoodDeckMaintenance = 'woodDeckMaintenance',
    WoodDeckStain = 'woodDeckStain',
    WaxedConcrete = 'waxedConcrete',
    Welding = 'welding',
    WindowSeal = 'windowSeal',
    WindowSillInstallation = 'windowSillInstallation',
    VinylFenceInstallation = 'vinylFenceInstallation',
    VinylFlooringInsatllation = 'vinylFlooringInsatllation',
    VinylFlooringSale = 'vinylFlooringSale',
    VinylFloorRepair = 'vinylFloorRepair',
    AwningInstallation = 'awningInstallation',
    WallPanel = 'wallPanel',
    WallpaperLaying = 'wallpaperLaying',
    WardrobeAndClosetSale = 'wardrobeAndClosetSale',
    WashroomDesign = 'washroomDesign',
    WashroomRenovation = 'washroomRenovation',
    Waterproofing = 'waterproofing',
    WaterproofingOfWoodenDeck = 'waterproofingOfWoodenDeck',
    PVCCladding = 'pVCCladding',
    RailingInstallation = 'railingInstallation',
    RecessedLightingInstallation = 'recessedLightingInstallation',
    ReplacementOfAboveGroundPoolLiner = 'replacementOfAboveGroundPoolLiner',
    ResidentialWindowTinting = 'residentialWindowTinting',
    RestaurationAfterWaterDamage = 'restaurationAfterWaterDamage',
    RestorationFurniture = 'restorationFurniture',
    RestorationOfOldFurniture = 'restorationOfOldFurniture',
    RetainingWallBuild = 'retainingWallBuild',
    RollerShutterInstallation = 'rollerShutterInstallation',
    RoofCleaningAndDefoaming = 'roofCleaningAndDefoaming',
    RoofDeckLayout = 'roofDeckLayout',
    RoofFlashingInstallation = 'roofFlashingInstallation',
    RoofSoffitInstallation = 'roofSoffitInstallation',
    RoofSoffitRepair = 'roofSoffitRepair',
    RoofWaterproofing = 'roofWaterproofing',
    RoofWindowInstallation = 'roofWindowInstallation',
    SaleAndInstalOfWindaowDoorShutterAndBlind = 'saleAndInstalOfWindaowDoorShutterAndBlind',
    SaunaInstallation = 'saunaInstallation',
    SaunaRepair = 'saunaRepair',
    SaunaSale = 'saunaSale',
    SeamlessBathroom = 'seamlessBathroom',
    ShowerInstallation = 'showerInstallation',
    ShutterInstallation = 'shutterInstallation',
    SidingRepair = 'sidingRepair',
    SidingSale = 'sidingSale',
    SitePlan = 'sitePlan',
    SitePreparation = 'sitePreparation',
    SlindingDoorInstallation = 'slindingDoorInstallation',
    SlindingDoorRepair = 'slindingDoorRepair',
    SolarSwimmingPoolWaterHeater = 'solarSwimmingPoolWaterHeater',
    SolidParquetSale = 'solidParquetSale',
    SoundAndAcousticInsulation = 'soundAndAcousticInsulation',
    SpaInstallation = 'spaInstallation',
    SpaSale = 'spaSale',
    StairRepair = 'stairRepair',
    SteelFabrication = 'steelFabrication',
    StoneFacing = 'stoneFacing',
    StoneSale = 'stoneSale',
    StretchCeiing = 'stretchCeiing',
    SubjectMastery = 'subjectMastery',
    SwimingPoolLightingInstallation = 'swimingPoolLightingInstallation',
    SwimingPoolRenovation = 'swimingPoolRenovation',
    SwimmingPoolRenovation = 'swimmingPoolRenovation',
    ThreeDComputerGraphic = 'threeDComputerGraphic',
    TilesSale = 'tilesSale',
    Tiling = 'tiling',
    TimberFrameHouse = 'timberFrameHouse',
    TinyHouseBuild = 'tinyHouseBuild',
    TitleRepair = 'titleRepair',
    TitlPainting = 'titlPainting',
    ToiletRenovation = 'toiletRenovation',
    UnderlaymentLaying = 'underlaymentLaying',
    Upholstery = 'upholstery',
    VanityUnitInstallation = 'vanityUnitInstallation',
    LayingWoodenFence = 'layingWoodenFence',
    GlassWallComplex = 'glassWallComplex',
    GlassWallWithBase = 'glassWallWithBase',
    GlassWallWithFrame = 'glassWallWithFrame',
    WardrobeUnderEaves = 'wardrobeUnderEaves',
    LibraryWall = 'libraryWall',
    LibraryWithNiches = 'libraryWithNiches',
    LibraryWithShelves = 'libraryWithShelves',
    WoodenDeckBuild = 'woodenDeckBuild',
}

export enum PublishedFormId {
    Default = 'default',
    SwimmingPoolBuild = 'swimmingPoolBuild',
    SwimingPoolLightingInstallation = 'swimingPoolLightingInstallation',
    PoolDeckDesignAndLayout = 'poolDeckDesignAndLayout',
    AboveGroundPool = 'aboveGroundPool',
    ArborDesignAndBuild = 'arborDesignAndBuild',
    Bench = 'bench',
    BenchAndStool = 'benchAndStool',
    ChildBedroomLayout = 'childBedroomLayout',
    ChimneyBuild = 'chimneyBuild',
    Claustras = 'claustras',
    ClosetDoor = 'closetDoor',
    ConcreteFloor = 'concreteFloor',
    Console = 'console',
    ConsoleSuspended = 'consoleSuspended',
    CounterBar = 'counterBar',
    CounterBarAngle = 'counterBarAngle',
    CounterBarCentral = 'counterBarCentral',
    CounterBarCurved = 'counterBarCurved',
    Credenza = 'credenza',
    CustomBeds = 'customBeds',
    CustomBlind = 'customBlind',
    CustomBlindOutdoor = 'customBlindOutdoor',
    CustomBrazier = 'customBrazier',
    CustomBuiltInCabinet = 'customBuiltInCabinet',
    CustomBunkBed = 'customBunkBed',
    CustomChimney = 'customChimney',
    CustomCupboard = 'customCupboard',
    CustomCupboards = 'customCupboards',
    CustomCurtainAndVeil = 'customCurtainAndVeil',
    CustomExteriorDoor = 'customExteriorDoor',
    CustomMadeVanityUnit = 'customMadeVanityUnit',
    CustomMezzanine = 'customMezzanine',
    CustomPantry = 'customPantry',
    CustomRugs = 'customRugs',
    DesignAndBuildOfGardenSheds = 'designAndBuildOfGardenSheds',
    Desk = 'desk',
    DeskExecutive = 'deskExecutive',
    DpBakery = 'dpBakery',
    DpBar = 'dpBar',
    DpBeautyInstitute = 'dpBeautyInstitute',
    DpButcherShop = 'dpButcherSHop',
    DpCheeseDairy = 'dpCheeseDairy',
    DpEvents = 'dpEvents',
    DpFastFood = 'dpFastFood',
    DpGrocery = 'dpGrocery',
    DpHairdresser = 'dpHairdresser',
    DpHotel = 'dpHotel',
    DpHouseInterior = 'dpHouseInterior',
    DpHouseOutside = 'dpHouseOutside',
    DpPharmacy = 'dpPharmacy',
    DpRealEstate = 'dpRealEstate',
    DpRestaurant = 'dpRestaurant',
    DpShop = 'dpShop',
    DpWineCellar = 'dpWineCellar',
    DpWorkspace = 'dpWorkspace',
    DresserSideboard = 'dresserSideboard',
    DressingTable = 'dressingTable',
    ExteriorShutter = 'exteriorShutter',
    Fence = 'fence',
    Fencing = 'fencing',
    FloorHeating = 'floorHeating',
    FlooringSale = 'flooringSale',
    GarageBuild = 'garageBuild',
    GarageDoor = 'garageDoor',
    GarageLayout = 'garageLayout',
    GlassGreenHouse = 'glassGreenHouse',
    GlassShape = 'glassShape',
    GlassWall = 'glassWall',
    HighWind = 'highWind',
    InteriorShutter = 'interiorShutter',
    KitchenDesign = 'kitchenDesign',
    Landscaping = 'landscaping',
    LayoutAndBuildOfPoolHouse = 'layoutAndBuildOfPoolHouse',
    LibraryComposition = 'libraryComposition',
    MeetingTable = 'meetingTable',
    Mirror = 'mirror',
    OutdoorKitchenBuild = 'outdoorKitchenBuild',
    PartitionWall = 'partitionWall',
    PavingSlab = 'pavingSlab',
    PergolaBioclimatic = 'pergolaBioclimatic',
    PergolaClassic = 'pergolaClassic',
    PlayroomLayout = 'playroomLayout',
    PVCCarpentry = 'pVCCarpentry',
    Shelf = 'shelf',
    ShelfShoes = 'shelfShoes',
    ShoesCabinet = 'shoesCabinet',
    SideTableAndBedsideTable = 'sideTableAndBedsideTable',
    StaircaseDesign = 'staircaseDesign',
    StaircaseHalfTurn = 'staircaseHalfTurn',
    StaircaseQuarterTurn = 'staircaseQuarterTurn',
    StaircaseSpiralRound = 'staircaseSpiralRound',
    StaircaseSpiralSquare = 'staircaseSpiralSquare',
    StaircaseStraight = 'staircaseStraight',
    StaircaseTurnLanding = 'staircaseTurnLanding',
    StairInstallation = 'stairInstallation',
    StorageUnderStairs = 'storageUnderStairs',
    Table = 'table',
    TableConcrete = 'tableConcrete',
    TableFarm = 'tableFarm',
    TableIndustrial = 'tableIndustrial',
    TableLegs = 'tableLegs',
    TableLiveEdge = 'tableLiveEdge',
    TableMarbleAndStone = 'tableMarbleAndStone',
    TableMikado = 'tableMikado',
    TableRiverTable = 'tableRiverTable',
    TableScandinavian = 'tableScandinavian',
    TableVintage = 'tableVintage',
    TerraceWood = 'terraceWood',
    TerraceStones = 'terraceStones',
    TextileDecorationCurtainAndVeil = 'textileDecorationCurtainAndVeil',
    Tray = 'tray',
    TvUnitStand = 'tvUnitStand',
    TvUnitSuspended = 'tvUnitSuspended',
    VegetalWall = 'vegetalWall',
    Veranda = 'veranda',
    WallHanging = 'wallHanging',
    WallPaneling = 'wallPaneling',
    WardrobeRoom = 'wardrobeRoom',
    WindowFilm = 'windowFilm',
    Windows = 'windows',
    WineCellar = 'wineCellar',
    WineCellarDedicatedRoom = 'wineCellarDedicatedRoom',
    Worktop = 'worktop',
    WorktopAssembled = 'worktopAssembled',
    BedroomDesign = 'bedroomDesign',
    Stool = 'stool',
    PalisadeAndClaustra = 'palisadeAndClaustra',
    CustomKitchenCabinet = 'customKitchenCabinet',
    CustomKitchenBaseCabinet = 'customKitchenBaseCabinet',
    KitchenIsland = 'kitchenIsland',
    KitchenBack = 'kitchenBack',
    FrenchWindow = 'frenchWindow',
    RoofWindow = 'roofWindow',
    SlidingBayWindow = 'slidingBayWindow',
    FrontDoor = 'frontDoor',
    ServiceDoor = 'serviceDoor',
    Shutter = 'shutter',
    SunBreeze = 'sunBreeze',
    Portal = 'portal',
    Gate = 'gate',
    GrillageRigide = 'grillageRigide',
    Balustrade = 'balustrade',
    PorchDesignAndLayout = 'porchDesignAndLayout',
    GardenShelter = 'gardenShelter',
    Tonelle = 'tonelle',
    InteriorDoor = 'interiorDoor',
    CustomStorage = 'customStorage',
    OfficePedestals = 'officePedestals',
    CustomCabinet = 'customCabinet',
    Trunk = 'trunk',
    ChestsOfDrawersAndCabinet = 'chestsOfDrawersAndCabinet',
    CustomKitchenCupboard = 'customKitchenCupboard',
    StorageCupboard = 'storageCupboard',
    HallCabinet = 'hallCabinet',
    CoatHanger = 'coatHanger',
    LowTable = 'lowTable',
    LowTableNoLegs = 'lowTableNoLegs',
    LowTableNesting = 'lowTableNesting',
    LowTableNestingNoLegs = 'lowTableNestingNoLegs',
    NestingTable = 'nestingTable',
    SofaAndpedestalTable = 'sofaAndpedestalTable',
    Pouf = 'pouf',
    Sofa = 'sofa',
    EndOfBed = 'endOfBed',
    BarChair = 'barChair',
    BarStool = 'barStool',
    Chair = 'chair',
    Armchair = 'armchair',
    Bedhead = 'bedhead',
    Light = 'light',
    GreenWall = 'greenWall',
    ReceptionDesk = 'receptionDesk',
    SalesCounter = 'salesCounter',
    Showcase = 'showcase',
    SalesTable = 'salesTable',
    Shelving = 'shelving',
    Row = 'row',
    Teaches = 'teaches',
    TableMeeting = 'tableMeeting',
    Sunbath = 'sunbath',
    Nightstand = 'nightstand',
    BedroomLayout = 'bedroomLayout',
    StorageCabinet = 'storageCabinet',
    Ground = 'ground',
    EatStandingUp = 'eatStandingUp',
    PhoneBox = 'phoneBox',
    ShowerScreen = 'showerScreen',
    GardenPavilionDesignAndBuild = 'gardenPavilionDesignAndBuild',
    StoneLaying = 'stoneLaying',
}

export type DeprecatedFormIdUnion = `${DeprecatedFormId}`

export type PublishedFormIdUnion = `${PublishedFormId}`

export type FormIdUnion = DeprecatedFormIdUnion | PublishedFormIdUnion

export type FormId = PublishedFormId | DeprecatedFormId;

export default FormId;
