import SubmissionSaleStatusId from '@core/constants/SubmissionSaleStatus';

const SubmissionSaleStatusFR: { [key in SubmissionSaleStatusId]: string } = {
    budgetOk         : 'Interessé budget ok',
    coherentProject  : 'Projet cohérent, téléphone non vérifé',
    commission       : 'Commission',
    curiousUser      : 'Curieux',
    doNotAnswer      : 'Ne réponds pas',
    impossibleBudget : 'Budget impossible',
    lowBudget        : 'Budget faible',
    wrongPhoneNumber : 'Faux numéro',
};

export default SubmissionSaleStatusFR;
