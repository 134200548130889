import { useCallback, useState } from 'react';
import styled from '@ui/styles/styled';
import {
    browserVersion,
    isChrome,
    isEdge,
    isFirefox,
    isIE,
    isIOS,
    isSafari,
} from 'react-device-detect';
import { FormattedMessage } from 'react-intl';
import CheckIcon from '@ui/Icons/Check';
import Typography from '@ui/Typography';
import StyledButton from '@ui/StyledButton';
import Modal from '@ui/Modal';
import { lilmUrl } from '@core/config/Url';
import LogoDark from '../../../assets/images/logo-lilm-dark.png';

import {
    containerWrapperStyle,
    logoLinkStyle,
    contentStyle,
    titleStyle,
    confirmButtonStyle,
} from './styles';

const StyledWrapper = styled('div')(containerWrapperStyle);
const StyledLogoLink = styled('a')(logoLinkStyle);
const StyledImageTitle = styled(Typography)(titleStyle);
const StyledContent = styled(Typography)(contentStyle);
const StyledConfirmButton = styled(StyledButton)(confirmButtonStyle);

const BrowsersDetector = () => {
    const [open, setOpen] = useState(
        (isChrome && Number(browserVersion) < 49)
        || (isSafari && Number(browserVersion) < 11)
        || (isFirefox && (!isIOS && Number(browserVersion) < 60) && Number(browserVersion) < 31)
        || (isEdge && Number(browserVersion) < 79)
        || isIE,
    );

    const handleClose = useCallback(() => { setOpen(false); }, [setOpen]);

    return open ? (
        <Modal open={open} onClose={handleClose}>
            <StyledWrapper>
                <StyledLogoLink href={lilmUrl} target="_blank">
                    <img src={LogoDark} alt="Logo LILM" />
                </StyledLogoLink>

                <StyledImageTitle variant="h4" color="primary">
                    <FormattedMessage id="components.declineBrowsers.title" />
                </StyledImageTitle>

                <StyledContent variant="h6" color="primary">
                    <FormattedMessage id="components.declineBrowsers.content" />

                    <StyledConfirmButton onClick={handleClose}>
                        <CheckIcon />
                        <FormattedMessage id="continue" />
                    </StyledConfirmButton>
                </StyledContent>
            </StyledWrapper>
        </Modal>
    ) : null;
};

export default BrowsersDetector;
