import { IDesignProjectId, IDesignProjectCategoryId } from '../interfaces/DesignProject';

export const DesignProjectCategoriesFR: {
    [key in IDesignProjectCategoryId] : { name: string }
} = {
    BarCounter            : { name: 'Comptoir de bar' },
    BarRestaurant         : { name: 'Bar / restaurant' },
    BathRoom              : { name: 'Salle de bain' },
    Bedding               : { name: 'Literie' },
    BedRoom               : { name: 'Chambre' },
    Counter               : { name: 'Comptoir' },
    Decoration            : { name: 'Décoration' },
    DeskAndTable          : { name: 'Bureau et table' },
    DisplayCase           : { name: 'Vitrine' },
    DisplayCaseRefrigered : { name: 'Vitrine  réfrigérée' },
    Event                 : { name: 'Élements d\'agencement' },
    GreenWall             : { name: 'Mur végétal' },
    Ground                : { name: 'Sol' },
    HotelRoomFurniture    : { name: 'Mobilier chambre d\'hôtel' },
    Kitchen               : { name: 'Cuisine' },
    Lights                : { name: 'Luminaire' },
    LightsAndDeco         : { name: 'Luminaire et décoration' },
    LivingRoom            : { name: 'Salon / Séjour' },
    LobbyLounge           : { name: 'Loby / Lounge' },
    OfficeFurniture       : { name: 'Mobilier de bureau' },
    OfficeWorkSpace       : { name: 'Espace bureau' },
    Outside               : { name: 'Extérieur' },
    PartitionAndAcoustics : { name: 'Cloison et acoustique' },
    PhoneBooth            : { name: 'Cabine acoustique et phone box' },
    ReceptionFurniture    : { name: 'Mobilier d\'accueil' },
    RemovablePartition    : { name: 'Cloison amovible' },
    restaurantTable       : { name: 'Table de restaurant' },
    Seated                : { name: 'Assise' },
    ShopFurniture         : { name: 'Mobilier' },
    ShowCaseLight         : { name: 'Luminaire vitrine' },
    StorageUnit           : { name: 'Meuble de rangement' },
    Table                 : { name: 'Table' },
    Teaches               : { name: 'Enseigne' },
    TerraceFurniture      : { name: 'Mobilier terrasse' },
};

export const DesignProjectFR: {
    [key in IDesignProjectId]: {name: string}
} = {
    BistroTable                     : { name: 'Table bistrot' },
    DinnerTable                     : { name: 'Table à manger' },
    HighTable                       : { name: 'Table haute' },
    LowTable                        : { name: 'Table basse' },
    CounterRight                    : { name: 'Comptoir droit' },
    CornerCounter                   : { name: 'Comptoir d\'angle' },
    RoundedCounter                  : { name: 'Comptoir arrondi' },
    CentralCounter                  : { name: 'Comptoir central' },
    OutdoorTable                    : { name: 'Table d\'extérieur' },
    OutdoorChair                    : { name: 'Chaise d\'extérieur' },
    SummerKitchen                   : { name: 'Cuisine d\'été' },
    GlassWall                       : { name: 'Verrière' },
    Separator                       : { name: 'Séparateur ' },
    Chair                           : { name: 'Chaise' },
    BarChair                        : { name: 'Chaise de bar' },
    Stool                           : { name: 'Tabouret' },
    Bench                           : { name: 'Banc' },
    BenchLong                       : { name: 'Banquette' },
    Armchair                        : { name: 'Fauteuil' },
    Bind                            : { name: 'Cellier' },
    Library                         : { name: 'Bibliothèque' },
    Sideboard                       : { name: 'Enfilade' },
    RefrigeratedDisplay             : { name: 'Vitrine réfrigérée ' },
    MetalCabinet                    : { name: 'Armoire métallique ' },
    WoodenWardrobe                  : { name: 'Armoire en bois' },
    MetalicWardrobe                 : { name: 'Armoire métallique' },
    TiledFloor                      : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloor              : { name: 'Sol béton ciré (m²)' },
    ParquetFloor                    : { name: 'Sol parquet (m²)' },
    TiledFloorLobyLounge            : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorLobyLounge    : { name: 'Sol béton ciré (m²)' },
    ParquetFloorLobyLounge          : { name: 'Sol parquet (m²)' },
    TiledFloorRoom                  : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorRoom          : { name: 'Sol béton ciré (m²)' },
    ParquetFloorRoom                : { name: 'Sol parquet (m²)' },
    TiledFloorRestaurant            : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorRestaurant    : { name: 'Sol béton ciré (m²)' },
    ParquetFloorRestaurant          : { name: 'Sol parquet (m²)' },
    TiledFloorHouseInterior         : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorHouseInterior : { name: 'Sol béton ciré (m²)' },
    ParquetFloorHouseInterior       : { name: 'Sol parquet (m²)' },
    TiledFloorKitchen               : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorKitchen       : { name: 'Sol béton ciré (m²)' },
    ParquetFloorKitchen             : { name: 'Sol parquet (m²)' },
    TiledFloorBathRoom              : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorBathRoom      : { name: 'Sol béton ciré (m²)' },
    ParquetFloorBathRoom            : { name: 'Sol parquet (m²)' },
    TiledFloorBedRoom               : { name: 'Sol carrelage (m²)' },
    WaxedConcreteFloorBedRoom       : { name: 'Sol béton ciré (m²)' },
    ParquetFloorBedRoom             : { name: 'Sol parquet (m²)' },
    receptionDesk                   : { name: 'Banque d\'accueil' },
    Table                           : { name: 'Table' },
    GreenWall                       : { name: 'Mur végétal' },
    Bedding                         : { name: 'Literie' },
    BunkBed                         : { name: 'Lit superposé' },
    CabinBed                        : { name: 'Lit cabane' },
    ClassicBed                      : { name: 'Lit classique' },
    MezzanineBed                    : { name: 'Lit-mezzanine' },
    GroundMattress                  : { name: 'Matelat de sol' },
    Bedspring                       : { name: 'Sommier' },
    Bedhead                         : { name: 'Tête de lit' },
    DressingRoom                    : { name: 'Dressing' },
    Wardrobe                        : { name: 'Penderie' },
    Cupboard                        : { name: 'Placard' },
    VanityUnit                      : { name: 'Meuble sous vasque' },
    GlassShowerScreen               : { name: 'Pare-douche en verre' },
    Mirror                          : { name: 'Miroir' },
    EatStandingUp                   : { name: 'Mange-debout' },
    Sofa                            : { name: 'Canapé' },
    Sunbath                         : { name: 'Bain de soleil' },
    WoodenSign                      : { name: 'Enseigne en bois' },
    MetalSign                       : { name: 'Enseigne en métal' },
    NeonSign                        : { name: 'Enseigne lumineuse' },
    ShowCase                        : { name: 'Vitrine' },
    SalesTable                      : { name: 'Table de vente' },
    StorageUnit                     : { name: 'Meuble de rangement' },
    Shelving                        : { name: 'Rayonnage' },
    ShowCaseLight                   : { name: 'Luminaire vitrine' },
    DisplayCasePositiveTemperature  : { name: 'Vitrine réfrigérée' },
    WallDisplayCase                 : { name: 'Vitrine réfrigérée murale' },
    DisplayCaseButchery             : { name: 'Vitrine réfrigérée boucherie' },
    DisplayCaseIceCream             : { name: 'Vitrine réfrigérée à glace' },
    SelfServiceDisplayCase          : { name: 'Vitrine réfrigérée libre service' },
    DisplayCaseSandwichShop         : { name: 'Vitrine réfrigérée sandwicherie' },
    DisplayCaseBakery               : { name: 'Vitrine réfrigérée boulangerie' },
    DisplayCaseCheese               : { name: 'Vitrine réfrigérée fromagerie' },
    MeetingTable                    : { name: 'Table de réunion' },
    CoworkingTable                  : { name: 'Table de coworking' },
    ConferenceTable                 : { name: 'Table de conférence' },
    Desk                            : { name: 'Bureau' },
    OfficeChair                     : { name: 'Chaise de bureau' },
    InduvidualDesk                  : { name: 'Bureau individuel' },
    HeightAdjustableDesk            : { name: 'Bureau réglable en hauteur' },
    ExecutiveDesk                   : { name: 'Bureau de direction' },
    MobileBoxes                     : { name: 'Caisson mobile' },
    PhoneBooth                      : { name: 'Cabine acoustique et phone box' },
    RemovablePartition              : { name: 'Cloison amovible' },
    PartitionSeparation             : { name: 'Cloison de séparation' },
    PartitionPanel                  : { name: 'Panneau de séparation' },
    CompleteStaircase               : { name: 'Escalier complet' },
    StairRailing                    : { name: 'Rampe d\'escalier' },
    Stairs                          : { name: 'Marches d\'escalier' },
    TvUnit                          : { name: 'Meuble TV' },
    Buffet                          : { name: 'Buffet' },
    Console                         : { name: 'Console' },
    Dresser                         : { name: 'Commode' },
    ShoesCabinet                    : { name: 'Meuble chaussure' },
    Door                            : { name: 'Porte' },
    WorkTop                         : { name: 'Plan de travail' },
    Equippedkitchen                 : { name: 'Cuisine équipée' },
    ArmorWardrobe                   : { name: 'Armoire' },
    WoodenDeck                      : { name: 'Terrasse en bois' },
    Awning                          : { name: 'Auvent' },
    Marquise                        : { name: 'Marquise' },
    Pergola                         : { name: 'Pergola' },
    Veranda                         : { name: 'Veranda' },
    Fence                           : { name: 'Garde-corps' },
    SwimmingPool                    : { name: 'Piscine' },
    Portal                          : { name: 'Portail' },
    Stand                           : { name: 'Stand' },
    BindBottle                      : { name: 'Casier à bouteilles' },
    HighChair                       : { name: 'Chaise haute' },
    BarStool                        : { name: 'Tabouret de bar' },
    LEDStrip                        : { name: 'Strip LED' },
    RecessedSpot                    : { name: 'Spot encastré' },
    CeilingLight                    : { name: 'Plafonnier' },
    StreetLamp                      : { name: 'Lampadaire' },
    Chandelier                      : { name: 'Lustre' },
    WallLamp                        : { name: 'Applique' },
    WallStylingUnit                 : { name: 'Coiffeuse murale' },
    CentralDressingTable            : { name: 'Coiffeuse centrale' },
    Trolley                         : { name: 'Trolley' },
    Laboratory                      : { name: 'Laboratoire' },
    CuttingStool                    : { name: 'Tabouret de coupe' },
    StylingChair                    : { name: 'Fauteuil de coiffage' },
    TreatmentTable                  : { name: 'Table de soins' },
    ManicureTable                   : { name: 'Table de manucure' },
    ProductDisplay                  : { name: 'Présentoir produit' },
    BottleDisplay                   : { name: 'Présentoir à bouteilles' },
    PresentationTable               : { name: 'Table de présentation' },
    Desert                          : { name: 'Desserte' },
    Panetiere                       : { name: 'Panetière' },
    ButchersBlock                   : { name: 'Billot de boucher' },
    ReceptionCounter                : { name: 'Banque d\'accueil' },
    BeenBag                         : { name: 'Pouf' },
    ReceptionChairs                 : { name: 'Chaise d\'accueil' },
    ReceptionBenches                : { name: 'Banc d\'accueil' },
    OfficeDivider                   : { name: 'Séparateur de bureau' },
    AcousticPanel                   : { name: 'Dalle acoustique' },
    ToyBox                          : { name: 'Coffre à jouet' },
    NightStand                      : { name: 'Table de chevet' },
    LightFixture                    : { name: 'Luminaires' },
    Carpet                          : { name: 'Tapis' },
    CurtainsAndNetCurtains          : { name: 'Rideaux et voilages' },
    Wallpaper                       : { name: 'Papier peint' },
};

export default DesignProjectFR;
