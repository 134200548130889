enum FormGroupId {
    Beds = 'beds',
    Consoles = 'consoles',
    CounterBar = 'counterBar',
    CustomMadeFurniture = 'customMadeFurniture',
    Default = 'default',
    Desk = 'desk',
    DpEvents = 'dpEvents',
    DpHouse = 'dpHouse',
    DpOfficeAndWorkspace = 'dpOfficeAndWorkspace',
    DpRestaurant = 'dpRestaurant',
    DpShop = 'dpShop',
    GlassAndMirror = 'glassAndMirror',
    GlassWall = 'glassWall',
    Kitchen = 'kitchen',
    Library = 'library',
    Pergola = 'pergola',
    ShoesStorage = 'shoesStorage',
    Staircase = 'staircase',
    Table = 'table',
    Terrace = 'terrace',
    TvUnit = 'tvUnit',
    WardrobesAndStorages = 'wardrobe',
    WindowBlinds = 'windowBlinds',
    WineCellar = 'wineCellar',
    Worktop = 'worktop',
}

export default FormGroupId;
