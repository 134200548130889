import {
    spaceRegular,
    spaceMicro,
    fontLarge,
} from '@ui/styles/spaces';
import {
    lightMarineColor,
    azurColor,
    warningColor,
    errorColor,
} from '@ui/styles/colors';
import { ISnackbarContentMode, ISnackbarContentProperties } from './types';

export const snackBarContentStyle = {
    backgroundColor: ({ mode }: ISnackbarContentProperties) => (
        (mode === ISnackbarContentMode.Info && `${lightMarineColor} !important`)
        || (mode === ISnackbarContentMode.Success && `${azurColor} !important`)
        || (mode === ISnackbarContentMode.Error && `${errorColor} !important`)
        || (mode === ISnackbarContentMode.Warning && `${warningColor} !important`)
        || (mode === ISnackbarContentMode.Congratulation && `${lightMarineColor} !important`)
        || (mode === ISnackbarContentMode.Greetings && `${lightMarineColor} !important`)
        || `${lightMarineColor} !important`
    ),

    display       : 'flex' as 'flex',
    flexDirection : 'row' as 'row',
    flexWrap      : 'nowrap' as 'nowrap',
    alignItems    : 'flex-start' as 'flex-start',
    padding       : 0,
    margin        : 0,

    '& .MuiSnackbarContent-message': {
        flexGrow      : 1,
        flexShrink    : 1,
        padding       : 0,
        margin        : 0,
        display       : 'flex' as 'flex',
        flexDirection : 'row' as 'row',
        flexWrap      : 'nowrap' as 'nowrap',
        alignItems    : 'flex-start' as 'flex-start',

    },

    '& .MuiSnackbarContent-action': {
        flexGrow   : 0,
        flexShrink : 0,
        margin     : 0,
        padding    : 0,
    },
};

export const snackbarContentIconStyle = {
    fontSize : fontLarge,
    padding  : spaceRegular,
};

export const snackbarMessageWrapperStyle = {
    flexGrow      : 1,
    marginTop     : spaceMicro,
    paddingTop    : spaceRegular,
    paddingBottom : spaceRegular,
};
