export enum SubmissionStatusId {
    Draft = 'draft',
    PendingAutoMatch = 'pendingAutoMatch',
    PendingManuMatch = 'pendingManuMatch',
    Submitted = 'submitted',
    Accepted = 'accepted',
    ClosedForRequests = 'closedForRequests',
    Canceled = 'canceled',
}

export default SubmissionStatusId;
