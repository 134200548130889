import {
    FC, ChangeEvent, FormEvent, useState, useCallback, useMemo,
} from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Typography from '@ui/Typography';
import TextField from '@ui/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgressIcon from '@material-ui/core/CircularProgress';
import CheckIcon from '@ui/Icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import styled from '@ui/styles/styled';
import StyledButton from '@ui/StyledButton';
import Routes, { RouteKey } from '@core/constants/Route';
import {
    IUserCredentials,
    IUserKey,
} from '../../../interfaces/User';
import {
    signInButtonStyle,
    signInTextFieldStyle,
    signInFormStyle,
    signInTitleStyle,
    signInSubtitleStyle,
    signInResetPwdLinkStyle,
} from './styles';
import firebaseService from '../../../services/Firebase';
import { validateEmail, validatePassword } from '../../../validations/Auth';
import { validateUserSignInData } from '../../../validations/User';
import SnackbarContent from '../SnackbarContent';
import { ISnackbarContentMode } from '../SnackbarContent/types';
import ISignInProperties from './types';
import passwordPattern from '../../../constants/PasswordPattern';
import { useUserDispatch, useUserState } from '../../context/UserContext';
import { FirebaseErrorId } from '../../../constants/ApiErrors';

const StyledSignInButton = styled(StyledButton)(signInButtonStyle);
const StyledSignInTextField = styled(TextField)(signInTextFieldStyle);
const StyledSignInForm = styled('form')(signInFormStyle);
const StyledSignInTitle = styled(Typography)(signInTitleStyle);
const StyledSignInSubtile = styled(Typography)(signInSubtitleStyle);
const StyledSignInResetPwdLink = styled(Button)(signInResetPwdLinkStyle);

const SignIn: FC<ISignInProperties> = ({ onSignIn }) => {
    const intl = useIntl();
    const { user } = useUserState();
    const storeUser = useUserDispatch();

    const [isPasswordDisplayed, setIsPasswordDisplayed] = useState<boolean>(false);

    const [hasSentResetPasswordEmail, setHasSentResetPasswordEmail] = useState<boolean>(false);

    const [firebaseErrorResetPassword, setFirebaseErrorResetPassword] = useState<string | undefined>();

    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [firebaseError, setFirebaseError] = useState<string | undefined>();

    const [userData, setUserData] = useState<IUserCredentials>({
        password : '',
        email    : user?.email || '',
    });

    const isReadyForSubmission = useMemo(() => validateUserSignInData(userData), [userData]);

    const resetErrors = useCallback(() => { setFirebaseError(undefined); setFirebaseErrorResetPassword(undefined); }, [setFirebaseError, setFirebaseErrorResetPassword]);

    const handleIsPasswordDisplayedToggle = useCallback(() => setIsPasswordDisplayed((previousIsPasswordDisplayed) => !previousIsPasswordDisplayed), [setIsPasswordDisplayed]);

    const handleUserDataChange = useCallback((userDataKey: IUserKey) => ({ target }: ChangeEvent<HTMLInputElement>) => {
        setFirebaseError(undefined);
        setUserData((previousState) => ({ ...previousState, [userDataKey]: target.value }));
    }, [setFirebaseError, setUserData]);

    const handleSignInSubmission = useCallback(async (event: FormEvent): Promise<void> => {
        event.preventDefault();
        event.stopPropagation();
        if (!isReadyForSubmission) {
            setFirebaseError(FirebaseErrorId.AuthInvalidCredentials);
        } else {
            try {
                setIsLoading(true);
                storeUser(await (await firebaseService.getInstance()).signIn(userData));
                if (onSignIn) onSignIn();
            } catch (error) {
                setFirebaseError(error.code);
            } finally {
                setIsLoading(false);
            }
        };
    }, [isReadyForSubmission, setIsLoading, storeUser, onSignIn, setFirebaseError, userData]);

    const handleSendResetPasswordEmail = useCallback(async () => {
        if (!validateEmail(userData.email)) {
            setFirebaseErrorResetPassword(FirebaseErrorId.AuthInvalidEmail);
        } else {
            try {
                setIsLoadingResetPassword(true);
                await (await firebaseService.getInstance()).sendResetPasswordEmail(userData.email, { url: `${window.location.href}`, handleCodeInApp: true });
                setHasSentResetPasswordEmail(true);
            } catch (error) {
                setFirebaseErrorResetPassword(error.code);
            } finally {
                setIsLoadingResetPassword(false);
            }
        }
    }, [setIsLoadingResetPassword, setFirebaseErrorResetPassword, userData]);

    return (
        <>
            {!hasSentResetPasswordEmail && !isLoadingResetPassword && (
                <>
                    <StyledSignInTitle variant="h6" gutterBottom color="primary">
                        <FormattedMessage id="components.SignIn.title" />
                    </StyledSignInTitle>

                    <StyledSignInSubtile variant="caption" gutterBottom>
                        <FormattedHTMLMessage id="components.SignIn.subtitle" />
                    </StyledSignInSubtile>
                </>
            )}

            {hasSentResetPasswordEmail && !isLoadingResetPassword && (
                <>
                    <StyledSignInTitle variant="h6" gutterBottom color="primary">
                        <FormattedMessage id="resetPasswordSentTitle" />
                    </StyledSignInTitle>

                    <StyledSignInSubtile variant="caption" gutterBottom>
                        <FormattedMessage id="resetPasswordSentSubtitle" />
                    </StyledSignInSubtile>
                </>
            )}

            {isLoadingResetPassword && (
                <>
                    <StyledSignInTitle variant="h6" gutterBottom color="primary">
                        <FormattedMessage id="resetPasswordSendingTitle" />
                    </StyledSignInTitle>

                    <StyledSignInSubtile variant="caption" gutterBottom>
                        <FormattedMessage id="resetPasswordSendingSubtitle" />
                    </StyledSignInSubtile>
                </>
            )}

            <StyledSignInForm onSubmit={handleSignInSubmission}>
                <StyledSignInTextField
                    id={IUserKey.Email}
                    name={IUserKey.Email}
                    type="email"
                    onChange={handleUserDataChange(IUserKey.Email)}
                    label={intl.formatMessage({ id: `components.SignIn.${IUserKey.Email}` })}
                    value={userData.email}
                    required
                    margin="normal"
                    variant="outlined"
                    disabled={isLoading || isLoadingResetPassword}
                    fullWidth
                />

                {!hasSentResetPasswordEmail && !isLoadingResetPassword && (
                    <>
                        <StyledSignInTextField
                            InputProps={{
                                placeholder  : 'Ex: Lilm4Life!',
                                // @ts-ignore
                                pattern      : passwordPattern,
                                endAdornment : (
                                    <InputAdornment position="end">
                                        <IconButton aria-label="toggle password visibility" onClick={handleIsPasswordDisplayedToggle}>
                                            {isPasswordDisplayed ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            id={IUserKey.Password}
                            name={IUserKey.Password}
                            type={isPasswordDisplayed ? 'text' : 'password'}
                            onChange={handleUserDataChange(IUserKey.Password)}
                            label={intl.formatMessage({ id: 'password' })}
                            value={userData.password}
                            required
                            margin="normal"
                            variant="outlined"
                            disabled={isLoading || isLoadingResetPassword}
                            fullWidth
                        />

                        <StyledSignInButton
                            variant="contained"
                            color="primary"
                            type="submit"
                            loading={isLoading}
                            error={!!firebaseError}
                            icon={(<CheckIcon />)}
                            disabled={!!isLoading || !!firebaseError || !validateEmail(userData.email) || !validatePassword(userData.password)}
                        >
                            <FormattedMessage id="confirm" />
                        </StyledSignInButton>

                        <Link to={generatePath(Routes[RouteKey.ArtisanOnboarding], { step: undefined })}>
                            <StyledSignInButton
                                variant="contained"
                                color="secondary"
                                type="submit"
                                disabled={!!isLoading || !!firebaseError}
                            >
                                <FormattedMessage id="signUpArtisan" />
                                <span>
                                    <ArrowRightAltIcon style={{
                                        width: 28, height: 28, marginLeft: 6, marginRight: -6, marginTop: 4,
                                    }}
                                    />
                                </span>
                            </StyledSignInButton>
                        </Link>
                    </>
                )}

                <StyledSignInResetPwdLink
                    onClick={handleSendResetPasswordEmail}
                    disabled={!!isLoadingResetPassword || !!firebaseErrorResetPassword}
                >
                    <Typography variant="caption">
                        {firebaseErrorResetPassword ? <ErrorOutlineIcon /> : isLoadingResetPassword && <CircularProgressIcon size={12} />}
                        {hasSentResetPasswordEmail ? (
                            <FormattedMessage id="resetPasswordAgain" />
                        ) : (
                            <FormattedMessage id="forgottenPassword" />
                        )}
                    </Typography>
                </StyledSignInResetPwdLink>
            </StyledSignInForm>

            {(!!firebaseError || !!firebaseErrorResetPassword) && (
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={!!firebaseError || !!firebaseErrorResetPassword}
                    onClose={resetErrors}
                >
                    <SnackbarContent
                        mode={ISnackbarContentMode.Error}
                        message={intl.formatMessage({ id: `errors.firebase.${firebaseError || firebaseErrorResetPassword}` })}
                        onClose={resetErrors}
                    />
                </Snackbar>
            )}
        </>
    );
};

export default SignIn;
