import { ArtisanLabel } from '@core/constants/Artisan';

const ArtisanLabelsFR: {[key in ArtisanLabel] : {
    description: string;
    name: string;
}} = {
    AEF: {
        name        : 'Label Artisans Ebénistes de France',
        description : 'Le chef d’entreprise agréé s’engage à respecter et perpétuer les règles de l’art de la fabrication, dans la sélection des bois à l’humidité contrôlée, la précision des assemblages, la finition et la maîtrise des services associés pour les meubles de style et contemporains, les boiseries murales et les agencements, l’aménagement de cuisines artisanales.',
    },
    ARTISAN: {
        name        : 'Label Artisan d\'Art',
        description : 'La qualité d’Artisan d’Art est reconnue de droit par le Président de la Chambre de Métiers et de l’Artisanat aux personnes dont l’activité exercée est répertoriée dans la liste des métiers d’art fixée par l’arrêté du 12 décembre 2003 et qui répondent aux conditions pour se prévaloir de la qualité d’Artisan.',
    },
    DMAE: {
        name        : 'Label Maître d\'Art',
        description : 'Le titre de Maître d’art distingue des artisans de passion pour la singularité de leur savoir-faire, leur parcours exceptionnel et leur implication dans le renouvellement des métiers d’art. Plus qu’une reconnaissance, ce titre est le symbole d’un engagement et d’une volonté de transmettre.',
    },
    ECO_DEFIS: {
        name        : 'Label Eco-défis des commerçants et artisans',
        description : 'Le label « Eco-défis des commerçants et artisans » permet de valoriser les actions menées par les entreprises locales en matière d’environnement, énergie, transport, déchets, éco-produits, gestion de l’eau, emballages, responsabilité sociétale. Cette opération est menée localement avec des collectivités qui s’engagent. Elle permet d’impliquer un grand nombre d’entreprises avec des actions simples, précises et bénéfiques à tous.',
    },
    FSC: {
        name        : 'Label FSC',
        description : 'Le FSC (Forest Stewardship Council) est un label qui assure que la production d\'un produit à base de bois, comme un meuble, a suivi le cahier des charges d\'une gestion durable des forêts.',
    },
    MAITRE_ARTISAN: {
        name        : 'Label Maître Artisan',
        description : 'Le titre de Maître Artisan constitue la récompense d’une haute qualité professionnelle et d’un véritable engagement dans la promotion de l’artisanat.',
    },
    MOF: {
        name        : 'Label Meilleurs Ouvriers de France',
        description : 'Il s’agit d’un concours organisé par le ministère de l’éducation nationale qui délivre un diplôme d’Etat. Les lauréats se voient attribuer le titre « Un des Meilleurs Ouvriers de France », attestant de l’acquisition d’une haute qualification. Le concours est organisé tous les trois ans.',
    },
    PEFC: {
        name        : 'Label PEFC',
        description : 'Le label PEFC garantit au consommateur que le produit qu’il achète est issu de sources responsables et qu’à travers son acte d’achat, il participe à la gestion durable des forêts. Gérer durablement une forêt, c’est prendre en compte ses dimensions environnementales, sociétales et économiques.',
    },
    RGE_ECO_ARTSIAN: {
        name        : 'Label RGE Eco Artisan',
        description : 'Les ECO Artisans® font la différence en s’engageant concrètement pour le confort et l’amélioration de la performance énergétique des logements Ils proposent des solutions pertinentes en termes d’économie d’énergie.',
    },
};

export default ArtisanLabelsFR;
