const setDynamicFullHeightVariable = () => {
    const setVHVariable = () => {
        const vh = window.innerHeight / 100;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVHVariable();
    window.addEventListener('resize', setVHVariable);
    window.addEventListener('orientationchange', setVHVariable);
};

export default setDynamicFullHeightVariable;
