import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ErrorOutline } from '@material-ui/icons';
import styled from '../styles/styled';

import { getStyledButtonStyles, styledButtonIconStyle } from './styles';
import { IStyledButtonModes, IStyledButtonProperties } from './types';

const StyledButton = styled(({
    active: _active,
    colored: _colored,
    mode: _mode,
    type,
    disabled,
    error,
    loading,
    icon,
    children,
    ...rest
}: IStyledButtonProperties) => (
    <Button disabled={disabled || !!loading || !!error} type={type || 'button'} {...rest}>
        {error
            ? <ErrorOutline />
            : (loading
                ? <CircularProgress size={24} style={styledButtonIconStyle} />
                : icon)}
        {children}
    </Button>
))(getStyledButtonStyles);

// @ts-ignore
StyledButton.defaultProps = {
    active           : false,
    color            : 'primary',
    variant          : 'contained',
    disableElevation : true,
    mode             : IStyledButtonModes.Medium,
    colored          : false,
};

export default StyledButton;
