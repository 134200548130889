import {
    sizeHuge,
    spaceMedium,
    spaceWide,
    spaceSmall,
    fontSmall,
    spaceMicro,
    spaceNano,
} from '@ui/styles/spaces';
import { lightGreyColor } from '@ui/styles/colors';

export const signInFormStyle = {
    display        : 'flex',
    flexDirection  : 'column' as 'column',
    flexWrap       : 'wrap' as 'wrap',
    justifyContent : 'center' as 'center',
    alignItems     : 'center' as 'center',
    width          : '100%',
    maxWidth       : sizeHuge,
    boxSizing      : 'border-box' as 'border-box',
};

export const signInTextFieldStyle = {
    width     : '100%',
    maxWidth  : sizeHuge,
    boxSizing : 'border-box' as 'border-box',

    '& .MuiInputBase-input': {
        backgroundColor: lightGreyColor,
    },
    '& .MuiInputBase-root': {
        backgroundColor: `${lightGreyColor} !important`,
    },
};

export const signInButtonStyle = {
    marginTop: spaceMedium,
};

export const signInTitleStyle = {
    marginTop    : spaceMedium,
    marginBottom : spaceMedium,
    width        : '100%',
    maxWidth     : sizeHuge,
    boxSizing    : 'border-box' as 'border-box',
    textAlign    : 'center' as 'center',
};

export const signInSubtitleStyle = {
    width        : '100%',
    maxWidth     : sizeHuge,
    boxSizing    : 'border-box' as 'border-box',
    textAlign    : 'center' as 'center',
    marginBottom : spaceMedium,
};

export const signInResetPwdLinkStyle = {
    textDecoration : 'none' as 'none',
    textTransform  : 'none' as 'none',
    marginTop      : spaceWide,
    marginBottom   : spaceSmall,

    '& .MuiSvgIcon-root': {
        fontSize     : fontSmall,
        marginRight  : spaceMicro,
        marginBottom : -spaceNano,
    },
};
