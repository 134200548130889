import { SubmissionInternalContactQualificationId } from '@core/constants/SubmissionInternalSaleStatus';

const InternalContactQualificationStatusFR: { [key in SubmissionInternalContactQualificationId]: string } = {
    connectedCall : 'Appel connecté',
    vocalMessage  : 'A laissé un message vocal',
    sentSms       : 'A envoyé un SMS',
    sentMail      : 'A envoyé un mail',
};

export default InternalContactQualificationStatusFR;
