import Country from '@core/constants/Country';
import { DeprecatedFormId, PublishedFormIdUnion } from '@furnish-hx/constants/FormId';
import FormTypeId from '@core/constants/FormTypeId';
import SubmissionSaleStatusId from '@core/constants/SubmissionSaleStatus';
import { ShapeId } from '@core/constants/Shape';
import PersonaId from '@core/constants/Persona';
import { RequestMatchingSourceId, RequestStatusId } from '@core/constants/Request';
import LookingForOptionId from '@core/constants/LookingForOption';
import EmergencyOptionId from '@core/constants/EmergencyOption';
import FinishingOptionId from '@core/constants/FinishingOption';
import AvailablityOptionId from '@core/constants/AvailabilityOption';
import OptionId from '@core/constants/Option';
import {
    MaterialCategoryId,
    MaterialClassId,
    MaterialId,
} from '@core/constants/Material';
import ILocation, { IGeometry } from '@core/interfaces/ILocation';
import { ShippingAccessId, ShippingFloorId } from '@core/constants/ShippingDetail';
import FieldDimensionId from '@core/constants/FieldDimension';
import PropertyOptionId from '@core/constants/PropertyOption';
import OwnerTenantOptionId from '@core/constants/OwnerTenantOption';
import { SubmissionInternalQualificationId, SubmissionInternalContactQualificationId } from '@core/constants/SubmissionInternalSaleStatus';
import { IUserRole } from './User';
import { IDesignProjectId, IDesignProjectCategoryId } from './DesignProject';

export interface IFormRange {
    min: number
    max: number
}

export enum IFormInputType {
    Text = 'text',
    Select = 'select',
    Textarea = 'textarea',
    Number = 'number',
    File = 'file',
    Email = 'email',
    Tel = 'tel',
    Range = 'range',
}

export interface IPersonaField {
    value: PersonaId | undefined
    options: PersonaId[]
    required: boolean
}

export interface IAvailabilityField {
    value?: AvailablityOptionId | undefined
    options: AvailablityOptionId[]
    required: boolean
}

export interface IFirstNameField {
    value: string | undefined
    type: IFormInputType.Text
    required: boolean
}

export interface ILastNameField {
    value: string | undefined
    type: IFormInputType.Text
    required: boolean
}

export interface IEmailField {
    value: string | undefined
    type: IFormInputType.Email
    required: boolean
}

export interface IPhoneField {
    value: string | undefined
    type: IFormInputType.Tel
    required: boolean
}

export interface IShippingAddressField {
    value: string | undefined
    type: IFormInputType.Text
    required: boolean
}

export interface IShippingAddressCityField {
    value: string | undefined
    type: IFormInputType.Text
    required: boolean
}

export interface IPropertyField {
    value?: PropertyOptionId | null
    options: PropertyOptionId[]
    required: boolean
}

export interface IOwnerTenantField {
    value?: OwnerTenantOptionId | null
    options: OwnerTenantOptionId[]
    required: boolean
}

export interface IShippingAddressZipField {
    value: string | undefined
    type: IFormInputType.Number
    required: boolean
}

export interface IShippingAddressCountryField {
    value: Country | undefined
    options: Country[]
    required: boolean
}

export interface IShippingAddressFloorField {
    value: ShippingFloorId | undefined
    options: ShippingFloorId[]
    required: boolean
}

export interface IShippingAddressAccessField {
    value: ShippingAccessId | undefined
    options: ShippingAccessId[]
    required: boolean
}

export enum IFormFieldContactKey {
    Availability = 'availability',
    Email = 'email',
    FirstName = 'firstName',
    LastName = 'lastName',
    Persona = 'persona',
    Phone = 'phone',
    ShippingAddress = 'shippingAddress',
    ShippingAddressAccess = 'shippingAddressAccess',
    ShippingAddressCity = 'shippingAddressCity',
    ShippingAddressCountry = 'shippingAddressCountry',
    ShippingAddressFloor = 'shippingAddressFloor',
    ShippingAddressZip = 'shippingAddressZip',
}

export interface IFormFieldContact {
    [IFormFieldContactKey.Persona]: IPersonaField
    [IFormFieldContactKey.FirstName]: IFirstNameField
    [IFormFieldContactKey.LastName]: ILastNameField
    [IFormFieldContactKey.Email]: IEmailField
    [IFormFieldContactKey.Phone]: IPhoneField
    [IFormFieldContactKey.Availability]: IAvailabilityField
    [IFormFieldContactKey.ShippingAddress]?: IShippingAddressField
    [IFormFieldContactKey.ShippingAddressCity]?: IShippingAddressCityField
    [IFormFieldContactKey.ShippingAddressZip]: IShippingAddressZipField
    [IFormFieldContactKey.ShippingAddressCountry]: IShippingAddressCountryField
    [IFormFieldContactKey.ShippingAddressFloor]: IShippingAddressFloorField
    [IFormFieldContactKey.ShippingAddressAccess]: IShippingAddressAccessField
}

export interface IFormFieldMaterial {
    material: {
        value: MaterialId | undefined | null
        options: MaterialId[]
        required: boolean
    }
    materialCategory?: {
        value?: MaterialCategoryId
    }
    materialClass?: {
        value?: MaterialClassId
    }
}

export interface IQuantityField {
    value: number | undefined
    min: number
    max: number
    step?: number
    info?: boolean
}

export type IFormFieldShapeQuantities = {
    [key in ShapeId]?: IQuantityField
}

export type IFormFieldDesignQuantities = {
    [key in IDesignProjectCategoryId]?: {
        [id in IDesignProjectId]?: IQuantityField
    }
}

export interface IFormFieldShape {
    value: ShapeId | null | undefined
    options: ShapeId[]
    required: boolean
    withFilter?: boolean
}

export type DimensionsValues = { [key in FieldDimensionId]?: { value: DimensionFieldValue } }

export interface IFormFieldShapeDimension {
    shape: IFormFieldShape
    dimension: DimensionsValues & {
        options: {
            [key in ShapeId]?: IFormFieldDimension
        }
    }
}

export interface IFormFieldShapeMaterial {
    shape: IFormFieldShape
    material: IFormFieldMaterial
}

export type IBudgetMarks = {value: number}[]

export interface IBudgetField {
    value: number | null | undefined
    type: IFormInputType.Range
    step: number | null,
    range: IFormRange,
    marks?: IBudgetMarks
    required: boolean,
    defaultValue?: number
}

export interface ICommentField {
    value: string | null | undefined
    required: boolean
}

export interface IUploadFileField {
    value: FileList | string | string[] | null | undefined
    required: boolean
}

export interface ILookingForField {
    value?: LookingForOptionId | null
    options: LookingForOptionId[]
    required: boolean
}

export interface IEmergencyField {
    value?: EmergencyOptionId | null
    options: EmergencyOptionId[]
    required: boolean
}

export interface IFinishingField {
    value?: FinishingOptionId | null
    options: FinishingOptionId[]
    required: boolean
}

export interface IMultiSelectorField {
    value?: string[] | null
    options: string[]
    required: boolean
}

export enum IFormFieldAdditionalDataKey {
    Quantity = 'quantity',
    LookingFor = 'lookingFor',
    Emergency = 'emergency',
    Finishing = 'finishing',
    Budget = 'budget',
    Comment = 'comment',
    UploadFile = 'uploadFile',
    IsPrivate = 'isPrivate',
}

export enum FormFieldPortfolioDataKey {
    Title = 'title',
    Place = 'place',
    Price = 'price',
    Description = 'description',
    Images = 'images',
}

export interface IFormFieldPortfolioData {
    [FormFieldPortfolioDataKey.Title]: {
        value: string | undefined
        required: boolean
    }
    [FormFieldPortfolioDataKey.Place]: {
        value :string | undefined
        location: {
            placeId: string | undefined
            geometry: IGeometry
        } | undefined
        required: boolean
    }
    [FormFieldPortfolioDataKey.Price]: {
        value: number | undefined
        required: boolean
    }
    [FormFieldPortfolioDataKey.Description]: {
        value: string | undefined
        required: boolean
    }
    [FormFieldPortfolioDataKey.Images]: {
        value: { id: string, url: string }[] | undefined
        min: number
        max: number
        required: boolean
    }
}

export interface IFormFieldAdditionalData {
    [IFormFieldAdditionalDataKey.Quantity]?: IQuantityField
    [IFormFieldAdditionalDataKey.LookingFor]: ILookingForField
    [IFormFieldAdditionalDataKey.Emergency]: IEmergencyField
    [IFormFieldAdditionalDataKey.Budget]?: IBudgetField
    [IFormFieldAdditionalDataKey.Comment]?: ICommentField
    [IFormFieldAdditionalDataKey.UploadFile]?: IUploadFileField
    [IFormFieldAdditionalDataKey.IsPrivate]?: IFormFieldBoolean
}

export interface IFormFieldComment {
    comment?: ICommentField
}

export interface IFormFieldUploadFiles{
    value: FileList | string | string[] | null | undefined
    required: boolean
}

export enum IDimensionUnit {
    Meters = 'm',
    Decimeters = 'dm',
    Centimeters = 'cm',
    Millimeters = 'mm',
    Degrees = 'C°',
    Humidity = '%',
    SquareMeter = 'm²',
}

export enum IFormFieldDimensionDefaultValueKey {
    Table = 'table',
    CoffeeTable = 'coffeeTable',
    BarTable = 'barTable',
    StandardWidth = 'standardWidth',
    StandardDepth = 'standardDepth',
    StandardLength = 'standardLength',
    StandardHeight = 'standardHeight',
    StandardThickness = 'standardThickness',
    MediumThickness = 'mediumThickness',
    LargeThickness = 'largeThickness',
    StandardDiameter = 'standardDiameter',
    BabyBed = 'babyBed',
    ChildBed = 'childBed',
    TeenagerBed = 'teenagerBed',
    AdultBed = 'adultBed',
    StandardBed = 'standardBed',
    QueenSizeBed = 'queenSizeBed',
    KingSizeBed = 'kingSizeBed',
    SuperKingSizeBed = 'superKingSizeBed',
}

export type IFormFieldDimensionDefaultValue = [IFormFieldDimensionDefaultValueKey, number];

export type IFormFieldDimensionDefaultValues = IFormFieldDimensionDefaultValue[];

export type DimensionFieldValue = number | undefined | null;

export interface IDimensionField {
    value: DimensionFieldValue
    type: IFormInputType.Range
    unit: IDimensionUnit
    step: number
    range: IFormRange
    required: boolean
    defaultValue?: number
    defaultValues?: IFormFieldDimensionDefaultValues
}

export type IFormFieldDimension = {
    [key in FieldDimensionId]?: IDimensionField
}

export interface IFormFieldMaterialComposite {
    materialPrimary: IFormFieldMaterial
    materialSecondary: IFormFieldMaterial
}

export interface IFormFieldBoolean {
    value: boolean | undefined
}

export interface IFormFieldMultiSelector {
    options: OptionId[]
    value?: OptionId[] | null | undefined
    required: boolean
}

export interface IFormFieldRadioList {
    options: OptionId[]
    value: OptionId | undefined | null
    required: boolean
}

export interface IFormFieldDescription {
    value: string | undefined
}

export enum IFormFieldProjectInformationKey {
    Laying = 'laying',
    Accompaniment = 'accompaniment',
    Property = 'property',
    OwnerTenant = 'ownerTenant',
}

export interface IFormFieldProjectInformation {
    [IFormFieldProjectInformationKey.Laying]: IFormFieldBoolean
    [IFormFieldProjectInformationKey.Accompaniment]: IFormFieldBoolean
    [IFormFieldProjectInformationKey.Property]: IPropertyField
    [IFormFieldProjectInformationKey.OwnerTenant]: IOwnerTenantField
}

export interface IFormFieldBudget {
    value: OptionId | undefined
    options: OptionId[]
    required: boolean
}

export interface IFormFieldApplianceLevel {
    value: OptionId | undefined
    options: OptionId[]
    required: boolean
}

export enum IFormFieldKey {
    PortfolioData = 'portfolioData',
    Comment = 'comment',
    AdditionalData = 'additionalData',
    AddOns = 'addOns',
    ApplianceLevel = 'applianceLevel',
    Background = 'background',
    BedShapeDimension = 'bedShapeDimension',
    Border = 'border',
    Budget= 'budget',
    ClaustraDestination = 'claustraDestination',
    ClaustraLaying = 'claustraLaying',
    ClaustraMobility = 'claustraMobility',
    ClaustraType = 'claustraType',
    ClosetDoorFrameMaterial = 'closetDoorFrameMaterial',
    ClosetDoorSens = 'closetDoorSens',
    ClosingType = 'closingType',
    Connectics = 'connectics',
    Constraints = 'constraints',
    Contact = 'contact',
    Cutting = 'cutting',
    DeckBoardShape = 'deckBoardShape',
    DeckBoardWidth = 'deckBoardWidth',
    Description = 'description',
    DesignProject = 'designProject',
    DestinationShape = 'destinationShape',
    Dimension = 'dimension',
    Extension = 'extension',
    FenceDestination = 'fenceDestination',
    FencingDestination = 'fencingDestination',
    FenceFilling = 'fenceFilling',
    FenceGateDimension = 'fenceGateDimension',
    FenceMaterial = 'fenceMaterial',
    FenceSecondMaterial = 'fenceSecondMaterial',
    FencePoles = 'fencePoles',
    FenceProtection = 'fenceProtection',
    FenceRailing = 'fenceRailing',
    FencingPose = 'fencingPose',
    Finishing = 'finishing',
    FrameMaterial = 'frameMaterial',
    FrameShapeDimension = 'frameShapeDimension',
    GarageDoorGate = 'garageDoorGate',
    GarageDoorGroove = 'garageDoorGroove',
    GarageDoorOpening = 'garageDoorOpening',
    GarageDoorOptions = 'garageDoorOptions',
    GlassWallStyle = 'glassWallStyle',
    GroundShapeDimension = 'groundShapeDimension',
    HighWindDestination = 'highWindDestination',
    HighWindLaying = 'highWindLaying',
    KitchenFurniture = 'kitchenFurniture',
    KitchenHandle = 'kitchenHandle',
    KitchenHomeAppliance = 'kitchenHomeAppliance',
    KitchenLayout = 'kitchenLayout',
    KitchenMaterialShade = 'kitchenMaterialShade',
    KitchenProjectType ='kitchenProjectType',
    KitchenShape = 'kitchenShape',
    KitchenStyle = 'kitchenStyle',
    LegsDimension = 'legsDimension',
    LegsMaterial = 'legsMaterial',
    LegsShape = 'legsShape',
    LimonShape = 'limonShape',
    Material = 'material',
    MirrorFrameMaterial = 'mirrorFrameMaterial',
    MultiSelector = 'multiSelector',
    Obstacles = 'obstacles',
    Openings = 'openings',
    PergolaOptions = 'pergolaOptions',
    PergolaRoof = 'pergolaRoof',
    PergolaShape = 'pergolaShape',
    PlainSteps = 'plainSteps',
    ProjectInformation = 'projectInformation',
    PortalFilling = 'portalFilling',
    PortalGate = 'portalGate',
    PortalLaying = 'portalLaying',
    PortalOpening = 'portalOpening',
    PortalShape = 'portalShape',
    PortalShapeDimension = 'portalShapeDimension',
    Quantity = 'quantity',
    SecondMaterial = 'secondMaterial',
    RoofShapeDimension = 'roofShapeDimension',
    Shape = 'shape',
    ShapeDimension = 'shapeDimension',
    ShoesQuantity = 'shoesQuantity',
    StaircaseGuard = 'staircaseGuard',
    StaircaseLanding = 'staircaseLanding',
    StaircaseLayingStairs = 'staircaseLayingStairs',
    StaircaseProtection = 'staircaseProtection',
    StaircaseRailing = 'staircaseRailing',
    StaircaseSideTurn = 'staircaseSideTurn',
    StepsDimension = 'stepsDimension',
    TerraceLaying = 'terraceLaying',
    TerraceLight = 'terraceLight',
    PortalMotorization = 'portalMotorization',
    TerracePedestals = 'terracePedestals',
    TopBedShapeDimension = 'topBedShapeDimension',
    TrayDimension = 'trayDimension',
    TrayMaterial = 'trayMaterial',
    TrayShape = 'trayShape',
    TrayShapeDimension = 'trayShapeDimension',
    Type = 'type',
    UploadFiles = 'uploadFiles',
    Vegetals = 'vegetals',
    WindowBlindsDimension = 'windowBlindsDimension',
    WindowBlindsDisposition = 'windowBlindsDisposition',
    WindowBlindsDwelling = 'windowBlindsDwelling',
    WindowBlindsElectric = 'windowBlindsElectric',
    WindowBlindsFilling = 'windowBlindsFilling',
    WindowBlindsFunction = 'windowBlindsFunction',
    WindowBlindsGlassDimension = 'windowBlindsGlassDimension',
    WindowBlindsLight = 'windowBlindsLight',
    WindowBlindsManeuver = 'windowBlindsManeuver',
    WindowBlindsPose = 'windowBlindsPose',
    WindowBlindsProtection = 'windowBlindsProtection',
    WindowBlindsStyle = 'windowBlindsStyle',
    WindowGlazing = 'windowGlazing',
    WindowInsulation = 'windowInsulation',
    WindowInstallation = 'windowInstallation',
    WindowLeaf = 'windowLeaf',
    WindowMaterial = 'windowMaterial',
    WindowOpening = 'windowOpening',
    WineCellarDegrees = 'wineCellarDegrees',
    WineCellarDoor = 'wineCellarDoor',
    WineCellarHumidity = 'wineCellarHumidity',
    WineCellarLightingExpose = 'wineCellarLightingExpose',
    WineCellarMaterial = 'wineCellarMaterial',
    WineCellarNbBottle = 'wineCellarNbBottle',
    WineCellarNbDoor = 'wineCellarNbDoor',
    WineCellarPose = 'wineCellarPose',
    WineCellarRoom = 'wineCellarRoom',
    WineCellarSensDoor = 'wineCellarSensDoor',
    WineCellarShape = 'wineCellarShape',
    WineCellarShelf = 'wineCellarShelf',
    WineCellarShelfMaterial = 'wineCellarShelfMaterial',
    WineCellarSquareMeter = 'wineCellarSquareMeter',
}

export type IFormField =
    | IFormFieldAdditionalData
    | IFormFieldComment
    | IFormFieldBoolean
    | IFormFieldBudget
    | IFormFieldApplianceLevel
    | IFormFieldContact
    | IFormFieldDescription
    | IFormFieldDesignQuantities
    | IFormFieldDimension
    | IFormFieldMaterial
    | IFormFieldMaterialComposite
    | IFormFieldMultiSelector
    | IFormFieldRadioList
    | IFormFieldShape
    | IFormFieldShapeDimension
    | IFormFieldShapeMaterial
    | IFormFieldShapeQuantities
    | IFormFieldPortfolioData
    | IFormFieldProjectInformation

export interface IRequests {
    [key: string] : RequestStatusId
}

export interface IFormFields {
    [IFormFieldKey.PortfolioData]?: IFormFieldPortfolioData
    [IFormFieldKey.Comment]?: IFormFieldComment
    [IFormFieldKey.AdditionalData]: IFormFieldAdditionalData
    [IFormFieldKey.AddOns]?: IFormFieldShapeQuantities
    [IFormFieldKey.ApplianceLevel]?: IFormFieldApplianceLevel
    [IFormFieldKey.Background]?: IFormFieldBoolean
    [IFormFieldKey.BedShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.Budget]?: IFormFieldBudget
    [IFormFieldKey.Border]?: IFormFieldShape
    [IFormFieldKey.ClaustraDestination]?: IFormFieldShape
    [IFormFieldKey.ClaustraLaying]?: IFormFieldBoolean
    [IFormFieldKey.ClaustraMobility]?: IFormFieldShape
    [IFormFieldKey.ClaustraType]?: IFormFieldShape
    [IFormFieldKey.ClosetDoorFrameMaterial]?: IFormFieldMaterial
    [IFormFieldKey.ClosetDoorSens]?: IFormFieldRadioList
    [IFormFieldKey.ClosingType]?: IFormFieldShape
    [IFormFieldKey.Connectics]?: IFormFieldShapeQuantities
    [IFormFieldKey.Constraints]?: IFormFieldMultiSelector
    [IFormFieldKey.Contact]: IFormFieldContact
    [IFormFieldKey.Cutting]?: IFormFieldShapeQuantities
    [IFormFieldKey.DeckBoardShape]?: IFormFieldShape
    [IFormFieldKey.DeckBoardWidth]?: IFormFieldRadioList
    [IFormFieldKey.Description]?: IFormFieldDescription
    [IFormFieldKey.DesignProject]?: IFormFieldDesignQuantities
    [IFormFieldKey.DestinationShape]?: IFormFieldShape
    [IFormFieldKey.Dimension]?: IFormFieldDimension
    [IFormFieldKey.Extension]?: IFormFieldShapeDimension
    [IFormFieldKey.FenceDestination]?: IFormFieldShape
    [IFormFieldKey.FencingDestination]?: IFormFieldMultiSelector
    [IFormFieldKey.FenceFilling]?: IFormFieldShape
    [IFormFieldKey.FenceGateDimension]?: IFormFieldDimension
    [IFormFieldKey.FenceMaterial]?: IFormFieldMaterial
    [IFormFieldKey.FencePoles]?: IFormFieldShapeMaterial
    [IFormFieldKey.FenceProtection]?: IFormFieldBoolean
    [IFormFieldKey.FenceSecondMaterial]?: IFormFieldMaterial
    [IFormFieldKey.FenceRailing]?: IFormFieldMaterial
    [IFormFieldKey.FencingPose]?: IFormFieldRadioList
    [IFormFieldKey.Finishing]?: IFormFieldRadioList
    [IFormFieldKey.FrameMaterial]?: IFormFieldMaterial
    [IFormFieldKey.FrameShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.GarageDoorGate]?: IFormFieldBoolean
    [IFormFieldKey.GarageDoorGroove]?: IFormFieldShape
    [IFormFieldKey.GarageDoorOpening]?: IFormFieldShape
    [IFormFieldKey.GarageDoorOptions]?: IFormFieldMultiSelector
    [IFormFieldKey.GlassWallStyle]?: IFormFieldShape
    [IFormFieldKey.GroundShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.HighWindDestination]?: IFormFieldShape
    [IFormFieldKey.HighWindLaying]?: IFormFieldBoolean
    [IFormFieldKey.KitchenFurniture]?: IFormFieldShapeQuantities
    [IFormFieldKey.KitchenHandle]?: IFormFieldShape
    [IFormFieldKey.KitchenHomeAppliance]?: IFormFieldShapeQuantities
    [IFormFieldKey.KitchenLayout]?: IFormFieldShape
    [IFormFieldKey.KitchenMaterialShade]?: IFormFieldShape
    [IFormFieldKey.KitchenProjectType]?: IFormFieldRadioList
    [IFormFieldKey.KitchenShape]?: IFormFieldShapeDimension
    [IFormFieldKey.KitchenStyle]?: IFormFieldShape
    [IFormFieldKey.LegsDimension]?: IFormFieldDimension
    [IFormFieldKey.LegsMaterial]?: IFormFieldMaterial
    [IFormFieldKey.LegsShape]?: IFormFieldShape
    [IFormFieldKey.LimonShape]?: IFormFieldShape
    [IFormFieldKey.Material]?: IFormFieldMaterial
    [IFormFieldKey.MirrorFrameMaterial]?: IFormFieldMaterial
    [IFormFieldKey.MultiSelector]?: IFormFieldMultiSelector
    [IFormFieldKey.Obstacles]?: IFormFieldShapeQuantities
    [IFormFieldKey.Openings]?: IFormFieldShapeQuantities
    [IFormFieldKey.PergolaOptions]?: IFormFieldShapeQuantities
    [IFormFieldKey.PergolaRoof]?: IFormFieldRadioList
    [IFormFieldKey.PergolaShape]?: IFormFieldShape
    [IFormFieldKey.PlainSteps]?: IFormFieldShape
    [IFormFieldKey.ProjectInformation]?: IFormFieldProjectInformation
    [IFormFieldKey.PortalGate]?: IFormFieldDimension
    [IFormFieldKey.PortalFilling]?: IFormFieldShape
    [IFormFieldKey.PortalLaying]?: IFormFieldBoolean
    [IFormFieldKey.PortalMotorization]?: IFormFieldBoolean
    [IFormFieldKey.PortalOpening]?: IFormFieldShape
    [IFormFieldKey.PortalShape]?: IFormFieldShape
    [IFormFieldKey.PortalShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.Quantity]?: IFormFieldShapeQuantities
    [IFormFieldKey.SecondMaterial]?: IFormFieldMaterial
    [IFormFieldKey.RoofShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.Shape]?: IFormFieldShape
    [IFormFieldKey.ShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.ShoesQuantity]?: IFormFieldShapeQuantities
    [IFormFieldKey.StaircaseGuard]?: IFormFieldMaterialComposite
    [IFormFieldKey.StaircaseLanding]?: IFormFieldShape
    [IFormFieldKey.StaircaseLayingStairs]?: IFormFieldBoolean
    [IFormFieldKey.StaircaseProtection]?: IFormFieldBoolean
    [IFormFieldKey.StaircaseRailing]?: IFormFieldMaterial
    [IFormFieldKey.StaircaseSideTurn]?: IFormFieldShape
    [IFormFieldKey.StepsDimension]?: IFormFieldDimension
    [IFormFieldKey.TerraceLaying]?: IFormFieldBoolean
    [IFormFieldKey.TerraceLight]?: IFormFieldBoolean
    [IFormFieldKey.TerracePedestals]?: IFormFieldBoolean
    [IFormFieldKey.TopBedShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.TrayDimension]?: IFormFieldDimension
    [IFormFieldKey.TrayMaterial]?: IFormFieldMaterial
    [IFormFieldKey.TrayShape]?: IFormFieldShape
    [IFormFieldKey.TrayShapeDimension]?: IFormFieldShapeDimension
    [IFormFieldKey.Type]?: IFormFieldShape
    [IFormFieldKey.UploadFiles]?: IFormFieldUploadFiles
    [IFormFieldKey.Vegetals]?: IFormFieldShapeQuantities
    [IFormFieldKey.WindowBlindsDimension]?: IFormFieldDimension
    [IFormFieldKey.WindowBlindsDisposition]?: IFormFieldShape
    [IFormFieldKey.WindowBlindsDwelling]?: IFormFieldShape
    [IFormFieldKey.WindowBlindsElectric]?: IFormFieldBoolean
    [IFormFieldKey.WindowBlindsFilling]?: IFormFieldRadioList
    [IFormFieldKey.WindowBlindsFunction]?: IFormFieldShape
    [IFormFieldKey.WindowBlindsGlassDimension]?: IFormFieldDimension
    [IFormFieldKey.WindowBlindsLight]?: IFormFieldBoolean
    [IFormFieldKey.WindowBlindsManeuver]?: IFormFieldRadioList
    [IFormFieldKey.WindowBlindsPose]?: IFormFieldRadioList
    [IFormFieldKey.WindowBlindsProtection]?: IFormFieldRadioList
    [IFormFieldKey.WindowBlindsStyle]?: IFormFieldShape
    [IFormFieldKey.WindowGlazing]?: IFormFieldRadioList
    [IFormFieldKey.WindowInsulation]?: IFormFieldRadioList
    [IFormFieldKey.WindowInstallation]?: IFormFieldBoolean
    [IFormFieldKey.WindowLeaf]?: IFormFieldShape
    [IFormFieldKey.WindowMaterial]?: IFormFieldMaterial
    [IFormFieldKey.WindowOpening]?: IFormFieldRadioList
    [IFormFieldKey.WineCellarDegrees]?: IFormFieldDimension
    [IFormFieldKey.WineCellarDoor]?: IFormFieldShape
    [IFormFieldKey.WineCellarHumidity]?: IFormFieldDimension
    [IFormFieldKey.WineCellarLightingExpose]?: IFormFieldBoolean
    [IFormFieldKey.WineCellarMaterial]?: IFormFieldMaterial
    [IFormFieldKey.WineCellarNbBottle]?: IFormFieldShapeQuantities
    [IFormFieldKey.WineCellarNbDoor]?: IFormFieldShapeQuantities
    [IFormFieldKey.WineCellarPose]?: IFormFieldShape
    [IFormFieldKey.WineCellarRoom]?: IFormFieldShape
    [IFormFieldKey.WineCellarSensDoor]?: IFormFieldShapeQuantities
    [IFormFieldKey.WineCellarShape]?: IFormFieldShape
    [IFormFieldKey.WineCellarShelf]?: IFormFieldShapeQuantities
    [IFormFieldKey.WineCellarShelfMaterial]?: IFormFieldMaterial
    [IFormFieldKey.WineCellarSquareMeter]?: IFormFieldDimension
}

export enum IFormKey {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    FormId = 'formId',
    SubmissionId = 'submissionId',
    Type = 'type',
    UserId = 'userId',
    UserRole = 'userRole',
    CreationDate = 'creationDate',
    LastUpdateDate = 'lastUpdateDate',
    ApprovalUpdateDate = 'approvalUpdateDate',
    Fields = 'fields',
    FieldsOrder = 'fieldsOrder',
    ThumbnailURL = 'thumbnailURL',
    ImagesURLs = 'imagesURLs',
    Status = 'status',
    StatusHistory = 'statusHistory',
    Requests = 'requests',
    MatchingSource = 'matchingSource',
    Location = 'location',
    LilmStatus = 'lilmStatus',
    LilmComment = 'lilmComment',
    LilmInternalComment = 'lilmInternalComment',
    LilmInternalContactQualificationStatus = 'lilmInternalContactQualificationStatus',
    LilmInternalQualificationStatus = 'lilmInternalQualificationStatus',
}

export interface IFormBase {
    [IFormKey.SubmissionId]?: string
    [IFormKey.Type]: FormTypeId
    [IFormKey.UserId]?: string
    [IFormKey.UserRole]?: IUserRole
    [IFormKey.CreationDate]?: Date
    [IFormKey.LastUpdateDate]?: Date
    [IFormKey.ApprovalUpdateDate]?: Date
    [IFormKey.Fields]: IFormFields
    [IFormKey.FieldsOrder]: IFormFieldKey[]
    [IFormKey.ThumbnailURL]?: string
    [IFormKey.ImagesURLs]?: string[]
    [IFormKey.Status]?: RequestStatusId
    [IFormKey.Requests]?: IRequests
    [IFormKey.MatchingSource]?: RequestMatchingSourceId
    [IFormKey.Location]?: ILocation
    [IFormKey.LilmStatus]?: SubmissionSaleStatusId
    [IFormKey.LilmComment]?: string
    [IFormKey.LilmInternalComment]?: string
    [IFormKey.LilmInternalContactQualificationStatus]?: SubmissionInternalContactQualificationId
    [IFormKey.LilmInternalQualificationStatus]?: SubmissionInternalQualificationId
}

export interface IPublishedForm extends IFormBase {
    [IFormKey.FormId]: PublishedFormIdUnion
}

export interface IDeprecatedForm extends IFormBase {
    [IFormKey.FormId]: DeprecatedFormId
}

export type IForm = IPublishedForm | IDeprecatedForm;

export type IForms = IForm[];

export type IFormFieldValidate = (formField: IFormField) => boolean
