import { spaceMedium } from './spaces';

// Lilm colors
export const darkMarineColor = 'rgb(6,26,52)';
export const marineColor = 'rgb(50,60,100)';
export const lighterMarineColor = 'rgb(175,181,200)';
export const lightMarineColor = 'rgb(94,107,139)';
export const bluishGrey = 'rgb(99,112,155)';
export const lightMarineColorShade = 'rgba(130,140,190, 0.7)';
export const lighterMarineColorShade = 'rgb(230,234,244)';
export const blueColor = 'rgb(63,137,229)';
export const darkAzurColor = 'rgb(49,182,187)';
export const lightAzurColor = 'rgb(104,255,255)';
export const azurColor = 'rgb(119,209,211)';
export const azurColorGradient = `linear-gradient(130deg, ${blueColor} 0%, ${azurColor} 100%)`;
export const darkGreyColor = 'rgb(225,226,225)';
export const greyColor = 'rgb(196,196,196)';
export const lightGreyColor = 'rgb(243,245,250)';
export const lightGreyColorDark = 'rgb(236, 239, 249)';
export const lightGreyColorShade = 'rgba(217, 224, 240, .5)';

export const basicShadow = `0 0 ${spaceMedium}px 1px ${lightGreyColorShade}`;

// extra colors
export const pureWhite = 'rgb(255,255,255)';
export const pureWhiteShade = 'rgba(255, 255, 255, 0.3)';
export const successColor = 'rgb(90, 200, 110)';
export const warningColor = 'rgb(255,159,0)';
export const errorColor = 'rgb(211,47,63)';
export const purpleColor = 'rgb(122,73,165)';
