import {
    MaterialId,
    MaterialClassId,
    MaterialCategoryId,
} from '@core/constants/Material';
import materialNames from '@core/translations/materialNames';
import MaterialTypeId from '../constants/MaterialTypeId';
import MaterialTintId from '../constants/MaterialTintId';

type MaterialCategoriesTranslations = { [key in MaterialCategoryId]: { name: string, label: string, learnMore?:{ info: string }} }
type MaterialClassesTranslations = { [key in MaterialClassId]: { name: string, label: string, learnMore?:{ info: string }} }
type MaterialsTranslations = { [key in MaterialId]: { name: string, label: string, learnMore?:{ info: string }} }

export const MaterialsFR: {
    materialTypes: { [key in MaterialTypeId]: string }
    materialTints: { [key in MaterialTintId]: string }
    materialCategories: MaterialCategoriesTranslations
    materialClasses: MaterialClassesTranslations
    materials: MaterialsTranslations
} = {
    materialTypes: {
        veneerWood     : 'Bois de placage',
        engineeredWood : 'Mélaminé',
        hardWood       : 'Bois massif',
        recycledWood   : 'Bois recyclé',
    },
    materialTints: {
        extraLight : 'Extra clair',
        light      : 'Clair',
        natural    : 'Naturel',
        dark       : 'Foncé',
    },
    materialCategories: {
        other: {
            name  : 'Autre',
            label : ' ',
        },
        wood: {
            name  : 'Bois',
            label : 'Chaleur et convivialité',
        },
        ceramic: {
            name  : 'Céramique',
            label : 'Modernité et tradition',
        },
        riverTable: {
            name  : 'River',
            label : 'Visuelle et tendance',
        },
        metal: {
            name  : 'Métal',
            label : 'Charme industriel',
        },
        marbleAndStone: {
            name  : 'Marbre et pierre',
            label : 'Robustesse intemporelle',
        },
        concrete: {
            name      : 'Béton',
            label     : 'Force brute',
            learnMore : {
                info: '🌍 100 % naturel, sans solvant nocif pour votre santé et celle de la planète.',
            },
        },
        fabric: {
            name  : 'Tissu',
            label : 'Ambiance chaleureuse et douce',
        },
        glass: {
            name  : 'Verre',
            label : 'Elégant et aérien',
        },
        melamined: {
            name  : 'Mélaminé',
            label : 'Economique',
        },
        mirror: {
            name  : 'Miroir',
            label : 'Elégant et aérien',
        },
        naturalFiber: {
            name  : 'Fibres naturelles',
            label : 'Apporte une touche naturelle et douce',
        },
        plastic: {
            name  : 'Plastique',
            label : 'Robuste, isolant et sans entretien',
        },
        terracotta: {
            name  : 'Terre cuite',
            label : 'Authentique',
        },
    },
    materialClasses: {
        chestnut: {
            name  : 'Châtaignier',
            label : 'Un bois clair au veinage subtile',
        },
        birch: {
            name  : 'Bouleau',
            label : 'Bois très clair nuancé de beige',
        },
        blackLocust: {
            name  : 'Robinier',
            label : 'Résistant aux intempéries',
        },
        brass: {
            name  : 'Laiton',
            label : 'Résiste à la corrosion par l\'eau',
        },
        elm: {
            name  : 'Orme',
            label : 'Un bois rare plein de caractère',
        },
        alderwood: {
            name  : 'Aulne',
            label : 'Un bois blanc rosé tout en élégance',
        },
        aluminum: {
            name  : 'Aluminium',
            label : ' ',
        },
        acacia: {
            name  : 'Acacia',
            label : 'Bois naturel agrémenté de nuances dorées',
        },
        beech: {
            name      : 'Hêtre',
            label     : 'La star du style scandinave',
            learnMore : {
                info: '🌱🇫🇷 Le hêtre, avec le chêne, sont les bois les plus présents dans nos forêts françaises. #consommerlocal ',
            },
        },
        ceramic: {
            name  : 'Céramique',
            label : ' ',
        },
        corian: {
            name  : 'Corian',
            label : ' ',
        },
        dekton: {
            name  : 'Dekton',
            label : ' ',
        },
        cherrywood: {
            name  : 'Merisier',
            label : 'Des nuances rouges chaleureuses',
        },
        composite: {
            name      : 'Composite',
            label     : 'Résistant et performant',
            learnMore : {
                info: 'Le bois composite possède tous les avantages du bois massif sans les inconvénients. Il nécessite très peu d’entretien et offre une grande durabilité.',
            },
        },
        concrete: {
            name  : 'Béton',
            label : ' ',
        },
        cumaru: {
            name  : 'Cumaru',
            label : 'Panache de différentes couleurs',
        },
        douglas: {
            name  : 'Douglas',
            label : 'Bois aux nuances rosées qui grise avec le temps pour un rendu argenté',
        },
        driftWood: {
            name  : 'Bois flotté',
            label : 'Protégé naturellement par ses séjours dans les étendues d’eau',
        },
        ipe: {
            name  : 'IPÉ',
            label : 'Un bois exotique aux finitions hautes gammes',
        },
        iroko: {
            name  : 'Iroko',
            label : 'Résistants à l’eau et aux agressions extérieures',
        },
        iron: {
            name  : 'Fer',
            label : 'Rustique et esthétique',
        },
        fabric: {
            name  : 'Tissu',
            label : 'Ambiance chaleureuse et douce',
        },
        fabricAcrylic: {
            name      : 'Toile acrylique',
            label     : 'Résistante et facile d’entretien',
            learnMore : {
                info: 'La toile acrylique résiste très bien aux UV et s\'entretient facilement avec de l’eau. Réputée imputrescible elle présente une bonne tenue et longévité.',
            },
        },
        fabricMicroPerforated: {
            name      : 'Toile micro-perforées',
            label     : ' La toile n°1',
            learnMore : {
                info: 'Résistante aux UV, aux déchirures, elle s’entretient facilement avec de l’eau. On retient également la qualité de la ventilation grâce, elle ne retient aucun sas de chaleur.',
            },
        },
        fabricPolyester: {
            name      : 'Toile polyester',
            label     : 'Le plus économique',
            learnMore : {
                info: 'Le polyester est une matière sensible aux UV pour un usage extérieur. C’est une solution envisageable pour un usage occasionnel à moindre coût.',
            },
        },
        ebony: {
            name  : 'Ébène',
            label : 'Bois exotique élégant et intense',
        },
        larch: {
            name  : 'Mélèze',
            label : 'Bois dense et extrêmement résistant',
        },
        melamineTone: {
            name  : 'Mélaminé uni',
            label : ' ',
        },
        melamineWood: {
            name  : 'Mélaminé effet bois',
            label : ' ',
        },
        melamineConcreteEffect: {
            name  : 'Mélaminé effet béton',
            label : 'Apportez un effet de force brute',
        },
        melamineMarbleEffect: {
            name  : 'Mélaminé effet marbre',
            label : 'Une touche distinguée',
        },
        melamineMetalEffect: {
            name  : 'Mélaminé effet métal',
            label : 'Le charme industriel',
        },
        melamineUniform: {
            name  : 'Mélaminé uniforme',
            label : 'Le plein de couleurs',
        },
        melamineClearWoodEffect: {
            name  : 'Mélaminé effet bois clair',
            label : 'Une touche réconfortante',
        },
        melamineDarkWoodEffect: {
            name  : 'Mélaminé effet bois foncé',
            label : 'Une touche réconfortante',
        },
        muiracatiara: {
            name  : 'Muiracatiara',
            label : 'Un bois tigré pour une touche d’originalité',
        },
        other: {
            name  : 'Autre',
            label : ' ',
        },
        naturalFiber: {
            name  : 'Fibres naturelles',
            label : 'Apporte une touche naturelle et douce',
        },
        padouk: {
            name  : 'Padouk',
            label : 'Un bois exotique au coloris rouge vif',
        },
        pavingStone: {
            name  : 'Pavé',
            label : 'Authentique et rustique',
        },
        plexiglass: {
            name  : 'Plexiglass',
            label : 'Pour une utilisation décorative en intérieur',
        },
        polycarbonate: {
            name  : 'Polycarbonate',
            label : 'Durabilité hors pair et entièrement recyclable',
        },
        recycledPlastic: {
            name  : 'Plastique recyclé',
            label : 'Recycler pour préserver les ressources naturelles',
        },
        riverEpoxyTransparent: {
            name  : 'Résine Époxy transparente',
            label : ' ',
        },
        riverEpoxyOpaque: {
            name  : 'Résine Époxy Opaque',
            label : ' ',
        },
        riverGlassTransparent: {
            name  : 'Verre transparent',
            label : ' ',
        },
        riverGlassOpaque: {
            name  : 'Verre Opaque',
            label : ' ',
        },
        rubber: {
            name  : 'Hévéa',
            label : 'Un bois stable et facile à travailler',
        },
        terracotta: {
            name  : 'Terre cuite',
            label : 'Un effet classique intemporel',
        },
        garapa: {
            name  : 'Garapa',
            label : 'Authentique pour un style inégalable',
        },
        glass: {
            name  : 'Verre',
            label : ' ',
        },
        mirror: {
            name  : 'Miroir',
            label : ' ',
        },
        granite: {
            name  : 'Granit',
            label : ' ',
        },
        mahogany: {
            name  : 'Acajou',
            label : 'Bois tropical à la teinte rouge très recherchée',
        },
        maple: {
            name  : 'Érable',
            label : 'Un bois doux et chaleureux',
        },
        marble: {
            name  : 'Marbre',
            label : ' ',
        },
        oak: {
            name      : 'Chêne',
            label     : 'Un bois noble et authentique',
            learnMore : {
                info: '🌱🇫🇷 Le chêne utilisé pour votre table est français et sourcé dans des forêts gérées durablement. #consommerlocal',
            },
        },
        ashOlive: {
            name  : 'Frêne olivier',
            label : 'Bois blanc crème très tendance',
        },
        ash: {
            name  : 'Frêne',
            label : 'Bois clair avec des nuances brunes',
        },
        pine: {
            name      : 'Pin',
            label     : 'Bois résineux clair et lumineux',
            learnMore : {
                info: '🌱🇫🇷 Le pin et les autres résineux représentent un tiers de nos forêts françaises ! #consommerlocal',
            },
        },
        poplar: {
            name  : 'Peuplier',
            label : 'Un bois jaunâtre et souple',
        },
        quartz: {
            name  : 'Quartz',
            label : ' ',
        },
        ratan: {
            name  : 'Rotin',
            label : 'Un style naturel et authentique',
        },
        redcedar: {
            name  : 'Cèdre rouge',
            label : 'Bois résineux et naturellement résistant, aux teintes grisées',
        },
        wagonfloor: {
            name  : 'Plancher de wagon',
            label : 'Authentique et écologique',
        },
        sheetMetal: {
            name  : 'Tôle',
            label : 'Longévité et durabilité',
        },
        slabOutdoor: {
            name      : 'Dalle extérieure',
            label     : 'Plus épaisses, plus solides',
            learnMore : {
                info: 'Les dalles extérieures se différencient du carrelage par leur épaisseur, leurs poids et leur matériaux. Elles sont plus épaisses et donc plus solides.',
            },
        },
        spruce: {
            name  : 'Epicéa',
            label : 'Haut degré de résistance à l’humidité',
        },
        steel: {
            name  : 'Acier',
            label : ' ',
        },
        zinc: {
            name  : 'Zinc',
            label : ' ',
        },
        stoneAndSlate: {
            name  : 'Pierre et ardoise',
            label : ' ',
        },
        teak: {
            name  : 'Teck',
            label : 'Bois exotique idéal pour l\'extérieur',
        },
        thermoHeatedWood: {
            name      : 'Bois thermo chauffé',
            label     : 'Un bois qui ne manque pas de chaleur',
            learnMore : {
                info: 'Le bois traité thermiquement a été chauffé à haute température afin de le rendre imputrescible. On utilise ce procédé pour améliorer ses caractéristiques et le rendre plus résistant.',
            },
        },
        tilesOutdoor: {
            name      : 'Carrelage extérieur',
            label     : 'Revêtement intemporelle',
            learnMore : {
                info: 'Le carrelage extérieur est la solution de revêtement intemporelle notamment grâce à sa facilité d’entretien et sa praticité. Il présente également un fort potentiel décoratif.',
            },
        },
        walnut: {
            name  : 'Noyer',
            label : 'Bois rare issu des forêts françaises',
        },
        wenge: {
            name  : 'Wengé',
            label : 'Bois sombre des régions tropicales',
        },
        pvc: {
            name  : 'PVC',
            label : 'Robuste, isolant et sans entretien.',
        },
        wroughtIron: {
            name  : 'Fer Forgé',
            label : ' ',
        },
    },
    materials: {
        acacia                         : { name: materialNames.acacia, label: ' ' },
        acaciaEngineered               : { name: materialNames.acaciaEngineered, label: ' ' },
        acaciaRecycled                 : { name: materialNames.acaciaRecycled, label: ' ' },
        acaciaVeneer                   : { name: materialNames.acaciaVeneer, label: ' ' },
        agedMirror                     : { name: materialNames.agedMirror, label: ' ' },
        alderwood                      : { name: materialNames.alderwood, label: ' ' },
        alderwoodEngineered            : { name: materialNames.alderwoodEngineered, label: ' ' },
        alderwoodRecycled              : { name: materialNames.alderwoodRecycled, label: ' ' },
        alderwoodVeneer                : { name: materialNames.alderwoodVeneer, label: ' ' },
        aluminum                       : { name: materialNames.aluminum, label: ' ' },
        ash                            : { name: materialNames.ash, label: ' ' },
        ashEngineered                  : { name: materialNames.ashEngineered, label: ' ' },
        ashOlive                       : { name: materialNames.ashOlive, label: ' ' },
        ashOliveEngineered             : { name: materialNames.ashOliveEngineered, label: ' ' },
        ashOliveRecycled               : { name: materialNames.ashOliveRecycled, label: ' ' },
        ashOliveVeneer                 : { name: materialNames.ashOliveVeneer, label: ' ' },
        ashRecycled                    : { name: materialNames.ashRecycled, label: ' ' },
        ashVeneer                      : { name: materialNames.ashVeneer, label: ' ' },
        beech                          : { name: materialNames.beech, label: ' ' },
        beechEngineered                : { name: materialNames.beechEngineered, label: ' ' },
        beechRecycled                  : { name: materialNames.beechRecycled, label: ' ' },
        beechVeneer                    : { name: materialNames.beechVeneer, label: ' ' },
        birch                          : { name: materialNames.birch, label: ' ' },
        birchEngineered                : { name: materialNames.birchEngineered, label: ' ' },
        birchRecycled                  : { name: materialNames.birchRecycled, label: ' ' },
        birchVeneer                    : { name: materialNames.birchVeneer, label: ' ' },
        blackLocust                    : { name: materialNames.blackLocust, label: ' ' },
        brass                          : { name: materialNames.brass, label: ' ' },
        bronzeMirror                   : { name: materialNames.bronzeMirror, label: ' ' },
        caning                         : { name: materialNames.caning, label: 'Le charme d\'antan' },
        ceramicKadum                   : { name: materialNames.ceramicKadum, label: ' ' },
        ceramicKeo                     : { name: materialNames.ceramicKeo, label: ' ' },
        ceramicKeranium                : { name: materialNames.ceramicKeranium, label: ' ' },
        ceramicManhattan               : { name: materialNames.ceramicManhattan, label: ' ' },
        ceramicNilium                  : { name: materialNames.ceramicNilium, label: ' ' },
        ceramicOrix                    : { name: materialNames.ceramicOrix, label: ' ' },
        ceramicStrato                  : { name: materialNames.ceramicStrato, label: ' ' },
        ceramicWhiteConcrete           : { name: materialNames.ceramicWhiteConcrete, label: ' ' },
        cherrywood                     : { name: materialNames.cherrywood, label: ' ' },
        cherrywoodEngineered           : { name: materialNames.cherrywoodEngineered, label: ' ' },
        cherrywoodRecycled             : { name: materialNames.cherrywoodRecycled, label: ' ' },
        cherrywoodVeneer               : { name: materialNames.cherrywoodVeneer, label: ' ' },
        chestnut                       : { name: materialNames.chestnut, label: ' ' },
        chestnutEngineered             : { name: materialNames.chestnutEngineered, label: ' ' },
        chestnutRecycled               : { name: materialNames.chestnutRecycled, label: ' ' },
        chestnutVeneer                 : { name: materialNames.chestnutVeneer, label: ' ' },
        compositeBlack                 : { name: materialNames.compositeBlack, label: ' ' },
        compositeBrown                 : { name: materialNames.compositeBrown, label: ' ' },
        compositeGrey                  : { name: materialNames.compositeGrey, label: ' ' },
        compositeIpe                   : { name: materialNames.compositeIpe, label: ' ' },
        compositeTaupe                 : { name: materialNames.compositeTaupe, label: ' ' },
        compositeTeak                  : { name: materialNames.compositeTeak, label: ' ' },
        compositeWhite                 : { name: materialNames.compositeWhite, label: ' ' },
        concreteFibred                 : { name: materialNames.concreteFibred, label: ' ' },
        concreteWaxed                  : { name: materialNames.concreteWaxed, label: ' ' },
        corianAnthracite               : { name: materialNames.corianAnthracite, label: ' ' },
        corianBurledBeach              : { name: materialNames.corianBurledBeach, label: ' ' },
        corianBurled                   : { name: materialNames.corianBurled, label: ' ' },
        corianCocoaPrima               : { name: materialNames.corianCocoaPrima, label: ' ' },
        corianCosmosPrima              : { name: materialNames.corianCosmosPrima, label: ' ' },
        corianDeepBlackQuartz          : { name: materialNames.corianDeepBlackQuartz, label: ' ' },
        corianDusk                     : { name: materialNames.corianDusk, label: ' ' },
        corianEveningPrima             : { name: materialNames.corianEveningPrima, label: ' ' },
        corianGlacierWhite             : { name: materialNames.corianGlacierWhite, label: ' ' },
        corianGrayOnyx                 : { name: materialNames.corianGrayOnyx, label: ' ' },
        corianHazelnut                 : { name: materialNames.corianHazelnut, label: ' ' },
        corianLimestonePrima           : { name: materialNames.corianLimestonePrima, label: ' ' },
        corianLinen                    : { name: materialNames.corianLinen, label: ' ' },
        corianNimbusPrima              : { name: materialNames.corianNimbusPrima, label: ' ' },
        corianStratus                  : { name: materialNames.corianStratus, label: ' ' },
        corianTumbleweed               : { name: materialNames.corianTumbleweed, label: ' ' },
        corianWhiteOnyx                : { name: materialNames.corianWhiteOnyx, label: ' ' },
        corianXitchHazel               : { name: materialNames.corianXitchHazel, label: ' ' },
        cumaru                         : { name: materialNames.cumaru, label: ' ' },
        darkGreyMirror                 : { name: materialNames.darkGreyMirror, label: ' ' },
        dektonDanae                    : { name: materialNames.dektonDanae, label: ' ' },
        dektonEther                    : { name: materialNames.dektonEther, label: ' ' },
        dektonHelena                   : { name: materialNames.dektonHelena, label: ' ' },
        dektonKhalo                    : { name: materialNames.dektonKhalo, label: ' ' },
        dektonKira                     : { name: materialNames.dektonKira, label: ' ' },
        dektonKylia                    : { name: materialNames.dektonKylia, label: ' ' },
        dektonLaurent                  : { name: materialNames.dektonLaurent, label: ' ' },
        dektonLiquidEmbers             : { name: materialNames.dektonLiquidEmbers, label: ' ' },
        dektonLiquidSky                : { name: materialNames.dektonLiquidSky, label: ' ' },
        dektonRem                      : { name: materialNames.dektonRem, label: ' ' },
        dektonSoke                     : { name: materialNames.dektonSoke, label: ' ' },
        dektonStrato                   : { name: materialNames.dektonStrato, label: ' ' },
        dektonVienna                   : { name: materialNames.dektonVienna, label: ' ' },
        dektonWhiteConcrete            : { name: materialNames.dektonWhiteConcrete, label: ' ' },
        douglas                        : { name: materialNames.douglas, label: ' ' },
        douglasEngineered              : { name: materialNames.douglasEngineered, label: ' ' },
        douglasRecycled                : { name: materialNames.douglasRecycled, label: ' ' },
        douglasVeneer                  : { name: materialNames.douglasVeneer, label: ' ' },
        driftWood                      : { name: materialNames.driftWood, label: ' ' },
        driftWoodRecycled              : { name: materialNames.driftWoodRecycled, label: ' ' },
        ebony                          : { name: materialNames.ebony, label: ' ' },
        ebonyEngineered                : { name: materialNames.ebonyEngineered, label: ' ' },
        ebonyRecycled                  : { name: materialNames.ebonyRecycled, label: ' ' },
        ebonyVeneer                    : { name: materialNames.ebonyVeneer, label: ' ' },
        elm                            : { name: materialNames.elm, label: ' ' },
        elmEngineered                  : { name: materialNames.elmEngineered, label: ' ' },
        elmRecycled                    : { name: materialNames.elmRecycled, label: ' ' },
        elmVeneer                      : { name: materialNames.elmVeneer, label: ' ' },
        fabric                         : { name: materialNames.fabric, label: ' ' },
        fabricAcrylic                  : { name: materialNames.fabricAcrylic, label: ' ' },
        fabricMicroPerforated          : { name: materialNames.fabricMicroPerforated, label: ' ' },
        fabricPolyester                : { name: materialNames.fabricPolyester, label: ' ' },
        garapa                         : { name: materialNames.garapa, label: ' ' },
        glass                          : { name: materialNames.glass, label: 'On oublierait presque qu\'il est là' },
        glassOpaque                    : { name: materialNames.glassOpaque, label: 'Un maximum de lumière tout en protégeant l’intimité des lieux' },
        GlassVitroceramic              : { name: materialNames.GlassVitroceramic, label: 'Design et parfaitement adapté pour le foyer d\'une cheminée' },
        graniteAbsoluteBlackZimbabwe   : { name: materialNames.graniteAbsoluteBlackZimbabwe, label: ' ' },
        graniteAmazzonite              : { name: materialNames.graniteAmazzonite, label: ' ' },
        graniteAzulAran                : { name: materialNames.graniteAzulAran, label: ' ' },
        graniteBalmoral                : { name: materialNames.graniteBalmoral, label: ' ' },
        graniteCambrianBlack           : { name: materialNames.graniteCambrianBlack, label: ' ' },
        graniteCoffeeBrown             : { name: materialNames.graniteCoffeeBrown, label: ' ' },
        graniteCoralRed                : { name: materialNames.graniteCoralRed, label: ' ' },
        graniteGialloVeneziano         : { name: materialNames.graniteGialloVeneziano, label: ' ' },
        granitePorrino                 : { name: materialNames.granitePorrino, label: ' ' },
        graniteRosaBeta                : { name: materialNames.graniteRosaBeta, label: ' ' },
        ipe                            : { name: materialNames.ipe, label: ' ' },
        ipeEngineered                  : { name: materialNames.ipeEngineered, label: ' ' },
        ipeRecycled                    : { name: materialNames.ipeRecycled, label: ' ' },
        ipeVeneer                      : { name: materialNames.ipeVeneer, label: ' ' },
        iroko                          : { name: materialNames.iroko, label: ' ' },
        iron                           : { name: materialNames.iron, label: ' ' },
        larch                          : { name: materialNames.larch, label: ' ' },
        larchEngineered                : { name: materialNames.larchEngineered, label: ' ' },
        larchRecycled                  : { name: materialNames.larchRecycled, label: ' ' },
        larchVeneer                    : { name: materialNames.larchVeneer, label: ' ' },
        mahogany                       : { name: materialNames.mahogany, label: ' ' },
        mahoganyEngineered             : { name: materialNames.mahoganyEngineered, label: ' ' },
        mahoganyRecycled               : { name: materialNames.mahoganyRecycled, label: ' ' },
        mahoganyVeneer                 : { name: materialNames.mahoganyVeneer, label: ' ' },
        maple                          : { name: materialNames.maple, label: ' ' },
        mapleEngineered                : { name: materialNames.mapleEngineered, label: ' ' },
        mapleRecycled                  : { name: materialNames.mapleRecycled, label: ' ' },
        mapleVeneer                    : { name: materialNames.mapleVeneer, label: ' ' },
        marbleArabescatoOribicoRosso   : { name: materialNames.marbleArabescatoOribicoRosso, label: 'Un marbre rouge à l\'éclat extraordinaire' },
        marbleAzulImperial             : { name: materialNames.marbleAzulImperial, label: 'Un bleu marbré tout en nuances' },
        marbleBlackSahara              : { name: materialNames.marbleBlackSahara, label: 'Un marbre noir intense au subtile veinage doré' },
        marbleBrecciaPontificia        : { name: materialNames.marbleBrecciaPontificia, label: 'Un marbre coloré original' },
        marbleBrecheDeVendome          : { name: materialNames.marbleBrecheDeVendome, label: 'Un marbre français tout en élégance' },
        marbleCalacatta                : { name: materialNames.marbleCalacatta, label: 'Un marbre italien de 1ère qualité très recherché' },
        marbleCarrare                  : { name: materialNames.marbleCarrare, label: 'Le marbre blanc par excellence' },
        marbleForestBrown              : { name: materialNames.marbleForestBrown, label: 'Un marbre beige qui évoque les racines d\'un arbre' },
        marbleGrandAntiqueAubert       : { name: materialNames.marbleGrandAntiqueAubert, label: 'Un marbre français luxueux' },
        marbleMarquina                 : { name: materialNames.marbleMarquina, label: 'Un noir intense veiné de blanc' },
        marblePortLaurent              : { name: materialNames.marblePortLaurent, label: 'Un marbre élégant et recherché' },
        marbleRosePortugal             : { name: materialNames.marbleRosePortugal, label: 'Un marbre rose lumineux' },
        marbleSilverWave               : { name: materialNames.marbleSilverWave, label: 'Un veinage original qui rappelle les vagues' },
        marbleStriatoOlimpico          : { name: materialNames.marbleStriatoOlimpico, label: 'Un marbre aux rayures élégantes' },
        marbleTravertinClassicMedium   : { name: materialNames.marbleTravertinClassicMedium, label: 'Un beige foncé subtile et nuancé' },
        marbleVerdeAlpi                : { name: materialNames.marbleVerdeAlpi, label: 'Un veinage vert unique et intense' },
        marbleVerdeGuatemala           : { name: materialNames.marbleVerdeGuatemala, label: 'Le célèbre marbre vert indien' },
        marbleWhiteBeauty              : { name: materialNames.marbleWhiteBeauty, label: 'Un marbre vert original et plein de richesses' },
        melamineAluminiumMetal         : { name: materialNames.melamineAluminiumMetal, label: ' ' },
        melamineAnthraciteLarch        : { name: materialNames.melamineAnthraciteLarch, label: ' ' },
        melamineAnthraciteMetal        : { name: materialNames.melamineAnthraciteMetal, label: ' ' },
        melamineBarnWood               : { name: materialNames.melamineBarnWood, label: ' ' },
        melamineBeigeUniform           : { name: materialNames.melamineBeigeUniform, label: ' ' },
        melamineBlackBrownOak          : { name: materialNames.melamineBlackBrownOak, label: ' ' },
        melamineBlackOak               : { name: materialNames.melamineBlackOak, label: ' ' },
        melamineBlackUniform           : { name: materialNames.melamineBlackUniform, label: ' ' },
        melamineBlackWood              : { name: materialNames.melamineBlackWood, label: ' ' },
        melamineBlueUniform            : { name: materialNames.melamineBlueUniform, label: ' ' },
        melamineBordeauxUniform        : { name: materialNames.melamineBordeauxUniform, label: ' ' },
        melamineBrassMetal             : { name: materialNames.melamineBrassMetal, label: ' ' },
        melamineBronzeConcrete         : { name: materialNames.melamineBronzeConcrete, label: ' ' },
        melamineBrownLarch             : { name: materialNames.melamineBrownLarch, label: ' ' },
        melamineBrushedAluminium       : { name: materialNames.melamineBrushedAluminium, label: ' ' },
        melamineBrushedSteel           : { name: materialNames.melamineBrushedSteel, label: ' ' },
        melamineCefalConcrete          : { name: materialNames.melamineCefalConcrete, label: ' ' },
        melamineChalkWhite             : { name: materialNames.melamineChalkWhite, label: ' ' },
        melamineChampagneMaple         : { name: materialNames.melamineChampagneMaple, label: ' ' },
        melamineChestnutOak            : { name: materialNames.melamineChestnutOak, label: ' ' },
        melamineClearBlueUniform       : { name: materialNames.melamineClearBlueUniform, label: ' ' },
        melamineClearBrownUniform      : { name: materialNames.melamineClearBrownUniform, label: ' ' },
        melamineClearConcrete          : { name: materialNames.melamineClearConcrete, label: ' ' },
        melamineClearGrayConcrete      : { name: materialNames.melamineClearGrayConcrete, label: ' ' },
        melamineClearGrayUniform       : { name: materialNames.melamineClearGrayUniform, label: ' ' },
        melamineClearGreenUniform      : { name: materialNames.melamineClearGreenUniform, label: ' ' },
        melamineDarkBlueUniform        : { name: materialNames.melamineDarkBlueUniform, label: ' ' },
        melamineDarkBrownUniform       : { name: materialNames.melamineDarkBrownUniform, label: ' ' },
        melamineDarkGrayConcrete       : { name: materialNames.melamineDarkGrayConcrete, label: ' ' },
        melamineDarkGrayUniform        : { name: materialNames.melamineDarkGrayUniform, label: ' ' },
        melamineDarkGreenUniform       : { name: materialNames.melamineDarkGreenUniform, label: ' ' },
        melamineGrayArticUniform       : { name: materialNames.melamineGrayArticUniform, label: ' ' },
        melamineGrayElm                : { name: materialNames.melamineGrayElm, label: ' ' },
        melamineGrayOak                : { name: materialNames.melamineGrayOak, label: ' ' },
        melamineGrayPine               : { name: materialNames.melamineGrayPine, label: ' ' },
        melamineGreenUniform           : { name: materialNames.melamineGreenUniform, label: ' ' },
        melamineIndigoMetal            : { name: materialNames.melamineIndigoMetal, label: ' ' },
        melamineLightAcacia            : { name: materialNames.melamineLightAcacia, label: ' ' },
        melamineMarbleAnthraciteGrigia : { name: materialNames.melamineMarbleAnthraciteGrigia, label: ' ' },
        melamineMarbleBlackGrigia      : { name: materialNames.melamineMarbleBlackGrigia, label: ' ' },
        melamineMarbleWhiteLevanto     : { name: materialNames.melamineMarbleWhiteLevanto, label: ' ' },
        melamineNaturalOak             : { name: materialNames.melamineNaturalOak, label: ' ' },
        melamineNudeUniform            : { name: materialNames.melamineNudeUniform, label: ' ' },
        melamineOrangeUniform          : { name: materialNames.melamineOrangeUniform, label: ' ' },
        melaminePinkUniform            : { name: materialNames.melaminePinkUniform, label: ' ' },
        melaminePolarPin               : { name: materialNames.melaminePolarPin, label: ' ' },
        melaminePolarWhite             : { name: materialNames.melaminePolarWhite, label: ' ' },
        melaminePurpleUniform          : { name: materialNames.melaminePurpleUniform, label: ' ' },
        melamineRedUniform             : { name: materialNames.melamineRedUniform, label: ' ' },
        melamineSandAsh                : { name: materialNames.melamineSandAsh, label: ' ' },
        melamineSandChestnut           : { name: materialNames.melamineSandChestnut, label: ' ' },
        melamineSandOak                : { name: materialNames.melamineSandOak, label: ' ' },
        melamineStainlessSteel         : { name: materialNames.melamineStainlessSteel, label: ' ' },
        melamineTabaccoOak             : { name: materialNames.melamineTabaccoOak, label: ' ' },
        melamineTerracottaUniform      : { name: materialNames.melamineTerracottaUniform, label: ' ' },
        melamineToneBeige              : { name: materialNames.melamineToneBeige, label: ' ' },
        melamineToneBrown              : { name: materialNames.melamineToneBrown, label: ' ' },
        melamineToneGrey               : { name: materialNames.melamineToneGrey, label: ' ' },
        melamineToneWhite              : { name: materialNames.melamineToneWhite, label: ' ' },
        melamineVintageOak             : { name: materialNames.melamineVintageOak, label: ' ' },
        melamineWhiteConcrete          : { name: materialNames.melamineWhiteConcrete, label: ' ' },
        melamineWhiteElm               : { name: materialNames.melamineWhiteElm, label: ' ' },
        melamineWhiteLarch             : { name: materialNames.melamineWhiteLarch, label: ' ' },
        melamineWhiteMarbleCarrarre    : { name: materialNames.melamineWhiteMarbleCarrarre, label: ' ' },
        melamineWhiteOak               : { name: materialNames.melamineWhiteOak, label: ' ' },
        melamineWhitePin               : { name: materialNames.melamineWhitePin, label: ' ' },
        melamineWhiteWood              : { name: materialNames.melamineWhiteWood, label: ' ' },
        melamineWoodDark               : { name: materialNames.melamineWoodDark, label: ' ' },
        melamineWoodNatural            : { name: materialNames.melamineWoodNatural, label: ' ' },
        melamineWoodWhite              : { name: materialNames.melamineWoodWhite, label: ' ' },
        melamineWroughtIronBronze      : { name: materialNames.melamineWroughtIronBronze, label: ' ' },
        melamineWroughtIronGrey        : { name: materialNames.melamineWroughtIronGrey, label: ' ' },
        melamineYellowUniform          : { name: materialNames.melamineYellowUniform, label: ' ' },
        muiracatiara                   : { name: materialNames.muiracatiara, label: ' ' },
        oakOld                         : { name: materialNames.oakOld, label: ' ' },
        oakRegular                     : { name: materialNames.oakRegular, label: ' ' },
        oakRegularEngineered           : { name: materialNames.oakRegularEngineered, label: ' ' },
        oakRegularRecycled             : { name: materialNames.oakRegularRecycled, label: ' ' },
        oakRegularVeneer               : { name: materialNames.oakRegularVeneer, label: ' ' },
        oneWayMirror                   : { name: materialNames.oneWayMirror, label: ' ' },
        other                          : { name: materialNames.other, label: ' ' },
        padouk                         : { name: materialNames.padouk, label: ' ' },
        padoukEngineered               : { name: materialNames.padoukEngineered, label: ' ' },
        padoukRecycled                 : { name: materialNames.padoukRecycled, label: ' ' },
        padoukVeneer                   : { name: materialNames.padoukVeneer, label: ' ' },
        pavingStone                    : { name: materialNames.pavingStone, label: ' ' },
        pine                           : { name: materialNames.pine, label: ' ' },
        pineEngineered                 : { name: materialNames.pineEngineered, label: ' ' },
        pineRecycled                   : { name: materialNames.pineRecycled, label: ' ' },
        pineVeneer                     : { name: materialNames.pineVeneer, label: ' ' },
        poplar                         : { name: materialNames.poplar, label: ' ' },
        poplarEngineered               : { name: materialNames.poplarEngineered, label: ' ' },
        poplarRecycled                 : { name: materialNames.poplarRecycled, label: ' ' },
        poplarVeneer                   : { name: materialNames.poplarVeneer, label: ' ' },
        plexiglassFluorescent          : { name: materialNames.plexiglassFluorescent, label: ' ' },
        plexiglassFrosted              : { name: materialNames.plexiglassFrosted, label: ' ' },
        plexiglassMirror               : { name: materialNames.plexiglassMirror, label: ' ' },
        plexiglassTransparent          : { name: materialNames.plexiglassTransparent, label: ' ' },
        plexiglassWhite                : { name: materialNames.plexiglassWhite, label: ' ' },
        polycarbonateOpaque            : { name: materialNames.polycarbonateOpaque, label: ' ' },
        polycarbonateTeinted           : { name: materialNames.polycarbonateTeinted, label: ' ' },
        polycarbonateTransparent       : { name: materialNames.polycarbonateTransparent, label: ' ' },
        pvc                            : { name: materialNames.pvc, label: ' ' },
        pvcRigid                       : { name: materialNames.pvcRigid, label: ' ' },
        quartzAzabache                 : { name: materialNames.quartzAzabache, label: ' ' },
        quartzBlackPortoro             : { name: materialNames.quartzBlackPortoro, label: ' ' },
        quartzBotticino                : { name: materialNames.quartzBotticino, label: ' ' },
        quartzChorme                   : { name: materialNames.quartzChorme, label: ' ' },
        quartzImperialBrown            : { name: materialNames.quartzImperialBrown, label: ' ' },
        quartzWhiteFusion              : { name: materialNames.quartzWhiteFusion, label: ' ' },
        quartzWhiteGalaxy              : { name: materialNames.quartzWhiteGalaxy, label: ' ' },
        quartzWhitePlatinium           : { name: materialNames.quartzWhitePlatinium, label: ' ' },
        ratan                          : { name: materialNames.ratan, label: 'L\'indémodable qui se marie à tous les styles' },
        recycledPlastic                : { name: materialNames.recycledPlastic, label: ' ' },
        redcedar                       : { name: materialNames.redcedar, label: ' ' },
        redcedarEngineered             : { name: materialNames.redcedarEngineered, label: ' ' },
        redcedarRecycled               : { name: materialNames.redcedarRecycled, label: ' ' },
        redcedarVeneer                 : { name: materialNames.redcedarVeneer, label: ' ' },
        riverEpoxyBlackOpaque          : { name: materialNames.riverEpoxyBlackOpaque, label: ' ' },
        riverEpoxyBlackTransparent     : { name: materialNames.riverEpoxyBlackTransparent, label: ' ' },
        riverEpoxyBlueOpaque           : { name: materialNames.riverEpoxyBlueOpaque, label: ' ' },
        riverEpoxyBlueTransparent      : { name: materialNames.riverEpoxyBlueTransparent, label: ' ' },
        riverEpoxyWhiteOpaque          : { name: materialNames.riverEpoxyWhiteOpaque, label: ' ' },
        riverEpoxyWhiteTransparent     : { name: materialNames.riverEpoxyWhiteTransparent, label: ' ' },
        riverGlassBlackOpaque          : { name: materialNames.riverGlassBlackOpaque, label: ' ' },
        riverGlassBlackTransparent     : { name: materialNames.riverGlassBlackTransparent, label: ' ' },
        riverGlassBlueOpaque           : { name: materialNames.riverGlassBlueOpaque, label: ' ' },
        riverGlassBlueTransparent      : { name: materialNames.riverGlassBlueTransparent, label: ' ' },
        riverGlassWhiteOpaque          : { name: materialNames.riverGlassWhiteOpaque, label: ' ' },
        riverGlassWhiteTransparent     : { name: materialNames.riverGlassWhiteTransparent, label: ' ' },
        rubber                         : { name: materialNames.rubber, label: ' ' },
        rubberRecycled                 : { name: materialNames.rubberRecycled, label: ' ' },
        rust                           : { name: materialNames.rust, label: ' ' },
        silverMirror                   : { name: materialNames.silverMirror, label: ' ' },
        slabOutdoorArdoise             : { name: materialNames.slabOutdoorArdoise, label: ' ' },
        slabOutdoorCerameGres          : { name: materialNames.slabOutdoorCerameGres, label: ' ' },
        slabOutdoorConcrete            : { name: materialNames.slabOutdoorConcrete, label: ' ' },
        slabOutdoorGranite             : { name: materialNames.slabOutdoorGranite, label: ' ' },
        slabOutdoorMarbre              : { name: materialNames.slabOutdoorMarbre, label: ' ' },
        slabOutdoorNaturalStone        : { name: materialNames.slabOutdoorNaturalStone, label: ' ' },
        slabOutdoorReconstituedStone   : { name: materialNames.slabOutdoorReconstituedStone, label: ' ' },
        slabOutdoorSlabGravel          : { name: materialNames.slabOutdoorSlabGravel, label: ' ' },
        slabOutdoorTerracotta          : { name: materialNames.slabOutdoorTerracotta, label: ' ' },
        slabOutdoorTravertin           : { name: materialNames.slabOutdoorTravertin, label: ' ' },
        slabOutdoorWood                : { name: materialNames.slabOutdoorWood, label: ' ' },
        slateBlack                     : { name: materialNames.slateBlack, label: ' ' },
        slateGrey                      : { name: materialNames.slateGrey, label: ' ' },
        sheetMetal                     : { name: materialNames.sheetMetal, label: ' ' },
        spruce                         : { name: materialNames.spruce, label: ' ' },
        spruceEngineered               : { name: materialNames.spruceEngineered, label: ' ' },
        spruceRecycled                 : { name: materialNames.spruceRecycled, label: ' ' },
        spruceVeneer                   : { name: materialNames.spruceVeneer, label: ' ' },
        stainlessSteel                 : { name: materialNames.stainlessSteel, label: ' ' },
        steel                          : { name: materialNames.steel, label: ' ' },
        stoneBlueHainot                : { name: materialNames.stoneBlueHainot, label: ' ' },
        stoneBourgogne                 : { name: materialNames.stoneBourgogne, label: ' ' },
        stoneLens                      : { name: materialNames.stoneLens, label: ' ' },
        stoneLuserne                   : { name: materialNames.stoneLuserne, label: ' ' },
        stoneSerpentinite              : { name: materialNames.stoneSerpentinite, label: ' ' },
        teak                           : { name: materialNames.teak, label: ' ' },
        teakEngineered                 : { name: materialNames.teakEngineered, label: ' ' },
        teakRecycled                   : { name: materialNames.teakRecycled, label: ' ' },
        teakVeneer                     : { name: materialNames.teakVeneer, label: ' ' },
        thermoHeatedWoodAsh            : { name: materialNames.thermoHeatedWoodAsh, label: ' ' },
        thermoHeatedWoodPine           : { name: materialNames.thermoHeatedWoodPine, label: ' ' },
        thermoLaqueredBlack            : { name: materialNames.thermoLaqueredBlack, label: ' ' },
        thermoLaqueredBlue             : { name: materialNames.thermoLaqueredBlue, label: ' ' },
        thermoLaqueredGreen            : { name: materialNames.thermoLaqueredGreen, label: ' ' },
        thermoLaqueredGrey             : { name: materialNames.thermoLaqueredGrey, label: ' ' },
        thermoLaqueredOrange           : { name: materialNames.thermoLaqueredOrange, label: ' ' },
        thermoLaqueredPink             : { name: materialNames.thermoLaqueredPink, label: ' ' },
        thermoLaqueredRed              : { name: materialNames.thermoLaqueredRed, label: ' ' },
        thermoLaqueredWhite            : { name: materialNames.thermoLaqueredWhite, label: ' ' },
        thermoLaqueredYellow           : { name: materialNames.thermoLaqueredYellow, label: ' ' },
        terracotta                     : { name: materialNames.terracotta, label: ' ' },
        tilesOutdoorBeigeStone         : { name: materialNames.tilesOutdoorBeigeStone, label: ' ' },
        tilesOutdoorBlackMarble        : { name: materialNames.tilesOutdoorBlackMarble, label: ' ' },
        tilesOutdoorBlackStone         : { name: materialNames.tilesOutdoorBlackStone, label: ' ' },
        tilesOutdoorConcreteBeige      : { name: materialNames.tilesOutdoorConcreteBeige, label: ' ' },
        tilesOutdoorConcreteBrown      : { name: materialNames.tilesOutdoorConcreteBrown, label: ' ' },
        tilesOutdoorConcreteDark       : { name: materialNames.tilesOutdoorConcreteDark, label: ' ' },
        tilesOutdoorConcreteGrey       : { name: materialNames.tilesOutdoorConcreteGrey, label: ' ' },
        tilesOutdoorConcreteWhite      : { name: materialNames.tilesOutdoorConcreteWhite, label: ' ' },
        tilesOutdoorDarkWood           : { name: materialNames.tilesOutdoorDarkWood, label: ' ' },
        tilesOutdoorGreyMarble         : { name: materialNames.tilesOutdoorGreyMarble, label: ' ' },
        tilesOutdoorGreyStone          : { name: materialNames.tilesOutdoorGreyStone, label: ' ' },
        tilesOutdoorGreyWood           : { name: materialNames.tilesOutdoorGreyWood, label: ' ' },
        tilesOutdoorLightWood          : { name: materialNames.tilesOutdoorLightWood, label: ' ' },
        tilesOutdoorNaturalStone       : { name: materialNames.tilesOutdoorNaturalStone, label: ' ' },
        tilesOutdoorWhiteMarble        : { name: materialNames.tilesOutdoorWhiteMarble, label: ' ' },
        tilesOutdoorWhiteStone         : { name: materialNames.tilesOutdoorWhiteStone, label: ' ' },
        wagonfloorDark                 : { name: materialNames.wagonfloorDark, label: ' ' },
        wagonfloorLight                : { name: materialNames.wagonfloorLight, label: ' ' },
        walnut                         : { name: materialNames.walnut, label: ' ' },
        walnutEngineered               : { name: materialNames.walnutEngineered, label: ' ' },
        walnutRecycled                 : { name: materialNames.walnutRecycled, label: ' ' },
        walnutVeneer                   : { name: materialNames.walnutVeneer, label: ' ' },
        wenge                          : { name: materialNames.wenge, label: ' ' },
        wengeEngineered                : { name: materialNames.wengeEngineered, label: ' ' },
        wengeRecycled                  : { name: materialNames.wengeRecycled, label: ' ' },
        wengeVeneer                    : { name: materialNames.wengeVeneer, label: ' ' },
        wroughtIron                    : { name: materialNames.wroughtIron, label: ' ' },
        zinc                           : { name: materialNames.zinc, label: ' ' },
    },
};

export default MaterialsFR;
