import { FC } from 'react';
import {
    BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl';
import flatten from 'flat';
// @ts-ignore
import { ThemeProvider } from '@material-ui/core/styles';
import materialTheme from '@ui/styles/materialTheme';
import Routes, { RouteKey } from '@core/constants/Route';
import { Helmet } from 'react-helmet';
import * as Sentry from '@sentry/react';
import seoDefaultImageUrl from '../constants/seo';
import translations from '../translations';
import AuthRoute from './components/AuthRoute';
import BrowsersDetector from './components/BrowsersDetector';
import setDynamicFullHeightVariable from './styles/setDynamicFullHeightVariable';
import TrackRouter from './components/TrackRouter';
import LazyComponentLoader from './components/LazyComponentLoader';
import ErrorBoundarySnackbar from './components/ErrorBoundarySnackbar';
import { ISupportedLocale } from '../interfaces/Locales';
import { FormComposerComponentChat } from './components-forms/formComposer/Chat/types';
import { UserProvider } from './context/UserContext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

setDynamicFullHeightVariable();

// Intl Setup
const supportedLanguages: ISupportedLocale[] = [ISupportedLocale.FrFR];
const locale = supportedLanguages.find((langSearch) => langSearch === new URLSearchParams(window.location.search).get('lang')) || ISupportedLocale.FrFR;
const cache = createIntlCache();
const intl = createIntl({ locale, messages: flatten(translations[locale]) }, cache);

const FormComposerChat = LazyComponentLoader({ loader: () => import('./components-forms/formComposer/Chat' /* webpackChunkName: "formComposerChat" */) }) as FormComposerComponentChat;
const ArtisanProjectForm = LazyComponentLoader({ loader: () => import('./components-forms/formComposer/ArtisanProjectForm' /* webpackChunkName: "artisanProjectForm" */) }) as FormComposerComponentChat;
const PasswordResetPage = LazyComponentLoader({ loader: () => import('./pages/PasswordResetPage' /* webpackChunkName: "PasswordResetPage" */) });
const RequestPage = LazyComponentLoader({ loader: () => import('./pages/RequestPage' /* webpackChunkName: "RequestPage" */) });
const SalesPage = LazyComponentLoader({ loader: () => import('./pages/SalesPage' /* webpackChunkName: "SalesPage" */) });
const AccountPage = LazyComponentLoader({ loader: () => import('./pages/AccountPage' /* webpackChunkName: "AccountPage" */) });
const WelcomePage = LazyComponentLoader({ loader: () => import('./pages/WelcomePage' /* webpackChunkName: "WelcomePage" */) });
const SuccessPage = LazyComponentLoader({ loader: () => import('./pages/SuccessPage')/* webpackChunkName: "SuccessPage" */ }) as FC<{ isExternal?: boolean }>;
const ArtisanPage = LazyComponentLoader({ loader: () => import('./pages/ArtisanPage' /* webpackChunkName: "ArtisanPage" */) });
const ArtisanOnboardingPage = LazyComponentLoader({ loader: () => import('./pages/ArtisanOnboardingPage' /* webpackChunkName: "ArtisanOnboardingPage" */) });
const ArtisanNewPasswordRequestPage = LazyComponentLoader({ loader: () => import('./pages/ArtisanNewPasswordRequestPage' /* webpackChunkName: "ArtisanNewPasswordRequestPage" */) });
const RequestRedirect = LazyComponentLoader({ loader: () => import('./components/RequestRedirect' /* webpackChunkName: "RequestRedirect" */) });
const SubmissionRedirect = LazyComponentLoader({ loader: () => import('./components/SubmissionRedirect' /* webpackChunkName: "SubmissionRedirect" */) });
const PureSubmission = LazyComponentLoader({ loader: () => import('./pages/PureSubmissionPage' /* webpackChunkName: "PureSubmissionPage" */) });
const PublicSubmission = LazyComponentLoader({ loader: () => import('./pages/SubmissionPage' /* webpackChunkName: "SubmissionPage" */) });
const SignUpPage = LazyComponentLoader({ loader: () => import('./pages/SignUpPage' /* webpackChunkName: "SignUpPage" */) });

const App: FC = () => (
    <ThemeProvider theme={materialTheme}>
        <UserProvider>
            <RawIntlProvider value={intl}>
                <ErrorBoundarySnackbar>
                    <Router>
                        <BrowsersDetector />
                        {/* Analytics routes tracker */}
                        <TrackRouter />

                        <Helmet>
                            <title>{intl.formatMessage({ id: 'seoTitleDefault' })}</title>
                            <meta name="description" content={intl.formatMessage({ id: 'seoDescriptionDefault' })} />
                            <meta property="og:title" content={intl.formatMessage({ id: 'seoTitleDefault' })} />
                            <meta property="og:description" content={intl.formatMessage({ id: 'seoDescriptionDefault' })} />
                            <meta property="og:site_name" content="LILM" />
                            <meta property="og:image" content={seoDefaultImageUrl} />
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:title" content={intl.formatMessage({ id: 'seoTitleDefault' })} />
                            <meta name="twitter:description" content={intl.formatMessage({ id: 'seoDescriptionDefault' })} />
                        </Helmet>

                        <Switch>
                            {/* Admin routes */}
                            <AuthRoute path={Routes[RouteKey.Sales]} adminOnly><SalesPage /></AuthRoute>

                            {/* Private routes */}
                            <AuthRoute path={Routes[RouteKey.Account]} exact><AccountPage /></AuthRoute>
                            <AuthRoute path={Routes[RouteKey.Artisan]}><ArtisanPage /></AuthRoute>

                            {/* Public routes */}
                            <Route path={Routes[RouteKey.SignUp]}><SignUpPage /></Route>
                            <Route path={Routes[RouteKey.ArtisanOnboarding]}><ArtisanOnboardingPage /></Route>
                            <Route path={Routes[RouteKey.ArtisanNewPasswordRequest]}><ArtisanNewPasswordRequestPage /></Route>
                            <Route path={Routes[RouteKey.PasswordReset]} exact><PasswordResetPage /></Route>
                            <Route path={Routes[RouteKey.Success]} exact><SuccessPage /></Route>
                            <Route path={Routes[RouteKey.SuccessArtisanForms]} exact><SuccessPage /></Route>
                            <Route path={Routes[RouteKey.SuccessArtisanDedicatedApp]} exact><SuccessPage isExternal /></Route>
                            <Route path={Routes[RouteKey.Forms]} exact><FormComposerChat shouldHideIsPrivateQuestion /></Route>
                            <Route path={Routes[RouteKey.Form]} exact><FormComposerChat shouldHideIsPrivateQuestion /></Route>
                            <Route path={Routes[RouteKey.FormStep]} exact><FormComposerChat shouldHideIsPrivateQuestion /></Route>
                            <Route path={Routes[RouteKey.FormTmpSubmission]} exact><FormComposerChat shouldHideIsPrivateQuestion /></Route>
                            <Route path={Routes[RouteKey.FormGroup]} exact><FormComposerChat shouldHideIsPrivateQuestion /></Route>
                            <Route path={Routes[RouteKey.FormGroupStep]} exact><FormComposerChat shouldHideIsPrivateQuestion /></Route>
                            <Route path={Routes[RouteKey.ArtisanDedicatedApp]} exact><FormComposerChat shouldHideIsPrivateQuestion isExternal /></Route>
                            <Route path={Routes[RouteKey.ArtisanDedicatedAppForm]} exact><FormComposerChat shouldHideIsPrivateQuestion isExternal /></Route>
                            <Route path={Routes[RouteKey.ArtisanDedicatedAppFormStep]} exact><FormComposerChat shouldHideIsPrivateQuestion isExternal /></Route>
                            <Route path={Routes[RouteKey.ArtisanDedicatedAppTmpSubmission]} exact><FormComposerChat shouldHideIsPrivateQuestion isExternal /></Route>
                            <Route path={Routes[RouteKey.ArtisanDedicatedAppFormGroup]} exact><FormComposerChat shouldHideIsPrivateQuestion isExternal /></Route>
                            <Route path={Routes[RouteKey.ArtisanDedicatedAppFormGroupStep]} exact><FormComposerChat shouldHideIsPrivateQuestion isExternal /></Route>
                            <Route path={Routes[RouteKey.ArtisanForms]} exact><FormComposerChat /></Route>
                            <Route path={Routes[RouteKey.ArtisanForm]} exact><FormComposerChat /></Route>
                            <Route path={Routes[RouteKey.ArtisanFormStep]} exact><FormComposerChat /></Route>
                            <Route path={Routes[RouteKey.ArtisanTmpSubmission]} exact><FormComposerChat /></Route>
                            <Route path={Routes[RouteKey.ArtisanFormGroup]} exact><FormComposerChat /></Route>
                            <Route path={Routes[RouteKey.ArtisanFormGroupStep]} exact><FormComposerChat /></Route>
                            <Route path={Routes[RouteKey.ArtisanProjectForm]} exact><ArtisanProjectForm /></Route>
                            <Route path={Routes[RouteKey.ArtisanProjectFormTmpSubmission]} exact><ArtisanProjectForm /></Route>
                            <Route path={Routes[RouteKey.FormRequest]} exact><RequestPage /></Route>
                            <Route path={Routes[RouteKey.FormRequestRedirect]} exact><RequestRedirect /></Route>
                            <Route path={Routes[RouteKey.FormSubmission]} exact><SubmissionRedirect /></Route>
                            <Route path={Routes[RouteKey.PublicSubmission]} exact><PublicSubmission /></Route>
                            <Route path={Routes[RouteKey.PureSubmission]} exact><PureSubmission /></Route>
                            <Route path={Routes[RouteKey.Welcome]}><WelcomePage /></Route>
                        </Switch>
                    </Router>
                </ErrorBoundarySnackbar>
            </RawIntlProvider>
        </UserProvider>
    </ThemeProvider>
);

export default Sentry.withProfiler(App);
