import { FC, useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import { Cancel, ArrowBack } from '@material-ui/icons';
import styled from '../styles/styled';
import Grow from '../Grow';
import Card from '../Card';
import Container from '../Container';
import { IAppModalCardProps, IAppModalContainerProps, IAppModalProps } from './types';
import {
    modalContainerStyle,
    modalCrossIconStyle,
    modalBackIconStyle,
    modalCardStyle,
} from './styles';

const StyledContainer = styled(({
    full: _full,
    ...rest
}: IAppModalContainerProps) => <Container {...rest} />)(modalContainerStyle);
const StyledCard = styled(({
    full: _full,
    gutterBottom: _gutterBottom,
    ...rest
}: IAppModalCardProps) => <Card {...rest} />)(modalCardStyle);
const StyledIconCrossButton = styled(IconButton)(modalCrossIconStyle);
const StyledIconBackButton = styled(IconButton)(modalBackIconStyle);

const AppModal: FC<IAppModalProps> = ({
    children,
    onClose,
    back,
    full,
    cardStyle,
    containerStyle,
    timeout,
    gutterBottom,
    maxWidth = 'sm',
    ...properties
}) => {
    // @ts-ignore
    const handleClose = useCallback((event: MouseEvent<{}>) => { if (onClose) { event.stopPropagation(); onClose(); }; }, [onClose]);

    return (
        <Modal {...properties} onClose={onClose}>
            <StyledContainer full={full} maxWidth={maxWidth} disableGutters style={containerStyle}>
                <Grow timeout={timeout || timeout === 0 ? timeout : 400} in>
                    <StyledCard full={full} style={cardStyle} elevation={0} gutterBottom={gutterBottom}>
                        {onClose && (back ? (
                            <StyledIconBackButton onClick={handleClose}>
                                <ArrowBack />
                            </StyledIconBackButton>
                        ) : (
                            <StyledIconCrossButton onClick={handleClose}>
                                <Cancel />
                            </StyledIconCrossButton>
                        ))}
                        {children}
                    </StyledCard>
                </Grow>
            </StyledContainer>
        </Modal>
    );
};

export default AppModal;
