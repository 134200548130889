import {
    createContext, FC, useContext, useEffect, useState,
} from 'react';
import FirebaseService from '../../services/Firebase';
import { IUser } from '../../interfaces/User';

const UserStateContext = createContext<{user?:IUser | null}>({});
UserStateContext.displayName = 'UserStateContext';
const UserDispatchContext = createContext<((user: IUser | null | undefined) => void) | undefined>(undefined);
UserDispatchContext.displayName = 'UserDispatchContext';

const UserProvider:FC = ({ children }) => {
    const [user, setUser] = useState<IUser | null>();

    useEffect(() => {
        // eslint-disable-next-line no-console
        const destructor = { unsubscribe: () => (console.log('onAuthUserListener unsubscribe not provided')) };
        (async (unsubscribe) => {
            // eslint-disable-next-line no-param-reassign
            unsubscribe.unsubscribe = await (await FirebaseService.getInstance()).onAuthUserListener(setUser);
        })(destructor);

        return destructor.unsubscribe;
    }, [setUser]);

    return (
        <UserDispatchContext.Provider value={setUser}>
            <UserStateContext.Provider value={{ user }}>
                {children}
            </UserStateContext.Provider>
        </UserDispatchContext.Provider>
    );
};

const useUserState = () => {
    const context = useContext(UserStateContext);

    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider');
    }

    return context;
};

const useUserDispatch = () => {
    const context = useContext(UserDispatchContext);

    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider');
    }

    return context;
};

export { UserProvider, useUserState, useUserDispatch };
