import { FC } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Info from '@ui/Icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import styled from '@ui/styles/styled';

import { iconEmojiSizeBig } from '@ui/styles/typography';
import { snackBarContentStyle, snackbarContentIconStyle, snackbarMessageWrapperStyle } from './styles';
import { ISnackbarContentMode, ISnackbarContentProperties } from './types';

const StyledMessageWrapper = styled('div')(snackbarMessageWrapperStyle);
const StyledCheckCircleIcon = styled(CheckCircleIcon)(snackbarContentIconStyle);
const StyledWarningIcon = styled(WarningIcon)(snackbarContentIconStyle);
const StyledErrorIcon = styled(ErrorIcon)(snackbarContentIconStyle);
const StyledInfoIcon = styled(Info)(snackbarContentIconStyle);

const variantIcon = {
    [ISnackbarContentMode.Success]        : StyledCheckCircleIcon,
    [ISnackbarContentMode.Warning]        : StyledWarningIcon,
    [ISnackbarContentMode.Error]          : StyledErrorIcon,
    [ISnackbarContentMode.Info]           : StyledInfoIcon,
    [ISnackbarContentMode.Congratulation] : () => <span role="img" style={{ fontSize: iconEmojiSizeBig, marginRight: 16 }} aria-label="congratulation">🎉</span>,
    [ISnackbarContentMode.Greetings]      : () => <span role="img" style={{ fontSize: iconEmojiSizeBig, marginRight: 16 }} aria-label="greetings">👋</span>,
};

const CustomSnackbarContent: FC<ISnackbarContentProperties> = ({
    message,
    onClose,
    mode = ISnackbarContentMode.Info,
    ...properties
}) => {
    const Icon = variantIcon[mode];

    return (
        <SnackbarContent
            {...properties}
            message={(
                <>
                    <Icon />
                    <StyledMessageWrapper>
                        {message}
                    </StyledMessageWrapper>
                </>
            )}
            action={onClose ? (
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : (
                <span style={{ width: 30 }} />
            )}
        />
    );
};

export default styled(CustomSnackbarContent)(snackBarContentStyle);
