/* eslint-disable react/jsx-no-duplicate-props */
import {
    FC,
    useState,
    useCallback,
    ChangeEvent,
} from 'react';
import MaterialTextField, { TextFieldProps } from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '../Typography';

const TextField: FC<TextFieldProps & { maxLength?: number, defaultValue?: string | number }> = ({
    inputProps,
    InputProps,
    maxLength,
    onChange,
    defaultValue,
    ...props
}) => {
    const [remainingLength, setRemainingLength] = useState<number | undefined>(defaultValue && typeof defaultValue === 'string' && maxLength ? maxLength - defaultValue.length : maxLength);

    const handleChange = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.persist();

        if (maxLength) {
            setRemainingLength(maxLength - event.target.value.length);
        }

        if (onChange) {
            onChange(event);
        }
    }, [setRemainingLength, onChange, maxLength]);

    return (
        <MaterialTextField
            onChange={handleChange}
            fullWidth
            defaultValue={defaultValue}
            variant="outlined"
            inputProps={{ ...inputProps, maxLength }}
            InputProps={{
                ...InputProps,
                ...(maxLength && {
                    endAdornment: (
                        <InputAdornment position="end" style={{ position: 'absolute', bottom: 30, right: 20 }}>
                            <Typography variant="caption">
                                <small>{remainingLength}</small>
                            </Typography>
                        </InputAdornment>),
                }),
            }}
            {...props}
        />
    );
};

export default TextField;
