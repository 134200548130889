import SignIn from '../../components/SignIn';
import AuthContainer from '../../components/AuthContainer';

const SignInPage = () => (
    <AuthContainer>
        <SignIn />
    </AuthContainer>
);

export default SignInPage;
