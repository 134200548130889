export enum ArtisanStatus {
    Enabled = 'enabled',
    Disabled = 'disabled',
}

export enum ArtisanPlan {
    Freemium = 'FREEMIUM',
    Start = 'START',
    Regular = 'ATELIER',
    Professional = 'ATELIER+',
}

export const PseudoPlanCommission = 'COMMISSION';

export type PseudoPlan = ArtisanPlan | 'COMMISSION';

export const artisanPlansAllowedForPortfolioElementsInEstimates = [
    ArtisanPlan.Start,
    ArtisanPlan.Regular,
    ArtisanPlan.Professional,
];

export enum ArtisanLabel {
    AEF = 'AEF',
    ARTISAN = 'ARTISAN',
    DMAE = 'DMAE',
    ECO_DEFIS = 'ECO_DEFIS',
    FSC = 'FSC',
    MAITRE_ARTISAN = 'MAITRE_ARTISAN',
    MOF = 'MOF',
    PEFC = 'PEFC',
    RGE_ECO_ARTSIAN = 'RGE_ECO_ARTSIAN',
}

export enum ArtisanMatchingPriority {
    REGULAR = 'regular',
    HIGH = 'high',
}
