import { lightMarineColor } from '../styles/colors';

export const spinnerStyle = {
    width             : 50,
    height            : 50,
    position          : 'fixed' as 'fixed',
    top               : '50%',
    left              : '50%',
    transform         : 'translate(-50%, -50%) rotate(0deg)',
    borderWidth       : 5,
    borderStyle       : 'solid' as 'solid',
    borderTopColor    : lightMarineColor,
    borderRightColor  : lightMarineColor,
    borderLeftColor   : 'transparent',
    borderBottomColor : 'transparent',
    borderRadius      : '50%',
    animation         : 'spin .5s linear infinite',
};

export default spinnerStyle;
