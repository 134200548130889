// margin padding
export const spaceNano = 2;
export const spaceMicro = 4;
export const spaceMini = 6;
export const spaceSmall = 8;
export const spaceRegular = 12;
export const spaceMedium = 18;
export const spaceLarge = 24;
export const spaceWide = 36;
export const spaceHuge = 48;

// height
export const sizeNano = 18;
export const sizeMicro = 24;
export const sizeMini = 36;
export const sizeSmall = 48;
export const sizeRegular = 56;
export const sizeMedium = 80;
export const sizeLarge = 140;
export const sizeWide = 260;
export const sizeHuge = 340;

// fontSize
export const fontMicro = 10;
export const fontMini = 12;
export const fontSmall = 14;
export const fontRegular = 16;
export const fontMedium = 18;
export const fontLarge = 24;
export const fontWide = 32;
export const fontHuge = 48;

// borderRadius
export const radiusNano = 3;
export const radiusMedium = 9;
export const radiusHuge = 16;

// specific values
export const fullHeight = 'calc(var(--vh, 1vh) * 100)';
export const oneThirdHeight = 'calc(var(--vh, 1vh) * 34)';

export const ARTISAN_DASHBOARD_INNER_MARGIN = spaceMedium;
export const ARTISAN_DASHBOARD_TOP_BAR_HEIGHT = spaceHuge;
