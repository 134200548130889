import { Theme, ThemeOptions } from '@material-ui/core/styles';
import { createTheme } from '@material-ui/core';

import {
    azurColor,
    azurColorGradient,
    bluishGrey,
    darkGreyColor,
    errorColor,
    lighterMarineColor,
    lightGreyColor,
    lightGreyColorDark,
    lightMarineColor,
    marineColor,
    pureWhite,
} from './colors';
import {
    fontMini,
    radiusMedium,
    radiusNano,
    spaceMedium,
    spaceMicro,
    spaceMini,
} from './spaces';
import {
    fontBody,
    fontHeadings,
} from './typography';

const themeOptions: ThemeOptions = {
    overrides: {
        // @ts-ignore
        MUIDataTable: {
            paper: {
                backgroundColor: 'transparent',
            },
        },
        MUIDataTableBodyCell: {
            root: {
                backgroundColor: 'transparent',
            },
        },
        MUIDataTableFilter: {
            root: {
                width: 400,
            },
        },
        MUIDataTableHeadCell: {
            root: {
                borderBottom : `4px solid ${darkGreyColor}`,
                color        : `${lightMarineColor} !important`,
                fontWeight   : 900,
                lineHeight   : 1.3,
            },
            sortAction: {
                alignItems    : 'center',
                display       : 'inline-flex',
                flexDirection : 'row',
            },
            sortActive: {
                color: `${bluishGrey} !important`,
            },
        },
        MUIDataTableToolbar: {
            root: {
                backgroundColor: 'transparent',
            },
        },
        MUIDataTableToolbarSelect: {
            root: {
                backgroundColor : pureWhite,
                boxShadow       : 'none !important',
                borderRadius    : 0,
                flex            : '0 1 auto',
                height          : 58,
                boxSizing       : 'border-box',
                paddingTop      : 10,
                paddingBottom   : 10,
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor : marineColor,
                color           : lightGreyColor,

                '& .MuiTypography-root': {
                    color: lightGreyColor,
                },
            },
            colorSecondary: {
                backgroundColor : lightMarineColor,
                color           : pureWhite,

                '& .MuiTypography-root': {
                    color: pureWhite,
                },
            },
            root: {
                color: lightGreyColor,
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: marineColor,
            },
        },
        MuiBadge: {
            colorSecondary: {
                color: pureWhite,
            },
            dot: {
                borderColor  : lightGreyColor,
                borderRadius : '50%',
                borderStyle  : 'solid',
                borderWidth  : 2,
                boxSizing    : 'content-box',
            },
        },
        MuiChip: {
            label: {
                alignItems : 'center',
                display    : 'flex',
            },
            root: {
                color      : bluishGrey,
                fontFamily : '\'Lato\', sans-serif',
            },
        },
        MuiFab: {
            root: {
                boxShadow: 'none',
            },
            secondary: {
                background: azurColorGradient,
            },
        },
        MuiFilledInput: {
            input: {
                appearance      : 'none' as 'none',
                backgroundColor : `${pureWhite} !important`,
                color           : `${bluishGrey} !important`,
            },
            root: {
                backgroundColor: `${pureWhite} !important`,

                '&:hover, &:active, &:focus, &.Mui-focussed': {
                    backgroundColor: `${pureWhite} !important`,
                },

                '@media (hover: none)': {
                    backgroundColor: `${pureWhite} !important`,

                    '&:hover, &:active, &:focus, &.Mui-focussed': {
                        backgroundColor: `${pureWhite} !important`,
                    },
                },
            },
        },
        MuiInputBase: {
            input: {
                appearance : 'none' as 'none',
                color      : `${bluishGrey} !important`,
            },
            inputMultiline: {
                minHeight: 100,
            },
        },
        MuiInputLabel: {
            root: {
                width        : 'calc(100% - 50px)',
                whiteSpace   : 'nowrap',
                overflow     : 'hidden',
                textOverflow : 'ellipsis',
                lineHeight   : 'inherit',
            },
        },
        MuiLinearProgress: {
            barColorPrimary: {
                backgroundColor : azurColor,
                height          : spaceMini,
            },
            colorPrimary: {
                backgroundColor : lightMarineColor,
                height          : spaceMini,
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: 'transparent',
            },
            root: {
                backgroundColor : `${pureWhite} !important`,
                borderColor     : 'transparent',
                borderRadius    : radiusMedium,

                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor : lightGreyColorDark,
                    borderWidth : 5,
                    transition  : 'border-color .3s ease',
                },

                '&:hover, &:active, &:focus, &.Mui-focused': {
                    backgroundColor: `${pureWhite} !important`,

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor : lightGreyColor,
                        borderWidth : 5,
                    },
                },

                '@media (hover: none)': {
                    backgroundColor: `${pureWhite} !important`,

                    '&:hover, &:active, &:focus, &.Mui-focused': {
                        backgroundColor: `${pureWhite} !important`,
                    },
                },
            },
        },
        MuiRadio: {
            root: {
                color: lightMarineColor,
            },
        },
        MuiSlider: {
            rail: {
                backgroundColor : lightMarineColor,
                borderRadius    : radiusNano,
                height          : spaceMicro,
                opacity         : 1,
            },
            thumb: {
                height    : 16,
                marginTop : -6,
                width     : 16,
            },
            track: {
                background   : azurColorGradient,
                borderRadius : radiusNano,
                height       : spaceMicro,
            },
            mark: {
                display: 'none' as 'none',
            },
        },
        MuiSnackbarContent: {
            root: {
                boxShadow: 'none',
            },
        },
        MuiStepConnector: {
            lineVertical: {
                minHeight: spaceMedium,
            },
            vertical: {
                flexGrow      : 0,
                paddingBottom : 0,
            },
        },
        MuiStepIcon: {
            root: {
                color: lighterMarineColor,

                '&.MuiStepIcon-active': {
                    color: azurColor,
                },
            },
        },
        MuiTableCell: {
            head: {
                color    : `${lighterMarineColor} !important`,
                fontSize : fontMini,
            },
            root: {
                borderBottomColor: lightGreyColor,
            },
        },
        MuiTableFooter: {
            root: {
                '& .MuiTableCell-root': {
                    borderBottomColor: 'transparent',
                },
            },
        },
        MuiTablePagination: {
            actions: {
                marginRight: 60,
            },
        },
        MuiTableRow: {
            hover: {
                cursor: 'pointer' as 'pointer',
            },
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: marineColor,
            },
            arrow: {
                color: marineColor,
            },
        },
    },
    palette: {
        action: {
            disabledBackground: 'rgba(230, 230, 230, 1)',
        },
        error: {
            main: errorColor,
        },
        primary: {
            main: lightMarineColor,
        },
        secondary: {
            main: azurColor,
        },
    },
    typography: {
        body1: {
            color      : bluishGrey,
            fontFamily : fontBody,
            fontSize   : 17,
            lineHeight : 1.2,
        },
        body2: {
            color      : bluishGrey,
            fontFamily : fontBody,
            lineHeight : 1.2,
        },
        button: {
            color      : bluishGrey,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        caption: {
            color      : bluishGrey,
            fontFamily : fontBody,
            lineHeight : 1.2,
        },
        h1: {
            color      : bluishGrey,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        h2: {
            color      : bluishGrey,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        h3: {
            color      : bluishGrey,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        h4: {
            color      : marineColor,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        h5: {
            color      : marineColor,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        h6: {
            color      : bluishGrey,
            fontFamily : fontHeadings,
            lineHeight : 1.2,
        },
        overline: {
            color      : bluishGrey,
            fontFamily : fontBody,
            lineHeight : 1.2,
        },
        subtitle1: {
            color      : bluishGrey,
            fontFamily : fontBody,
            lineHeight : 1.2,
        },
        subtitle2: {
            color      : bluishGrey,
            fontFamily : fontBody,
        },
    },
};

const materialTheme: Theme = createTheme(themeOptions);

export default materialTheme;
