import { RouteKey } from '@core/constants/Route';

export const RoutesFR: {[key in RouteKey]?: string} = {
    landing               : 'Accueil',
    signUp                : 'M\'enregistrer',
    signIn                : 'Me connecter',
    signOut               : 'Me déconnecter',
    account               : 'Profil',
    salesArtisans         : 'Artisans',
    salesArtisansProjects : 'Réalisations',
    salesRequests         : 'Requêtes',
    salesSubmissions      : 'Demandes',
    passwordReset         : 'Réinitialiser votre mot de passe',
    artisanHome           : 'Accueil',
    artisanRequests       : 'Mes demandes',
    artisanMarketPlace    : 'Opportunités',
    artisanDelivery       : 'Livraison',
    artisanStats          : 'Tendances marché',
    artisanServices       : 'Mes services',
    artisanPortfolio      : 'Mes réalisations',
    artisanProfile        : 'Mon profil',
    artisanApp            : 'Formulaires personnalisés',
    artisanSettings       : 'Mes critères projet',
    artisanCompany        : 'Mes coordonnées',
    artisanMaterials      : 'Mes matériaux',
};

export default RoutesFR;
