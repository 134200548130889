import FormId from '@furnish-hx/constants/FormId';

export const formsTranslations:{[key in FormId]:{
    name: string
    named: string
    namedPlural?: string
    label?: string
    description?: {
        title:string
        content:string
    }
}} = {
    default: {
        name        : 'Projet divers',
        named       : 'Le projet de {name}',
        namedPlural : 'Le projet de {name}',
    },
    table: {
        name        : 'Table personnalisée',
        named       : 'La table personnalisée de {name}',
        namedPlural : 'Les {quantity} tables personnalisées de {name}',
        label       : 'Imaginée de A à Z',
        description : {
            title   : 'La table de vos rêves',
            content : '🛋️🏠 C\'est vous qui créez la table de vos rêves de A à Z afin qu\'elle corresponde parfaitement à votre déco ! ',
        },
    },
    tableFarm: {
        name        : 'Table de ferme',
        named       : 'La table de ferme de {name}',
        namedPlural : 'Les {quantity} tables de ferme de {name}',
        label       : 'Convivialité et authenticité',
        description : {
            title   : 'La table de ferme',
            content : '🛋️🏠 Pour une ambiance campagne authentique accompagnez votre table de bancs pour affirmer son caractère chaleureux.',
        },
    },
    tray: {
        name        : 'Plateau seul',
        named       : 'Le plateau de {name}',
        namedPlural : 'Les {quantity} plateaux de {name}',
        label       : 'Juste le plateau',
    },
    tableLegs: {
        name        : 'Pieds de table',
        named       : 'Les pieds de table de {name}',
        namedPlural : 'Les {quantity} pieds de table de {name}',
        label       : 'Juste les pieds',
    },
    tableScandinavian: {
        name        : 'Table scandinave',
        named       : 'La table scandinave de {name}',
        namedPlural : 'Les {quantity} tables scandinaves de {name}',
        label       : 'Look cosy et rétro',
        description : {
            title   : 'La table scandinave',
            content : '🛋️🏠 Pour une ambiance scandinave réussie oubliez les teintes foncées et privilégiez un joli bois blond.',
        },
    },
    tableLiveEdge: {
        name        : 'Table "Live-Edge"',
        named       : 'La table "Live-Edge" de {name}',
        namedPlural : 'Les {quantity} tables "Live-Edge" de {name}',
        label       : 'Les bords naturels de l\'arbre',
        description : {
            title   : 'Le Live-Edge, pour quel type de meuble ?',
            content : '🛋️🏠 Associé à un piètement en métal, les lignes naturelles du plateau Live-Edge (appelé aussi Free form) révèlent la beauté naturelle de chaque essence qui apporte un caractère à votre intérieur.',
        },
    },
    tableRiverTable: {
        name        : 'Table "River Table"',
        named       : 'La "River Table" de {name}',
        namedPlural : 'Les {quantity} tables "River Table" de {name}',
        label       : 'La résine ou le verre épouse les formes du bois',
        description : {
            title   : 'La River Table',
            content : '🛋️🏠 Créée à partir de résine epoxy ou de verre associé au bois massif, la River table (ou table rivière) est l\'une des dernières tendances. Une pièce unique et un style disponible dans toutes les couleurs qui offre un contraste spectaculaire !',
        },
    },
    tableIndustrial: {
        name        : 'Table industrielle',
        named       : 'La table industrielle de {name}',
        namedPlural : 'Les {quantity} tables industrielles de {name}',
        label       : 'L\'alliance du bois et de l\'acier',
        description : {
            title   : 'Le style industriel',
            content : '🛋️🏠 Associez votre table à du mobilier style industiel chiné directement sur les brocantes pour une déco "Factory" réussie.',
        },
    },
    tableConcrete: {
        name        : 'Table en béton',
        named       : 'La table en béton de {name}',
        namedPlural : 'Les {quantity} tables en béton de {name}',
        label       : 'Moderne et épurée',
        description : {
            title   : 'La table béton',
            content : '🛋️🏠 Optez pour la légèreté d\'une table en béton ciré, associez votre plateau à un piètement en acier pour l\'ambiance "Loft" ou en bois pour plus de chaleur.',
        },
    },
    tableMarbleAndStone: {
        name        : 'Table marbre et pierre',
        named       : 'La table marbre et pierre de {name}',
        namedPlural : 'Les {quantity} tables marbre et pierre de {name}',
        label       : 'Une somptueuse sobriété',
        description : {
            title   : 'La table en marbre',
            content : '🛋️🏠  Marbre, céramique ou Dekton© des matériaux chic et résistants avec un grand choix de coloris pour votre table à manger.',
        },
    },
    tableMikado: {
        name        : 'Table mikado',
        named       : 'La table mikado de {name}',
        namedPlural : 'Les {quantity} tables mikado de {name}',
        label       : 'Un design déstructuré',
        description : {
            title   : 'La table mikado',
            content : '🛋️🏠 Le piètement Mikado s\'accorde à tous les intérieurs, choisissez simplement le matériau le plus adapté à votre déco.',
        },
    },
    tableVintage: {
        name        : 'Table vintage',
        named       : 'La table vintage de {name}',
        namedPlural : 'Les {quantity} tables vintage de {name}',
        label       : 'Le charme d\'antan',
        description : {
            title   : 'La table vintage',
            content : '🛋️🏠 Pour une ambiance vintage moderne assumée, ajoutez quelques objets vintage de récup\' à votre déco, sans en faire trop !',
        },
    },
    meetingTable: {
        name        : 'Table de réunion',
        named       : 'La table de réunion de {name}',
        namedPlural : 'Les {quantity} tables de réunion de {name}',
        label       : 'La pièce maîtresse de votre espace de travail',
        description : {
            title   : 'La table de réunion',
            content : 'Modélisez votre espace de travail et choisissez la table de réunion qui vous ressemble. Nos ateliers vous proposent un large choix de matériaux, finitions et fonctionnalités pour vous permettre de concevoir une table de réunion fonctionnelle et design, qui reflète votre entreprise ! ✨',
        },
    },
    lowTable: {
        name        : 'Table basse avec pieds',
        named       : 'La table basse avec pieds de {name}',
        namedPlural : 'Les {quantity} tables basses avec pieds de {name}',
        label       : 'L\'élément phare de votre salon',
        description : {
            title   : 'La table basse avec pieds',
            content : '🛋️🏠 C\'est vous qui créez la table basse avec pieds de vos rêves de A à Z afin qu\'elle corresponde parfaitement à votre déco !',
        },
    },
    lowTableNesting: {
        name        : 'Table basse gigogne avec pieds',
        named       : 'La table basse gigogne avec pieds de {name}',
        namedPlural : 'Les {quantity} tables basses gigognes avec pieds de {name}',
        label       : 'Inséparable et indémodable',
        description : {
            title   : 'La table basse gigogne avec pieds',
            content : '🛋️🏠 C\'est vous qui créez la table basse gigogne avec pieds de vos rêves de A à Z afin qu\'elle corresponde parfaitement à votre déco !',
        },
    },
    lowTableNestingNoLegs: {
        name        : 'Table basse gigogne sans pieds',
        named       : 'La table basse gigogne sans pieds de {name}',
        namedPlural : 'Les {quantity} tables basses gigognes sans pieds de {name}',
        label       : 'Voir les choses en grand',
        description : {
            title   : 'La table basse gigogne sans pieds',
            content : '🛋️🏠 C\'est vous qui créez la table basse gigogne sans pieds de vos rêves de A à Z afin qu\'elle corresponde parfaitement à votre déco !',
        },
    },
    lowTableNoLegs: {
        name        : 'Table basse sans pieds',
        named       : 'La table basse sans pieds de {name}',
        namedPlural : 'Les {quantity} tables basses sans pieds de {name}',
        label       : 'Élégance garantie',
        description : {
            title   : 'La table basse sans pieds',
            content : '🛋️🏠 C\'est vous qui créez la table basse sans pieds de vos rêves de A à Z afin qu\'elle corresponde parfaitement à votre déco !',
        },
    },
    desk: {
        name        : 'Bureau',
        named       : 'Le bureau de {name}',
        namedPlural : 'Les {quantity} bureaux de {name}',
        label       : 'Fonctionnel et design',
        description : {
            title   : 'Un confort pour le travail',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un bureau fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    deskExecutive: {
        name        : 'Bureau de direction',
        named       : 'Le bureau de direction de {name}',
        namedPlural : 'Les {quantity} bureaux de direction de {name}',
        label       : 'Un bureau à votre hauteur',
        description : {
            title   : 'Un confort de travail',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un bureau d\'exception fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    consoleSuspended: {
        name        : 'Console suspendue',
        named       : 'La console suspendue de {name}',
        namedPlural : 'Les {quantity} consoles suspendues de {name}',
        label       : 'Légèreté et élégance',
    },
    counterBar: {
        name        : 'Comptoir de bar droit',
        named       : 'Le comptoir de bar de {name}',
        namedPlural : 'Les {quantity} comptoirs de bar de {name}',
        label       : 'Un bar linéaire et design',
        description : {
            title   : 'Un comptoir de bar à votre image',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un comptoir de bar d\'exeption fonctionnel et design, qui reflète votre établissement ! ✨',
        },
    },
    counterBarCentral: {
        name        : 'Comptoir de bar en îlot',
        named       : 'Le comptoir de bar de {name}',
        namedPlural : 'Les {quantity} comptoirs de bar de {name}',
        label       : 'Un comptoir sur-mesure en U',
        description : {
            title   : 'Un comptoir de bar à votre image',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un comptoir de bar d\'exeption fonctionnel et design, qui reflète votre établissement ! ✨',
        },
    },
    counterBarCurved: {
        name        : 'Comptoir de bar arrondi',
        named       : 'Le comptoir de bar de {name}',
        namedPlural : 'Les {quantity} comptoirs de bar de {name}',
        label       : 'Une forme chaleureuse et accueillante',
        description : {
            title   : 'Un comptoir de bar à votre image',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un comptoir de bar d\'exeption fonctionnel et design, qui reflète votre établissement ! ✨',
        },
    },
    counterBarAngle: {
        name        : 'Comptoir de bar avec angle',
        named       : 'Le comptoir de bar de {name}',
        namedPlural : 'Les {quantity} comptoirs de bar de {name}',
        label       : 'Un comptoir sur-mesure en L',
        description : {
            title   : 'Un comptoir de bar à votre image',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un comptoir de bar d\'exeption fonctionnel et design, qui reflète votre établissement ! ✨',
        },
    },
    worktop: {
        name        : 'Plan de travail linéaire',
        named       : 'Le plan de travail de {name}',
        namedPlural : 'Les {quantity} plans de travail de {name}',
        label       : 'Pour cuisiner comme un chef',
        description : {
            title   : 'Une cuisine chaleureuse',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une cuisine d\'exeption fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    worktopAssembled: {
        name        : 'Plan de travail assemblé',
        named       : 'Le plan de travail de {name}',
        namedPlural : 'Les {quantity} plans de travail de {name}',
        label       : 'Découpé selon vos besoins',
        description : {
            title   : 'Une cuisine chaleureuse',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une cuisine d\'exeption fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    wardrobeRoom: {
        name        : 'Dressing personnalisé',
        named       : 'Le dressing de {name}',
        namedPlural : 'Les {quantity} dressing de {name}',
        label       : 'Adapté à vos besoins',
        description : {
            title   : 'Pour organiser votre rangement',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un dressing/rangement d\'exeption fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    wardrobeUnderEaves: {
        name        : 'Dressing sous pente ou combles',
        named       : 'Le dressing sous pente ou combles de {name}',
        namedPlural : 'Les {quantity} dressing sous pente de {name}',
        label       : 'Optimisé pour votre espace',
        description : {
            title   : 'Pour organiser votre rangement',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un dressing/rangement d\'exeption fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    storageUnderStairs: {
        name        : 'Rangement sous escalier',
        named       : 'Le rangement sous escalier de {name}',
        namedPlural : 'Les {quantity} rangements sous escalier de {name}',
        label       : 'Valorise les espaces perdus',
        description : {
            title   : 'Pour organiser votre rangement',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un dressing/rangement d\'exeption fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    libraryWithNiches: {
        name        : 'Bibliothèque à niches',
        named       : 'La bibliothèque à niches de {name}',
        namedPlural : 'Les {quantity} bibliothèques de {name}',
        label       : 'Fonctionnelle et design',
        description : {
            title   : 'Pour organiser votre espace',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une bibliothèque d\'exeption, fonctionnelle et design, qui reflète votre personnalité ! ✨',
        },
    },
    libraryWithShelves: {
        name        : 'Bibliothèque à étagères',
        named       : 'La bibliothèque à étagères de {name}',
        namedPlural : 'Les {quantity} bibliothèques à étagères de {name}',
        label       : 'Classique et épurée',
        description : {
            title   : 'Pour organiser votre espace',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une bibliothèque d\'exeption, fonctionnelle et design, qui reflète votre personnalité ! ✨',
        },
    },
    libraryWall: {
        name        : 'Bibliothèque murale',
        named       : 'La bibliothèque murale de {name}',
        namedPlural : 'Les {quantity} bibliothèques murale de {name}',
        label       : 'Gagnez de l\'espace',
        description : {
            title   : 'Pour organiser votre espace',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une bibliothèque d\'exeption, fonctionnelle et design, qui reflète votre personnalité ! ✨',
        },
    },
    libraryComposition: {
        name        : 'Bibliothèque personnalisée',
        named       : 'La bibliothèque personnalisée de {name}',
        namedPlural : 'Les {quantity} bibliothèques personnalisées de {name}',
        label       : 'Composez votre propre bibliothèque sur-mesure',
        description : {
            title   : 'Pour organiser votre espace',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une bibliothèque d\'exeption, fonctionnelle et design, qui reflète votre personnalité ! ✨',
        },
    },
    glassWall: {
        name        : 'Verrière sur-mesure',
        named       : 'La verrière sur-mesure de {name}',
        namedPlural : 'Les {quantity} verrières simples de {name}',
        label       : 'Tout en transparence',
        description : {
            title   : 'Et la lumière fut',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une verrière sur-mesure fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    glassWallWithBase: {
        name        : 'Verrière avec soubassement',
        named       : 'La verrière avec soubassement de {name}',
        namedPlural : 'Les {quantity} verrières avec soubassement de {name}',
        label       : 'Délimite les espaces',
        description : {
            title   : 'Et la lumière fut',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une verrière avec soubassement fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    glassWallWithFrame: {
        name        : 'Verrière avec traverse',
        named       : 'La verrière avec traverse de {name}',
        namedPlural : 'Les {quantity} verrières avec traverse de {name}',
        label       : 'Laissez entrer la lumière',
        description : {
            title   : 'Et la lumière fut',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une verrière avec traverse fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    glassWallComplex: {
        name        : 'Verrière complexe',
        named       : 'La verrière complexe de {name}',
        namedPlural : 'Les {quantity} verrières complexes de {name}',
        label       : 'S\'adapte à toutes les pièces',
        description : {
            title   : 'Et la lumière fut',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir une verrière complexe fonctionnel et design, qui reflète votre personnalité ! ✨',
        },
    },
    garageDoor: {
        name        : 'Porte de garage',
        named       : 'La porte de garage de {name}',
        namedPlural : 'Les {quantity} portes de garage de {name}',
        label       : '',
    },
    mirror: {
        name        : 'Miroir',
        named       : 'Le miroir de {name}',
        namedPlural : 'Les {quantity} miroirs de {name}',
        label       : 'Agrandit votre espace',
    },
    credenza: {
        name        : 'Crédence',
        named       : 'La crédence de {name}',
        namedPlural : 'Les {quantity} crédences de {name}',
        label       : 'Une finition élégante',
    },
    shelf: {
        name        : 'Étagère',
        named       : 'L\'étagère de {name}',
        namedPlural : 'Les {quantity} étagères de {name}',
        label       : 'Habille vos murs',
    },
    closetDoor: {
        name        : 'Porte de placard',
        named       : 'La porte de placard de {name}',
        namedPlural : 'Les {quantity} portes de placard de {name}',
        label       : 'Pour ranger',
    },
    windows: {
        name        : 'Fenêtre sur-mesure',
        named       : 'La fenêtre sur-mesure de {name}',
        namedPlural : 'Les {quantity} fenêtres sur-mesure de {name}',
        label       : 'Solide et économique',
    },
    pavingSlab: {
        name        : 'Dalle de sol',
        named       : 'La dalle de sol de {name}',
        namedPlural : 'Les {quantity} dalles de sol de {name}',
        label       : 'Pour un sol unique',
    },
    glassShape: {
        name        : 'Découpe de verre',
        named       : 'La découpe de verre de {name}',
        namedPlural : 'Les {quantity} découpes de verre de {name}',
        label       : 'Découpe de verre sur-mesure',
    },
    partitionWall: {
        name        : 'Cloison de séparation',
        named       : 'La cloison de séparation de {name}',
        namedPlural : 'Les {quantity} cloisons de séparation de {name}',
        label       : 'Aménagement d\'espace',
    },
    showerScreen: {
        name        : 'Paroi de douche',
        named       : 'La paroi de douche de {name}',
        namedPlural : 'Les {quantity} parois de douche de {name}',
        label       : 'Aménagement d\'espace',
    },
    fence: {
        name        : 'Garde-corps',
        named       : 'Le garde-corps de {name}',
        namedPlural : 'Les {quantity} gardes-corps de {name}',
        label       : 'Sécurisez votre piscine',
    },
    fencing: {
        name        : 'Clôture',
        named       : 'La clôture de {name}',
        namedPlural : 'Les {quantity} clôtures de {name}',
        label       : 'Sécurisez votre jardin',
    },
    highWind: {
        name        : 'Marquise',
        named       : 'La marquise de {name}',
        namedPlural : 'Les {quantity} marquises de {name}',
        label       : 'Habillez votre porte d\'entrée',
    },
    veranda: {
        name        : 'Véranda',
        named       : 'La véranda de {name}',
        namedPlural : 'Les {quantity} vérandas de {name}',
        label       : 'Une pièce en plus',
    },
    staircaseStraight: {
        name        : 'Escalier droit',
        named       : 'L\'escalier droit de {name}',
        namedPlural : 'Les {quantity} escaliers de {name}',
        label       : 'Un escalier simple et confortable',
    },
    staircaseSpiralRound: {
        name        : 'Escalier hélicoïdal rond',
        named       : 'L\'escalier hélicoïdal rond de {name}',
        namedPlural : 'Les {quantity} escaliers de {name}',
        label       : 'L’allié des petits espaces',
    },
    staircaseSpiralSquare: {
        name        : 'Escalier hélicoïdal carré',
        named       : 'L\'escalier hélicoïdal carré de {name}',
        namedPlural : 'Les {quantity} escaliers de {name}',
        label       : 'L’allié des petits espaces',
    },
    staircaseQuarterTurn: {
        name        : 'Escalier 1/4 tournant',
        named       : 'L\'escalier 1/4 tournant de {name}',
        namedPlural : 'Les {quantity} escaliers de {name}',
        label       : 'Optimisez un grand espace',
    },
    staircaseHalfTurn: {
        name        : 'Escalier 1/2 tournant',
        named       : 'L\'escalier 1/2 tournant de {name}',
        namedPlural : 'Les {quantity} escaliers de {name}',
        label       : 'Plus grande liberté de mouvement',
    },
    staircaseTurnLanding: {
        name        : 'Escalier tournant avec palier',
        named       : 'L\'escalier tournant avec palier de {name}',
        namedPlural : 'Les {quantity} escaliers de {name}',
        label       : 'Plus grande liberté de mouvement',
    },
    glassGreenHouse: {
        name        : 'Serre en verre',
        named       : 'La serre de {name}',
        namedPlural : 'Les {quantity} serres de {name}',
        label       : 'Pour vos plantes',
    },
    pergolaBioclimatic: {
        name        : 'Pergola aluminium bioclimatique',
        named       : 'La pergola bioclimatique de {name}',
        namedPlural : 'Les {quantity} pergolas bioclimatique de {name}',
        label       : 'Votre pergola bioclimatique au design unique',
    },
    pergolaClassic: {
        name        : 'Pergola classique',
        named       : 'La pergola classique de {name}',
        namedPlural : 'Les {quantity} pergolas classique de {name}',
        label       : 'Votre pergola pour des belles soirées d’été',
    },
    tvUnitStand: {
        name        : 'Meuble télévision au sol',
        named       : 'Le meuble télévision de {name}',
        namedPlural : 'Les {quantity} meubles télévision de {name}',
        label       : 'L\'ecrin pour votre télévision',
        description : {
            title   : 'Le meuble qui expose votre télévision',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un meuble télévision d\'exeption fonctionnel et design, qui reflète votre intérieur ! ✨',
        },
    },
    tvUnitSuspended: {
        name        : 'Meuble télévision suspendu',
        named       : 'Le meuble télévision suspendu de {name}',
        namedPlural : 'Les {quantity} meubles télévision suspendu de {name}',
        label       : 'Le meuble télévision design et épuré',
        description : {
            title   : 'Le meuble qui expose votre télévision',
            content : 'On vous propose un large choix de matériaux, de finitions et de fonctionnalités pour vous permettre de concevoir un meuble télévision d\'exeption fonctionnel et design, qui reflète votre intérieur ! ✨',
        },
    },
    wineCellar: {
        name  : 'Cave à vin',
        named : 'La cave à vin de {name}',
        label : 'Le tout en un pour une dégustation réussie',
    },
    wineCellarDedicatedRoom: {
        name  : 'Cave à vin pour pièce dédiée',
        named : 'La cave à vin pour pièce dédiée de {name}',
        label : 'Conservez votre vin jusqu\'à son apogée',
    },
    dpRestaurant: {
        name  : 'Agencement restaurant',
        named : 'L\'agencement de restaurant de {name}',
        label : 'Mettez le design au menu !',
    },
    dpBar: {
        name  : 'Agencement bar',
        named : 'L\'agencement de bar de {name}',
        label : 'Créez un lieu chaleureux',
    },
    dpFastFood: {
        name  : 'Agencement fast-food',
        named : 'L\'agencement de fast-food de {name}',
        label : 'Convivial et épuré',
    },
    dpWineCellar: {
        name  : 'Agencement cave à vin',
        named : 'L\'agencement de cave à vin de {name}',
        label : 'Vos produits mis en valeur',
    },
    dpHotel: {
        name  : 'Agencement d\'hôtel',
        named : 'L\'agencement d\'Hôtel de {name}',
        label : 'Misez sur la détente',
    },
    dpShop: {
        name  : 'Agencement boutique',
        named : 'L\'agencement de boutique de {name}',
        label : 'Accueillez vos clients dans un espace qui vous ressemble ',
    },
    dpPharmacy: {
        name  : 'Agencement pharmacie',
        named : 'L\'agencement de pharmacie de {name}',
        label : 'Un espace clair et lumineux',
    },
    dpRealEstate: {
        name  : 'Agencement agence immobilière',
        named : 'L\'agencement d\'agence immobilière de {name}',
        label : 'Pour un accueil chaleureux',
    },
    dpHairdresser: {
        name  : 'Agencement salon de coiffure',
        named : 'L\'agencement de salon de coiffure de {name}',
        label : 'Un espace design et élégant',
    },
    dpBeautyInstitute: {
        name  : 'Agencement institut de beauté',
        named : 'L\'agencement de institut de beauté de {name}',
        label : 'Un espace chic et cocooning',
    },
    dpGrocery: {
        name  : 'Agencement épicerie fine',
        named : 'L\'agencement de épicerie fine de {name}',
        label : 'Présentez un large choix de produits',
    },
    dpButcherSHop: {
        name  : 'Agencement boucherie',
        named : 'L\'agencement de boucherie de {name}',
        label : 'Faites découvrir à vos clients des produits uniques',
    },
    dpCheeseDairy: {
        name  : 'Agencement fromagerie',
        named : 'L\'agencement de fromagerie de {name}',
        label : 'Un espace convivial pour vos produits d\'exceptions',
    },
    dpBakery: {
        name  : 'Agencement boulangerie',
        named : 'L\'agencement de boulangerie de {name}',
        label : 'Mettez en valeur le produit préféré des Français',
    },
    dpWorkspace: {
        name  : 'Agencement bureau et espace de travail',
        named : 'L\'agencement bureau et espace de travail de {name}',
        label : 'Pour motiver vos équipes !',
    },
    dpHouseInterior: {
        name  : 'Agencement intérieur',
        named : 'L\'agencement intérieur de {name}',
        label : 'Un espace qui vous ressemble',
    },
    dpHouseOutside: {
        name  : 'Agencement extérieur',
        named : 'L\'agencement extérieur de {name}',
        label : 'Pour profiter par tous les temps',
    },
    dpEvents: {
        name  : 'Agencement et mobilier événementiel',
        named : 'L\'agencement évènementiel de {name}',
        label : 'Soyez original et attirez les regards',
    },
    aboveGroundPool: {
        name  : 'Piscine hors-sol',
        named : 'La piscine hors-sol de {name}',
    },
    accordionDoorInstallation: {
        name  : 'Pose de porte accordéon',
        named : 'La pose de porte accordéon de {name}',
    },
    alluminiumFenceInstallation: {
        name  : 'Installation de clôture en aluminium',
        named : 'L\'installation de clôture en aluminium de {name}',
    },
    aluminiumCarpentry: {
        name  : 'Menuiserie en aluminium',
        named : 'La menuiserie en aluminium de {name}',
    },
    aluminiumCladding: {
        name  : 'Bardage en aluminium',
        named : 'Le bardage en aluminium de {name}',
    },
    aluminiumFenceInstallation: {
        name  : 'Installation de clôture en aluminium',
        named : 'L\'installation de clôture en aluminium de {name}',
    },
    aluminiumFenceRepair: {
        name  : 'Réparation de clôture en aluminium',
        named : 'La réparation de clôture en aluminium de {name}',
    },
    anticyclonicShutterInstallation: {
        name  : 'Installation de volet anticyclonique',
        named : 'L\'installation de volet anticyclonique de {name}',
    },
    anticyclonicShutterRepair: {
        name  : 'Réparation de volet anticyclonique',
        named : 'La réparation de volet anticyclonique de {name}',
    },
    apartementRenovation: {
        name  : 'Rénovation d\'appartement',
        named : 'La rénovation d\'appartement de {name}',
    },
    arborDesignAndBuild: {
        name  : 'Conception et construction de tonnelle',
        named : 'La conception et construction de tonnelle de {name}',
    },
    architectHouse: {
        name  : 'Maison d\'architecte',
        named : 'La maison d\'architecte de {name}',
    },
    asphalting: {
        name  : 'Asphaltage',
        named : 'L\'asphaltage de {name}',
    },
    asphaltShingleRoof: {
        name  : 'Toiture en bardeau bitumé',
        named : 'La toiture en bardeau bitumé de {name}',
    },
    atticLayout: {
        name  : 'Aménagement de combles',
        named : 'L\'aménagement de combles de {name}',
    },
    atticRenovation: {
        name  : 'Rénovation de combles',
        named : 'La rénovation de combles de {name}',
    },
    automaticGateInstallation: {
        name  : 'Installation de portail automatique',
        named : 'L\'installation de portail automatique de {name}',
    },
    automaticPortalInstallation: {
        name  : 'Installation de portail automatique',
        named : 'L\'installation de portail automatique de {name}',
    },
    awningCleaning: {
        name  : 'Nettoyage de store',
        named : 'Le nettoyage de store de {name}',
    },
    awningInstallation: {
        name  : 'Pose de store',
        named : 'La pose de store de {name}',
    },
    awningRepair: {
        name  : 'Réparation de store banne',
        named : 'La réparation de store banne de {name}',
    },
    babyRoomDesign: {
        name  : 'Chambre bébé',
        named : 'La chambre bébé de {name}',
    },
    balconyBuild: {
        name  : 'Construction de balcon',
        named : 'La construction de balcon de {name}',
    },
    balconyRepair: {
        name  : 'Réparation de balcon',
        named : 'La réparation de balcon de {name}',
    },
    balconyWaterproofing: {
        name  : 'Étanchéité de balcon',
        named : 'L\'étanchéité de balcon de {name}',
    },
    bathroomDesign: {
        name  : 'Salle de bain',
        named : 'La salle de bain de {name}',
    },
    bathroomInstallation: {
        name  : 'Installation de salle de bain',
        named : 'L\'installation de salle de bain de {name}',
    },
    bathroomRenovation: {
        name  : 'Rénovation de salle de bain',
        named : 'La rénovation de salle de bain de {name}',
    },
    bedroomLayout: {
        name  : 'Aménagement de chambre',
        named : 'L\'aménagement de chambre de {name}',
    },
    bench: {
        name  : 'Banquette',
        named : 'La banquette de {name}',
    },
    benchAndStool: {
        name  : 'Banc et tabouret',
        named : 'Le banc et tabouret de {name}',
    },
    billiardRepair: {
        name  : 'Réparation de billard',
        named : 'La réparation de billard de {name}',
    },
    blindAndRollerShutter: {
        name  : 'Stores et volets roulants',
        named : 'Les stores et volets roulants de {name}',
    },
    blindSale: {
        name  : 'Vente de stores',
        named : 'La vente de stores de {name}',
    },
    brickFacing: {
        name  : 'Parement de brique',
        named : 'Le parement de brique de {name}',
    },
    brickRepair: {
        name  : 'Réparation de brique',
        named : 'La réparation de brique de {name}',
    },
    brickSale: {
        name  : 'Vente de briques',
        named : 'La vente de briques de {name}',
    },
    brickyard: {
        name  : 'Briqueterie',
        named : 'La briqueterie de {name}',
    },
    carpetCleaning: {
        name  : 'Nettoyage de moquette',
        named : 'Le nettoyage de moquette de {name}',
    },
    carpetLaying: {
        name  : 'Pose de moquette',
        named : 'La pose de moquette de {name}',
    },
    carpetRepair: {
        name  : 'Réparation de moquette',
        named : 'La réparation de moquette de {name}',
    },
    carpetSale: {
        name  : 'Vente de moquette',
        named : 'La vente de moquette de {name}',
    },
    carpetStretch: {
        name  : 'Étirement de moquette',
        named : 'L\'étirement de moquette de {name}',
    },
    carportBuild: {
        name  : 'Construction de carport',
        named : 'La construction de carport de {name}',
    },
    caulking: {
        name  : 'Calfeutrage',
        named : 'Le calfeutrage de {name}',
    },
    ceilingFanInstallation: {
        name  : 'Installation de ventilateur de plafond',
        named : 'L\'installation de ventilateur de plafond de {name}',
    },
    ceilingFanRepair: {
        name  : 'Réparation de ventilateur de plafond',
        named : 'La réparation de ventilateur de plafond de {name}',
    },
    cellarWaterproofing: {
        name  : 'Étanchéité de cave',
        named : 'L\'étanchéité de cave de {name}',
    },
    chaletBuild: {
        name  : 'Construction de chalet',
        named : 'La construction de chalet de {name}',
    },
    changingThePoolLiner: {
        name  : 'Changement de liner de piscine',
        named : 'Le changement de liner de piscine de {name}',
    },
    childAndBabyRoom: {
        name  : 'Chambre enfant et bébé',
        named : 'La chambre enfant et bébé de {name}',
    },
    childBedroomLayout: {
        name  : 'Chambre d’enfant',
        named : 'La chambre d’enfant de {name}',
        label : 'Un univers ludique et intime',
    },
    chimneyBuild: {
        name  : 'Construction de cheminée',
        named : 'La construction de cheminée de {name}',
    },
    chimneyDiagnosis: {
        name  : 'Diagnostic cheminée',
        named : 'Le diagnostic cheminée de {name}',
    },
    chimneyInstallation: {
        name  : 'Installation de cheminée',
        named : 'L\'installation de cheminée de {name}',
    },
    chimneyRepair: {
        name  : 'Réparation de cheminée',
        named : 'La réparation de cheminée de {name}',
    },
    chimneySale: {
        name  : 'Vente de cheminée',
        named : 'La vente de cheminée de {name}',
    },
    christmasLight: {
        name  : 'Illuminations de Noël',
        named : 'Les illuminations de Noël de {name}',
    },
    claddingInstallation: {
        name  : 'Pose de bardage',
        named : 'La pose de bardage de {name}',
    },
    claustras: {
        name  : 'Claustra',
        named : 'La claustra de {name}',
        label : 'Cloisonnez un petit espace avec légèreté',
    },
    clearing: {
        name  : 'Défrichement',
        named : 'Le défrichement de {name}',
    },
    closetInstallation: {
        name  : 'Pose de placard',
        named : 'La pose de placard de {name}',
    },
    closetMakeover: {
        name  : 'Relooking de placard',
        named : 'Le relooking de placard de {name}',
    },
    closetPainting: {
        name  : 'Peinture de placard',
        named : 'La peinture de placard de {name}',
    },
    closetRenovation: {
        name  : 'Rénovation de placard',
        named : 'La rénovation de placard de {name}',
    },
    closetRepair: {
        name  : 'Réparation de placard',
        named : 'La réparation de placard de {name}',
    },
    closingSale: {
        name  : 'Vente de clôture',
        named : 'La vente de clôture de {name}',
    },
    completeRenovation: {
        name  : 'Rénovation complète',
        named : 'La rénovation complète de {name}',
    },
    concreteBuild: {
        name  : 'Construction de béton',
        named : 'La construction de béton de {name}',
    },
    concreteCaulking: {
        name  : 'Calfeutrage du béton',
        named : 'Le calfeutrage du béton de {name}',
    },
    concreteDrivewayInstallation: {
        name  : 'Installation d\'allée carrossable en béton',
        named : 'L\'installation d\'allée carrossable en béton de {name}',
    },
    concreteDrivewaySealing: {
        name  : 'Scellement d\'allée carrossable en béton',
        named : 'Le scellement d\'allée carrossable en béton de {name}',
    },
    concreteFloor: {
        name  : 'Sol en béton',
        named : 'Le sol en béton de {name}',
    },
    concreteRepair: {
        name  : 'Réparation de béton',
        named : 'La réparation de béton de {name}',
    },
    concreteSale: {
        name  : 'Vente de béton',
        named : 'La vente de béton de {name}',
    },
    concreteTint: {
        name  : 'Teinte de béton',
        named : 'La teinte de béton de {name}',
    },
    console: {
        name        : 'Console',
        named       : 'La console de {name}',
        namedPlural : 'Les {quantity} consoles  de {name}',
        label       : 'Pour une entrée réussie',
    },
    createAndRepairOfStainedGlass: {
        name  : 'Création et réparation de vitrail',
        named : 'La création et réparation de vitrail de {name}',
    },
    curtainCleaning: {
        name  : 'Nettoyage de rideaux',
        named : 'Le nettoyage de rideaux de {name}',
    },
    customBeds: {
        name  : 'Lit sur-mesure',
        named : 'Le lit sur-mesure de {name}',
    },
    customBilliard: {
        name  : 'Billard sur-mesure',
        named : 'Le billard sur-mesure de {name}',
    },
    customBlind: {
        name        : 'Store intérieur',
        named       : 'Le store intérieur de {name}',
        namedPlural : 'Les {quantity} stores intérieur de {name}',
        label       : 'À l\'abri des regards indiscrets',
    },
    customBlindOutdoor: {
        name        : 'Store extérieur',
        named       : 'Le store extérieur de {name}',
        namedPlural : 'Les {quantity} stores extérieur de {name}',
        label       : 'Optez pour plus de confort',
    },
    customBrazier: {
        name  : 'Brasero sur-mesure',
        named : 'Le brasero sur-mesure de {name}',
    },
    customBuiltInCabinet: {
        name  : 'Meuble encastré sur-mesure',
        named : 'Le meuble encastré sur-mesure de {name}',
    },
    customBunkBed: {
        name  : 'Lit superposé',
        named : 'Le lit superposé sur-mesure de {name}',
    },
    customChimney: {
        name  : 'Cheminée sur-mesure',
        named : 'La cheminée sur-mesure de {name}',
    },
    customChimneyMantel: {
        name  : 'Manteau de cheminée sur-mesure',
        named : 'Le manteau de cheminée sur-mesure de {name}',
    },
    customCupboard: {
        name  : 'Placard sur-mesure',
        named : 'Le placard sur-mesure de {name}',
    },
    customCupboards: {
        name  : 'Placards sur-mesure',
        named : 'Les placards sur-mesure de {name}',
    },
    customCurtainAndVeil: {
        name  : 'Rideaux et voilages sur-mesure',
        named : 'La rideaux et voilages sur-mesure de {name}',
    },
    customEmbroidery: {
        name  : 'Broderie personnalisée',
        named : 'La broderie personnalisée de {name}',
    },
    customExteriorDoor: {
        name  : 'Portes extérieures sur-mesure',
        named : 'Les portes extérieures sur-mesure de {name}',
    },
    customFoldingDoor: {
        name  : 'Portes pliantes sur-mesure',
        named : 'Les portes pliantes sur-mesure de {name}',
    },
    customGarageDoor: {
        name  : 'Porte de garage sur-mesure',
        named : 'La porte de garage sur-mesure de {name}',
    },
    customMadeVanityUnit: {
        name  : 'Meuble sous-vasque sur-mesure',
        named : 'Le meuble sous-vasque sur-mesure de {name}',
    },
    customMezzanine: {
        name  : 'Lit-mezzanine',
        named : 'Le lit-mezzanine de {name}',
    },
    customMosquitoNet: {
        name  : 'Moustiquaire sur-mesure',
        named : 'La moustiquaire sur-mesure de {name}',
    },
    customPantry: {
        name  : 'Garde-manger sur-mesure',
        named : 'Le garde-manger sur-mesure de {name}',
    },
    customRugs: {
        name  : 'Tapis sur-mesure',
        named : 'Le tapis sur-mesure de {name}',
    },
    customSauna: {
        name  : 'Sauna sur-mesure',
        named : 'Le sauna sur-mesure de {name}',
    },
    deckBuild: {
        name  : 'Terrasse sur-mesure',
        named : 'La terrasse sur-mesure de {name}',
    },
    deckLayout: {
        name  : 'Terrasse sur-mesure',
        named : 'La terrasse sur-mesure de {name}',
    },
    decorativeConcrete: {
        name  : 'Béton décoratif',
        named : 'Le béton décoratif de {name}',
    },
    demolition: {
        name  : 'Démolition',
        named : 'La démolition de {name}',
    },
    designAndBuildOfGardenSheds: {
        name  : 'Conception et construction d\'abri de jardin',
        named : 'La conception et construction d\'abri de jardin de {name}',
    },
    dockDesingAndBuild: {
        name  : 'Conception et construction de quai',
        named : 'La conception et construction de quai de {name}',
    },
    doorPainting: {
        name  : 'Peinture de porte',
        named : 'La peinture de porte de {name}',
    },
    doorRepair: {
        name  : 'Réparation de porte',
        named : 'La réparation de porte de {name}',
    },
    doorSale: {
        name  : 'Vente de porte',
        named : 'La vente de porte de {name}',
    },
    dresserSideboard: {
        name  : 'Commode, buffet et bahut',
        named : 'La commode, buffet et bahut de {name}',
    },
    dressingTable: {
        name  : 'Coiffeuse',
        named : 'La coiffeuse de {name}',
    },
    drivewayGateInstallation: {
        name  : 'Installation de portail d\'allée carrossable',
        named : 'L\'installation de portail d\'allée carrossable de {name}',
    },
    drivewayRenovation: {
        name  : 'Rénovation d\'allée carrossable',
        named : 'La rénovation d\'allée carrossable de {name}',
    },
    drivewayRepair: {
        name  : 'Réparation d\'allée carrossable',
        named : 'La réparation d\'allée carrossable de {name}',
    },
    ecologicalBuild: {
        name  : 'Construction écologique',
        named : 'La construction écologique de {name}',
    },
    electricChimneyInstallation: {
        name  : 'Installation de cheminée électrique',
        named : 'L\'installation de cheminée électrique de {name}',
    },
    electricChimneyRepair: {
        name  : 'Réparation de cheminée électrique',
        named : 'La réparation de cheminée électrique de {name}',
    },
    elevation: {
        name  : 'Surélévation',
        named : 'La surélévation de {name}',
    },
    epoxyResinFloor: {
        name  : 'Sol en résine époxy',
        named : 'Le sol en résine époxy de {name}',
    },
    excavation: {
        name  : 'Excavation',
        named : 'L\'excavation de {name}',
    },
    exteriorDoorInstallation: {
        name  : 'Installation de porte extérieure',
        named : 'L\'installation de porte extérieure de {name}',
    },
    exteriorLightingDesign: {
        name  : 'Conception d\'éclairage extérieur',
        named : 'La conception d\'éclairage extérieur de {name}',
    },
    exteriorPainting: {
        name  : 'Peinture extérieure',
        named : 'La peinture extérieure de {name}',
    },
    exteriorShutter: {
        name  : 'Volets extérieurs',
        named : 'Les volets extérieurs de {name}',
    },
    facadPlasterRepair: {
        name  : 'Réparation de crépi de façade',
        named : 'La réparation de crépi de façade de {name}',
    },
    facelift: {
        name  : 'Ravalement de façade',
        named : 'Le ravalement de façade de {name}',
    },
    fenceInstallation: {
        name  : 'Installation de clôture',
        named : 'L\'installation de clôture de {name}',
    },
    fenceRepair: {
        name  : 'Réparation de clôture',
        named : 'La réparation de clôture de {name}',
    },
    fireproofing: {
        name  : 'Ignifugation',
        named : 'L\'ignifugation de {name}',
    },
    floorCleaning: {
        name  : 'Nettoyage de sol',
        named : 'Le nettoyage de sol de {name}',
    },
    floorCoveringInstallation: {
        name  : 'Installation de revêtement de sol',
        named : 'L\'installation de revêtement de sol de {name}',
    },
    floorHeating: {
        name  : 'Chauffage au sol',
        named : 'Le chauffage au sol de {name}',
    },
    flooringSale: {
        name  : 'Vente de revêtement de sol',
        named : 'La vente de revêtement de sol de {name}',
    },
    floorPlanForBuildingPermit: {
        name  : 'Plan de masse pour permis de construire',
        named : 'Le plan de masse pour permis de construire de {name}',
    },
    floorPolishing: {
        name  : 'Polissage de parquet',
        named : 'Le polissage de parquet de {name}',
    },
    floorRenovation: {
        name  : 'Rénovation de sol',
        named : 'La rénovation de sol de {name}',
    },
    floorSanding: {
        name  : 'Ponçage de sol',
        named : 'Le ponçage de sol de {name}',
    },
    foldingDoor: {
        name  : 'Portes pliantes',
        named : 'Les portes pliantes de {name}',
    },
    frameAndJoinery: {
        name  : 'Charpente et menuiseries',
        named : 'La charpente et menuiseries de {name}',
    },
    furnitureDelivery: {
        name  : 'Livraison de meuble',
        named : 'La livraison de meuble de {name}',
    },
    furnitureRepair: {
        name  : 'Réparation de meuble',
        named : 'La réparation de meuble de {name}',
    },
    furnitureSale: {
        name  : 'Vente de meuble',
        named : 'La vente de meuble de {name}',
    },
    garageBuild: {
        name  : 'Construction de garage',
        named : 'La construction de garage de {name}',
    },
    garageDoorInstallation: {
        name  : 'Installation de porte de garage',
        named : 'L\'installation de porte de garage de {name}',
    },
    garageDoorRepair: {
        name  : 'Réparation de porte de garage',
        named : 'La réparation de porte de garage de {name}',
    },
    garageDoorSale: {
        name  : 'Vente de porte de garage',
        named : 'La vente de porte de garage de {name}',
    },
    garageDoorSellerAndInstaller: {
        name  : 'Vente et installation de porte de garage',
        named : 'La vente et installation de porte de garage de {name}',
    },
    garageFlooring: {
        name  : 'Revêtement de sol de garage',
        named : 'Le revêtement de sol de garage de {name}',
    },
    garageFloorRenovation: {
        name  : 'Rénovation de sol de garage',
        named : 'La rénovation de sol de garage de {name}',
    },
    garageLayout: {
        name  : 'Aménagement de garage',
        named : 'L\'aménagement de garage de {name}',
    },
    gardenFurnitureSale: {
        name  : 'Vente de meubles de jardin',
        named : 'La vente de meubles de jardin de {name}',
    },
    gardenLightingInstallation: {
        name  : 'Installation d\'éclairage de jardin',
        named : 'L\'installation d\'éclairage de jardin de {name}',
    },
    gardenPavilionDesignAndBuild: {
        name  : 'Conception et construction de pavillon de jardin',
        named : 'La conception et construction de pavillon de jardin de {name}',
    },
    gazChimneyInstallation: {
        name  : 'Installation de cheminée à gaz',
        named : 'L\'installation de cheminée à gaz de {name}',
    },
    gazChimneyRepair: {
        name  : 'Réparation de cheminée à gaz',
        named : 'La réparation de cheminée à gaz de {name}',
    },
    generalBuildingContractor: {
        name  : 'Entreprises générales de bâtiment',
        named : 'Les entreprises générales de bâtiment de {name}',
    },
    glassBrickInstallation: {
        name  : 'Installation de brique de verre',
        named : 'L\'installation de brique de verre de {name}',
    },
    glutterRepair: {
        name  : 'Réparation de gouttière',
        named : 'La réparation de gouttière de {name}',
    },
    gutterCleaning: {
        name  : 'Nettoyage de gouttière',
        named : 'Le nettoyage de gouttière de {name}',
    },
    gutterInstallation: {
        name  : 'Installation de gouttière',
        named : 'L\'installation de gouttière de {name}',
    },
    heaterSale: {
        name  : 'Vente d\'appareils de chauffage',
        named : 'La vente d\'appareils de chauffage de {name}',
    },
    highPressureWashing: {
        name  : 'Lavage haute pression',
        named : 'Le lavage haute pression de {name}',
    },
    houseExtension: {
        name  : 'Extension de maison',
        named : 'L\'extension de maison de {name}',
    },
    houseFoundationBuild: {
        name  : 'Construction de fondations de maison',
        named : 'La construction de fondations de maison de {name}',
    },
    installationOfAwning: {
        name  : 'Installation de store banne',
        named : 'L\'installation de store banne de {name}',
    },
    installationOfVanityUnit: {
        name  : 'Installation de meuble sous lavabo',
        named : 'L\'installation de meuble sous lavabo de {name}',
    },
    insulationInstallation: {
        name  : 'Pose d\'isolation',
        named : 'La pose d\'isolation de {name}',
    },
    interiorDoorInstallation: {
        name  : 'Installation de porte intérieure',
        named : 'L\'installation de porte intérieure de {name}',
    },
    interiorShutter: {
        name  : 'Volets intérieurs',
        named : 'Les volets intérieurs de {name}',
    },
    ironworker: {
        name  : 'Ferronnerie',
        named : 'La ferronnerie de {name}',
    },
    kitchenDesign: {
        name  : 'Cuisine',
        named : 'La cuisine de {name}',
    },
    kitchenInstallation: {
        name  : 'Installation de cuisine',
        named : 'L\'installation de cuisine de {name}',
    },
    kitchenRenovation: {
        name  : 'Rénovation de cuisine',
        named : 'La rénovation de cuisine de {name}',
    },
    laminateFlooringLaying: {
        name  : 'Pose de sol stratifié',
        named : 'La pose de sol stratifié de {name}',
    },
    laminateFlooringSale: {
        name  : 'Vente de parquet stratifié',
        named : 'La vente de parquet stratifié de {name}',
    },
    laminateFloorRepair: {
        name  : 'Réparation de sol stratifié',
        named : 'La réparation de sol stratifié de {name}',
    },
    landscaping: {
        name  : 'Aménagement extérieur',
        named : 'L\'aménagement extérieur de {name}',
    },
    landscapingSupply: {
        name  : 'Fourniture d\'aménagement paysager',
        named : 'La fourniture d\'aménagement paysager de {name}',
    },
    latticeBuild: {
        name  : 'Fabrication de treillis',
        named : 'La fabrication de treillis de {name}',
    },
    layingLightPipe: {
        name  : 'Pose de conduits de lumière',
        named : 'La pose de conduits de lumière de {name}',
    },
    layingWoodenFence: {
        name  : 'Pose de clôture en bois',
        named : 'La pose de clôture en bois de {name}',
    },
    layoutAndBuildOfPoolHouse: {
        name  : 'Aménagement et construction de pool house',
        named : 'L\'aménagement et construction de pool house de {name}',
    },
    leatherRepair: {
        name  : 'Réparation de cuir',
        named : 'La réparation de cuir de {name}',
    },
    levelingAndEarthworks: {
        name  : 'Ragréage et terrassement',
        named : 'Le ragréage et terrassement de {name}',
    },
    levelingGround: {
        name  : 'Nivellement du sol',
        named : 'Le nivellement du sol de {name}',
    },
    lightingDesign: {
        name  : 'Conception d\'éclairage',
        named : 'La conception d\'éclairage de {name}',
    },
    lightingSale: {
        name  : 'Vente de luminaire',
        named : 'La vente de luminaire de {name}',
    },
    ligthingInstallation: {
        name  : 'Installation d\'éclairage',
        named : 'L\'installation d\'éclairage de {name}',
    },
    linoleumFlooringInstallation: {
        name  : 'Installation de sol en linoléum',
        named : 'L\'installation de sol en linoléum de {name}',
    },
    linoleumFlooringSale: {
        name  : 'Vente de sol en linoléum',
        named : 'La vente de sol en linoléum de {name}',
    },
    linoleumFloorRepair: {
        name  : 'Réparation de sol en linoléum',
        named : 'La réparation de sol en linoléum de {name}',
    },
    locksmith: {
        name  : 'Serrurerie',
        named : 'Le projet de serrurerie de {name}',
    },
    lowEnergyBuilding: {
        name  : 'Bâtiment basse consommation',
        named : 'Le bâtiment basse consommation de {name}',
    },
    luxuryRenovation: {
        name  : 'Rénovation de luxe',
        named : 'La rénovation de luxe de {name}',
    },
    masonry: {
        name  : 'Maçonnerie',
        named : 'La maçonnerie de {name}',
    },
    metalFabrication: {
        name  : 'Fabrication de métal',
        named : 'La fabrication de métal de {name}',
    },
    moistureTreatment: {
        name  : 'Traitement de l\'humidité',
        named : 'Le traitement de l\'humidité de {name}',
    },
    mosquitoNetInstallation: {
        name  : 'Pose de moustiquaire',
        named : 'La pose de moustiquaire de {name}',
    },
    mosquitoNetRepair: {
        name  : 'Réparation de moustiquaire',
        named : 'La réparation de moustiquaire de {name}',
    },
    newHouseBuild: {
        name  : 'Construction de maison neuve',
        named : 'La construction de maison neuve de {name}',
    },
    oldFurnitureRestoration: {
        name  : 'Restauration de meuble ancien',
        named : 'La restauration de meuble ancien de {name}',
    },
    outdoorChimneyBuild: {
        name  : 'Construction de cheminée extérieure',
        named : 'La construction de cheminée extérieure de {name}',
    },
    outdoorKitchenBuild: {
        name  : 'Construction de cuisine extérieure',
        named : 'La construction de cuisine extérieure de {name}',
    },
    outdoorKitchenDesign: {
        name  : 'Cuisine extérieure',
        named : 'La cuisine extérieure de {name}',
    },
    outdoorLightingInstallation: {
        name  : 'Installation d\'éclairage extérieur',
        named : 'L\'installation d\'éclairage extérieur de {name}',
    },
    parquetColor: {
        name  : 'Teinte de parquet',
        named : 'La teinte de parquet de {name}',
    },
    parquetInstallation: {
        name  : 'Pose de parquet',
        named : 'La pose de parquet de {name}',
    },
    parquetRenovation: {
        name  : 'Rénovation de parquet',
        named : 'La rénovation de parquet de {name}',
    },
    pelletStoveCleaning: {
        name  : 'Nettoyage de poêle à granulés',
        named : 'Le nettoyage de poêle à granulés de {name}',
    },
    pelletStoveInstallation: {
        name  : 'Installation de poêle à granulés',
        named : 'L\'installation de poêle à granulés de {name}',
    },
    pelletStoveRepair: {
        name  : 'Réparation de poêle à granulés',
        named : 'La réparation de poêle à granulés de {name}',
    },
    pepoleWithReducedMobilityLayout: {
        name  : 'Aménagement pour personne à mobilité réduite',
        named : 'L\'aménagement pour personne à mobilité réduite de {name}',
    },
    pergolaBuild: {
        name  : 'Construction de pergola',
        named : 'La construction de pergola de {name}',
    },
    pergolaConstruction: {
        name  : 'Construction de pergola',
        named : 'La construction de pergola de {name}',
    },
    plastering: {
        name  : 'Plâtrerie',
        named : 'La plâtrerie de {name}',
    },
    plasteringPlaster: {
        name  : 'Pose de crépis',
        named : 'La pose de crépis de {name}',
    },
    plasterRepair: {
        name  : 'Réparation de plâtre',
        named : 'La réparation de plâtre de {name}',
    },
    playroomLayout: {
        name  : 'Aménagement de salle de jeux',
        named : 'L\'aménagement de salle de jeux de {name}',
    },
    plinthInstallation: {
        name  : 'Pose de plinthe',
        named : 'La pose de plinthe de {name}',
    },
    poolAndSpaRepair: {
        name  : 'Réparation de piscine et spa',
        named : 'La réparation de piscine et spa de {name}',
    },
    poolCover: {
        name  : 'Couvre-piscine',
        named : 'Le couvre-piscine de {name}',
    },
    poolDeckDesignAndLayout: {
        name  : 'Conception et aménagement de plage de piscine',
        named : 'La conception et aménagement de plage de piscine de {name}',
    },
    poolDesign: {
        name  : 'Piscine',
        named : 'La piscine de {name}',
    },
    poolMaintenanceAndCleaning: {
        name  : 'Maintenance et nettoyage de piscine',
        named : 'La maintenance et nettoyage de piscine de {name}',
    },
    portalInstallation: {
        name  : 'Installation de portail',
        named : 'L\'installation de portail de {name}',
    },
    portalRepair: {
        name  : 'Réparation de portail',
        named : 'La réparation de portail de {name}',
    },
    portalSale: {
        name  : 'Vente de portail',
        named : 'La vente de portail de {name}',
    },
    prefabricatedHouse: {
        name  : 'Maison préfabriquée',
        named : 'La maison préfabriquée de {name}',
    },
    printedConcrete: {
        name  : 'Béton imprimé',
        named : 'Le béton imprimé de {name}',
    },
    projectedInsulation: {
        name  : 'Isolation projetée',
        named : 'L\'isolation projetée de {name}',
    },
    pVCCarpentry: {
        name  : 'Menuiserie en PVC',
        named : 'La menuiserie en PVC de {name}',
    },
    pVCCladding: {
        name  : 'Bardage en PVC',
        named : 'Le bardage en PVC de {name}',
    },
    railingInstallation: {
        name  : 'Installation de balustrade',
        named : 'L\'installation de balustrade de {name}',
    },
    recessedLightingInstallation: {
        name  : 'Installation d\'éclairage encastré',
        named : 'L\'installation d\'éclairage encastré de {name}',
    },
    replacementOfAboveGroundPoolLiner: {
        name  : 'Remplacement de liner de piscine hors-sol',
        named : 'Le remplacement de liner de piscine hors-sol de {name}',
    },
    residentialWindowTinting: {
        name  : 'Teintage de vitres résidentielles',
        named : 'Le teintage de vitres résidentielles de {name}',
    },
    restaurationAfterWaterDamage: {
        name  : 'Restauration après dégât des eaux',
        named : 'La restauration après dégât des eaux de {name}',
    },
    restorationFurniture: {
        name  : 'Restauration de meuble',
        named : 'La restauration de meuble de {name}',
    },
    restorationOfOldFurniture: {
        name  : 'Restauration de meuble ancien',
        named : 'La restauration de meuble ancien de {name}',
    },
    retainingWallBuild: {
        name  : 'Construction de mur de soutènement',
        named : 'La construction de mur de soutènement de {name}',
    },
    rollerShutterInstallation: {
        name  : 'Pose de volet roulant',
        named : 'La pose de volet roulant de {name}',
    },
    roofCleaningAndDefoaming: {
        name  : 'Nettoyage de toiture et démoussage',
        named : 'Le nettoyage de toiture et démoussage de {name}',
    },
    roofDeckLayout: {
        name  : 'Aménagement de toit-terrasse',
        named : 'L\'aménagement de toit-terrasse de {name}',
    },
    roofFlashingInstallation: {
        name  : 'Installation de solin de toiture',
        named : 'L\'installation de solin de toiture de {name}',
    },
    roofSoffitInstallation: {
        name  : 'Pose de sous-face de toiture',
        named : 'La pose de sous-face de toiture de {name}',
    },
    roofSoffitRepair: {
        name  : 'Réparation de sous-face de toiture',
        named : 'La réparation de sous-face de toiture de {name}',
    },
    roofWaterproofing: {
        name  : 'Étanchéité de toiture',
        named : 'L\'étanchéité de toiture de {name}',
    },
    roofWindowInstallation: {
        name  : 'Pose de fenêtre de toit',
        named : 'La pose de fenêtre de toit de {name}',
    },
    saleAndInstalOfWindaowDoorShutterAndBlind: {
        name  : 'Vendeur et installateur de fenêtres, portes, volets et stores',
        named : 'Le vendeur et installateur de fenêtres, portes, volets et stores de {name}',
    },
    saunaInstallation: {
        name  : 'Installation de sauna',
        named : 'L\'installation de sauna de {name}',
    },
    saunaRepair: {
        name  : 'Réparation de sauna',
        named : 'La réparation de sauna de {name}',
    },
    saunaSale: {
        name  : 'Vente de sauna',
        named : 'La vente de sauna de {name}',
    },
    seamlessBathroom: {
        name  : 'Salle de bain sans joint',
        named : 'La salle de bain sans joint de {name}',
    },
    shelfShoes: {
        name  : 'Étagère à chaussures',
        named : 'L\'étagère à chaussures de {name}',
    },
    shoesCabinet: {
        name  : 'Armoire à chaussures',
        named : 'L\'armoire à chaussures de {name}',
    },
    showerInstallation: {
        name  : 'Installation de douche',
        named : 'L\'installation de douche de {name}',
    },
    shutterInstallation: {
        name  : 'Pose de volet battant',
        named : 'La pose de volet battant de {name}',
    },
    sideTableAndBedsideTable: {
        name  : 'Table d\'appoint et table de chevet',
        named : 'La table d\'appoint et table de chevet de {name}',
    },
    sidingRepair: {
        name  : 'Réparation de bardage',
        named : 'La réparation de bardage de {name}',
    },
    sidingSale: {
        name  : 'Vente de bardage',
        named : 'La vente de bardage de {name}',
    },
    sitePlan: {
        name  : 'Plans de chantier',
        named : 'Les plans de chantier de {name}',
    },
    sitePreparation: {
        name  : 'Préparation de chantier',
        named : 'La préparation de chantier de {name}',
    },
    slindingDoorInstallation: {
        name  : 'Installation de porte coulissante',
        named : 'L\'installation de porte coulissante de {name}',
    },
    slindingDoorRepair: {
        name  : 'Réparation de porte coulissante',
        named : 'La réparation de porte coulissante de {name}',
    },
    solarSwimmingPoolWaterHeater: {
        name  : 'Chauffe-eau solaire de piscine',
        named : 'Le chauffe-eau solaire de piscine de {name}',
    },
    solidParquetSale: {
        name  : 'Vente de parquet massif',
        named : 'La vente de parquet massif de {name}',
    },
    soundAndAcousticInsulation: {
        name  : 'Isolation phonique et acoustique',
        named : 'L\'isolation phonique et acoustique de {name}',
    },
    spaInstallation: {
        name  : 'Installation de spa',
        named : 'L\'installation de spa de {name}',
    },
    spaSale: {
        name  : 'Vente de spa',
        named : 'La vente de spa de {name}',
    },
    staircaseDesign: {
        name  : 'Conception d\'escalier',
        named : 'La conception d\'escalier de {name}',
    },
    stairInstallation: {
        name  : 'Pose d\'escalier',
        named : 'La pose d\'escalier de {name}',
    },
    stairRepair: {
        name  : 'Réparation d\'escalier',
        named : 'La réparation d\'escalier de {name}',
    },
    steelFabrication: {
        name  : 'Fabrication d\'acier',
        named : 'La fabrication d\'acier de {name}',
    },
    stoneFacing: {
        name  : 'Parement en pierre',
        named : 'Le parement en pierre de {name}',
    },
    stoneLaying: {
        name  : 'Pose de pierre',
        named : 'La pose de pierre de {name}',
    },
    stoneSale: {
        name  : 'Vente de pierre',
        named : 'La vente de pierre de {name}',
    },
    stretchCeiing: {
        name  : 'Plafonds tendus',
        named : 'Le plafond tendu de {name}',
    },
    subjectMastery: {
        name  : 'Maîtrise d\'œuvre',
        named : 'La maîtrise d\'œuvre de {name}',
    },
    swimingPoolLightingInstallation: {
        name  : 'Installation d\'éclairage de piscine',
        named : 'L\'installation d\'éclairage de piscine de {name}',
    },
    swimingPoolRenovation: {
        name  : 'Rénovation de piscine',
        named : 'La rénovation de piscine de {name}',
    },
    swimmingPoolBuild: {
        name  : 'Piscine',
        named : 'La piscine de {name}',
    },
    swimmingPoolRenovation: {
        name  : 'Rénovation de piscine',
        named : 'La rénovation de piscine de {name}',
    },
    terraceWood: {
        name  : 'Terrasse en bois sur-mesure',
        named : 'La terrasse en bois sur-mesure de {name}',
        label : 'Apportez une touche chaleureuse et conviviale à votre extérieur',
    },
    terraceStones: {
        name  : 'Terrasse en pierre sur-mesure',
        named : 'La terrasse en pierre sur-mesure de {name}',
        label : ' Apportez une touche chic et intemporelle à votre extérieur',
    },
    textileDecorationCurtainAndVeil: {
        name  : 'Décoration textile, rideaux et voilage',
        named : 'La décoration textile, rideaux et voilage de {name}',
    },
    threeDComputerGraphic: {
        name  : 'Infographie 3D',
        named : 'L\'infographie 3D de {name}',
    },
    tilesSale: {
        name  : 'Vente de carrelage',
        named : 'La vente de carrelage de {name}',
    },
    tiling: {
        name  : 'Pose de carrelage',
        named : 'La pose de carrelage de {name}',
    },
    timberFrameHouse: {
        name  : 'Maison à ossature en bois',
        named : 'La maison à ossature en bois de {name}',
    },
    tinyHouseBuild: {
        name  : 'Construction de Tiny House',
        named : 'La construction de Tiny House de {name}',
    },
    titleRepair: {
        name  : 'Réparation de carrelage',
        named : 'La réparation de carrelage de {name}',
    },
    titlPainting: {
        name  : 'Peinture de carrelage',
        named : 'La peinture de carrelage de {name}',
    },
    toiletRenovation: {
        name  : 'Rénovation de toilettes',
        named : 'La rénovation de toilettes de {name}',
    },
    underlaymentLaying: {
        name  : 'Pose de sous-couche de sol',
        named : 'La pose de sous-couche de sol de {name}',
    },
    upholstery: {
        name  : 'Tapisserie d\'ameublement',
        named : 'La tapisserie d\'ameublement de {name}',
    },
    vanityUnitInstallation: {
        name  : 'Installation de meuble sous lavabo',
        named : 'L\'installation de meuble sous lavabo de {name}',
    },
    vegetalWall: {
        name        : 'Mur végétal',
        named       : 'Le mur végétal  de {name}',
        namedPlural : 'Les {quantity} Mur végétaux de {name}',
        label       : 'La nature au quotidien',
    },
    vinylFenceInstallation: {
        name  : 'Pose de clôture en vinyle',
        named : 'La pose de clôture en vinyle de {name}',
    },
    vinylFlooringInsatllation: {
        name  : 'Pose de sol en vinyle',
        named : 'La pose de sol en vinyle de {name}',
    },
    vinylFlooringSale: {
        name  : 'Vente de sol en vinyle',
        named : 'La vente de sol en vinyle de {name}',
    },
    vinylFloorRepair: {
        name  : 'Réparation de sol en vinyle',
        named : 'La réparation de sol en vinyle de {name}',
    },
    wallHanging: {
        name  : 'Tenture murale',
        named : 'La tenture murale de {name}',
    },
    wallPanel: {
        name  : 'Panneaux muraux',
        named : 'Le panneau mural de {name}',
    },
    wallPaneling: {
        name  : 'Boiserie murale',
        named : 'La boiserie murale de {name}',
    },
    wallpaperLaying: {
        name  : 'Pose de papier-peint',
        named : 'La pose de papier-peint de {name}',
    },
    wardrobeAndClosetSale: {
        name  : 'Vente d\'armoire et de placard',
        named : 'La vente d\'armoire et de placard de {name}',
    },
    washroomDesign: {
        name  : 'Salle d\'eau',
        named : 'La salle d\'eau de {name}',
    },
    washroomRenovation: {
        name  : 'Rénovation de salle d\'eau',
        named : 'La rénovation de salle d\'eau de {name}',
    },
    waterproofing: {
        name  : 'Étanchéité',
        named : 'L\'étanchéité de {name}',
    },
    waterproofingOfWoodenDeck: {
        name  : 'Imperméabilisation de terrasse en bois',
        named : 'L\' imperméabilisation de terrasse en bois de {name}',
    },
    waxedConcrete: {
        name  : 'Béton ciré',
        named : 'Le béton ciré de {name}',
    },
    welding: {
        name  : 'Soudure',
        named : 'La soudure de {name}',
    },
    windowFilm: {
        name  : 'Film pour vitrage',
        named : 'Le film pour vitrage de {name}',
    },
    windowSeal: {
        name  : 'Joint d\'étanchéité de fenêtre',
        named : 'La jointure d\'étanchéité de fenêtre de {name}',
    },
    windowSillInstallation: {
        name  : 'Pose d\'appui de fenêtre',
        named : 'La pose d\'appui de fenêtre de {name}',
    },
    wireMeshInstallation: {
        name  : 'Installation de grillage',
        named : 'L\'installation de grillage de {name}',
    },
    woodDeckCleaning: {
        name  : 'Nettoyage de terrasse en bois',
        named : 'Le nettoyage de terrasse en bois de {name}',
    },
    woodDeckLayout: {
        name  : 'Aménagement de terrasse en bois',
        named : 'L\'aménagement de terrasse en bois de {name}',
    },
    woodDeckMaintenance: {
        name  : 'Entretien de terrasse en bois',
        named : 'L\'entretien de terrasse en bois de {name}',
    },
    woodDeckStain: {
        name  : 'Teinture de terrasse en bois',
        named : 'La teinture de terrasse en bois de {name}',
    },
    woodenDeckBuild: {
        name  : 'Terrasse en bois sur-mesure',
        named : 'La terrasse en bois sur-mesure de {name}',
    },
    woodenDeckLightingInstallation: {
        name  : 'Installation d\'éclairage de terrasse en bois',
        named : 'L\'installation d\'éclairage de terrasse en bois de {name}',
    },
    woodenDeckRepair: {
        name  : 'Réparation de terrasse en bois',
        named : 'La réparation de terrasse en bois de {name}',
    },
    woodenFenceRepair: {
        name  : 'Réparation de clôture en bois',
        named : 'La réparation de clôture en bois de {name}',
    },
    woodenPatioLightingInstallation: {
        name  : 'Installation d\'éclairage de terrasse en bois',
        named : 'L\'installation d\'éclairage de terrasse en bois de {name}',
    },
    woodFinish: {
        name  : 'Finition du bois',
        named : 'La finition du bois de {name}',
    },
    woodStoveInstallation: {
        name  : 'Installation de poêle à bois',
        named : 'L\'installation de poêle à bois de {name}',
    },
    woodStoveRepair: {
        name  : 'Réparation de poêle à bois',
        named : 'La réparation de poêle à bois de {name}',
    },
    wroughtIronFenceInstallation: {
        name  : 'Installation de clôture en fer forgé',
        named : 'L\'installation de clôture en fer forgé de {name}',
    },
    wroughtIronFenceRepair: {
        name  : 'Réparation de clôture en fer forgé',
        named : 'La réparation de clôture en fer forgé de {name}',
    },
    bedroomDesign: {
        name  : 'Chambre',
        named : 'La chambre de {name}',
    },
    stool: {
        name  : 'Tabouret',
        named : 'Le tabouret de {name}',
    },
    palisadeAndClaustra: {
        name  : 'Palissade et claustra',
        named : 'La palissade et claustra de {name}',
    },
    customKitchenCabinet: {
        name  : 'Meuble haut de cuisine sur-mesure',
        named : 'Le meuble haut de cuisine sur-mesure de {name}',
    },
    customKitchenBaseCabinet: {
        name  : 'Meuble bas de cuisine sur-mesure',
        named : 'Le meuble bas de cuisine sur-mesure de {name}',
    },
    kitchenIsland: {
        name  : 'Ilot de cuisine sur-mesure',
        named : 'L\'ilot de cuisine sur-mesure de {name}',
    },
    frenchWindow: {
        name  : 'Porte fenêtre sur-mesure',
        named : 'La porte fenêtre sur-mesure de {name}',
    },
    roofWindow: {
        name  : 'Fenêtre de toit',
        named : 'La fenêtre de toit de {name}',
    },
    slidingBayWindow: {
        name  : 'Baie coulissante',
        named : 'La baie coulissante de {name}',
    },
    frontDoor: {
        name  : "Porte d'entrée sur-mesure",
        named : "La porte d'entrée sur-mesure de {name}",
    },
    serviceDoor: {
        name  : 'Porte de service sur-mesure',
        named : 'La porte de service sur-mesure de {name}',
    },
    shutter: {
        name  : 'Volet sur-mesure',
        named : 'La volet sur-mesure de {name}',
    },
    sunBreeze: {
        name  : 'Brise soleil sur-mesure',
        named : 'Le brise soleil sur-mesure de {name}',
    },
    portal: {
        name  : 'Portail sur-mesure',
        named : 'Le portail sur-mesure de {name}',
    },
    gate: {
        name  : 'Portillon',
        named : 'Le portillon de {name}',
    },
    closing: {
        name  : 'Clôture',
        named : 'La clôture de {name}',
    },
    grillageRigide: {
        name  : 'Grillage rigide',
        named : 'Le grillage rigide de {name}',
    },
    balustrade: {
        name  : 'Balustrade',
        named : 'La balustrade de {name}',
    },
    porchDesignAndLayout: {
        name  : 'Porche sur-mesure',
        named : 'Le porche sur-mesure de {name}',
    },
    gardenShelter: {
        name  : 'Abris de jardin',
        named : 'L\'abris de jardin de {name}',
    },
    tonelle: {
        name  : 'Tonelle',
        named : 'La tonelle de {name}',
    },
    interiorDoor: {
        name  : 'Porte intérieure sur-mesure',
        named : 'La porte intérieure sur-mesure de {name}',
    },
    customStorage: {
        name  : 'Rangement sur-mesure',
        named : 'Le rangement sur-mesure de {name}',
    },
    officePedestals: {
        name  : 'Caissons de bureau',
        named : 'Les caissons de bureau de {name}',
    },
    customCabinet: {
        name  : 'Armoire sur-mesure',
        named : 'L\'armoire sur-mesure de {name}',
    },
    trunk: {
        name  : 'Coffres et malles',
        named : 'Les coffres et malles de {name}',
    },
    chestsOfDrawersAndCabinet: {
        name  : 'Commodes et cabinet',
        named : 'La commodes et cabinet de {name}',
    },
    customKitchenCupboard: {
        name  : 'Placard de cuisine sur-mesure',
        named : 'Le placard de cuisine sur-mesure de {name}',
    },
    storageCupboard: {
        name  : 'Placard et rangement',
        named : 'Le placard et rangement de {name}',
    },
    hallCabinet: {
        name  : "Meuble d'entrée sur-mesure",
        named : "Le meuble d'entrée sur-mesure de {name}",
    },
    coatHanger: {
        name  : 'Porte-manteaux',
        named : 'Le porte-manteaux de {name}',
    },
    nestingCofeeTable: {
        name  : 'Table basse gigogne',
        named : 'La table basse gigogne de {name}',
    },
    nestingTable: {
        name  : 'Table gigogne',
        named : 'La table gigogne de {name}',
    },
    sofaAndpedestalTable: {
        name  : 'Bout de canapé et guéridon',
        named : 'Le bout de canapé et guéridon de {name}',
    },
    pouf: {
        name  : 'Pouf',
        named : 'Le pouf de {name}',
    },
    sofa: {
        name  : 'Canapé',
        named : 'Le canapé de {name}',
    },
    endOfBed: {
        name  : 'Bout de lit',
        named : 'Le bout de lit de {name}',
    },
    barChair: {
        name  : 'Chaise de bar',
        named : 'La chaise de bar de {name}',
    },
    barStool: {
        name  : 'Tabouret de bar',
        named : 'Le tabouret de bar de {name}',
    },
    chair: {
        name  : 'Chaise',
        named : 'La chaise de {name}',
    },
    armchair: {
        name  : 'Fauteuil',
        named : 'Le fauteuil de {name}',
    },
    bedhead: {
        name  : 'Tête de lit',
        named : 'La tête de lit de {name}',
    },
    light: {
        name  : 'Luminaires',
        named : 'Les luminaires de {name}',
    },
    greenWall: {
        name  : 'Mur végétal',
        named : 'Le mur végétal de {name}',
    },
    showerDoor: {
        name  : 'Porte de douche',
        named : 'La porte de douche de {name}',
    },
    receptionDesk: {
        name  : "Banque d'accueil",
        named : "La banque d'accueil de {name}",
    },
    salesCounter: {
        name  : 'Comptoir de vente',
        named : 'Le comptoir de vente de {name}',
    },
    showcase: {
        name  : 'Vitrine',
        named : 'La vitrine de {name}',
    },
    salesTable: {
        name  : 'Table de vente',
        named : 'La table de vente de {name}',
    },
    shelving: {
        name  : 'Rayonnage',
        named : 'Le rayonnage de {name}',
    },
    row: {
        name  : 'Enfilade',
        named : 'L\'enfilade de {name}',
    },
    teaches: {
        name  : 'Enseigne',
        named : 'L\'enseigne de {name}',
    },
    tableMeeting: {
        name  : 'Table de réunion',
        named : 'La table de réunion de {name}',
    },
    sunbath: {
        name  : 'Bain de soleil',
        named : 'Le bain de soleil de {name}',
    },
    nightstand: {
        name  : 'Table de chevet',
        named : 'La table de chevet de {name}',
    },
    storageCabinet: {
        name  : 'Meuble de rangement',
        named : 'Le meuble de rangement de {name}',
    },
    ground: {
        name  : 'Sol',
        named : 'Le sol de {name}',
    },
    eatStandingUp: {
        name  : 'Mange debout',
        named : 'Le mange debout de {name}',
    },
    phoneBox: {
        name  : 'Cabine acoustique et phone box',
        named : 'La cabine acoustique et phone box de {name}',
    },
    kitchenBack: {
        name  : 'Arrière cuisine',
        named : 'L\' arrière cuisine de {name}',
    },
};

export default formsTranslations;
