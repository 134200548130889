import { fullHeight, spaceHuge, spaceRegular } from '../styles/spaces';
import { lightGreyColor, lightMarineColor, pureWhite } from '../styles/colors';
import { IAppModalCardProps, IAppModalContainerProps } from './types';

export const modalContainerStyle = ({
    full,
}: IAppModalContainerProps) => ({
    display        : 'flex',
    flexDirection  : 'column' as 'column',
    height         : fullHeight,
    justifyContent : 'center' as 'center',
    outline        : 'none',
    maxWidth       : full ? 'none' : undefined,
    width          : full ? '100%' : undefined,
    boxSizing      : 'border-box' as 'border-box',
});

export const iconStyle = {
    '& .MuiSvgIcon-root': {
        borderRadius : '50%',
        transform    : 'scale(1.22)',
    },

    '& .MuiIconButton-label': {
        backgroundColor : pureWhite,
        borderRadius    : '50%',
    },
};

export const modalCrossIconStyle = {
    ...iconStyle,
    position : 'absolute' as 'absolute',
    top      : 5,
    right    : 5,
    zIndex   : 5,
    color    : lightMarineColor,
};

export const modalBackIconStyle = {
    position : 'absolute' as 'absolute',
    top      : 0,
    left     : 0,
    zIndex   : 5,
    color    : lightMarineColor,
};

export const modalCardStyle = ({
    full,
    gutterBottom,
}: IAppModalCardProps) => ({
    position        : 'relative' as 'relative',
    justifyContent  : 'center' as 'center',
    alignItems      : 'center' as 'center',
    maxHeight       : 'calc(var(--vh, 1vh) * 100)',
    overflowY       : 'auto' as 'auto',
    backgroundColor : lightGreyColor,
    paddingTop      : spaceHuge,
    paddingBottom   : gutterBottom ? spaceHuge : spaceRegular,
    paddingLeft     : spaceRegular,
    paddingRight    : spaceRegular,
    height          : full ? fullHeight : undefined,

    '@media screen and (max-width: 600px)': {
        height         : fullHeight,
        justifyContent : 'flex-start' as 'flex-start',
        alignItems     : 'center' as 'center',
        display        : 'flex' as 'flex',
        flexDirection  : 'column' as 'column',

        '& > *': {
            flexGrow: 1,
        },
    },
});
