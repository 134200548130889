import {
    spaceLarge,
    radiusNano,
    spaceHuge,
    sizeWide,
    sizeLarge,
    sizeMedium,
    spaceRegular,
    fontMedium,
    sizeSmall,
} from '@ui/styles/spaces';
import { fontBody, fontHeadings } from '@ui/styles/typography';
import { pureWhite } from '@ui/styles/colors';
import holdBrowser from '../../../assets/images/unsuportedBrowserslilm.png';

export const containerWrapperStyle = {
    display        : 'flex',
    flexDirection  : 'column' as 'column',
    alignItems     : 'center' as 'center',
    justifyContent : 'space-between' as 'space-between',
    boxSizing      : 'border-box' as 'border-box',
};

export const logoLinkStyle = {
    flexGrow       : 0.3,
    display        : 'flex' as 'flex',
    flexDirection  : 'column' as 'column',
    justifyContent : 'flex-end' as 'flex-end',
    marginBottom   : spaceLarge,
    width          : 60,

    '& img': {
        objectFit      : 'contain' as 'contain',
        objectPosition : 'left top',
        width          : '100%',
    },
};

export const titleStyle = {
    flexGrow           : 1,
    padding            : spaceLarge,
    paddingTop         : spaceHuge,
    paddingBottom      : sizeLarge,
    textAlign          : 'center' as 'center',
    fontFamily         : fontHeadings,
    color              : pureWhite,
    fontSize           : '3vh',
    backgroundImage    : `url(${holdBrowser})`,
    backgroundSize     : 'cover' as 'cover',
    backgroundPosition : 'center bottom',
    width              : '100%',
    boxSizing          : 'border-box' as 'border-box',
    borderRadius       : radiusNano,

    '@media screen and (max-height: 600px)': {
        paddingBottom : sizeSmall,
        paddingTop    : spaceLarge,
    },

    '@media screen and (min-width: 600px)': {
        paddingTop    : sizeMedium,
        paddingBottom : sizeWide,
    },
};

export const contentStyle = {
    display        : 'flex' as 'flex',
    justifyContent : 'flex-start' as 'flex-start',
    alignItems     : 'center' as 'center',
    flexDirection  : 'column' as 'column',
    flexGrow       : 0.5,
    width          : '100%',
    boxSizing      : 'border-box' as 'border-box',
    textAlign      : 'center' as 'center',
    fontFamily     : fontBody,
    paddingTop     : spaceLarge,
    paddingBottom  : spaceHuge,
    paddingLeft    : spaceRegular,
    paddingRight   : spaceRegular,
    fontSize       : fontMedium,

    '@media screen and (max-height: 600px)': {
        paddingBottom : 0,
        paddingTop    : spaceLarge,
    },

    '@media screen and (max-width: 420px)': {
        fontSize: '5vw',
    },
};

export const confirmButtonStyle = {
    marginTop: spaceLarge,
};
