export enum RequestStatusId {
    Pending = 'pending',
    Declined = 'declined',
    Accepted = 'accepted',
    Estimated = 'estimated',
    DeclinedByUser = 'declinedByUser',
    AcceptedByUser = 'acceptedByUser',
    QuoteSent = 'quoteSent',
    QuoteAcceptedByUser = 'quoteAcceptedByUser',
    QuoteDeclinedByUser = 'quoteDeclinedByUser',
    DepositPaid = 'depositPaid',
    ProjectBilled = 'projectBilled',
    ProjectDelivered = 'projectDelivered',
    ProjectFinalized = 'projectFinalized',
    ProjectCanceled = 'projectCanceled',
    Canceled = 'canceled',
}

export enum RequestDeclineArtisanReasonId {
    BudgetTooLow = 'budgetTooLow',
    CannotWorkTrayMaterial = 'cannotWorkTrayMaterial',
    CannotWorkExtensions = 'cannotWorkExtensions',
    CannotWorkLegsShape = 'cannotWorkLegsShape',
    ProjectTooComplex = 'ProjectTooComplex',
    CannotWorkDimensions = 'cannotWorkDimensions',
    CannotMakeWhole = 'cannotMakeWhole',
    TooFar = 'tooFar',
    NotAvailable = 'notAvailable',
    DeliveryTooShort = 'deliveryTooShort',
    CannotWorkStyle = 'cannotWorkStyle',
    CannotMakeSeries = 'cannotMakeSeries',
    Other = 'other',
}

export enum RequestDeclineUserReasonId {
    AlreadyFoundAtLILM = 'alreadyFoundAtLILM',
    AlreadyFoundElsewhere = 'alreadyFoundElsewhere',
    BudgetTooHigh = 'budgetTooHigh',
    DeliveryTooLate = 'deliveryTooLate',
    NoLongerRelevant = 'noLongerRelevant',
    Other = 'other',
}

export enum RequestMatchingSourceId {
    Auto = 'auto',
    Manual = 'manual',
    MarketPlace = 'marketPlace',
    User = 'user',
    External = 'external',
}

export enum RequestMessageSource {
    Client = 'client',
    Artisan = 'artisan',
}
