import { SnackbarContentProps } from '@material-ui/core/SnackbarContent';

export enum ISnackbarContentMode {
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Info = 'info',
    Congratulation = 'congratulation',
    Greetings = 'greetings',
}

export interface ISnackbarContentProperties extends SnackbarContentProps {
  className?: string;
  onClose?: () => void;
  mode: ISnackbarContentMode;
}
