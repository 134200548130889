import CatchmentAreaId from '@core/constants/CatchmentArea';

const DeptCodeFR: {[key in CatchmentAreaId] : { label:string, withPreposition:string }} = {
    'FR-01': {
        label           : 'Ain',
        withPreposition : 'dans l\'Ain',
    },
    'FR-02': {
        label           : 'Aisne',
        withPreposition : 'dans l\'Aisne',
    },
    'FR-03': {
        label           : 'Allier',
        withPreposition : 'dans l\'Allier',
    },
    'FR-04': {
        label           : 'Alpes-de-Haute-Provence',
        withPreposition : 'dans les Alpes-de-Haute-Provence',
    },
    'FR-05': {
        label           : 'Hautes-Alpes',
        withPreposition : 'dans les Hautes-Alpes',
    },
    'FR-06': {
        label           : 'Alpes-Maritimes',
        withPreposition : 'dans les Alpes-Maritimes',
    },
    'FR-07': {
        label           : 'Ardèche',
        withPreposition : 'en Ardèche',
    },
    'FR-08': {
        label           : 'Ardennes',
        withPreposition : 'dans les Ardennes',
    },
    'FR-09': {
        label           : 'Ariège',
        withPreposition : 'en Ariège',
    },
    'FR-10': {
        label           : 'Aube',
        withPreposition : 'dans l\'Aube',
    },
    'FR-11': {
        label           : 'Aude',
        withPreposition : 'dans l\'Aude',
    },
    'FR-12': {
        label           : 'Aveyron',
        withPreposition : 'en Aveyron',
    },
    'FR-13': {
        label           : 'Bouches-du-Rhône',
        withPreposition : 'dans les Bouches-du-Rhône',
    },
    'FR-14': {
        label           : 'Calvados',
        withPreposition : 'dans le Calvados',
    },
    'FR-15': {
        label           : 'Cantal',
        withPreposition : 'dans le Cantal',
    },
    'FR-16': {
        label           : 'Charente',
        withPreposition : 'en Charente',
    },
    'FR-17': {
        label           : 'Charente-Maritime',
        withPreposition : 'en Charente-Maritime',
    },
    'FR-18': {
        label           : 'Cher',
        withPreposition : 'dans le Cher',
    },
    'FR-19': {
        label           : 'Corrèze',
        withPreposition : 'en Corrèze',
    },
    'FR-21': {
        label           : 'Côte-d\'or',
        withPreposition : 'en Côte-d\'Or',
    },
    'FR-22': {
        label           : 'Côtes-d\'armor',
        withPreposition : 'dans les Côtes d\'Armor',
    },
    'FR-23': {
        label           : 'Creuse',
        withPreposition : 'dans la Creuse',
    },
    'FR-24': {
        label           : 'Dordogne',
        withPreposition : 'en Dordogne',
    },
    'FR-25': {
        label           : 'Doubs',
        withPreposition : 'dans le Doubs',
    },
    'FR-26': {
        label           : 'Drôme',
        withPreposition : 'dans la Drôme',
    },
    'FR-27': {
        label           : 'Eure',
        withPreposition : 'dans l\'Eure',
    },
    'FR-28': {
        label           : 'Eure-et-Loir',
        withPreposition : 'dans l\'Eure-et-Loir',
    },
    'FR-29': {
        label           : 'Finistère',
        withPreposition : 'dans le Finistère',
    },
    'FR-2A': {
        label           : 'Corse-du-Sud',
        withPreposition : 'en Corse-du-Sud',
    },
    'FR-2B': {
        label           : 'Haute-Corse',
        withPreposition : 'en Haute-Corse',
    },
    'FR-30': {
        label           : 'Gard',
        withPreposition : 'dans le Gard',
    },
    'FR-31': {
        label           : 'Haute-Garonne',
        withPreposition : 'dans la Haute-Garonne',
    },
    'FR-32': {
        label           : 'Gers',
        withPreposition : 'dans le Gers',
    },
    'FR-33': {
        label           : 'Gironde',
        withPreposition : 'dans la Gironde',
    },
    'FR-34': {
        label           : 'Hérault',
        withPreposition : 'dans l\'Hérault',
    },
    'FR-35': {
        label           : 'Ille-et-Vilaine',
        withPreposition : 'en Ille-et-Vilaine',
    },
    'FR-36': {
        label           : 'Indre',
        withPreposition : 'en Indre',
    },
    'FR-37': {
        label           : 'Indre-et-Loire',
        withPreposition : 'en Indre-et-Loire',
    },
    'FR-38': {
        label           : 'Isère',
        withPreposition : 'en Isère',
    },
    'FR-39': {
        label           : 'Jura',
        withPreposition : 'dans le Jura',
    },
    'FR-40': {
        label           : 'Landes',
        withPreposition : 'dans les Landes',
    },
    'FR-41': {
        label           : 'Loir-et-Cher',
        withPreposition : 'dans le Loir-et-Cher',
    },
    'FR-42': {
        label           : 'Loire',
        withPreposition : 'dans la Loire',
    },
    'FR-43': {
        label           : 'Haute-Loire',
        withPreposition : 'dans la Haute-Loire',
    },
    'FR-44': {
        label           : 'Loire-Atlantique',
        withPreposition : 'dans la Loire-Atlantique',
    },
    'FR-45': {
        label           : 'Loiret',
        withPreposition : 'dans le Loiret',
    },
    'FR-46': {
        label           : 'Lot',
        withPreposition : 'dans le Lot',
    },
    'FR-47': {
        label           : 'Lot-et-Garonne',
        withPreposition : 'dans le Lot-et-Garonne',
    },
    'FR-48': {
        label           : 'Lozère',
        withPreposition : 'en Lozère',
    },
    'FR-49': {
        label           : 'Maine-et-Loire',
        withPreposition : 'dans la Maine-et-Loire',
    },
    'FR-50': {
        label           : 'Manche',
        withPreposition : 'dans la Manche',
    },
    'FR-51': {
        label           : 'Marne',
        withPreposition : 'dans la Marne',
    },
    'FR-52': {
        label           : 'Haute-Marne',
        withPreposition : 'dans la Haute-Marne',
    },
    'FR-53': {
        label           : 'Mayenne',
        withPreposition : 'en Mayenne',
    },
    'FR-54': {
        label           : 'Meurthe-et-Moselle',
        withPreposition : 'en Meurthe-et-Moselle',
    },
    'FR-55': {
        label           : 'Meuse',
        withPreposition : 'dans la Meuse',
    },
    'FR-56': {
        label           : 'Morbihan',
        withPreposition : 'dans le Morbihan',
    },
    'FR-57': {
        label           : 'Moselle',
        withPreposition : 'en Moselle',
    },
    'FR-58': {
        label           : 'Nièvre',
        withPreposition : 'dans la Nièvre',
    },
    'FR-59': {
        label           : 'Nord',
        withPreposition : 'dans le Nord',
    },
    'FR-60': {
        label           : 'Oise',
        withPreposition : 'dans l\'Oise',
    },
    'FR-61': {
        label           : 'Orne',
        withPreposition : 'dans l\'Orne',
    },
    'FR-62': {
        label           : 'Pas-de-Calais',
        withPreposition : 'dans le Pas-de-Calais',
    },
    'FR-63': {
        label           : 'Puy-de-Dôme',
        withPreposition : 'dans le Puy-de-Dôme',
    },
    'FR-64': {
        label           : 'Pyrénées-Atlantiques',
        withPreposition : 'dans les Pyrénées-Atlantiques',
    },
    'FR-65': {
        label           : 'Hautes-Pyrénées',
        withPreposition : 'dans les Hautes-Pyrénées',
    },
    'FR-66': {
        label           : 'Pyrénées-Orientales',
        withPreposition : 'dans les Pyrénées-Orientales',
    },
    'FR-67': {
        label           : 'Bas-Rhin',
        withPreposition : 'dans le Bas-Rhin',
    },
    'FR-68': {
        label           : 'Haut-Rhin',
        withPreposition : 'dans le Haut-Rhin',
    },
    'FR-69': {
        label           : 'Rhône',
        withPreposition : 'dans le Rhône',
    },
    'FR-70': {
        label           : 'Haute-Saône',
        withPreposition : 'en Haute-Saône',
    },
    'FR-71': {
        label           : 'Saône-et-Loire',
        withPreposition : 'dans la Saône-et-Loire',
    },
    'FR-72': {
        label           : 'Sarthe',
        withPreposition : 'dans la Sarthe',
    },
    'FR-73': {
        label           : 'Savoie',
        withPreposition : 'dans la Savoie',
    },
    'FR-74': {
        label           : 'Haute-Savoie',
        withPreposition : 'dans la Haute-Savoie',
    },
    'FR-75': {
        label           : 'Paris',
        withPreposition : 'à Paris',
    },
    'FR-76': {
        label           : 'Seine-Maritime',
        withPreposition : 'dans la Seine-Maritime',
    },
    'FR-77': {
        label           : 'Seine-et-Marne',
        withPreposition : 'dans la Seine-et-Marne',
    },
    'FR-78': {
        label           : 'Yvelines',
        withPreposition : 'dans les Yvelines',
    },
    'FR-79': {
        label           : 'Deux-Sèvres',
        withPreposition : 'dans les Deux-Sèvres',
    },
    'FR-80': {
        label           : 'Somme',
        withPreposition : 'dans la Somme',
    },
    'FR-81': {
        label           : 'Tarn',
        withPreposition : 'dans le Tarn',
    },
    'FR-82': {
        label           : 'Tarn-et-Garonne',
        withPreposition : 'dans le Tarn-et-Garonne',
    },
    'FR-83': {
        label           : 'Var',
        withPreposition : 'dans le Var',
    },
    'FR-84': {
        label           : 'Vaucluse',
        withPreposition : 'dans le Vaucluse',
    },
    'FR-85': {
        label           : 'Vendée',
        withPreposition : 'dans la Vendée',
    },
    'FR-86': {
        label           : 'Vienne',
        withPreposition : 'dans la Vienne',
    },
    'FR-87': {
        label           : 'Haute-Vienne',
        withPreposition : 'dans la Haute-Vienne',
    },
    'FR-88': {
        label           : 'Vosges',
        withPreposition : 'dans les Vosges',
    },
    'FR-89': {
        label           : 'Yonne',
        withPreposition : 'dans l\'Yonne',
    },
    'FR-90': {
        label           : 'Territoire de Belfort',
        withPreposition : 'dans le Territoire de Belfort',
    },
    'FR-91': {
        label           : 'Essonne',
        withPreposition : 'dans l\'Essonne',
    },
    'FR-92': {
        label           : 'Hauts-de-Seine',
        withPreposition : 'dans les Hauts-de-Seine',
    },
    'FR-93': {
        label           : 'Seine-Saint-Denis',
        withPreposition : 'en Seine-St-Denis',
    },
    'FR-94': {
        label           : 'Val-de-Marne',
        withPreposition : 'dans le Val-de-Marne',
    },
    'FR-95': {
        label           : 'Val-D\'Oise',
        withPreposition : 'dans le Val-D\'Oise',
    },
    'FR-98': {
        label           : 'Monaco',
        withPreposition : 'à',
    },
    'BE-VAN': {
        label           : 'Anvers',
        withPreposition : 'à Anvers',
    },
    'BE-BBR': {
        label           : 'Région de Bruxelles-Capitale',
        withPreposition : 'en Région de Bruxelles-Capitale',
    },
    'BE-WHT': {
        label           : 'Hainaut',
        withPreposition : 'dans le Hainaut',
    },
    'BE-WLG': {
        label           : 'Liège',
        withPreposition : 'à Liège',
    },
    'BE-VLI': {
        label           : 'Limbourg',
        withPreposition : 'dans le Limbourg',
    },
    'BE-WLX': {
        label           : 'Luxembourg',
        withPreposition : 'dans le Luxembourg',
    },
    'BE-WNA': {
        label           : 'Namur',
        withPreposition : 'à Namur',
    },
    'BE-VOV': {
        label           : 'Flandre orientale',
        withPreposition : 'en Flandre orientale',
    },
    'BE-VWV': {
        label           : 'Flandre occidentale',
        withPreposition : 'en Flandre occidentale',
    },
    'BE-WBR': {
        label           : 'Brabant wallon',
        withPreposition : 'dans le Brabant wallon',
    },
    'BE-VBR': {
        label           : 'Brabant flamand',
        withPreposition : 'dans le Brabant flamand',
    },
    'CH-AG': {
        label           : 'Argovie',
        withPreposition : 'dans l\'Argovie',
    },
    'CH-AI': {
        label           : 'Appenzell Rhodes-Intérieures',
        withPreposition : 'dans l\'Appenzell Rhodes-Intérieures',
    },
    'CH-AR': {
        label           : 'Appenzell Rhodes-Extérieures',
        withPreposition : 'dans l\'Appenzell Rhodes-Extérieures',
    },
    'CH-BE': {
        label           : 'Berne',
        withPreposition : 'à Berne',
    },
    'CH-BL': {
        label           : 'Bâle-Campagne',
        withPreposition : 'à Bâle-Campagne',
    },
    'CH-BS': {
        label           : 'Bâle-Ville',
        withPreposition : 'à Bâle-Ville',
    },
    'CH-FR': {
        label           : 'Fribourg',
        withPreposition : 'à Fribourg',
    },
    'CH-GE': {
        label           : 'Genève',
        withPreposition : 'à Genève',
    },
    'CH-GL': {
        label           : 'Glaris',
        withPreposition : 'à Glaris',
    },
    'CH-GR': {
        label           : 'Grisons',
        withPreposition : 'à Grisons',
    },
    'CH-JU': {
        label           : 'Jura',
        withPreposition : 'dans le Jura',
    },
    'CH-LU': {
        label           : 'Lucerne',
        withPreposition : 'à Lucerne',
    },
    'CH-NE': {
        label           : 'Neuchâtel',
        withPreposition : 'à Neuchâtel',
    },
    'CH-NW': {
        label           : 'Nidwald',
        withPreposition : 'dans le Nidwald',
    },
    'CH-OW': {
        label           : 'Obwald',
        withPreposition : 'dans l\'Obwald',
    },
    'CH-SG': {
        label           : 'Saint-Gall',
        withPreposition : 'à Saint-Gall',
    },
    'CH-SH': {
        label           : 'Schaffhouse',
        withPreposition : 'à Schaffhouse',
    },
    'CH-SO': {
        label           : 'Soleure',
        withPreposition : 'à Soleure',
    },
    'CH-SZ': {
        label           : 'Schwytz',
        withPreposition : 'à Schwytz',
    },
    'CH-TG': {
        label           : 'Thurgovie',
        withPreposition : 'en Thurgovie',
    },
    'CH-TI': {
        label           : 'Tessin',
        withPreposition : 'dans le Tessin',
    },
    'CH-UR': {
        label           : 'Uri',
        withPreposition : 'dans l\'Uri',
    },
    'CH-VD': {
        label           : 'Vaud',
        withPreposition : 'dans le Vaud',
    },
    'CH-VS': {
        label           : 'Valais',
        withPreposition : 'dans le Valais',
    },
    'CH-ZG': {
        label           : 'Zoug',
        withPreposition : 'dans le Zoug',
    },
    'CH-ZH': {
        label           : 'Zurich',
        withPreposition : 'à Zurich',
    },
    LU: {
        label           : 'Luxembourg',
        withPreposition : 'au Luxembourg',
    },
};

export default DeptCodeFR;
