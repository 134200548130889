export enum FirebaseErrorId {
    AuthCredentialAlreadyInUse = 'auth/credential-already-in-use',
    AuthEmailAlreadyInUse = 'auth/email-already-in-use',
    AuthInvalidActionCode = 'auth/invalid-action-code',
    AuthInvalidContinueUri = 'auth/invalid-continue-uri',
    AuthInvalidCredentials = 'auth/invalid-credentials',
    AuthInvalidEmail = 'auth/invalid-email',
    AuthInvalidPassword = 'auth/invalid-password',
    AuthInvalidPhoneNumber = 'auth/invalid-phone-number',
    AuthInvalidVerificationCode = 'auth/invalid-verification-code',
    AuthOperationNotAllowed = 'auth/operation-not-allowed',
    AuthPhoneNumberAlreadyExists= 'auth/phone-number-already-exists',
    AuthProviderAlreadyLinked = 'auth/provider-already-linked',
    AuthTooManyRequests = 'auth/too-many-requests',
    AuthUserDisabled = 'auth/user-disabled',
    AuthUserNotFound = 'auth/user-not-found',
    AuthWeakPassword = 'auth/weak-password',
    AuthWrongPassword = 'auth/wrong-password',
    AuthCodeExpired = 'auth/code-expired',

    PermissionDenied = 'permission-denied',
    Aborted = 'aborted',
}

export default FirebaseErrorId;
