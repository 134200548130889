import { ServiceId } from '../constants/ServiceId';

const ServicesFR: { [key in ServiceId]: string } = {
    default                                   : 'Autre',
    table                                     : 'Table à manger, table basse, plateau, pieds',
    deskAndMeetingTable                       : 'Bureau et table de réunion',
    atticAndCarpenter                         : 'Combles, grenier et charpentier',
    bathroomAndSanitaryInstaller              : 'Installateur de salle de bain et sanitaires',
    buildingCraftsman                         : 'Artisan du batiment',
    chrLayout                                 : 'Agencement CHR',
    claddingAndFacade                         : 'Bardage et façade',
    cupboardAndStorage                        : 'Placards et rangements',
    customFurniture                           : 'Meuble sur-mesure',
    customizedFittingWardrobeAndLibrary       : 'Aménagements sur-mesure, dressings et bibliothèques',
    deckPatiosAndVerandas                     : 'Terrasses, patios et vérandas',
    drivewayPaverAndConcrete                  : 'Allées, pavés et béton',
    fireplacesAndStove                        : 'Cheminées et poêles',
    furnitureAndDecorativeAccessory           : 'Meubles et accessoires déco',
    gardenSupply                              : 'Fournitures de jardin',
    homeBuilderAndDeveloper                   : 'Constructeur de maison et promoteur',
    ironwork                                  : 'Ferronerie',
    kitchenDesigner                           : 'Cuisiniste et concepteur de cuisine',
    lampsAndLight                             : 'Lampes et Luminaires',
    officeLayout                              : 'Agencement bureaux',
    parquetFloor                              : 'Parquet et revêtement de sol',
    poolAndSpaMaintenance                     : 'Entretien piscine et spa',
    poolSpaAndSauna                           : 'Piscine, spa et sauna',
    saleAndInstallationOfFenceAndGate         : 'Vente et installatation de clôtures et portails',
    spaSeller                                 : 'Vendeur de spa',
    tilingAndFloorCovering                    : 'Carrelage et pose de revêtement de sol',
    upholstererUpholsteryAndCarpet            : 'Tapissier, tissu d\'ameublement et tapis',
    windowDoorShutterAndBlind                 : 'Fenêtres, portes, volets et stores',
    wineCellarDesignAndLayout                 : 'Cave à vin sur-mesure',
    doorWindowShutterBlind                    : 'Porte, fenêtre, volet, store',
    fenceAndGate                              : 'Clôture et portail',
    pergolaVerandaAndShelters                 : 'Pergola, véranda et abris',
    staircaseAndGuardrail                     : 'Escalier et garde-corps',
    interiorDoorGlassRoofAndPartition         : 'Verrière, claustra et porte intérieure',
    customTerrace                             : 'Terrasse',
    storageUnitAndCupboard                    : 'Meuble de rangement et placard',
    tableAndDesk                              : 'Table et bureau',
    seated                                    : 'Assises',
    bedroomAndBedding                         : 'Chambre et literie',
    frontDoorAndService                       : "Porte d'entrée et porte de service",
    kitchen                                   : 'Cuisine',
    stoneAndWorktop                           : 'Pierre et plan de travail',
    decorationAndLighting                     : 'Décoration et luminaire',
    mirroringAndGlazing                       : 'Miroiterie et vitrerie',
    shopLayout                                : 'Agencement boutique',
    layoutAndFurnitureCofeeHotelAndRestaurant : 'Agencement et mobilier café, hôtel et restaurant',
    workspaceLayoutAndFurniture               : "Agencement et mobilier d'espace de travail",
    interiorAndOutdoorLayoutAndFurniture      : 'Agencement et mobilier intérieur et extérieur',
    counterBar                                : 'Comptoir de bar',
    consoleAndDressingTable                   : 'Console et coiffeuse',
};

export default ServicesFR;
