import { IFormFieldKey } from '../interfaces/Form';

export const fieldsChatFR: {
    [key in IFormFieldKey]: any
} = {
    portfolioData : undefined,
    description   : {
        title : 'Pouvez-vous me décrire votre projet ?',
        label : 'Description',
    },
    trayMaterial: {
        question: {
            default               : 'Quel matériau pour le plateau ?',
            tableScandinavian     : 'Quel matériau pour le plateau de votre table scandinave ?',
            tableLiveEdge         : 'Quel matériau pour le plateau de votre table "Live-Edge" ?',
            tableRiverTable       : 'Quel matériau pour le plateau de votre "River Table" ?',
            tableFarm             : 'Quel matériau pour le plateau de votre table de ferme ?',
            tableIndustrial       : 'Quel matériau pour le plateau de votre table industrielle ?',
            tableConcrete         : 'Quel matériau pour le plateau de votre table en béton ?',
            tableMarbleAndStone   : 'Quel matériau pour le plateau de votre table ?',
            tableMikado           : 'Quel matériau pour le plateau de votre table Mikado ?',
            tableVintage          : 'Quel matériau pour le plateau de votre table vintage ?',
            table                 : 'Quel matériau pour le plateau de votre table personnalisée ?',
            tray                  : 'Quel matériau pour votre plateau ?',
            meetingTable          : 'Quel matériau pour le plateau de votre table de réunion ?',
            desk                  : 'Quel matériau pour le plateau de votre bureau ?',
            deskExecutive         : 'Quel matériau pour le plateau de votre bureau ?',
            lowTable              : 'Quel matériau pour votre table basse ?',
            lowTableNoLegs        : 'Quel matériau pour votre table basse ?',
            lowTableNesting       : 'Quel matériau pour votre table basse gigogne ?',
            lowTableNestingNoLegs : 'Quel matériau pour votre table basse gigogne ?',
        },
        label: 'Matériau',
    },
    windowMaterial: {
        question: {
            default: 'Quel type de verre ?',
        },
        label: 'Verre',
    },
    legsMaterial: {
        question: {
            default         : 'Quel matériau pour les pieds ?',
            lowTable        : 'Quel matériau pour les pieds de votre table basse ?',
            lowTableNesting : 'Quel matériau pour les pieds de votre table basse gigogne ?',
        },
        label: 'Matériau des pieds',
    },
    wineCellarMaterial: {
        question: {
            default: 'Quel matériau souhaitez-vous pour la structure de votre cave à vin ?',
        },
        label: 'Matériau structure',
    },
    wineCellarShelfMaterial: {
        question: {
            default: 'Quel matériau pour vos clayettes ?',
        },
        label: 'Matériau clayettes',
    },
    wineCellarDegrees: {
        question: {
            default: 'Connaissez-vous la température ambiante de la pièce de destination de votre cave à vin ?',
        },
        label             : 'Température',
        dimensionQuestion : 'Quelle est la température ambiante de la pièce de destination de votre cave à vin ?',
    },
    wineCellarHumidity: {
        question: {
            default: 'Connaissez-vous le taux d\'humidité de la pièce de destination de votre cave à vin ?',
        },
        label             : 'Taux d\'humidité',
        dimensionQuestion : 'Quel est le taux d\'humidité de la pièce de destination de votre cave à vin ?',
    },
    wineCellarSquareMeter: {
        question: {
            default: 'Connaissez-vous la surface en m² de la piéce de destination de votre cave à vin ?',
        },
        label             : 'Surface en m²',
        dimensionQuestion : 'Quelle est la surface en m² de la piéce de destination de votre cave à vin ?',
    },
    wineCellarLightingExpose: {
        title : 'Votre cave à vin est-elle exposée à de la lumière naturelle ?',
        label : 'Luminosité',
    },
    wineCellarShape: {
        title : 'Quel forme souhaitez-vous pour votre cave à vin ?',
        label : 'Forme',
    },
    material: {
        question: {
            default               : 'Quel matériau voudriez-vous ?',
            staircaseStraight     : 'Quel matériau pour vos marches d\'éscalier ?',
            staircaseHalfTurn     : 'Quel matériau pour vos marches d\'éscalier ?',
            staircaseQuarterTurn  : 'Quel matériau pour vos marches d\'éscalier ?',
            staircaseSpiralRound  : 'Quel matériau pour vos marches d\'éscalier ?',
            staircaseSpiralSquare : 'Quel matériau pour vos marches d\'éscalier ?',
            staircaseTurnLanding  : 'Quel matériau pour vos marches d\'éscalier ?',
            highWind              : 'Quel matériau pour le toit de votre marquise ?',
            customBlindOutdoor    : 'Quel matériau pour la toile ?',
            shoesCabinet          : 'Quel matériau pour votre meuble à chaussure ?',
            mirror                : 'Quel type de verre pour votre miroir ?',
            closetDoor            : 'Quel matériau pour le remplissage de votre porte de placard ?',
            portal                : 'Quel matériau pour votre portail ?',
        },
        label: 'Matériau',
    },
    frameMaterial: {
        question: {
            default : 'Quel matériau pour la structure ?',
            veranda : 'Quel matériau pour la structure de votre veranda ?',
        },
        label: 'Structure',
    },
    wineCellarPose: {
        question: {
            default: 'Quel type de pose souhaitez-vous ?',
        },
        label: 'Pose',
    },
    wineCellarNbDoor: {
        question: {
            default: 'Combien de portes souhaitez-vous pour votre cave à vin ?',
        },
        label: 'Nombre de portes',
    },
    wineCellarRoom: {
        question: {
            default: 'Dans quelle pièce souhaitez-vous aménager votre cave à vin ?',
        },
        label: 'Pièce dédiée',
    },
    wineCellarDoor: {
        question: {
            default: 'Quel style de porte souhaitez-vous pour votre cave à vin ?',
        },
        label: 'Style de porte',
    },
    wineCellarSensDoor: {
        question: {
            default: 'Quel sens d\'ouverture souhaitez-vous pour votre porte ?',
        },
        label: 'Sens ouverture',
    },
    wineCellarShelf: {
        question: {
            default: 'Quel type de clayettes souhaitez-vous ?',
        },
        label: 'Clayettes',
    },
    wineCellarNbBottle: {
        question: {
            default: 'Combien de bouteilles souhaitez-vous pouvoir contenir dans votre cave à vin ?',
        },
        label: 'Nombre de bouteilles',
    },
    trayShapeDimension: {
        question: {
            default             : 'Quelle forme de plateau ?',
            tableScandinavian   : 'Quelle forme pour le plateau de votre table scandinave ?',
            tableLiveEdge       : 'Quelle forme pour le plateau de votre table "Live-Edge" ?',
            tableRiverTable     : 'Quelle forme pour le plateau de votre "River Table" ?',
            tableFarm           : 'Quelle forme pour le plateau de votre table de ferme ?',
            tableIndustrial     : 'Quelle forme pour le plateau de votre table industrielle ?',
            tableConcrete       : 'Quelle forme pour le plateau de votre table en béton ?',
            tableMarbleAndStone : 'Quelle forme pour le plateau de votre table ?',
            tableMikado         : 'Quelle forme pour le plateau de votre table Mikado ?',
            tableVintage        : 'Quelle forme pour le plateau de votre table vintage ?',
            table               : 'Quelle forme pour le plateau de votre table personnalisée ?',
            tray                : 'Quelle forme pour votre plateau ?',
            meetingTable        : 'Quelle forme pour le plateau de votre table de réunion ?',
            desk                : 'Quelle forme pour le plateau de votre bureau ?',
            deskExecutive       : 'Quelle forme pour le plateau de votre bureau ?',
        },
        label             : 'Plateau',
        dimensionQuestion : 'Quelles dimensions pour ce plateau ?',
    },
    shapeDimension: {
        question: {
            default               : 'Quelle forme désirez-vous ?',
            pavingSlab            : 'Quelle forme de dalle désirez-vous ?',
            closetDoor            : 'Quel type d\'armoire possédez-vous ?',
            lowTable              : 'Quel forme de table basse désirez-vous ?',
            lowTableNoLegs        : 'Quel forme de table basse désirez-vous ?',
            lowTableNesting       : 'Quel forme de table basse gigogne désirez-vous ?',
            lowTableNestingNoLegs : 'Quel forme de table basse gigogne désirez-vous ?',
        },
        label             : 'Forme',
        dimensionQuestion : 'Quelles dimensions vous faut-il ?',
    },
    trayShape: {
        question: {
            default             : 'Quelle forme de plateau ?',
            tableScandinavian   : 'Quelle forme pour le plateau de votre table scandinave ?',
            tableLiveEdge       : 'Quelle forme pour le plateau de votre table "Live-Edge" ?',
            tableRiverTable     : 'Quelle forme pour le plateau de votre "River Table" ?',
            tableFarm           : 'Quelle forme pour le plateau de votre table de ferme ?',
            tableIndustrial     : 'Quelle forme pour le plateau de votre table industrielle ?',
            tableConcrete       : 'Quelle forme pour le plateau de votre table en béton ?',
            tableMarbleAndStone : 'Quelle forme pour le plateau de votre table ?',
            tableMikado         : 'Quelle forme pour le plateau de votre table Mikado ?',
            tableVintage        : 'Quelle forme pour le plateau de votre table vintage ?',
            table               : 'Quelle forme pour le plateau de votre table personnalisée ?',
            tray                : 'Quelle forme pour votre plateau ?',
            meetingTable        : 'Quelle forme pour le plateau de votre table de réunion ?',
            desk                : 'Quelle forme pour le plateau de votre bureau ?',
            deskExecutive       : 'Quelle forme pour le plateau de votre bureau ?',
        },
        label: 'Forme de plateau',
    },
    shape: {
        question: {
            default: 'Quelle forme ?',
        },
        label: 'Forme',
    },
    glassWallStyle: {
        question: {
            default: 'Quel style pour votre verrière ?',
        },
        label: 'Style',
    },
    closingType: {
        question: {
            default: 'Quel type de fermetures souhaitez-vous ?',
        },
        label: 'Fermetures',
    },
    legsShape: {
        question: {
            default         : 'Quel type de pieds ?',
            lowTable        : 'Quel type de pieds pour votre table basse ?',
            lowTableNesting : 'Quel type de pieds pour votre table basse gigogne ?',
        },
        label: 'Type de pieds',
    },
    border: {
        question: {
            default: 'Quelle forme pour les bords ?',
        },
        label: 'Bords',
    },
    trayDimension: {
        question: {
            default: 'Quelles dimensions pour le plateau ?',
        },
        label: 'Dimensions de plateau',
    },
    connectics: {
        question: {
            default: 'Avez-vous besoin de connectiques ?',
        },
        label: 'Connectique',
    },
    legsDimension: {
        question: {
            default: 'Quelle hauteur pour ces pieds (épaisseur de plateau incluse) ?',
        },
        label: 'Hauteur des pieds',
    },
    dimension: {
        question: {
            default                 : 'Quelles dimensions souhaitez-vous ?',
            wineCellarDedicatedRoom : 'Quelle est la hauteur sous plafond dont vous disposez ?',
            vegetalWall             : 'Quelles sont les dimensions de votre mur végétal ?',
            customMezzanine         : 'Quelles dimensions pour la structure de votre mezzanine ?',
            customBunkBed           : 'Quelles dimensions pour la structure de votre lit superposé ?',
            customBlindOutdoor      : 'Quelles dimensions pour votre store ?',
            shelfShoes              : 'Quelle dimensions pour votre étagère à chaussures ?',
            shoesCabinet            : 'Quelle dimensions pour votre armoire à chaussures ?',
        },
        label: 'Dimensions',
    },
    cutting: {
        question: {
            default: 'Vous faut-il des découpes ?',
        },
        label: 'Découpe',
    },
    openings: {
        question: {
            default: 'Souhaitez-vous des ouvertures ?',
        },
        label: 'Ouvertures',
    },
    addOns: {
        question: {
            default                 : 'Souhaitez-vous ajouter des éléments ?',
            libraryWithNiches       : 'Combien de niches souhaitez-vous ?',
            libraryWithShelves      : 'Combien d\'étagères souhaitez-vous ?',
            staircaseStraight       : 'Quelles options souhaitez-vous ?',
            staircaseSpiralRound    : 'Quelles options souhaitez-vous ?',
            staircaseSpiralSquare   : 'Quelles options souhaitez-vous ?',
            staircaseTurnLanding    : 'Quelles options souhaitez-vous ?',
            staircaseQuarterTurn    : 'Quelles options souhaitez-vous ?',
            staircaseHalfTurn       : 'Quelles options souhaitez-vous ?',
            wineCellar              : 'Quelles options souhaitez-vous ?',
            wineCellarDedicatedRoom : 'Quelles options souhaitez-vous ?',
            customBeds              : 'Souhaitez-vous des options pour votre lit ?',
            customBunkBed           : 'Souhaitez-vous des options pour votre lit ?',
            customMezzanine         : 'Souhaitez-vous des options pour votre lit ?',
            closetDoor              : 'Souhaitez-vous ajouter des options à votre porte de placard ?',
            lowTable                : 'Souhaitez-vous ajouter des options à votre table basse ?',
            lowTableNoLegs          : 'Souhaitez-vous ajouter des options à votre table basse ?',
            lowTableNesting         : 'Souhaitez-vous ajouter des options à votre table basse gigogne ?',
            lowTableNestingNoLegs   : 'Souhaitez-vous ajouter des options à votre table basse gigogne ?',
        },
        label: 'Options',
    },
    obstacles: {
        question: {
            default: 'Avez-vous des obstacles éventuels ?',
        },
        label: 'Obstacles',
    },
    limonShape: {
        question: {
            default: 'Quel type de limon souhaitez-vous ?',
        },
        label: 'Limon',
    },
    plainSteps: {
        question: {
            default: 'Souhaitez-vous des contremarches ?',
        },
        label: 'Contremarches',
    },
    stepsDimension: {
        question: {
            default: 'Quelle largeur pour vos marches d\'escalier ?',
        },
        label: 'Largeur des marches',
    },
    staircaseSideTurn: {
        question: {
            default: 'De quel côté souhaité vous le retour tournant ?',
        },
        label: 'Tournant',
    },
    staircaseLanding: {
        question: {
            default: 'Quel type d\'escalier 1/4 tournant souhaitez-vous ?',
        },
        label: 'Palier',
    },
    designProject: {
        title : 'De quels éléments avez-vous besoin pour votre projet d\'agencement ?',
        label : 'Éléments d\'agencement',
    },
    extension: {
        question: {
            default: 'Souhaitez-vous des rallonges ?',
        },
        label             : 'Rallonges',
        shapeQuestion     : 'Quel type de rallonges ?',
        dimensionQuestion : 'Quelles dimensions pour ces rallonges ?',
    },
    staircaseRailing: {
        question: {
            default: 'Souhaitez-vous une main courante ?',
        },
        label            : 'Main courante',
        materialQuestion : 'Quel matériau pour votre main courante ?',
    },
    staircaseGuard: {
        label    : 'Garde corps',
        question : {
            default: 'Souhaitez-vous un garde corps ?',
        },
        materialPrimaryQuestion   : 'Quel matériau pour votre garde corps ?',
        materialSecondaryQuestion : 'Quel matériau pour la structure ?',
    },
    background: {
        title : 'Souhaitez-vous un fond ?',
        label : 'Fond',
    },
    staircaseLayingStairs: {
        title : 'Souhaitez-vous que l\'artisan s\'occupe de la pose votre escalier ?',
        label : 'Pose de l\'escalier',
    },
    staircaseProtection: {
        title : 'Souhaitez-vous une protection pour les enfants ?',
        label : 'Protection enfant',
    },
    terraceLaying: {
        title : 'Souhaitez-vous la pose de votre terrasse ?',
        label : 'Pose',
    },
    terraceLight: {
        title : 'Souhaitez-vous ajouter des éclairages ?',
        label : 'Lumières',
    },
    terracePedestals: {
        title : 'Souhaitez-vous une terrasse sur plots ?',
        label : 'Sur plots',
    },
    deckBoardShape: {
        label    : 'Lames Bois',
        question : {
            default: 'Quel aspect pour les lames de votre terrasse ?',
        },
    },
    deckBoardWidth: {
        label         : 'Dimensions Bois',
        radioQuestion : 'Quelle largeur de lame souhaitez-vous ?',
    },
    constraints: {
        label    : 'Contraintes',
        question : {
            default: 'Avez-vous des contraintes ou obstacles à prendre en compte pour la construction de votre terrasse ?',
        },
    },
    contact: {
        title         : 'Pour vous envoyer les devis des meilleurs pros, nous avons besoin de ces informations !',
        titleExternal : 'Pour vous envoyer votre devis, j\'ai besoin de ces informations !',
        email         : 'À quelle adresse email souhaitez-vous recevoir les devis ?',
        emailExternal : 'À quelle adresse email souhaitez-vous recevoir votre devis ?',
        phone         : 'À quel numéro peut-on vous joindre ?',
        phoneExternal : 'À quel numéro puis-je vous joindre ?',
        info          : 'À qui doit-on adresser les devis ?',
        infoExternal  : 'À qui dois-je adresser le devis ?',
        availability  : 'Nous sommes susceptibles de vous appeler pour des questions complémentaires. De préférence quand êtes vous joignable ?',
        shipping      : {
            default: 'Où se situe votre projet ?',
        },
        label: 'Livraison',
    },
    additionalData: {
        title : 'Des informations à ajouter quant à votre projet ?',
        label : 'Spécificité',
        recap : 'Info complémentaires',
    },
    projectInformation: {
        title : 'Informations à ajouter',
        label : 'Info complémentaires',
    },
    multiSelector: {
        question: {
            default                 : 'Des options à ajouter à votre projet ?',
            wineCellar              : 'Quelles fonctions pour votre cave à vin ?',
            wineCellarDedicatedRoom : 'Quelles fonctions pour votre cave à vin ?',
        },
        label         : 'Fonctions',
        labelSingular : 'Fonction',
        noValue       : 'Pas d\'option spécifique.',
    },
    highWindDestination: {
        question: {
            default: 'Quelle est la destination de votre marquise ?',
        },
        label: 'Pièce de destination',
    },
    highWindLaying: {
        title : 'Souhaitez-vous la pose de votre marquise ?',
        label : 'Pose',
    },
    fenceDestination: {
        label    : 'Desintation',
        question : {
            default: 'Quel est le lieu de desintation de votre garde-corps ?',
        },
    },
    fencePoles: {
        label    : 'Poteaux',
        question : {
            default: 'Souhaitez-vous des poteaux avec votre garde-corps ?',
        },
        shapeQuestion    : 'Quel forme de potaux souhaitez-vous ?',
        materialQuestion : 'Quel type de matériau souhaitez-vous pour vos poteaux ?',
    },
    fenceRailing: {
        label    : 'Main courante',
        question : {
            default: 'Souhaitez-vous une main-courante avec votre garde-corps ?',
        },
        materialQuestion: 'Quel matériau pour votre main courante ?',
    },
    fenceFilling: {
        label    : 'Remplissage',
        question : {
            default: 'Quel style de remplissage souhaitez-vous ?',
        },
    },
    fenceProtection: {
        label : 'Protection enfant',
        title : 'Souhaitez-vous un garde-corps adapté à la sécurité des enfants ?',
    },
    fenceMaterial: {
        label    : 'Matériaux',
        question : {
            default: 'Quel type de matériau souhaitez-vous pour le remplissage de votre garde - corps ?',
        },
    },
    fenceSecondMaterial: {
        label    : 'Matériaux',
        question : {
            default: 'Souhaitez-vous un deuxième matériau pour le remplissage de votre garde - corps ?',
        },
        materialQuestion: 'Souhaitez-vous un deuxième matériel pour le remplissage de votre garde - corps ?',
    },
    pergolaShape: {
        label    : 'Style',
        question : {
            default: 'Quel style de pergola souhaitez-vous ?',
        },
    },
    pergolaRoof: {
        label         : 'Toit',
        radioQuestion : 'Quel type de toit pour votre pergola ?',
    },
    pergolaOptions: {
        label    : 'Options',
        question : {
            default: 'Souhaitez-vous ajouter des options ?',
        },
    },
    windowLeaf: {
        question: {
            default: 'Combien de vantaux souhaitez-vous pour votre fenêtre ?',
        },
        label: 'Vantaux',
    },
    windowOpening: {
        radioQuestion : 'Quel tirant pour votre fenêtre ?',
        label         : 'Tirant',
    },
    windowGlazing: {
        radioQuestion : 'Quel type de vitrage souhaitez-vous ?',
        label         : 'Vitrage',
    },
    windowInsulation: {
        radioQuestion : 'Quel niveau d\'isolation souhaitez-vous ?',
        label         : 'Isolation',
    },
    windowInstallation: {
        title : 'Souhaitez-vous une fenêtre avec ou sans pose ?',
        label : 'Pose ',
    },
    garageDoorOpening: {
        label    : 'Ouverture',
        question : {
            default: 'Quel type de porte de garage souhaitez-vous ?',
        },
    },
    garageDoorGroove: {
        label    : 'Finitions',
        question : {
            default: 'Quel style de rainures pour votre porte de garage ?',
        },
    },
    garageDoorGate: {
        title : 'Souhaitez-vous ajouter un portillon à votre porte de garage ?',
        label : 'Portillon',
    },
    garageDoorOptions: {
        label    : 'Options',
        question : {
            default: 'Quelles options souhaitez-vous pour votre garage ?',
        },
    },
    claustraDestination: {
        label    : 'Destination',
        question : {
            default: 'Quel est le lieu de destination de votre claustra ?',
        },
    },
    claustraMobility: {
        label    : 'Type',
        question : {
            default: 'Quel type de claustra souhaitez-vous ?',
        },
    },
    claustraType: {
        label    : 'Style',
        question : {
            default: 'Quel type de paroi pour votre claustra ?',
        },
    },
    claustraLaying: {
        title : 'Souhaitez-vous la pose de votre claustra ?',
        label : 'Pose',
    },
    quantity: {
        question: {
            default      : 'Combien en souhaitez-vous ?',
            shoesCabinet : 'Combien de casiers souhaitez-vous ?',
            shelfShoes   : 'Combien de casiers souhaitez-vous ?',
        },
        label: 'Casiers',
    },
    shoesQuantity: {
        question: {
            default: 'Combien de paires de chaussures souhaitez-vous ranger ?',
        },
        label: 'Chaussures',
    },
    secondMaterial: {
        question: {
            default: 'Souhaitez-vous ajouter un deuxième materiau ?',
        },
        label            : 'Matériau secondaire',
        materialQuestion : 'Quel deuxième matériau ?',
    },
    groundShapeDimension: {
        question: {
            default: 'Quelle forme désirez-vous ?',
        },
        label             : 'Surface',
        dimensionQuestion : 'Quelles dimensions au sol ?',
    },
    roofShapeDimension: {
        question: {
            default: 'Quelle forme de toit désirez-vous ?',
        },
        label             : 'Toit',
        dimensionQuestion : 'Quelle hauteur souhaitez-vous ?',
    },
    destinationShape: {
        label    : 'Destination',
        question : {
            default     : 'Quel est le lieu de destination ?',
            vegetalWall : 'Quel est le lieu de destination de votre mur végétal ?',
        },
    },
    vegetals: {
        question: {
            default: 'Quels végétaux pour habiller votre mur ?',
        },
        label: 'Plantes',
    },
    type: {
        question: {
            default     : 'Quel type souhaitez-vous ?',
            vegetalWall : 'Quel type de mur végétal souhaitez-vous ?',
            closetDoor  : 'Quel style de porte de placard souhaitez-vous ?',
            fencing     : 'Quel type de remplissage souhaitez-vous ?',
        },
        label: 'Type',
    },
    bedShapeDimension: {
        question: {
            default       : 'Combien de places souhaitez-vous ?',
            customBunkBed : 'Combien de places pour le lit du bas ?',
        },
        label             : 'Couchage',
        dimensionQuestion : 'Quelles dimensions pour votre couchage ?',
    },
    topBedShapeDimension: {
        question: {
            default       : 'Combien de places souhaitez-vous ?',
            customBunkBed : 'Combien de places pour le lit du haut ?',
        },
        label             : 'Couchage haut',
        dimensionQuestion : 'Quelles dimensions pour le couchage du haut ?',
    },
    frameShapeDimension: {
        question: {
            default    : 'Quel type de structure souhaitez-vous ?',
            customBeds : 'Quel type de structure pour votre lit ?',
        },
        label             : 'Structure',
        dimensionQuestion : 'Quelles dimensions pour la structure ?',
    },
    mirrorFrameMaterial: {
        question: {
            default: 'Voulez-vous un cadre pour votre miroir ?',
        },
        label            : 'Cadre',
        materialQuestion : 'Quel matériau pour la structure de votre miroir ?',
    },
    windowBlindsStyle: {
        question: {
            default            : 'Quel style ?',
            customBlind        : 'Quel style de store pour votre projet ?',
            customBlindOutdoor : 'Plus précisément quel type de store ?',
        },
        label: 'Style',
    },
    windowBlindsFilling: {
        radioQuestion : 'Quel type de remplissage souhaitez-vous ?',
        label         : 'Remplissage',
    },
    windowBlindsFunction: {
        question: {
            default: 'Quelle fonction cherchez-vous pour votre store ?',
        },
        label: 'Fonction',
    },
    windowBlindsDimension: {
        question: {
            default     : 'Quelles dimensions souhaitez-vous ?',
            customBlind : 'Quelles dimensions souhaitez-vous pour votre store ?',
        },
        label: 'Dimensions store',
    },
    windowBlindsGlassDimension: {
        question: {
            default     : 'Quelles dimensions souhaitez-vous ?',
            customBlind : 'Qu\'elle est la dimension de votre vitrage ?',
        },
        label: 'Dimensions vitrage',
    },
    windowBlindsElectric: {
        title : 'Souhaitez-vous ajouter un système électrique à votre store ?',
        label : 'Electrique',
    },
    windowBlindsDisposition: {
        question: {
            default: 'Vous souhaitez un store pour :',
        },
        label: 'Disposition',
    },
    windowBlindsDwelling: {
        question: {
            default: 'Dans quel type d\'habitation ?',
        },
        label: 'Habitation',
    },
    windowBlindsProtection: {
        radioQuestion : 'Quel type de protection souhaitez-vous ?',
        label         : 'Protection',
    },
    windowBlindsManeuver: {
        radioQuestion : 'Quel type de manoeuvre souhaitez-vous ?',
        label         : 'Manoeuvre',
    },
    windowBlindsLight: {
        title : 'Souhaitez-vous ajouter un éclairage ?',
        label : 'Eclairage',
    },
    windowBlindsPose: {
        question: {
            default: 'Souhaitez-vous la pose ?',
        },
        radioQuestion : 'Plus précisément quel type de pose ?',
        label         : 'Pose',
    },
    closetDoorSens: {
        label         : 'Ouverture',
        radioQuestion : 'Quel sens d\'ouverture ?',
    },
    closetDoorFrameMaterial: {
        question: {
            default: 'Souhaitez-vous un deuxième matériau ?',
        },
        label            : 'Structure',
        materialQuestion : 'Quel matériau pour la structure de votre porte de placard ?',
    },
    fencingDestination: {
        label    : 'Usage',
        question : {
            default: 'Quel usage pour votre clôture ?',
        },
    },
    fenceGateDimension: {
        question: {
            default: 'Souhaitez-vous ajouter un portillon ?',
        },
        label             : 'Portillon',
        dimensionQuestion : 'Qu\'elles dimensions pour votre portillon ?',
    },
    fencingPose: {
        question: {
            default: 'Souhaitez-vous la pose ?',
        },
        radioQuestion : 'Plus précisément quel type de pose ?',
        label         : 'Pose',
    },
    portalShape: {
        question: {
            default: 'Quelle forme pour votre portail ?',
        },
        label: 'Forme',
    },
    portalShapeDimension: {
        question: {
            default: 'Combien de vantaux pour votre portail ?',
        },
        label             : 'Vantaux',
        dimensionQuestion : 'Quelles dimensions pour votre portail ?',
    },
    portalOpening: {
        question: {
            default: 'Quelle type d\'ouverture pour votre portail ?',
        },
        label: 'Ouverture',
    },
    portalMotorization: {
        title : 'Souhaitez-vous une ouverture avec motorisation ?',
        label : 'Motorisation',
    },
    portalFilling: {
        question: {
            default: 'Quel remplissage ?',
        },
        label: 'Remplissage',
    },
    portalGate: {
        question: {
            default: 'Souhaitez-vous ajouter un portillon ?',
        },
        label             : 'Portillon',
        dimensionQuestion : 'Quelles dimensions pour votre portillon?',
    },
    portalLaying: {
        title : 'Souhaitez-vous la pose de votre portail ?',
        label : 'Pose',
    },
    kitchenFurniture: {
        question: {
            default: 'Combien souhaitez-vous d\'éléments ?',
        },
        label: 'Éléments',
    },
    kitchenHomeAppliance: {
        question: {
            default: 'Quel électroménager ?',
        },
        label: 'Électroménager',
    },
    kitchenProjectType: {
        label         : 'Type de projet',
        radioQuestion : 'Votre projet de cuisine concerne :',
    },
    kitchenHandle: {
        label    : 'Type de poignée',
        question : {
            default: 'Quel type de poignée souhaitez-vous ?',
        },
    },
    kitchenShape: {
        question: {
            default: 'Quelle est la configuration de votre pièce ?',
        },
        label             : 'Configuration pièce',
        dimensionQuestion : 'Connaissez-vous les dimensions de votre pièce ?',
    },
    kitchenLayout: {
        question: {
            default: 'Quelle implantation souaitez-vous ?',
        },
        label: 'Implantation',
    },
    finishing: {
        label         : 'Finition',
        radioQuestion : 'Quelle finition pour votre plan de travail ?',
    },
    kitchenStyle: {
        question: {
            default: 'Quel style de cuisine souhaitez-vous ?',
        },
        label: 'Style',
    },
    uploadFiles: {
        title      : 'Si vous avez des plans ou photos de votre cuisine déposez-les ici :',
        null       : "Pas d'image",
        nullAnswer : "Pas d'image à ajouter.",
        upload     : 'Envoyer un fichier',
        fileAnswer : "Oui, j'ai une image, la voici:",
        label      : 'Plans',
    },
    budget: {
        radioQuestion : 'Quel budget pour votre cuisine, tout inclus (électroménager, livraison et pose) ?',
        label         : 'Budget',
    },
    applianceLevel: {
        radioQuestion : 'Quelle gamme de prix pour votre électoménager?',
        label         : 'Gamme',
    },
    kitchenMaterialShade: {
        question: {
            default: 'Quel plan de travail ?',
        },
        label: 'Plan de travail',
    },
    comment: {
        title : 'Des informations à ajouter quant à votre projet ?',
        label : 'Mon projet',
        recap : 'Info supplémentaires',
    },
};

export default fieldsChatFR;
