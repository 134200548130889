import { MaterialId } from '../constants/Material';

const materialNames: { [key in MaterialId]: string } = {
    acacia                         : 'Bois massif - Acacia',
    acaciaEngineered               : 'Mélaminé - Acacia',
    acaciaRecycled                 : 'Bois recyclé - Acacia',
    acaciaVeneer                   : 'Placage bois - Acacia',
    agedMirror                     : 'Miroir vieilli',
    alderwood                      : 'Bois massif - Aulne',
    alderwoodEngineered            : 'Mélaminé - Aulne',
    alderwoodRecycled              : 'Bois recyclé - Aulne',
    alderwoodVeneer                : 'Placage bois - Aulne',
    aluminum                       : 'Aluminium',
    ash                            : 'Frêne massif',
    ashEngineered                  : 'Mélaminé - Frêne',
    ashOlive                       : 'Bois massif - Frêne olivier',
    ashOliveEngineered             : 'Mélaminé - Frêne olivier',
    ashOliveRecycled               : 'Bois recyclé - Frêne olivier',
    ashOliveVeneer                 : 'Placage bois - Frêne olivier',
    ashRecycled                    : 'Bois recyclé - Frêne',
    ashVeneer                      : 'Placage bois - Frêne',
    beech                          : 'Bois massif - Hêtre',
    beechEngineered                : 'Mélaminé - Hêtre',
    beechRecycled                  : 'Bois recyclé - Hêtre',
    beechVeneer                    : 'Placage bois - Hêtre',
    birch                          : 'Bois massif - Bouleau',
    birchEngineered                : 'Mélaminé - Bouleau',
    birchRecycled                  : 'Bois recyclé - Bouleau',
    birchVeneer                    : 'Placage bois - Bouleau',
    blackLocust                    : 'Robinier',
    brass                          : 'Laiton',
    bronzeMirror                   : 'Miroir bronze',
    caning                         : 'Cannage',
    ceramicKadum                   : 'Céramique Kadum',
    ceramicKeo                     : 'Céramique Keo',
    ceramicKeranium                : 'Céramique Keranium',
    ceramicManhattan               : 'Céramique Manhattan',
    ceramicNilium                  : 'Céramique Nilium',
    ceramicOrix                    : 'Céramique Orix',
    ceramicStrato                  : 'Céramique Strato',
    ceramicWhiteConcrete           : 'Céramique White concrete',
    cherrywood                     : 'Bois massif - Merisier',
    cherrywoodEngineered           : 'Mélaminé - Merisier',
    cherrywoodRecycled             : 'Bois recyclé - Merisier',
    cherrywoodVeneer               : 'Placage bois - Merisier',
    chestnut                       : 'Bois massif - Châtaignier',
    chestnutEngineered             : 'Mélaminé - Châtaignier',
    chestnutRecycled               : 'Bois recyclé - Châtaignier',
    chestnutVeneer                 : 'Placage bois - Châtaignier',
    compositeBlack                 : 'Composite Noir',
    compositeBrown                 : 'Composite Marron',
    compositeGrey                  : 'Composite Gris',
    compositeIpe                   : 'Composite IPÉ',
    compositeTaupe                 : 'Composite Taupe',
    compositeTeak                  : 'Composite Teck',
    compositeWhite                 : 'Composite Blanc',
    concreteFibred                 : 'Béton fibré',
    concreteWaxed                  : 'Béton ciré',
    corianAnthracite               : 'Corian anthracite',
    corianBurledBeach              : 'Corian aqua',
    corianBurled                   : 'Corian burled beach',
    corianCocoaPrima               : 'Corian cocoa prima',
    corianCosmosPrima              : 'Corian cosmos prima',
    corianDeepBlackQuartz          : 'Corian deep black quartz',
    corianDusk                     : 'Corian dusk',
    corianEveningPrima             : 'Corian evening prima',
    corianGlacierWhite             : 'Corian glacier white',
    corianGrayOnyx                 : 'Corian gray onyx',
    corianHazelnut                 : 'Corian hazelnut',
    corianLimestonePrima           : 'Corian limestone prima',
    corianLinen                    : 'Corian linen',
    corianNimbusPrima              : 'Corian nimbus prima',
    corianStratus                  : 'Corian stratus',
    corianTumbleweed               : 'Corian tumbleweed',
    corianWhiteOnyx                : 'Corian white onyx',
    corianXitchHazel               : 'Corian witch hazel',
    cumaru                         : 'Cumaru',
    darkGreyMirror                 : 'Miroir gris foncé',
    dektonDanae                    : 'Dekton danae',
    dektonEther                    : 'Dekton ether',
    dektonHelena                   : 'Dekton helena',
    dektonKhalo                    : 'Dekton khalo',
    dektonKira                     : 'Dekton kira',
    dektonKylia                    : 'Dekton kylia',
    dektonLaurent                  : 'Dekton laurent',
    dektonLiquidEmbers             : 'Dekton liquid embers',
    dektonLiquidSky                : 'Dekton liquid sky',
    dektonRem                      : 'Dekton rem',
    dektonSoke                     : 'Dekton soke',
    dektonStrato                   : 'Dekton strato',
    dektonVienna                   : 'Dekton vienna',
    dektonWhiteConcrete            : 'Dekton white concrete',
    douglas                        : 'Bois massif - Douglas',
    douglasEngineered              : 'Mélaminé - Douglas',
    douglasRecycled                : 'Bois recyclé - Douglas',
    douglasVeneer                  : 'Placage bois - Douglas',
    driftWood                      : 'Bois massif - Bois flotté',
    driftWoodRecycled              : 'Bois recyclé - Bois flotté',
    ebony                          : 'Bois massif - Ébène',
    ebonyEngineered                : 'Mélaminé - Ébène',
    ebonyRecycled                  : 'Bois recyclé - Ébène',
    ebonyVeneer                    : 'Placage bois - Ébène',
    elm                            : 'Bois massif - Orme',
    elmEngineered                  : 'Mélaminé - Orme',
    elmRecycled                    : 'Bois recyclé - Orme',
    elmVeneer                      : 'Placage bois - Orme',
    garapa                         : 'Garapa',
    glass                          : 'Verre transparent feuilleté',
    glassOpaque                    : 'Verre dépoli feuilleté',
    GlassVitroceramic              : 'Verre vitrocéramique',
    fabric                         : 'Tissu',
    fabricAcrylic                  : 'Toile acrylique',
    fabricMicroPerforated          : 'Toile micro-perforées',
    fabricPolyester                : 'Toile polyester',
    graniteAbsoluteBlackZimbabwe   : 'Granite Noir Absolu Zimbabwé',
    graniteAmazzonite              : 'Granite Amazzonite',
    graniteAzulAran                : 'Granite Azul Aran',
    graniteBalmoral                : 'Granite Balmoral',
    graniteCambrianBlack           : 'Granite Cambrian Black',
    graniteCoffeeBrown             : 'Granite Coffee Brown',
    graniteCoralRed                : 'Granite Rouge Corail',
    graniteGialloVeneziano         : 'Granite Giallo Veneziano',
    granitePorrino                 : 'Granite Porrino',
    graniteRosaBeta                : 'Granite Rosa Beta',
    ipe                            : 'Bois massif - IPÉ',
    ipeEngineered                  : 'Mélaminé - IPÉ',
    ipeRecycled                    : 'Bois recyclé - IPÉ',
    ipeVeneer                      : 'Placage bois - IPÉ',
    iroko                          : 'Iroko',
    iron                           : 'Fer',
    larch                          : 'Bois massif - Mélèze',
    larchEngineered                : 'Mélaminé - Mélèze',
    larchRecycled                  : 'Bois recyclé - Mélèze',
    larchVeneer                    : 'Placage bois - Mélèze',
    mahogany                       : 'Bois massif - Acajou',
    mahoganyEngineered             : 'Mélaminé - Acajou',
    mahoganyRecycled               : 'Bois recyclé - Acajou',
    mahoganyVeneer                 : 'Placage bois - Acajou',
    maple                          : 'Bois massif - Érable',
    mapleEngineered                : 'Mélaminé - Érable',
    mapleRecycled                  : 'Bois recyclé - Érable',
    mapleVeneer                    : 'Placage bois - Érable',
    marbleArabescatoOribicoRosso   : 'Marbre Rouge',
    marbleAzulImperial             : 'Marbre Azul Imperial',
    marbleBlackSahara              : 'Marbre Noir Sahara',
    marbleBrecciaPontificia        : 'Marbre Breccia Pontifica',
    marbleBrecheDeVendome          : 'Marbre Brêche de Vendôme',
    marbleCalacatta                : 'Marbre Calacatta',
    marbleCarrare                  : 'Marbre Carrare',
    marbleForestBrown              : 'Marbre Forest Brown',
    marbleGrandAntiqueAubert       : 'Marbre Grand Antique Aubert',
    marbleMarquina                 : 'Marbre Marquina',
    marblePortLaurent              : 'Marbre Port Laurent',
    marbleRosePortugal             : 'Marbre Rose Portugal',
    marbleSilverWave               : 'Marbre Silver Wave',
    marbleStriatoOlimpico          : 'Marbre Striato Olimpico',
    marbleTravertinClassicMedium   : 'Marbre Travertin Classic',
    marbleVerdeAlpi                : 'Marbre Verde Alpi',
    marbleVerdeGuatemala           : 'Marbre Verde Guatemala',
    marbleWhiteBeauty              : 'Marbre White Beauty',
    melamineAluminiumMetal         : 'Mélaminé aluminium',
    melamineAnthraciteLarch        : 'Mélaminé mélèze anthracite',
    melamineAnthraciteMetal        : 'Mélaminé métal anthracite',
    melamineBarnWood               : 'Mélaminé bois de grange',
    melamineBeigeUniform           : 'Mélaminé beige',
    melamineBlackBrownOak          : 'Mélaminé chêne brun noir',
    melamineBlackOak               : 'Mélaminé chêne noir',
    melamineBlackUniform           : 'Mélaminé noir',
    melamineBlackWood              : 'Mélaminé bois noir',
    melamineBlueUniform            : 'Mélaminé bleu',
    melamineBordeauxUniform        : 'Mélaminé bordeaux',
    melamineBrassMetal             : 'Mélaminé laiton',
    melamineBronzeConcrete         : 'Mélaminé béton bronze',
    melamineBrownLarch             : 'Mélaminé mélèze marron',
    melamineBrushedAluminium       : 'Mélaminé aluminium brossé',
    melamineBrushedSteel           : 'Mélaminé acier brossé',
    melamineCefalConcrete          : 'Mélaminé béton Céfalu',
    melamineChalkWhite             : 'Mélaminé blanc craie',
    melamineChampagneMaple         : 'Mélaminé erable champagne',
    melamineChestnutOak            : 'Mélaminé chêne châtain',
    melamineClearBlueUniform       : 'Mélaminé bleu clair',
    melamineClearBrownUniform      : 'Mélaminé Marron clair',
    melamineClearConcrete          : 'Mélaminé béton clair',
    melamineClearGrayConcrete      : 'Mélaminé béton gris clair',
    melamineClearGrayUniform       : 'Mélaminé gris clair',
    melamineClearGreenUniform      : 'Mélaminé vert clair',
    melamineDarkBlueUniform        : 'Mélaminé bleu foncé',
    melamineDarkBrownUniform       : 'Mélaminé marron foncé',
    melamineDarkGrayConcrete       : 'Mélaminé béton gris foncé',
    melamineDarkGrayUniform        : 'Mélaminé gris foncé',
    melamineDarkGreenUniform       : 'Mélaminé vert foncé',
    melamineGrayArticUniform       : 'Mélaminé gris arctique',
    melamineGrayElm                : 'Mélaminé orme gris',
    melamineGrayOak                : 'Mélaminé chêne gris',
    melamineGrayPine               : 'Mélaminé pin gris',
    melamineGreenUniform           : 'Mélaminé vert',
    melamineIndigoMetal            : 'Mélaminé métal indigo',
    melamineLightAcacia            : 'Mélaminé acacia clair',
    melamineMarbleAnthraciteGrigia : 'Mélaminé grigia anthracite',
    melamineMarbleBlackGrigia      : 'Mélaminé grigia noir',
    melamineMarbleWhiteLevanto     : 'Mélaminé levanto blanc',
    melamineNaturalOak             : 'Mélaminé chêne nature',
    melamineNudeUniform            : 'Mélaminé nude',
    melamineOrangeUniform          : 'Mélaminé orange',
    melaminePinkUniform            : 'Mélaminé rose',
    melaminePolarPin               : 'Mélaminé pin polaire',
    melaminePolarWhite             : 'Mélaminé blanc polaire',
    melaminePurpleUniform          : 'Mélaminé violet',
    melamineRedUniform             : 'Mélaminé rouge',
    melamineSandAsh                : 'Mélaminé frêne sable',
    melamineSandChestnut           : 'Mélaminé châtaignier sable',
    melamineSandOak                : 'Mélaminé chêne sable',
    melamineStainlessSteel         : 'Mélaminé inox',
    melamineTabaccoOak             : 'Mélaminé chêne tabac',
    melamineTerracottaUniform      : 'Mélaminé terracotta',
    melamineToneBeige              : 'Mélaminé beige argile',
    melamineToneBrown              : 'Mélaminé chocolat',
    melamineToneGrey               : 'Mélaminé gris fer',
    melamineToneWhite              : 'Mélaminé blanc alpin',
    melamineVintageOak             : 'Mélaminé chêne vintage',
    melamineWhiteConcrete          : 'Mélaminé béton blanc',
    melamineWhiteElm               : 'Mélaminé orme blanc',
    melamineWhiteLarch             : 'Mélaminé mélèze blanc',
    melamineWhiteMarbleCarrarre    : 'Mélaminé carrarre blanc',
    melamineWhiteOak               : 'Mélaminé chêne blanc',
    melamineWhitePin               : 'Mélaminé pin blanc',
    melamineWhiteWood              : 'Mélaminé bois blanc',
    melamineWoodDark               : 'Mélaminé Effet bois sombre',
    melamineWoodNatural            : 'Mélaminé Effet bois naturel',
    melamineWoodWhite              : 'Mélaminé Effet bois blanc',
    melamineWroughtIronBronze      : 'Mélaminé fer forgé bronze',
    melamineWroughtIronGrey        : 'Mélaminé fer forgé gris',
    melamineYellowUniform          : 'Mélaminé jaune',
    muiracatiara                   : 'Muiracatiara',
    oakOld                         : 'Plancher de wagon chêne patiné vieilli',
    oakRegular                     : 'Bois massif - Chêne',
    oakRegularEngineered           : 'Mélaminé - Chêne',
    oakRegularRecycled             : 'Bois recyclé - Chêne',
    oakRegularVeneer               : 'Placage bois - Chêne',
    oneWayMirror                   : 'Miroir sans tain',
    other                          : 'Autre',
    padouk                         : 'Bois massif - Padouk',
    padoukEngineered               : 'Mélaminé - Padouk',
    padoukRecycled                 : 'Bois recyclé - Padouk',
    padoukVeneer                   : 'Placage bois - Padouk',
    pavingStone                    : 'Pavé',
    pine                           : 'Bois massif - Pin',
    pineEngineered                 : 'Mélaminé - Pin',
    pineRecycled                   : 'Bois recyclé - Pin',
    pineVeneer                     : 'Placage bois - Pin',
    plexiglassFluorescent          : 'Plexiglass fluorescent',
    plexiglassFrosted              : 'Plexiglass dépoli',
    plexiglassMirror               : 'Plexiglass miroir',
    plexiglassTransparent          : 'Plexiglass transparent',
    plexiglassWhite                : 'Plexiglass Blanc',
    polycarbonateOpaque            : 'Polycarbonate opaque',
    polycarbonateTeinted           : 'Polycarbonate teinté',
    polycarbonateTransparent       : 'Polycarbonate transparent',
    poplar                         : 'Bois massif - Peuplier',
    poplarEngineered               : 'Mélaminé - Peuplier',
    poplarRecycled                 : 'Bois recyclé - Peuplier',
    poplarVeneer                   : 'Placage bois - Peuplier',
    pvc                            : 'PVC Expansé',
    pvcRigid                       : 'PVC Rigide',
    quartzAzabache                 : 'Quartz Azabache',
    quartzBlackPortoro             : 'Quartz Portoro',
    quartzBotticino                : 'Quartz Botticino',
    quartzChorme                   : 'Quartz Chrome',
    quartzImperialBrown            : 'Quartz Imperial Brown',
    quartzWhiteFusion              : 'Quartz White Fusion',
    quartzWhiteGalaxy              : 'Quartz White Galaxy',
    quartzWhitePlatinium           : 'Quartz White platinum',
    ratan                          : 'Rotin',
    recycledPlastic                : 'Plastique recyclé',
    redcedar                       : 'Bois massif - Cèdre rouge',
    redcedarEngineered             : 'Mélaminé - Cèdre rouge',
    redcedarRecycled               : 'Bois recyclé - Cèdre rouge',
    redcedarVeneer                 : 'Placage bois - Cèdre rouge',
    riverEpoxyBlackOpaque          : 'Époxy noire Opaque',
    riverEpoxyBlackTransparent     : 'Époxy noire transparente',
    riverEpoxyBlueOpaque           : 'Époxy bleue opaque',
    riverEpoxyBlueTransparent      : 'Époxy bleue transparente',
    riverEpoxyWhiteOpaque          : 'Époxy blanche Opaque',
    riverEpoxyWhiteTransparent     : 'Époxy blanche Transparente',
    riverGlassBlackOpaque          : 'Verre noir Opaque',
    riverGlassBlackTransparent     : 'Verre noir Transparente',
    riverGlassBlueOpaque           : 'Verre bleu Opaque',
    riverGlassBlueTransparent      : 'Verre bleu Transparente',
    riverGlassWhiteOpaque          : 'Verre blanc Opaque',
    riverGlassWhiteTransparent     : 'Verre blanc Transparente',
    rubber                         : 'Bois massif - Hévéa',
    rubberRecycled                 : 'Bois recyclé - Hévéa',
    rust                           : 'Acier rouillé / Corten',
    sheetMetal                     : 'Tôle',
    silverMirror                   : 'Miroir argenté',
    slabOutdoorArdoise             : 'Dalle - Ardoise',
    slabOutdoorCerameGres          : 'Dalle - Grès cérame',
    slabOutdoorConcrete            : 'Dalle - Béton',
    slabOutdoorGranite             : 'Dalle - Granite',
    slabOutdoorMarbre              : 'Dalle - Marbre',
    slabOutdoorNaturalStone        : 'Dalle - Pierre naturelle',
    slabOutdoorReconstituedStone   : 'Dalle - Pierre reconstituée',
    slabOutdoorSlabGravel          : 'Dalle - Gravilloné',
    slabOutdoorTerracotta          : 'Dalle - Terre cuite',
    slabOutdoorTravertin           : 'Dalle - Travertin',
    slabOutdoorWood                : 'Dalle - Bois',
    slateBlack                     : 'Ardoise noire',
    slateGrey                      : 'Ardoise anthracite',
    spruce                         : 'Bois massif - Épicéa',
    spruceEngineered               : 'Mélaminé - Épicéa',
    spruceRecycled                 : 'Bois recyclé - Épicéa',
    spruceVeneer                   : 'Placage bois - Épicéa',
    stainlessSteel                 : 'Inox',
    steel                          : 'Acier brut naturel',
    stoneBlueHainot                : 'Pierre bleue de Hainot',
    stoneBourgogne                 : 'Pierre de Bourgogne',
    stoneLens                      : 'Pierre de Lens',
    stoneLuserne                   : 'Pierre de Luserne',
    stoneSerpentinite              : 'Serpentinite',
    teak                           : 'Bois massif - Teck',
    teakEngineered                 : 'Mélaminé - Teck',
    teakRecycled                   : 'Bois recyclé - Teck',
    teakVeneer                     : 'Placage bois - Teck',
    thermoHeatedWoodAsh            : 'Frêne thermo chauffé',
    thermoHeatedWoodPine           : 'Pin thermo chauffé',
    thermoLaqueredBlack            : 'Acier thermolaqué noir',
    thermoLaqueredBlue             : 'Acier thermolaqué bleu',
    thermoLaqueredGreen            : 'Acier thermolaqué vert',
    thermoLaqueredGrey             : 'Acier thermolaqué gris',
    thermoLaqueredOrange           : 'Acier thermolaqué orange',
    thermoLaqueredPink             : 'Acier thermolaqué rose',
    thermoLaqueredRed              : 'Acier thermolaqué rouge',
    thermoLaqueredWhite            : 'Acier thermolaqué blanc',
    thermoLaqueredYellow           : 'Acier thermolaqué jaune',
    terracotta                     : 'Terre cuite',
    tilesOutdoorBeigeStone         : 'Effet pierre beige',
    tilesOutdoorBlackMarble        : 'Effet marbre noir',
    tilesOutdoorBlackStone         : 'Effet pierre Noir',
    tilesOutdoorConcreteBeige      : 'Effet béton beige',
    tilesOutdoorConcreteBrown      : 'Effet béton Marron',
    tilesOutdoorConcreteDark       : 'Effet béton noir',
    tilesOutdoorConcreteGrey       : 'Effet béton gris',
    tilesOutdoorConcreteWhite      : 'Effet béton Blanc',
    tilesOutdoorDarkWood           : 'Effet bois foncé',
    tilesOutdoorGreyMarble         : 'Effet marbre gris',
    tilesOutdoorGreyStone          : 'Effet pierre grise',
    tilesOutdoorGreyWood           : 'Effet bois gris',
    tilesOutdoorLightWood          : 'Effet bois clair',
    tilesOutdoorNaturalStone       : 'Effet pierre naturelle',
    tilesOutdoorWhiteMarble        : 'Effet marbre blanc',
    tilesOutdoorWhiteStone         : 'Effet pierre Blanche',
    wagonfloorDark                 : 'Plancher de wagon foncé',
    wagonfloorLight                : 'Plancher de wagon clair',
    walnut                         : 'Bois massif - Noyer',
    walnutEngineered               : 'Mélaminé - Noyer',
    walnutRecycled                 : 'Bois recyclé - Noyer',
    walnutVeneer                   : 'Placage bois - Noyer',
    wenge                          : 'Bois massif - Wengé',
    wengeEngineered                : 'Mélaminé - Wengé',
    wengeRecycled                  : 'Bois recyclé - Wengé',
    wengeVeneer                    : 'Placage bois - Wengé',
    wroughtIron                    : 'Fer forgé',
    zinc                           : 'Zinc',
};
export default materialNames;
