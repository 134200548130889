export const typographyStyle = {
    '& svg': {
        height       : '1.5em',
        width        : '1.5em',
        marginRight  : '0.4em',
        marginBottom : '-0.5em',
        fontSize     : '1.2em',
    },
};

export default typographyStyle;
